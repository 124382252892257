import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { API_URL } from '../../../constants/Config';
import { DROITS } from '../../../constants/Properties';

import { connect } from 'react-redux';
import { getIntervenantByEmail } from '../../../store/modules/auth';
import { addIntervenant, addIntervenantExistant } from '../../../store/modules/enfants';

import Modal from 'react-modal';
import modalDefaultClass from '../../../utils/ModalDefaultClass';

import { Form, Field } from 'react-final-form';

import { map, find, orderBy, filter, toLower, values, includes, omit } from 'lodash';
import { getProfilImgMin } from '../../../utils/methodes';

import Select from 'react-select';
import AvatarMask from '../../../utils/AvatarMask';
import AvatarOutline from '../../../utils/AvatarOutline';

const ModalAddMembreEquipe = ({
  enfant,
  user,
  className,
  getIntervenantByEmail,
  addIntervenantExistant,
  addIntervenant,
  roles,
  types_profils
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [checkMode, setCheckMode] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [userCreated, setUserCreated] = useState(null);
  const [createEtape, setCreateEtape] = useState(1);
  const [error, setError] = useState(null);
  const [optionsProfil, setOptionsProfil] = useState([]);

  const required = value => (value ? undefined : 'Champ requis !');

  useEffect(() => {
    // si on est de type famille
    if (user.profil === 'FAMILLE') {
      setOptionsProfil(
        map(omit(types_profils, ['STRUCTURE', 'FAMILLE', 'FAMILLE_STRUCTURE']), (item, key) => {
          return { value: key, label: item };
        })
      );
    } else {
      // si on est de type structure
      if (user.profil === 'STRUCTURE') {
        setOptionsProfil(
          map(omit(types_profils, ['STRUCTURE', 'FAMILLE']), (item, key) => {
            return { value: key, label: item };
          })
        );
      } else {
        //  on est jamais dans le cas en dessous
        setOptionsProfil(
          map(types_profils, (item, key) => {
            return { value: key, label: item };
          })
        );
      }
    }
  }, []);

  const optionsDroits = map(DROITS, (item, key) => {
    return { value: key, label: item };
  });

  const checkEmailSubmit = data => {
    //#6533 il faut vérifier que le mail n'appartient pas un intervenant deja existant pour cet enfant !
    setEmail(toLower(data.email));
    return getIntervenantByEmail(toLower(data.email)).then(res => {
      if (res.response && res.response.user) {
        // on regarde si le user intervenant a dans soess enfants l'id de l'enfant a rajouter :
        if (!includes(res.response.user.enfants, enfant.enfant_id)) {
          setCheckMode(false);
          setUserExist(res.response.user);
        } else {
          // l'intervenant est deja présent pour cet enfant
          setError('Intervenant déjà présent pour cet enfant');
        }
      } else {
        setCheckMode(false);
        setUserExist(false);
      }
    });
  };

  const closeModal = () => {
    setEmail('');
    setCheckMode(false);
    setUserExist(false);
    setIsOpen(false);
    setUserCreated(null);
    setCreateEtape(1);
  };

  const submitUserInfos = data => {
    setUserCreated({ ...data, email });

    setCreateEtape(createEtape + 1);
  };

  const submitIntervenant = values => {
    let data = {};
    data.droits = {
      dossier_prise_charge: values.dossier_prise_charge,
      medias: values.medias,
      pointage_intervenant: values.pointage_intervenant,
      programmes: values.programmes,
      comptes_rendus: values.comptes_rendus
    };
    data.parrain = {
      user_id: user.user_id,
      email: user.email,
      prenom: user.prenom,
      nom: user.nom,
      profil: user.profil
    };
    data.enfant = enfant;

    if (userExist) {
      data.intervenantId = userExist.user_id;

      return addIntervenantExistant(data)
        .then(() => {
          closeModal();
        })
        .catch(err => setError(err));
    } else {
      data.newUser = userCreated;
      return addIntervenant(data)
        .then(() => {
          closeModal();
        })
        .catch(err => setError(err));
    }
  };
  return (
    <Fragment>
      <div className="btn-group center bottom-sticky">
        <button
          onClick={() => {
            setCheckMode(true);
            setIsOpen(true);
          }}
          className={`${(className && className) || 'btn btn-primary'} `}
        >
          <i className="fas fa-plus-circle" />
          <span>Ajouter</span>
        </button>
      </div>
      <Modal {...modalDefaultClass} id="modal-check" isOpen={isOpen}>
        <header className="header">
          <div className="modal-title">Ajouter un membre de l'équipe</div>
          <div className="header-bkg">
            <img className="oiseau" src={require(`../../../../assets/images/oiseau.svg`)} />
            <img className="hexa" src={require(`../../../../assets/images/hexa-orange.svg`)} />
            <svg height="0px" width="0px">
              <defs>
                <clipPath
                  id="menumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01666666666)"
                >
                  <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </header>

        {checkMode && (
          <Fragment>
            <Form
              onSubmit={checkEmailSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="highlight-block">
                      <div className="highlight-block-message">
                        <i className="highlight-block-message-icon fal fa-info-circle" />
                        <div className="highlight-block-message-text">
                          Vérifions si cette personne possède déja un compte sur l'application
                          Origin'ailes
                        </div>
                      </div>
                      <div className="highlight-block-icon">
                        <i className="fas fa-info-circle" />
                      </div>
                    </div>
                    <Field name="email" validate={required} component="input" className="field">
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="email" autoCapitalize="none" />
                          <label>Adresse e-mail *</label>
                        </div>
                      )}
                    </Field>
                    {error && (
                      <div className="error-block">
                        <span className="error-icon">
                          <i className="fas fa-exclamation" />
                        </span>
                        <div className="error-message">{error}</div>
                      </div>
                    )}

                    <div className="btn-group center">
                      <button type="submit" className="btn btn-primary">
                        Vérifier
                      </button>
                    </div>
                  </div>
                  <div className="modal-footer bottom-sticky">
                    <div className="btn-group center">
                      <button className="btn btn-tertiary" onClick={() => closeModal()}>
                        Annuler
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </Fragment>
        )}

        {/* CONTENU FORM SI L'INTERVENANT EXISTE PAS  */}
        {!userExist && !checkMode && (
          <Fragment>
            {createEtape === 1 ? (
              <Form
                onSubmit={submitUserInfos}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-body">
                      <div className="highlight-block">
                        <div className="highlight-block-message">
                          <i className="highlight-block-message-icon fal fa-info-circle" />
                          <div className="highlight-block-message-text">
                            Cette personne ne possède pas de compte
                          </div>
                        </div>
                        <div className="highlight-block-icon">
                          <i className="fas fa-info-circle" />
                        </div>
                      </div>
                      <div className="field">
                        <input disabled={true} type="text" value={email} />
                        <label>Adresse e-mail </label>
                      </div>

                      <div className="step-title-block">
                        <div className="separator" />
                        <div className="step-title">
                          <div className="step-title-icon">
                            <div className="step-title-icon-text">
                              <span>1</span>
                              <span>/</span>
                              <span>2</span>
                            </div>
                            <img src={require(`../../../../assets/images/step-title-bkg.svg`)} />
                          </div>
                          <div className="step-title-text">Ses informations</div>
                        </div>
                        <div className="separator" />
                      </div>
                      <Field name="profil" component="select" validate={required}>
                        {({ input, meta }) => {
                          return (
                            <div className="field">
                              {meta.error && meta.touched && (
                                <div className="field-error">{meta.error}</div>
                              )}
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Sélectionner un profil"
                                options={orderBy(optionsProfil, ['label'], ['asc'])}
                                onChange={option => {
                                  input.onChange(option.value);
                                }}
                                value={find(optionsProfil, o => o.value === input.value)}
                              />
                              <label>Profil *</label>
                            </div>
                          );
                        }}
                      </Field>
                      <Field name="prenom" component="input" validate={required}>
                        {({ input, meta }) => {
                          return (
                            <div className="field">
                              {meta.error && meta.touched && (
                                <div className="field-error">{meta.error}</div>
                              )}
                              <input {...input} type="text" />
                              <label>Prénom *</label>
                            </div>
                          );
                        }}
                      </Field>
                      <Field name="nom" component="input" validate={required}>
                        {({ input, meta }) => {
                          return (
                            <div className="field">
                              {meta.error && meta.touched && (
                                <div className="field-error">{meta.error}</div>
                              )}
                              <input {...input} type="text" />
                              <label>Nom *</label>
                            </div>
                          );
                        }}
                      </Field>
                      {error && (
                        <div className="error-block">
                          <span className="error-icon">
                            <i className="fas fa-exclamation" />
                          </span>
                          <div className="error-message">{error}</div>
                        </div>
                      )}
                    </div>
                    <div className="modal-footer">
                      <div className="btn-group center">
                        <button className="btn btn-tertiary" onClick={() => closeModal()}>
                          Annuler
                        </button>
                        <button
                          type="submit"
                          disabled={!values.profil || !values.nom || !values.prenom}
                          className="btn btn-primary"
                        >
                          Suivant
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              />
            ) : (
              <Fragment>
                {userCreated && (
                  <Form
                    onSubmit={submitIntervenant}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                          <div className="field">
                            <input disabled={true} type="text" value={email} />
                            <label>Adresse e-mail </label>
                          </div>

                          <div className="step-title-block">
                            <div className="separator" />
                            <div className="step-title">
                              <div className="step-title-icon">
                                <div className="step-title-icon-text">
                                  <span>2</span>
                                  <span>/</span>
                                  <span>2</span>
                                </div>
                                <img
                                  src={require(`../../../../assets/images/step-title-bkg.svg`)}
                                />
                              </div>
                              <div className="step-title-text">Ses droits</div>
                            </div>
                            <div className="separator" />
                          </div>
                          <Field
                            name="pointage_intervenant"
                            component="select"
                            validate={required}
                            initialValue={
                              roles &&
                              userCreated.profil &&
                              roles[userCreated.profil]['pointage_intervenant'] &&
                              roles[userCreated.profil]['pointage_intervenant'].default[0]
                            }
                          >
                            {({ input, meta }) => {
                              return (
                                <div className="field">
                                  {meta.error && meta.touched && (
                                    <div className="field-error">{meta.error}</div>
                                  )}
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    options={map(
                                      roles &&
                                        userCreated.profil &&
                                        roles[userCreated.profil]['pointage_intervenant'] &&
                                        roles[userCreated.profil]['pointage_intervenant'].droits,
                                      droit => {
                                        return { label: DROITS[droit], value: droit };
                                      }
                                    )}
                                    onChange={option => {
                                      input.onChange(option.value);
                                    }}
                                    value={find(optionsDroits, o => o.value === input.value)}
                                  />
                                  <label>Pointages</label>
                                </div>
                              );
                            }}
                          </Field>
                          <Field
                            name="programmes"
                            component="select"
                            validate={required}
                            initialValue={
                              roles &&
                              userCreated.profil &&
                              roles[userCreated.profil]['programmes'] &&
                              roles[userCreated.profil]['programmes'].default[0]
                            }
                          >
                            {({ input, meta }) => {
                              return (
                                <div className="field">
                                  {meta.error && meta.touched && (
                                    <div className="field-error">{meta.error}</div>
                                  )}
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    options={map(
                                      roles &&
                                        userCreated.profil &&
                                        roles[userCreated.profil]['programmes'] &&
                                        roles[userCreated.profil]['programmes'].droits,
                                      droit => {
                                        return { label: DROITS[droit], value: droit };
                                      }
                                    )}
                                    onChange={option => {
                                      input.onChange(option.value);
                                    }}
                                    value={find(optionsDroits, o => o.value === input.value)}
                                  />
                                  <label>Programmes</label>
                                </div>
                              );
                            }}
                          </Field>
                          <Field
                            name="comptes_rendus"
                            component="select"
                            validate={required}
                            initialValue={
                              roles &&
                              userCreated.profil &&
                              roles[userCreated.profil]['comptes_rendus'] &&
                              roles[userCreated.profil]['comptes_rendus'].default[0]
                            }
                          >
                            {({ input, meta }) => {
                              return (
                                <div className="field">
                                  {meta.error && meta.touched && (
                                    <div className="field-error">{meta.error}</div>
                                  )}
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    options={map(
                                      roles &&
                                        userCreated.profil &&
                                        roles[userCreated.profil]['comptes_rendus'] &&
                                        roles[userCreated.profil]['comptes_rendus'].droits,
                                      droit => {
                                        return { label: DROITS[droit], value: droit };
                                      }
                                    )}
                                    onChange={option => {
                                      input.onChange(option.value);
                                    }}
                                    value={find(optionsDroits, o => o.value === input.value)}
                                  />
                                  <label>Comptes-rendus</label>
                                </div>
                              );
                            }}
                          </Field>

                          <Field
                            name="dossier_prise_charge"
                            component="select"
                            validate={required}
                            initialValue={
                              roles &&
                              userCreated.profil &&
                              roles[userCreated.profil]['dossier_prise_charge'] &&
                              roles[userCreated.profil]['dossier_prise_charge'].default[0]
                            }
                          >
                            {({ input, meta }) => {
                              return (
                                <div className="field">
                                  {meta.error && meta.touched && (
                                    <div className="field-error">{meta.error}</div>
                                  )}
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    options={map(
                                      roles &&
                                        userCreated.profil &&
                                        roles[userCreated.profil]['dossier_prise_charge'] &&
                                        roles[userCreated.profil]['dossier_prise_charge'].droits,
                                      droit => {
                                        return { label: DROITS[droit], value: droit };
                                      }
                                    )}
                                    onChange={option => {
                                      input.onChange(option.value);
                                    }}
                                    value={find(optionsDroits, o => o.value === input.value)}
                                  />
                                  <label>Dossier de prise en charge</label>
                                </div>
                              );
                            }}
                          </Field>
                          <Field
                            name="medias"
                            component="select"
                            validate={required}
                            initialValue={
                              roles &&
                              userCreated.profil &&
                              roles[userCreated.profil]['medias'] &&
                              roles[userCreated.profil]['medias'].default[0]
                            }
                          >
                            {({ input, meta }) => {
                              return (
                                <div className="field">
                                  {meta.error && meta.touched && (
                                    <div className="field-error">{meta.error}</div>
                                  )}
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    options={map(
                                      roles &&
                                        userCreated.profil &&
                                        roles[userCreated.profil]['medias'] &&
                                        roles[userCreated.profil]['medias'].droits,
                                      droit => {
                                        return { label: DROITS[droit], value: droit };
                                      }
                                    )}
                                    onChange={option => {
                                      input.onChange(option.value);
                                    }}
                                    value={find(optionsDroits, o => o.value === input.value)}
                                  />
                                  <label>Médias</label>
                                </div>
                              );
                            }}
                          </Field>
                          {error && (
                            <div className="error-block">
                              <span className="error-icon">
                                <i className="fas fa-exclamation" />
                              </span>
                              <div className="error-message">{error}</div>
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <div className="btn-group center">
                            <button className="btn btn-tertiary" onClick={() => setCreateEtape(1)}>
                              Annuler
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Suivant
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        )}

        {/* CONTENU FORM SI L'INTERVENANT EXIST DEJA  */}

        {userExist && !checkMode && (
          <Fragment>
            {createEtape === 1 ? (
              <Fragment>
                <div className="modal-body">
                  <div className="step-title-block">
                    <div className="step-title">
                      <div className="step-title-icon">
                        <div className="step-title-icon-text">
                          <span>1</span>
                          <span>/</span>
                          <span>2</span>
                        </div>
                        <img src={require(`../../../../assets/images/step-title-bkg.svg`)} />
                      </div>
                      <div className="step-title-text">Ses informations</div>
                    </div>
                  </div>
                  <div className="separator" />
                  <div className="member">
                    {userExist && userExist.photo ? (
                      <div className="member-avatar">
                        <div className="avatar-mask">
                          <img src={getProfilImgMin(userExist.photo)} alt="photo" />
                        </div>
                        <AvatarMask nameFile="ModalAddMembreEquipe" />
                        <AvatarOutline nameFile="ModalAddMembreEquipe" />
                      </div>
                    ) : (
                      <div className="member-avatar">
                        <span>
                          {userExist.prenom[0]}
                          {userExist.nom[0]}
                        </span>
                        <AvatarOutline nameFile="ModalAddMembreEquipe" bigger />
                      </div>
                    )}
                    <div className="member-infos">
                      <div className="member-name">
                        {userExist && userExist.prenom} {userExist && userExist.nom}
                      </div>
                      <div className="member-profil">
                        {userExist && `${types_profils[userExist.profil]}`}
                      </div>
                    </div>
                  </div>
                  <div className="separator" />
                  {userExist && userExist.tel1 && (
                    <a className="info-value" href={`tel:${userExist.tel1}`} target="_blank">
                      <i className="fas fa-phone" />
                      <span>{userExist.tel1}</span>
                    </a>
                  )}

                  <a className="info-value" href={`mailto:${userExist.email}`} target="_blank">
                    <i className="fas fa-envelope" />
                    <span>{userExist.email}</span>
                  </a>

                  {userExist && userExist.adresse1 && (
                    <div className="info-value">
                      <i className="fas fa-map-marker-alt" />
                      <span>{userExist.adresse1}</span>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <div className="btn-group center">
                    <button className="btn btn-tertiary" onClick={() => closeModal()}>
                      Annuler
                    </button>
                    <button
                      onClick={() => setCreateEtape(createEtape + 1)}
                      className="btn btn-primary"
                    >
                      Suivant
                    </button>
                  </div>
                </div>
              </Fragment>
            ) : (
              userExist && (
                <Form
                  onSubmit={submitIntervenant}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="modal-body">
                        <div className="step-title-block">
                          <div className="step-title">
                            <div className="step-title-icon">
                              <div className="step-title-icon-text">
                                <span>2</span>
                                <span>/</span>
                                <span>2</span>
                              </div>
                              <img src={require(`../../../../assets/images/step-title-bkg.svg`)} />
                            </div>
                            <div className="step-title-text">Ses droits</div>
                          </div>
                        </div>
                        <div className="separator" />
                        <div className="member">
                          {userExist && userExist.photo ? (
                            <div className="member-avatar">
                              <div className="avatar-mask">
                                <img src={getProfilImgMin(userExist.photo)} alt="photo" />
                              </div>
                              <AvatarMask nameFile="ModalAddMembreEquipe" />
                              <AvatarOutline nameFile="ModalAddMembreEquipe" />
                            </div>
                          ) : (
                            <div className="member-avatar">
                              <span>
                                {userExist.prenom[0]}
                                {userExist.nom[0]}
                              </span>
                              <AvatarOutline nameFile="ModalAddMembreEquipe" bigger />
                            </div>
                          )}
                          <div className="member-infos">
                            <div className="member-name">
                              {userExist && userExist.prenom} {userExist && userExist.nom}
                            </div>
                            <div className="member-profil">
                              {userExist && `${types_profils[userExist.profil]}`}
                            </div>
                          </div>
                        </div>
                        <div className="separator" />
                        <Field
                          name="pointage_intervenant"
                          component="select"
                          validate={required}
                          initialValue={
                            roles &&
                            userExist.profil &&
                            roles[userExist.profil]['pointage_intervenant'] &&
                            roles[userExist.profil]['pointage_intervenant'].default[0]
                          }
                        >
                          {({ input, meta }) => {
                            return (
                              <div className="field">
                                {meta.error && meta.touched && (
                                  <div className="field-error">{meta.error}</div>
                                )}
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  options={map(
                                    roles &&
                                      userExist.profil &&
                                      roles[userExist.profil]['pointage_intervenant'] &&
                                      roles[userExist.profil]['pointage_intervenant'].droits,
                                    droit => {
                                      return { label: DROITS[droit], value: droit };
                                    }
                                  )}
                                  onChange={option => {
                                    input.onChange(option.value);
                                  }}
                                  value={find(optionsDroits, o => o.value === input.value)}
                                />
                                <label>Pointages</label>
                              </div>
                            );
                          }}
                        </Field>
                        <Field
                          name="programmes"
                          component="select"
                          validate={required}
                          initialValue={
                            roles &&
                            userExist.profil &&
                            roles[userExist.profil]['programmes'] &&
                            roles[userExist.profil]['programmes'].default[0]
                          }
                        >
                          {({ input, meta }) => {
                            return (
                              <div className="field">
                                {meta.error && meta.touched && (
                                  <div className="field-error">{meta.error}</div>
                                )}
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  options={map(
                                    roles &&
                                      userExist.profil &&
                                      roles[userExist.profil]['programmes'] &&
                                      roles[userExist.profil]['programmes'].droits,
                                    droit => {
                                      return { label: DROITS[droit], value: droit };
                                    }
                                  )}
                                  onChange={option => {
                                    input.onChange(option.value);
                                  }}
                                  value={find(optionsDroits, o => o.value === input.value)}
                                />
                                <label>Programmes</label>
                              </div>
                            );
                          }}
                        </Field>
                        <Field
                          name="comptes_rendus"
                          component="select"
                          validate={required}
                          initialValue={
                            roles &&
                            userExist.profil &&
                            roles[userExist.profil]['comptes_rendus'] &&
                            roles[userExist.profil]['comptes_rendus'].default[0]
                          }
                        >
                          {({ input, meta }) => {
                            return (
                              <div className="field">
                                {meta.error && meta.touched && (
                                  <div className="field-error">{meta.error}</div>
                                )}
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  options={map(
                                    roles &&
                                      userExist.profil &&
                                      roles[userExist.profil]['comptes_rendus'] &&
                                      roles[userExist.profil]['comptes_rendus'].droits,
                                    droit => {
                                      return { label: DROITS[droit], value: droit };
                                    }
                                  )}
                                  onChange={option => {
                                    input.onChange(option.value);
                                  }}
                                  value={find(optionsDroits, o => o.value === input.value)}
                                />
                                <label>Comptes-rendus</label>
                              </div>
                            );
                          }}
                        </Field>

                        <Field
                          name="dossier_prise_charge"
                          component="select"
                          validate={required}
                          initialValue={
                            roles &&
                            userExist.profil &&
                            roles[userExist.profil]['dossier_prise_charge'] &&
                            roles[userExist.profil]['dossier_prise_charge'].default[0]
                          }
                        >
                          {({ input, meta }) => {
                            return (
                              <div className="field">
                                {meta.error && meta.touched && (
                                  <div className="field-error">{meta.error}</div>
                                )}
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  options={map(
                                    roles &&
                                      userExist.profil &&
                                      roles[userExist.profil]['dossier_prise_charge'] &&
                                      roles[userExist.profil]['dossier_prise_charge'].droits,
                                    droit => {
                                      return { label: DROITS[droit], value: droit };
                                    }
                                  )}
                                  onChange={option => {
                                    input.onChange(option.value);
                                  }}
                                  value={find(optionsDroits, o => o.value === input.value)}
                                />
                                <label>Dossier de prise en charge</label>
                              </div>
                            );
                          }}
                        </Field>
                        <Field
                          name="medias"
                          component="select"
                          validate={required}
                          initialValue={
                            roles &&
                            userExist.profil &&
                            roles[userExist.profil]['medias'] &&
                            roles[userExist.profil]['medias'].default[0]
                          }
                        >
                          {({ input, meta }) => {
                            return (
                              <div className="field">
                                {meta.error && meta.touched && (
                                  <div className="field-error">{meta.error}</div>
                                )}
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  options={map(
                                    roles &&
                                      userExist.profil &&
                                      roles[userExist.profil]['medias'] &&
                                      roles[userExist.profil]['medias'].droits,
                                    droit => {
                                      return { label: DROITS[droit], value: droit };
                                    }
                                  )}
                                  onChange={option => {
                                    input.onChange(option.value);
                                  }}
                                  value={find(optionsDroits, o => o.value === input.value)}
                                />
                                <label>Médias</label>
                              </div>
                            );
                          }}
                        </Field>
                        {error && (
                          <div className="error-block">
                            <span className="error-icon">
                              <i className="fas fa-exclamation" />
                            </span>
                            <div className="error-message">{error}</div>
                          </div>
                        )}
                      </div>
                      <div className="modal-footer">
                        <div className="btn-group center">
                          <button className="btn btn-primary" onClick={() => closeModal()}>
                            Annuler
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Valider
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                />
              )
            )}
          </Fragment>
        )}
      </Modal>
    </Fragment>
  );
};

ModalAddMembreEquipe.propTypes = {
  getIntervenantByEmail: PropTypes.func.isRequired,
  addIntervenant: PropTypes.func.isRequired,
  addIntervenantExistant: PropTypes.func.isRequired,
  enfant: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  types_profils:
    state.data.entities.settings &&
    state.data.entities.settings['types_profils'] &&
    state.data.entities.settings['types_profils'].data,
  roles:
    state.data.entities.settings &&
    state.data.entities.settings['roles_defaut'] &&
    state.data.entities.settings['roles_defaut'].data
});

export default connect(mapStateToProps, {
  addIntervenantExistant,
  getIntervenantByEmail,
  addIntervenant
})(ModalAddMembreEquipe);
