import React from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';

const ButtonsSelector = ({ buttons, onClickButton, selected }) => {
  return (
    <div className="subheader tabs-wrapper">
      <div className="tabs tabs-noWrap">
        {map(buttons, (button, index) => (
          <button
            onClick={() => onClickButton(button.value)}
            key={index}
            className={`tab ${selected === button.value ? 'active' : ''}`}
          >
            {button.label}
          </button>
        ))}
      </div>

      <div className="subheader-bkg">
        <svg width="0" height="0">
          <defs>
            <clipPath
              id="submenumask"
              clipPathUnits="objectBoundingBox"
              transform="scale(0.00266666666,0.01428571428)"
            >
              <path
                d="M361.2,70L23.8,64.3c-8.6-0.2-17-3-23.8-8.3V0h375v67.9
                c-4.2,1.5-8.6,2.1-13.1,2.1H361.2z"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

ButtonsSelector.propTypes = {
  selected: PropTypes.string.isRequired,
  buttons: PropTypes.array.isRequired,
  onClickButton: PropTypes.func.isRequired
};
export default ButtonsSelector;
