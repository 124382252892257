import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { getProfilImgMin } from '../../utils/methodes';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AvatarMask from '../../utils/AvatarMask';
import AvatarOutline from '../../utils/AvatarOutline';

const HeaderPage = ({
  backBtn,
  homeBtn,
  displayTopHeader,
  enfant,
  title,
  subtitle,
  bigTitle,
  isHome,
  withSubmenu,
  isPage,
  user,
  parents,
  headerClass
}) => {
  const navigation = useHistory();

  const parent =
    user &&
    (user.profil !== 'FAMILLE' || user.profil !== 'STRUCTURE') &&
    enfant &&
    parents[enfant.parent_id];

  const renderTextHello = () => {
    if (user && user.profil === 'FAMILLE') {
      return `Bonjour la famille ${user.nom} !`;
    } else {
      if (user && user.profil === 'STRUCTURE') {
        return `Bonjour`;
      } else {
        return `Bonjour ${(user && user.prenom) || ''}`;
      }
    }
  };

  return (
    <Fragment>
      {displayTopHeader && (
        <div className="nav-banner">
          <span className="nav-banner-title">{renderTextHello()}</span>
          <div className="btn-group">
            <NavLink className="btn" to="/">
              <i className="fal fa-home-lg" />
            </NavLink>
            <div className="btn" onClick={() => navigation.push('/mon-compte')}>
              <i className="fal fa-cog" />
            </div>
          </div>
        </div>
      )}

      <header
        className={`header${withSubmenu ? ' with-submenu' : ''}${isHome ? ' header-home' : ''}${
          bigTitle ? ' header-big-title' : ''
        }${headerClass ? ' ' + headerClass : ''}`}
      >
        <div className="header-container">
          {isHome && (
            <div className={`header-center`}>
              <div className="profil-infos">
                <div className="child-profil">
                  <div className="child-avatar-block">
                    <div className="avatar-mask-big">
                      {enfant && enfant.profil && enfant.profil.photo ? (
                        <img
                          src={
                            enfant &&
                            enfant.profil &&
                            enfant.profil.photo &&
                            getProfilImgMin(enfant.profil.photo)
                          }
                        />
                      ) : (
                        <i className="fas fa-user" />
                      )}
                    </div>
                    <AvatarMask nameFile="HeaderPage" />
                    <AvatarOutline nameFile="HeaderPage" />
                  </div>

                  <div className="child-infos">
                    <div className="child-identity">
                      <span className="child-firstname">{enfant && enfant.profil.prenom}</span>
                      {(user.profil !== 'FAMILLE' || user.profil !== 'STRUCTURE') && (
                        <span className="child-lastname">{enfant && enfant.profil.nom}</span>
                      )}
                      {enfant && (
                        <NavLink to={`/enfant/${enfant.enfant_id}`}>
                          <i className="fas fa-info" />
                        </NavLink>
                      )}
                    </div>
                    {user.profil !== 'FAMILLE' && enfant && enfant.profil && (
                      <a
                        className="btn-emergency"
                        href={`tel:${enfant && enfant.profil && enfant.profil.urgenceTel}`}
                      >
                        <i className="fal fa-phone" />
                        <div>
                          <div>Appel d'urgence</div>
                          <div>{(enfant && enfant.profil && enfant.profil.urgenceNom) || ''}</div>
                        </div>
                        <span className="icon-bkg">
                          <i className="fas fa-phone" />
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isPage && (
            <Fragment>
              {homeBtn && (
                <span className="btn" onClick={() => navigation.push('/dashboard')}>
                  <i style={{ color: 'white' }} className="fal fa-id-card" />
                </span>
              )}

              {backBtn && (
                <span
                  className="btn"
                  onClick={() => {
                    if (backBtn === 'GO_HOME') {
                      return navigation.push('/dashboard');
                    } else {
                      return navigation.goBack();
                    }
                  }}
                >
                  <i
                    style={{ color: 'white' }}
                    className={`far ${bigTitle ? 'fa-long-arrow-left' : 'fa-chevron-left'}`}
                  />
                  {bigTitle && <span>retour</span>}
                </span>
              )}
              <div className="header-center">
                <div className="header-title">{title}</div>
                {subtitle && <div className="header-subtitle">{subtitle}</div>}
              </div>
            </Fragment>
          )}
        </div>
        <div className="header-bkg">
          <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
          <img className="hexa" src={require(`../../../assets/images/hexa-orange.svg`)} />
          <svg height="0px" width="0px">
            <defs>
              <clipPath
                id="menumask"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.00266666666,0.01666666666)"
              >
                <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </header>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  parents: state.data.entities.parents
});

export default connect(mapStateToProps, {})(HeaderPage);
