import { flow, set } from 'lodash/fp';

import { CALL_API, Schemas } from '../Schemas';

const STRIPE_SUBSCRIPTION_REQUEST = 'origiwa/STRIPE_SUBSCRIPTION_REQUEST';
const STRIPE_SUBSCRIPTION_SUCCESS = 'origiwa/STRIPE_SUBSCRIPTION_SUCCESS';
const STRIPE_SUBSCRIPTION_FAILURE = 'origiwa/STRIPE_SUBSCRIPTION_FAILURE';

const STRIPE_RESUBSCRIPTION_REQUEST = 'origiwa/STRIPE_RESUBSCRIPTION_REQUEST';
const STRIPE_RESUBSCRIPTION_SUCCESS = 'origiwa/STRIPE_RESUBSCRIPTION_SUCCESS';
const STRIPE_RESUBSCRIPTION_FAILURE = 'origiwa/STRIPE_RESUBSCRIPTION_FAILURE';

const STRIPE_UPDATECARD_REQUEST = 'origiwa/STRIPE_UPDATECARD_REQUEST';
const STRIPE_UPDATECARD_SUCCESS = 'origiwa/STRIPE_UPDATECARD_SUCCESS';
const STRIPE_UPDATECARD_FAILURE = 'origiwa/STRIPE_UPDATECARD_FAILURE';

const STRIPE_GETSECRET_REQUEST = 'origiwa/STRIPE_GETSECRET_REQUEST';
const STRIPE_GETSECRET_SUCCESS = 'origiwa/STRIPE_GETSECRET_SUCCESS';
const STRIPE_GETSECRET_FAILURE = 'origiwa/STRIPE_GETSECRET_FAILURE';

const STRIPE_ATTACH_DEFAULT_PAYMENT_METHOD_REQUEST =
  'origiwa/STRIPE_ATTACH_DEFAULT_PAYMENT_METHOD_REQUEST';
const STRIPE_ATTACH_DEFAULT_PAYMENT_METHOD_SUCCESS =
  'origiwa/STRIPE_ATTACH_DEFAULT_PAYMENT_METHOD_SUCCESS';
const STRIPE_ATTACH_DEFAULT_PAYMENT_METHOD_FAILURE =
  'origiwa/STRIPE_ATTACH_DEFAULT_PAYMENT_METHOD_FAILURE';

const STRIPE_CHECKPROMOCODE_REQUEST = 'origiwa/STRIPE_CHECKPROMOCODE_REQUEST';
const STRIPE_CHECKPROMOCODE_SUCCESS = 'origiwa/STRIPE_CHECKPROMOCODE_SUCCESS';
const STRIPE_CHECKPROMOCODE_FAILURE = 'origiwa/STRIPE_CHECKPROMOCODE_FAILURE';

const STRIPE_CREATECUSTOMER_REQUEST = 'origiwa/STRIPE_CREATECUSTOMER_REQUEST';
const STRIPE_CREATECUSTOMER_SUCCESS = 'origiwa/STRIPE_CREATECUSTOMER_SUCCESS';
const STRIPE_CREATECUSTOMER_FAILURE = 'origiwa/STRIPE_CREATECUSTOMER_FAILURE';

export const stripeActionsHandlers = {
  [STRIPE_SUBSCRIPTION_REQUEST]: state =>
    flow(set('loaded.stripe', false), set('loading.stripe', true))(state),
  [STRIPE_SUBSCRIPTION_SUCCESS]: state =>
    flow(set('loaded.stripe', true), set('loading.stripe', false))(state),
  [STRIPE_SUBSCRIPTION_FAILURE]: state =>
    flow(set('loaded.stripe', false), set('loading.stripe', false))(state),
  [STRIPE_RESUBSCRIPTION_REQUEST]: state =>
    flow(set('loaded.stripe', false), set('loading.stripe', true))(state),
  [STRIPE_RESUBSCRIPTION_SUCCESS]: state =>
    flow(set('loaded.stripe', true), set('loading.stripe', false))(state),
  [STRIPE_RESUBSCRIPTION_FAILURE]: state =>
    flow(set('loaded.stripe', false), set('loading.stripe', false))(state),
  [STRIPE_UPDATECARD_REQUEST]: state =>
    flow(set('loaded.stripe', false), set('loading.stripe', true))(state),
  [STRIPE_UPDATECARD_SUCCESS]: state =>
    flow(set('loaded.stripe', true), set('loading.stripe', false))(state),
  [STRIPE_UPDATECARD_FAILURE]: state =>
    flow(set('loaded.stripe', false), set('loading.stripe', false))(state)
};

export function subscription(data) {
  return {
    [CALL_API]: {
      types: [
        STRIPE_SUBSCRIPTION_REQUEST,
        STRIPE_SUBSCRIPTION_SUCCESS,
        STRIPE_SUBSCRIPTION_FAILURE
      ],
      method: 'POST',
      endpoint: `/stripe/subscription`,
      body: data,
      successMessage: 'Stripe subscription'
    }
  };
}

export function subscriptionSepa(data) {
  return {
    [CALL_API]: {
      types: [
        STRIPE_SUBSCRIPTION_REQUEST,
        STRIPE_SUBSCRIPTION_SUCCESS,
        STRIPE_SUBSCRIPTION_FAILURE
      ],
      method: 'POST',
      endpoint: `/stripe/subscriptionSepa`,
      body: data,
      successMessage: 'Stripe subscription SEPA'
    }
  };
}

export function resubscription(data) {
  return {
    [CALL_API]: {
      types: [
        STRIPE_RESUBSCRIPTION_REQUEST,
        STRIPE_RESUBSCRIPTION_SUCCESS,
        STRIPE_RESUBSCRIPTION_FAILURE
      ],
      method: 'POST',
      endpoint: `/stripe/resubscription`,
      body: data,
      successMessage: 'Stripe resubscription'
    }
  };
}

export function resubscriptionStructure(data) {
  return {
    [CALL_API]: {
      types: [
        STRIPE_RESUBSCRIPTION_REQUEST,
        STRIPE_RESUBSCRIPTION_SUCCESS,
        STRIPE_RESUBSCRIPTION_FAILURE
      ],
      method: 'POST',
      endpoint: `/stripe/resubscriptionStructure`,
      body: data,
      successMessage: 'Stripe resubscription structure'
    }
  };
}

export function updateCard(customerId, paymentMethod) {
  return {
    [CALL_API]: {
      types: [STRIPE_UPDATECARD_REQUEST, STRIPE_UPDATECARD_SUCCESS, STRIPE_UPDATECARD_FAILURE],
      method: 'POST',
      endpoint: `/stripe/update_card`,
      body: { customerId, paymentMethod },
      successMessage: 'Stripe update_card'
    }
  };
}

export function getSecretSepa(customerId) {
  return {
    [CALL_API]: {
      types: [STRIPE_GETSECRET_REQUEST, STRIPE_GETSECRET_SUCCESS, STRIPE_GETSECRET_FAILURE],
      method: 'GET',
      endpoint: `/stripe/secret-sepa/${customerId}`,
      successMessage: 'Stripe client_secret SEPA'
    }
  };
}

export function getSecretCard(customerId) {
  return {
    [CALL_API]: {
      types: [STRIPE_GETSECRET_REQUEST, STRIPE_GETSECRET_SUCCESS, STRIPE_GETSECRET_FAILURE],
      method: 'GET',
      endpoint: `/stripe/secret-card/${customerId}`,
      successMessage: 'Stripe client_secret CARD'
    }
  };
}

export function attachDefaultPaymentMethod(customerId, paymentMethod) {
  return {
    [CALL_API]: {
      types: [
        STRIPE_ATTACH_DEFAULT_PAYMENT_METHOD_REQUEST,
        STRIPE_ATTACH_DEFAULT_PAYMENT_METHOD_SUCCESS,
        STRIPE_ATTACH_DEFAULT_PAYMENT_METHOD_FAILURE
      ],
      method: 'POST',
      endpoint: `/stripe/attachDefaultPaymentMethod`,
      successMessage: 'Stripe attach default payment method',
      body: { customerId, paymentMethod }
    }
  };
}

export function checkPromoCode(code) {
  return {
    [CALL_API]: {
      types: [
        STRIPE_CHECKPROMOCODE_REQUEST,
        STRIPE_CHECKPROMOCODE_SUCCESS,
        STRIPE_CHECKPROMOCODE_FAILURE
      ],
      method: 'POST',
      endpoint: `/stripe/checkPromoCode`,
      successMessage: 'Stripe check_promo_code',
      body: {
        code
      }
    }
  };
}

export function createCustomer(nom, email) {
  return {
    [CALL_API]: {
      types: [
        STRIPE_CREATECUSTOMER_REQUEST,
        STRIPE_CREATECUSTOMER_SUCCESS,
        STRIPE_CREATECUSTOMER_FAILURE
      ],
      method: 'POST',
      endpoint: `/stripe/createCustomer`,
      successMessage: 'Stripe create_customer',
      body: {
        nom,
        email
      }
    }
  };
}
