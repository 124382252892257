import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import HeaderPage from '../../components/shared/HeaderPage';
import BottomNavigation from '../../components/shared/BottomNavigation';

Modal.setAppElement('body');

const BaseLayout = ({
  children,
  displayTopHeader,
  headerClass,
  headerPageTitle,
  headerPageSubtitle,
  bigTitle,
  isHome,
  isPage,
  isLogin,
  backBtn,
  homeBtn,
  withSubmenu,
  classScreen,
  enfant,
  user,
  noHeader,
  noFooter
}) => {
  // Fix min/max height for webkit mobile browser
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  const closeModal = () => {
    document.getElementById('notifications').style.display = 'none';
  }
  const updateApp = () => {
    window.location.reload();
  }



  return (
    <div id="wrapper" className={classScreen}>
      {process.env.NODE_ENV === 'staging' && (
        <div id="staging-banner">
          <i className="fas fa-exclamation-triangle" />
          Environnement de recette
          <i className="fas fa-exclamation-triangle" />
        </div>
      )}

      {/* Modal qui s'affiche si il y a une nouvelle update */}
      <div id="notifications">
        <div className="updateModal">
          <button className="update-close" onClick={() => closeModal()}>✖</button>
          <img src="https://cdn-icons-png.flaticon.com/512/1688/1688988.png" alt='updateImage'/>
          <p>Une nouvelle version de l'application est disponible.</p>
          <button className="update-accept" onClick={() => updateApp()}>Mettre à Jour</button>
        </div>
      </div>


      {!noHeader && (
        <HeaderPage
          displayTopHeader={displayTopHeader}
          user={user}
          enfant={enfant}
          isHome={isHome}
          isPage={isPage}
          isLogin={isLogin}
          backBtn={backBtn}
          homeBtn={homeBtn}
          withSubmenu={withSubmenu}
          title={headerPageTitle}
          subtitle={headerPageSubtitle}
          bigTitle={bigTitle}
          headerClass={headerClass}
        />
      )}

      <main id="_main">
        {children}

        {!noFooter && <BottomNavigation />}
      </main>
    </div>
  );
};
BaseLayout.propTypes = {
  children: PropTypes.node
};

export default connect(state => ({ account: state.auth.user }))(BaseLayout);
