import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';

const BottomNavigation = ({ enfants }) => {
  return (
    <div className="bottom-navigation-wrapper">
      <div className="bottom-navigation">
        {enfants && !isEmpty(enfants) && (
          <Fragment>
            <NavLink to="/dashboard" className="item-navigation">
              <i className="fal fa-id-card" />
              <span>Profil</span>
            </NavLink>
            <NavLink to="/actus" className="item-navigation">
              <i className="fal fa-comments-alt" />
              <span>Fil d'actus</span>
            </NavLink>
            <NavLink to="/equipe" className="item-navigation">
              <i className="fal fa-address-book" />
              <span>L'Équipe</span>
            </NavLink>
          </Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  enfants: state.data.entities.enfants
});

export default connect(mapStateToProps, {})(BottomNavigation);
