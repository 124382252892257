import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, NavLink, useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
import { API_URL } from '../../constants/Config';
import { TYPES_DOCUMENTS, types_profils } from '../../constants/Properties';
import { getProfilImgMin, isDroitUser, isIOSDevice } from '../../utils/methodes';
import { getDocumentMedia, deleteDocumentMedia } from '../../store/modules/documentsMedias';
import ModalConfirmDelete from '../../components/modals/ModalConfirmDelete';
import AvatarMask from '../../utils/AvatarMask';
import AvatarOutline from '../../utils/AvatarOutline';

const DossierPage = ({
  user,
  dossier,
  getDocumentMedia,
  deleteDocumentMedia,
  enfantSelected,
  intervenants,
  startDate,
  endDate,
  types_profils
}) => {
  const [error, setError] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const navigation = useHistory();
  const documentId = useParams();

  useEffect(() => {
    getDocumentMedia(user.profil, user.user_id, enfantSelected.enfant_id, documentId.id);
  }, []);

  const deleteItem = () => {
    return deleteDocumentMedia(dossier)
      .then(() => {
        navigation.goBack();
      })
      .catch(err => {
        setError(err);
      });
  };

  const intervenant = dossier && intervenants[dossier.user_id];

  return (
    <div id="wrapper" className="page-preview">
      <header className="header">
        {(user.profil === 'FAMILLE' ||
          user.profil === 'STRUCTURE' ||
          includes(
            ['ADMINISTRATION', 'MODIFICATION'],
            isDroitUser('dossier_prise_charge', user, enfantSelected)
          )) && (
          <div className="header-right">
            <NavLink
              to={`/dossier-prise-en-charge/editer/${dossier && dossier.document_id}`}
              className="header-btn"
            >
              <i className="fas fa-pencil" />
            </NavLink>

            <div onClick={() => setOpenModalDelete(true)} className="header-btn">
              <i className="fas fa-trash-alt" />
            </div>

            <ModalConfirmDelete
              message={'Êtes-vous sûr de vouloir supprimer ce dossier ?'}
              openModal={openModalDelete}
              closeModal={() => setOpenModalDelete(false)}
              actionDelete={() => deleteItem()}
            />
          </div>
        )}
        <div className="header-bkg">
          <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
          <svg height="0px" width="0px">
            <defs>
              <clipPath
                id="menumask"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.00266666666,0.01666666666)"
              >
                <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </header>
      <main>
        <div className="container">
          <h2>{dossier && dossier.titre}</h2>
          <div className="event-schedule-infos">
            <div className="event-date">
              {dossier && moment(dossier.date).format('dddd D MMMM')}
            </div>
          </div>

          <div className="element-tag">
            {(dossier && TYPES_DOCUMENTS[dossier.type_document]) +
              (dossier && dossier.type_document === 'AUTRE'
                ? ' - ' + (dossier && dossier.libelle_autre)
                : '')}
          </div>
          <div className="separator" />
          {intervenant ? (
            <div className="member">
              {intervenant.photo ? (
                <div className="member-avatar">
                  <div className="avatar-mask">
                    <img src={getProfilImgMin(intervenant.photo)} alt="photo" />
                  </div>
                  <AvatarMask nameFile="DossierPage" />
                  <AvatarOutline nameFile="DossierPage" />
                </div>
              ) : (
                <div className="member-avatar">
                  <span>
                    {intervenant.prenom[0]}
                    {intervenant.nom[0]}
                  </span>
                  <AvatarOutline nameFile="DossierPage" bigger />
                </div>
              )}
              <div className="member-infos">
                <div className="member-name">
                  {intervenant && intervenant.prenom} {intervenant && intervenant.nom}
                </div>
                <div className="member-profil">
                  {intervenant && `${types_profils[intervenant.profil]}`}
                </div>
              </div>
            </div>
          ) : (
            <div className="member">
              <div className="member-infos">
                <div className="member-name">Famille {user.nom}</div>
              </div>
            </div>
          )}
          <div className="separator" />
          {dossier && dossier.file && (
            <a className="attachment" target="_blank" href={`${API_URL}/uploads/${dossier.file}`}>
              <i className="fas fa-file-pdf" />
              <span>{dossier.file}</span>
            </a>
          )}

          {isIOSDevice() && (
            <p>
              Pour revenir sur l'application après avoir consulté le document, balayez l'écran du
              bord gauche vers la droite
            </p>
          )}
        </div>
        <div className="btn-group center bottom-sticky">
          {startDate && endDate ? (
            <Link
              className="btn btn-primary"
              to={{
                pathname: `/dossiers-prise-en-charge`,
                state: { startDate: startDate, endDate: endDate }
              }}
            >
              Fermer
            </Link>
          ) : (
            <Link
              className="btn btn-primary"
              to={{
                pathname: `/dossiers-prise-en-charge`
              }}
            >
              Fermer
            </Link>
          )}
        </div>
      </main>
    </div>
  );
};

DossierPage.propTypes = {
  dossier: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getDocumentMedia: PropTypes.func.isRequired,
  deleteDocumentMedia: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { id }
    }
  } = props;
  const dossier =
    (id && state.data.entities.documentsMedias && state.data.entities.documentsMedias[id]) || null;

  return {
    startDate: props.location && props.location.state && props.location.state.startDate,
    endDate: props.location && props.location.state && props.location.state.endDate,
    user: state.auth.user,
    dossier: dossier,
    intervenants: state.data && state.data.entities && state.data.entities.intervenants,
    enfantSelected: state.auth.enfantSelected,
    types_profils:
      state.data.entities.settings &&
      state.data.entities.settings['types_profils'] &&
      state.data.entities.settings['types_profils'].data
  };
};

export default connect(mapStateToProps, { getDocumentMedia, deleteDocumentMedia })(DossierPage);
