import { flow, set } from 'lodash/fp';
import { find } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';
import { updateAuthUser } from './auth';

const CREATE_USER_SECONDAIRE_FAMILLE_REQUEST = 'ori/CREATE_USER_SECONDAIRE_FAMILLE_REQUEST';
const CREATE_USER_SECONDAIRE_FAMILLE_SUCCESS = 'ori/CREATE_USER_SECONDAIRE_FAMILLE_SUCCESS';
const CREATE_USER_SECONDAIRE_FAMILLE_FAILURE = 'ori/CREATE_USER_SECONDAIRE_FAMILLE_FAILURE';

const GET_USERS_SECONDAIRE_FAMILLE_REQUEST = 'ori/GET_USERS_SECONDAIRE_FAMILLE_REQUEST';
const GET_USERS_SECONDAIRE_FAMILLE_SUCCESS = 'ori/GET_USERS_SECONDAIRE_FAMILLE_SUCCESS';
const GET_USERS_SECONDAIRE_FAMILLE_FAILURE = 'ori/GET_USERS_SECONDAIRE_FAMILLE_FAILURE';

export const usersActionsHandlers = {
  [GET_USERS_SECONDAIRE_FAMILLE_SUCCESS]: (state, action) =>
    flow(
      set('entities.users', action.response.entities.users || {}),
      set('loaded.users', true),
      set('loading.users', false)
    )(state),

  [CREATE_USER_SECONDAIRE_FAMILLE_SUCCESS]: (state, action) => {
    return flow(
      set('entities.users', {
        ...state.entities.users,
        ...action.response.entities.users
      }),
      set('loaded.users', true),
      set('loading.users', false)
    )(state);
  }
};

export function createUserSecondaireFamille(userIdPrincipal, email, pseudo) {
  return {
    userIdPrincipal,
    [CALL_API]: {
      types: [
        CREATE_USER_SECONDAIRE_FAMILLE_REQUEST,
        CREATE_USER_SECONDAIRE_FAMILLE_SUCCESS,
        CREATE_USER_SECONDAIRE_FAMILLE_FAILURE
      ],
      method: 'POST',
      body: { email, pseudo },
      endpoint: `/users/famille/${userIdPrincipal}/secondaire`,

      schema: Schemas.USERS,
      successNext: [
        state => {
          let user = find(state.data.entities.users, user => user.email === email);
          return updateAuthUser(user.user_id);
        }
      ]
    }
  };
}

export function getUsersSecondaireFamille(userIdPrincipal) {
  return {
    userIdPrincipal,
    [CALL_API]: {
      types: [
        GET_USERS_SECONDAIRE_FAMILLE_REQUEST,
        GET_USERS_SECONDAIRE_FAMILLE_SUCCESS,
        GET_USERS_SECONDAIRE_FAMILLE_FAILURE
      ],
      method: 'GET',
      endpoint: `/users/famille/${userIdPrincipal}/secondaire`,
      schema: Schemas.USERS_ARRAY,
      successMessage: 'Comptes secondaires chargés avec succès'
    }
  };
}
