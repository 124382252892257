import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { updateEnfant } from '../../../store/modules/enfants';

import { Form, Field } from 'react-final-form';

import SwitchComponent from '../../shared/SwitchComponent';
import ModalSuccessUpdateEnfant from '../../modals/ModalSuccessUpdateEnfant';

const FormEnfantSante = ({ enfant, updateEnfant, setScrollToTop }) => {
  const [error, setError] = useState(null);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = data => {
    return updateEnfant(enfant, {
      sante: data
    })
      .then(res => {
        setOpenModalSuccess(true);
      })
      .catch(err => setError(err));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Field
            initialValue={
              (enfant && enfant.sante.diagnosticPose && enfant.sante.diagnosticPose) || false
            }
            name="diagnosticPose"
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <SwitchComponent
                  onChange={value => {
                    input.onChange(value);
                  }}
                  label="Votre enfant a-t-il un diagnostic posé ?"
                  value={input.value}
                  customStyle="switch-wrapper-left"
                />
              </div>
            )}
          </Field>
          {values && values.diagnosticPose === true && (
            <Field
              validate={required}
              initialValue={(enfant && enfant.sante.diagnosticPoseDetail) || ''}
              name="diagnosticPoseDetail"
              component="input"
              className="field"
            >
              {({ input, meta }) => (
                <div className="field">
                  {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                  <textarea {...input} row="4" />
                  <label>Lequel ? *</label>
                </div>
              )}
            </Field>
          )}
          <Field
            initialValue={(enfant && enfant.sante.allergies) || false}
            name="allergies"
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <SwitchComponent
                  onChange={value => {
                    input.onChange(value);
                  }}
                  label="Votre enfant a-t-il des allergies ?"
                  value={input.value}
                  customStyle="switch-wrapper-left"
                />
              </div>
            )}
          </Field>
          {values && values.allergies === true && (
            <Field
              validate={required}
              initialValue={(enfant && enfant.sante.allergiesDetail) || ''}
              name="allergiesDetail"
              component="input"
              className="field"
            >
              {({ input, meta }) => (
                <div className="field">
                  {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                  <textarea {...input} row="4" />
                  <label>Ses allergies ? *</label>
                </div>
              )}
            </Field>
          )}

          <Field
            initialValue={(enfant && enfant.sante.intolerances) || false}
            name="intolerances"
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <SwitchComponent
                  onChange={value => {
                    input.onChange(value);
                  }}
                  label="Votre enfant a-t-il des intolérances ?"
                  value={input.value}
                  customStyle="switch-wrapper-left"
                />
              </div>
            )}
          </Field>
          {values && values.intolerances === true && (
            <Field
              validate={required}
              initialValue={(enfant && enfant.sante.intolerancesDetail) || ''}
              name="intolerancesDetail"
              component="input"
              className="field"
            >
              {({ input, meta }) => (
                <div className="field">
                  {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                  <textarea {...input} row="4" />
                  <label>Ses intolérances ? *</label>
                </div>
              )}
            </Field>
          )}
          <Field
            initialValue={(enfant && enfant.sante.autre) || ''}
            name="autre"
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <textarea {...input} row="4" />
                <label>Autre</label>
              </div>
            )}
          </Field>
          <Field
            initialValue={(enfant && enfant.sante.medicationProtocole) || ''}
            name="medicationProtocole"
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <textarea {...input} row="4" />
                <label>
                  Indiquer ici si votre enfant est sous traitement et quel protocole doit être suivi
                </label>
              </div>
            )}
          </Field>
          <div className="highlight-block">
            <div className="highlight-block-infos">
              Pensez à ajouter un document signé dans la rubrique "Autorisations" pour toute prise
              de traitement particulier.
            </div>
            <div className="highlight-block-icon">
              <i className="fas fa-info-circle" />
            </div>
          </div>
          <ModalSuccessUpdateEnfant
            isOpen={openModalSuccess}
            closeModal={() => {
              setOpenModalSuccess(false);
              setScrollToTop();
            }}
          />
          {error && <div className="error-block">{error}</div>}
          <div className="btn-group center">
            <button type="submit" className="btn btn-primary">
              <span>Enregistrer</span>
            </button>
          </div>
        </form>
      )}
    />
  );
};

FormEnfantSante.propTypes = {
  enfant: PropTypes.object.isRequired,
  updateEnfant: PropTypes.func.isRequired,
  setScrollToTop: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  sensCategories:
    state.data.entities.settings && state.data.entities.settings['sens_categories'].data
});

export default connect(mapStateToProps, { updateEnfant })(FormEnfantSante);
