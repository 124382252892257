import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { register, createUserFamilleCode, checkEmail } from '../../store/modules/auth';
import { loadSettings } from '../../store/modules/settings';

import BaseLayout from '../shared/BaseLayout';
import ButtonProfilFamille from '../../components/ButtonProfilFamille';

const SelectProfilPageRegister = ({}) => {
  const navigation = useHistory();

  const [profilSelected, setProfilSelected] = useState(null);

  const [error, setError] = useState(false);

  const submit = () => {
    if (profilSelected) {
      navigation.push(`/inscription/profil/${profilSelected}`);
    } else {
      setError(true);
    }
  };

  const onChangeProfil = type => {
    setError(null);

    setProfilSelected(type);
  };

  return (
    <BaseLayout isAuthPages backBtn noFooter isPage bigTitle headerPageTitle="Choisissez un profil">
      <div className="main-container">
        <div className="content justifyCenter">
          <div className="grid">
            <div className="col-4_md-12">
              <ButtonProfilFamille
                profil="FAMILLE"
                profilSelected={profilSelected}
                onChangeProfil={() => onChangeProfil('FAMILLE')}
                icon="fa-family-pants"
                title="Vous êtes une famille"
                text="Vous êtes une famille indépendante, ou une famille rattachée à une structure (SESSAD, IME….)"
              />
            </div>
            <div className="col-4_md-12">
              <ButtonProfilFamille
                profil="INTERVENANT"
                profilSelected={profilSelected}
                onChangeProfil={() => onChangeProfil('INTERVENANT')}
                icon="fa-hospital-user"
                title="Vous êtes un Intervenant"
                text="Vous êtes un intervenant spécialisé : Psychologue, intervenant psycho-éducatif, orthophoniste, AESH..."
              />
            </div>
            <div className="col-4_md-12">
              <ButtonProfilFamille
                profil="STRUCTURE"
                profilSelected={profilSelected}
                onChangeProfil={() => onChangeProfil('STRUCTURE')}
                icon="fa-school"
                title="Vous êtes une structure"
                text="Vous êtes un SESSAD, un IME…"
              />
            </div>
          </div>
        </div>

        {error && (
          <div style={{ color: 'red', textAlign: 'center', marginBottom: '1em' }}>
            Veuillez choisir un type de profil
          </div>
        )}

        <div className="btn-group flexColumn center">
          <button className="btn btn-primary" onClick={() => submit()}>
            <span>Suivant</span>
          </button>
        </div>
      </div>
    </BaseLayout>
  );
};

SelectProfilPageRegister.propTypes = {
  register: PropTypes.func.isRequired,
  loadSettings: PropTypes.func.isRequired,
  createUserFamilleCode: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {
  register,
  createUserFamilleCode,
  checkEmail,
  loadSettings
})(SelectProfilPageRegister);
