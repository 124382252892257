import React from 'react';
import { NavLink } from 'react-router-dom';

import { version } from '../../../package.json';

const HomeAuthPage = () => {
  return (
    <div id="wrapper" className="login">
      {process.env.NODE_ENV === 'staging' && (
        <div id="staging-banner">
          <i className="fas fa-exclamation-triangle" />
          Environnement de recette
          <i className="fas fa-exclamation-triangle" />
        </div>
      )}
      <main>
        <div className="logo-block">
          <img
            className="logo"
            src={require(`../../../assets/images/logo-originailes-blanc.svg`)}
          />
          <p>Mieux vivre l'autisme au quotidien</p>
        </div>

        <div className="auth-buttons">
          <NavLink to="/inscription/profil" className="btn btn-primary">
            S'inscrire
          </NavLink>
          <NavLink to="/connexion" className="btn btn-primary">
            S'identifier
          </NavLink>
          <a className="link" href="https://www.originailes.fr/" target="_blank" rel="noopener">
            originailes.fr
          </a>

          <span className="version">
            <i className="far fa-laptop-code" />
            Version {version}
          </span>
        </div>
      </main>
      <div className="login-background">
        <img className="hexa-alpha" src={require(`../../../assets/images/hexa-alpha.svg`)} />
        <img className="hexa-fuschia" src={require(`../../../assets/images/hexa-fuschia.svg`)} />
        <img className="hexa-orange" src={require(`../../../assets/images/hexa-orange.svg`)} />
        {/* <img className="hexa-purple" src={require(`../../../assets/images/hexa-purple.svg`)} /> */}
      </div>
    </div>
  );
};

export default HomeAuthPage;
