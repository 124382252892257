import { settingsActionsHandlers } from './settings';
import { uploadsActionsHandlers } from './uploads';
import { enfantsActionsHandlers } from './enfants';
import { intervenantsActionsHandlers } from './intervenants';
import { documentsMediasActionsHandlers } from './documentsMedias';
import { eventsActionsHandlers } from './events';
import { pointagesActionsHandlers } from './pointages';
import { parentsActionsHandlers } from './parents';
import { comptesRendusActionsHandlers } from './comptesRendus';
import { messagesActionsHandlers } from './messages';
import { stripeActionsHandlers } from './stripe';
import { usersActionsHandlers } from './users';

import initialState from '../initialState';
import '../../index';

const actionsHandlers = {
  ...settingsActionsHandlers,
  ...enfantsActionsHandlers,
  ...uploadsActionsHandlers,
  ...intervenantsActionsHandlers,
  ...eventsActionsHandlers,
  ...documentsMediasActionsHandlers,
  ...pointagesActionsHandlers,
  ...parentsActionsHandlers,
  ...comptesRendusActionsHandlers,
  ...messagesActionsHandlers,
  ...usersActionsHandlers,
  ...stripeActionsHandlers
};

export default function reducer(state = initialState, action) {
  const reduceFn = actionsHandlers[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
