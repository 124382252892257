import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';

const ModalConfirmDelete = ({ actionDelete, message, openModal, closeModal }) => {
  const [error, setError] = useState(null);

  const confirmDelete = () => {
    return actionDelete()
      .then(() => {
        closeModal();
      })
      .catch(err => setError(err));
  };
  return (
    <Modal
      className="modal-content small-modal"
      overlayClassName="modal-overlay"
      id="confirm-delete"
      isOpen={openModal}
    >
      <div className="modal-body">
        <div className="message">{message}</div>

        {error && (
          <div className="error-block">
            <span className="error-icon">
              <i className="fas fa-exclamation" />
            </span>
            <div className="error-message">{error}</div>
          </div>
        )}
      </div>

      <div className="modal-footer">
        <div className="btn-group center">
          <button
            onClick={() => {
              closeModal();
              setError(null);
            }}
            className="btn btn-secondary"
          >
            <span>Annuler</span>
          </button>
          <button className="btn btn-primary" onClick={() => confirmDelete()}>
            <span>Supprimer</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalConfirmDelete.propTypes = {
  actionDelete: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ModalConfirmDelete;
