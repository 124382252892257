import React from 'react';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalInfo = ({ isOpen, confirmMethode, closeModal, icon, title, confirmText, text }) => {
  const confirm = () => {
    closeModal();
    confirmMethode();
  };

  return (
    <Modal
      {...modalDefaultClass}
      className="modal-content small-modal success-modal"
      id="modal-confirm"
      isOpen={isOpen}
    >
      <div className="modal-title">
        {icon && <i className={`fal fa-${icon}`} />}
        {(title && title) || 'Confirmation'}
      </div>
      <div className="modal-body">
        {text}
        <div className="btn-group center">
          <button onClick={() => confirm()} className="btn btn-primary">
            <span>{(confirmText && confirmText) || 'Confirmer'}</span>
          </button>
        </div>
      </div>
      <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
    </Modal>
  );
};

export default ModalInfo;
