import { flow, set } from 'lodash/fp';
import { groupBy, omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const CREATE_POINTAGE_REQUEST = 'ori/CREATE_POINTAGE_REQUEST';
const CREATE_POINTAGE_SUCCESS = 'ori/CREATE_POINTAGE_SUCCESS';
const CREATE_POINTAGE_FAILURE = 'ori/CREATE_POINTAGE_FAILURE';

const GET_POINTAGES_REQUEST = 'ori/GET_POINTAGES_REQUEST';
const GET_POINTAGES_SUCCESS = 'ori/GET_POINTAGES_SUCCESS';
const GET_POINTAGES_INTERVENANT_SUCCESS = 'ori/GET_POINTAGES_INTERVENANT_SUCCESS';
const GET_POINTAGES_FAILURE = 'ori/GET_POINTAGES_FAILURE';

const DELETE_POINTAGE_REQUEST = 'ori/DELETE_POINTAGE_REQUEST';
const DELETE_POINTAGE_SUCCESS = 'ori/DELETE_POINTAGE_SUCCESS';
const DELETE_POINTAGE_FAILURE = 'ori/DELETE_POINTAGE_FAILURE';

const UPDATE_POINTAGE_REQUEST = 'ori/UPDATE_POINTAGE_REQUEST';
const UPDATE_POINTAGE_SUCCESS = 'ori/UPDATE_POINTAGE_SUCCESS';
const UPDATE_POINTAGE_FAILURE = 'ori/UPDATE_POINTAGE_FAILURE';

export const pointagesActionsHandlers = {
  [GET_POINTAGES_SUCCESS]: (state, action) =>
    flow(
      set('entities.pointages', action.response.entities.pointages || {}),
      set('loaded.pointages', true),
      set('loading.pointages', false)
    )(state),

  [GET_POINTAGES_INTERVENANT_SUCCESS]: (state, action) => {
    // il faut regrouper les pointages en focntion des enfants
    return flow(
      set('entities.pointages', action.response.entities.pointages || {}),
      set('loaded.pointages', true),
      set('loading.pointages', false)
    )(state);
  },

  [CREATE_POINTAGE_SUCCESS]: (state, action) => {
    return flow(
      set('entities.pointages', {
        ...state.entities.pointages,
        ...action.response.entities.pointages
      }),
      set('loaded.pointages', true),
      set('loading.pointages', false)
    )(state);
  },
  [DELETE_POINTAGE_SUCCESS]: (state, action) =>
    flow(set('entities.pointages', omit(state.entities.pointages, action.pointage.pointage_id)))(
      state
    ),

  [UPDATE_POINTAGE_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.pointages.${action.pointageId}`, {
        ...action.response.entities.pointages[action.pointageId]
      }),
      set('loaded.pointages', true),
      set('loading.pointages', false)
    )(state);
  }
};

export function createPointage(data) {
  return {
    [CALL_API]: {
      types: [CREATE_POINTAGE_REQUEST, CREATE_POINTAGE_SUCCESS, CREATE_POINTAGE_FAILURE],
      method: 'POST',
      endpoint: '/pointages',
      body: data,
      schema: Schemas.POINTAGE
    }
  };
}

export function getPointages(parentId) {
  return {
    parentId,
    [CALL_API]: {
      types: [GET_POINTAGES_REQUEST, GET_POINTAGES_SUCCESS, GET_POINTAGES_FAILURE],
      method: 'GET',
      endpoint: `/pointages/${parentId}`,
      schema: Schemas.POINTAGE_ARRAY,
      successMessage: 'Pointages chargés avec succès'
    }
  };
}

export function getPointagesIntervenant(userId, enfantId) {
  return {
    userId,
    enfantId,
    [CALL_API]: {
      types: [GET_POINTAGES_REQUEST, GET_POINTAGES_INTERVENANT_SUCCESS, GET_POINTAGES_FAILURE],
      method: 'GET',
      endpoint: `/pointages/intervenant/${userId}/${enfantId}`,
      schema: Schemas.POINTAGE_ARRAY,
      successMessage: 'Pointages chargés avec succès'
    }
  };
}

export function deletePointage(pointage) {
  return {
    pointage,
    [CALL_API]: {
      types: [DELETE_POINTAGE_REQUEST, DELETE_POINTAGE_SUCCESS, DELETE_POINTAGE_FAILURE],
      method: 'DELETE',
      body: pointage,
      endpoint: `/pointages/${pointage.pointage_id}`,
      successMessage: 'Le pointage a bien été supprimé'
    }
  };
}

export function updatePointage(pointageId, data) {
  return {
    pointageId,
    [CALL_API]: {
      types: [UPDATE_POINTAGE_REQUEST, UPDATE_POINTAGE_SUCCESS, UPDATE_POINTAGE_FAILURE],
      method: 'PUT',
      body: data,
      schema: Schemas.POINTAGE,
      endpoint: `/pointages/${pointageId}`,
      successMessage: 'Le pointage a bien été modifié'
    }
  };
}
