import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const SwitchComponent = ({ value, onChange, label, customStyle }) => {
  return (
    <Fragment>
      <div className={`switch-wrapper ${customStyle}`}>
        <div
          onClick={() => {
            onChange(!value);
          }}
          className={`switch ${value && 'active'}`}
        >
          <div className="switch-text on">Oui</div>
          <span className="indicator" />
          <div className="switch-text off">Non</div>
        </div>
      </div>
      <label>{label}</label>
    </Fragment>
  );
};
SwitchComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
export default SwitchComponent;
