import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import CircleLoader from 'react-spinners/CircleLoader';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import useResponsiveFontSize from '../../../utils/useResponsiveFontSize';
import { updateCard } from '../../../store/modules/stripe';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#005a8d',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: 'red'
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const SplitFormUpdate = ({
  customerId,
  clientSecret,
  setModalSuccess,
  setOpenModalCardUpdatePayment,
  updateCard
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loading, setLoading] = useState(false);
  const [validCard, setValidCard] = useState(false);
  const [validDate, setValidDate] = useState(false);
  const [validCvc, setValidCvc] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    const cardNumberElement = elements && elements.getElement(CardNumberElement);
    const cardCvcElement = elements && elements.getElement(CardCvcElement);
    const cardExpiryElement = elements && elements.getElement(CardExpiryElement);
    cardNumberElement.update({ disabled: true });
    cardCvcElement.update({ disabled: true });
    cardExpiryElement.update({ disabled: true });

    const cardElements = {
      number: cardNumberElement,
      cvc: cardCvcElement,
      ...cardExpiryElement
    };

    if (!stripe || !cardElements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // await updateCard(customerId, cardElements).then(result => console.log(result));

    await stripe
      .confirmCardSetup(
        clientSecret,
        //`Elements` instance that was used to create the Payment Element
        { payment_method: { card: cardElements } }
      )
      .then(result => {
        if (result.setupIntent && result.setupIntent.status === 'succeeded') {
          updateCard(customerId, result.setupIntent.payment_method).then(result => {
            console.log(result);
            setLoading(false);
            setModalSuccess(true);
            setOpenModalCardUpdatePayment(false);
          });
          // console.log(result);
          // setLoading(false);
          // setModalSuccess(true);
          // setOpenModalCardUpdatePayment(false);
        } else {
          setErrorMessage(`Votre moyen de paiement n'a pu être mis à jour`);
          cardNumberElement.update({ disabled: false });
          cardCvcElement.update({ disabled: false });
          cardExpiryElement.update({ disabled: false });
          setLoading(false);
        }
      });
  };

  return (
    <form className="StripeElement SplitFormElement" onSubmit={handleSubmit}>
      <div className="grid">
        <div className="col-12">
          <label>
            Numéro de Carte
            <CardNumberElement
              className="stripeInput"
              options={options}
              onReady={() => {
                console.log('CardNumberElement [ready]');
              }}
              onChange={event => {
                setValidCard(!event.error && !event.empty && event.complete);
              }}
            />
          </label>
        </div>
        <div className="col-6">
          <label>
            Date d'Expiration
            <CardExpiryElement
              className="stripeInput"
              options={options}
              onReady={() => {
                console.log('CardExpiryElement [ready]');
              }}
              onChange={event => {
                setValidDate(!event.error && !event.empty && event.complete);
              }}
            />
          </label>
        </div>
        <div className="col-6">
          <label>
            Cryptogramme
            <CardCvcElement
              className="stripeInput"
              options={options}
              onReady={() => {
                console.log('CardCvcElement [ready]');
              }}
              onChange={event => {
                setValidCvc(!event.error && !event.empty && event.complete);
              }}
            />
          </label>
        </div>
      </div>

      <div className="btn-group center">
        {!loading && (
          <button
            type="button"
            className="btn btn-secondary"
            disabled={!stripe}
            onClick={() => setOpenModalCardUpdatePayment(false)}
            style={{ margin: 10 }}
          >
            Annuler
          </button>
        )}

        <button
          className="btn btn-primary"
          type="submit"
          disabled={!stripe || !validCard || !validDate || !validCvc || loading}
          style={{ margin: 10 }}
        >
          {loading ? <CircleLoader size={30} color={'black'} loading={loading} /> : `Confirmer`}
        </button>
      </div>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default connect(null, { updateCard })(SplitFormUpdate);
