import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const AvatarOutline = ({ nameFile, isGrey, smaller, bigger }) => {
  const [value, setValue] = useState({
    width: '',
    height: '',
    viewBox: '',
    d1: '',
    fill1: '',
    d2: '',
    fill2: ''
  });
  useEffect(() => {
    switch (nameFile) {
      case 'CardActu':
        setValue({
          width: '26',
          height: '30',
          viewBox: '0 0 44 48.1',
          d1:
            'M22,47.2a8.1,8.1,0,0,1-4.1-1.1L5,38.7A8.4,8.4,0,0,1,.9,31.5V16.6A8.2,8.2,0,0,1,5,9.5L17.9,2a8.2,8.2,0,0,1,8.2,0L39,9.5a8.2,8.2,0,0,1,4.1,7.1V31.5A8.4,8.4,0,0,1,39,38.7L26.1,46.1A8.1,8.1,0,0,1,22,47.2Z',
          fill1: '#F4FAFF',
          d2:
            'M22,1.8a8.2,8.2,0,0,1,3.7,1l12.8,7.5a7.2,7.2,0,0,1,3.7,6.3V31.5a7.4,7.4,0,0,1-3.7,6.4L25.7,45.3a7.3,7.3,0,0,1-7.4,0L5.5,37.9a7.4,7.4,0,0,1-3.7-6.4V16.6a7.2,7.2,0,0,1,3.7-6.3L18.3,2.8a8.2,8.2,0,0,1,3.7-1M22,0a9.3,9.3,0,0,0-4.6,1.2L4.6,8.7A9.1,9.1,0,0,0,0,16.6V31.5a9.4,9.4,0,0,0,4.6,8l12.8,7.4a9.4,9.4,0,0,0,9.2,0l12.8-7.4a9.4,9.4,0,0,0,4.6-8V16.6a9.1,9.1,0,0,0-4.6-7.9L26.6,1.2A9.3,9.3,0,0,0,22,0Z',
          fill2: '#2488ca'
        });
        break;

      case 'SelectAddDeleteEnfants':
      case 'ComptesRendusPage':
      case 'EquipeCard':
        setValue({
          width: smaller ? '44' : '46',
          height: smaller ? '48.1' : '50',
          viewBox: '0 0 44 48.1',
          d1:
            'M22,47.2a8.1,8.1,0,0,1-4.1-1.1L5,38.7A8.4,8.4,0,0,1,.9,31.5V16.6A8.2,8.2,0,0,1,5,9.5L17.9,2a8.2,8.2,0,0,1,8.2,0L39,9.5a8.2,8.2,0,0,1,4.1,7.1V31.5A8.4,8.4,0,0,1,39,38.7L26.1,46.1A8.1,8.1,0,0,1,22,47.2Z',
          fill1: isGrey ? '#6C6C6C' : '#F4FAFF',
          d2:
            'M22,1.8a8.2,8.2,0,0,1,3.7,1l12.8,7.5a7.2,7.2,0,0,1,3.7,6.3V31.5a7.4,7.4,0,0,1-3.7,6.4L25.7,45.3a7.3,7.3,0,0,1-7.4,0L5.5,37.9a7.4,7.4,0,0,1-3.7-6.4V16.6a7.2,7.2,0,0,1,3.7-6.3L18.3,2.8a8.2,8.2,0,0,1,3.7-1M22,0a9.3,9.3,0,0,0-4.6,1.2L4.6,8.7A9.1,9.1,0,0,0,0,16.6V31.5a9.4,9.4,0,0,0,4.6,8l12.8,7.4a9.4,9.4,0,0,0,9.2,0l12.8-7.4a9.4,9.4,0,0,0,4.6-8V16.6a9.1,9.1,0,0,0-4.6-7.9L26.6,1.2A9.3,9.3,0,0,0,22,0Z',
          fill2: isGrey ? '#6C6C6C' : '#2488ca'
        });
        break;
      case 'ModalProfil':
      case 'ModalAddMembreEquipe':
      case 'ModalAutorisation':
      case 'DossierPage':
      case 'DossiersPage':
      case 'PlanningPage':
      case 'PlanningsPage':
      case 'EnfantProfil':
        setValue({
          width: bigger ? '54' : '44',
          height: bigger ? '58.1' : '48.1',
          viewBox: '0 0 44 48.1',
          d1:
            'M22,47.2a8.1,8.1,0,0,1-4.1-1.1L5,38.7A8.4,8.4,0,0,1,.9,31.5V16.6A8.2,8.2,0,0,1,5,9.5L17.9,2a8.2,8.2,0,0,1,8.2,0L39,9.5a8.2,8.2,0,0,1,4.1,7.1V31.5A8.4,8.4,0,0,1,39,38.7L26.1,46.1A8.1,8.1,0,0,1,22,47.2Z',
          fill1: '#F4FAFF',
          d2:
            'M22,1.8a8.2,8.2,0,0,1,3.7,1l12.8,7.5a7.2,7.2,0,0,1,3.7,6.3V31.5a7.4,7.4,0,0,1-3.7,6.4L25.7,45.3a7.3,7.3,0,0,1-7.4,0L5.5,37.9a7.4,7.4,0,0,1-3.7-6.4V16.6a7.2,7.2,0,0,1,3.7-6.3L18.3,2.8a8.2,8.2,0,0,1,3.7-1M22,0a9.3,9.3,0,0,0-4.6,1.2L4.6,8.7A9.1,9.1,0,0,0,0,16.6V31.5a9.4,9.4,0,0,0,4.6,8l12.8,7.4a9.4,9.4,0,0,0,9.2,0l12.8-7.4a9.4,9.4,0,0,0,4.6-8V16.6a9.1,9.1,0,0,0-4.6-7.9L26.6,1.2A9.3,9.3,0,0,0,22,0Z',
          fill2: '#2488ca'
        });
        break;
      case 'HeaderPage':
      case 'FieldUploadDropZone':
        setValue({
          width: '88',
          height: '96',
          viewBox: '0 0 87.6 96.1',
          d1:
            'M43.8,95.1a16.1,16.1,0,0,1-8.1-2.2L9.1,77.5A16.2,16.2,0,0,1,1,63.5V32.6a16.3,16.3,0,0,1,8.1-14L35.7,3.2A16.1,16.1,0,0,1,43.8,1a16.4,16.4,0,0,1,8.1,2.2L78.5,18.6a16.3,16.3,0,0,1,8.1,14V63.5a16.2,16.2,0,0,1-8.1,14L51.9,92.9A16.4,16.4,0,0,1,43.8,95.1Z',
          fill1: '#fff',
          d2:
            'M43.8,2.1a16,16,0,0,1,7.6,2L78,19.5a15.2,15.2,0,0,1,7.5,13.1V63.5A15.2,15.2,0,0,1,78,76.6L51.4,92a15,15,0,0,1-7.6,2.1A14.8,14.8,0,0,1,36.2,92L9.6,76.6A15.2,15.2,0,0,1,2.1,63.5V32.6A15.2,15.2,0,0,1,9.6,19.5L36.2,4.1a15.7,15.7,0,0,1,7.6-2m0-2.1a18,18,0,0,0-8.7,2.3L8.6,17.7A17.3,17.3,0,0,0,0,32.6V63.5A17.2,17.2,0,0,0,8.6,78.4L35.1,93.8a18,18,0,0,0,8.7,2.3,17.3,17.3,0,0,0,8.6-2.3L79,78.4a17.2,17.2,0,0,0,8.6-14.9V32.6A17.3,17.3,0,0,0,79,17.7L52.4,2.3A17.3,17.3,0,0,0,43.8,0Z',
          fill2: '#2488ca'
        });
        break;
      case 'default':
        break;
    }
  }, []);

  return (
    <svg width={value.width} height={value.height} viewBox={value.viewBox}>
      <path id="svg-avatar-background" d={value.d1} fill={value.fill1} />
      <path id="svg-avatar-border" d={value.d2} fill={value.fill2} />
    </svg>
  );
};

export default AvatarOutline;
