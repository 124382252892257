import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { includes, replace } from 'lodash';
import { deleteSubscription, getSubscription, logout, updateUser } from '../../store/modules/auth';
import { purge } from '../../store/modules/settings';
import BaseLayout from '../shared/BaseLayout';
import moment from 'moment';
import Modal from 'react-modal';
import ModalCardUpdatePayment from '../../components/modals/Stripe/ModalCardUpdatePayment';
import ModalSuccessCardUpdate from '../../components/modals/Stripe/ModalSuccessCardUpdate';

const MonAbonnementPage = ({ user, getSubscription, deleteSubscription, logout, purge }) => {
  const navigation = useHistory();
  const [subscription, setSubscription] = useState(null);
  const [canceled, setcanceled] = useState(false);
  const [error, setError] = useState(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalCardUpdatePayment, setOpenModalCardUpdatePayment] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  useEffect(() => {
    getSubscription(user.user_id).then(res => {
      setSubscription(res.response.subscription);
      if (res.response.subscription.status === 'canceled') {
        setcanceled(true);
      }
    });
  }, []);

  const unsubscribeAbonnement = () => {
    setError(null);
    deleteSubscription(user.user_id, subscription.id)
      .then(res => {
        setOpenModalDelete(false);
        setError(null);
        return Promise.resolve()
          .then(() => logout())
          .then(() => purge())
          .then(() => navigation.push('/connexion'));
      })
      .catch(err => {
        setError(err);
        setOpenModalDelete(false);
      });
  };

  return (
    <BaseLayout headerPageTitle="Mon abonnement" isPage backBtn classScreen="MySubscription">
      <div className="main-container alignCenter">
        <div className="content">
          <div className="packages-container">
            <div
              className={`package${
                subscription &&
                subscription.plan &&
                subscription.plan.nickname &&
                includes(subscription.plan.nickname, 'annuel')
                  ? ' package-secondary'
                  : ''
              }`}
            >
              <div className="package-title">{subscription && subscription.plan.nickname}</div>
              <div className="package-separator"></div>
              <div className="package-price">
                {subscription &&
                  subscription.plan &&
                  subscription.plan.amount &&
                  replace(subscription.plan.amount / 100, '.', ',')}
                &nbsp;€
                <span>
                  TTC /
                  {subscription &&
                  subscription.plan &&
                  subscription.plan.nickname &&
                  includes(subscription.plan.nickname, 'annuel')
                    ? ' an'
                    : ' mois'}
                </span>
              </div>
              {canceled ? (
                <Fragment>
                  <div className="package-separator"></div>
                  <div className="package-benefit">
                    <span>Date de résiliation&nbsp;:&nbsp;</span>
                    {subscription && moment.unix(subscription.canceled_at).format('DD/MM/YYYY')}
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="package-separator"></div>
                  <div className="package-benefit">
                    <span>Date de souscription&nbsp;:&nbsp;</span>
                    {subscription &&
                      moment.unix(subscription.current_period_start).format('DD/MM/YYYY')}
                  </div>
                  {user.transactionReceipt &&
                    user.transactionReceipt.status &&
                    user.transactionReceipt.status === 'trialing' && (
                      <div className="package-benefit">
                        <span>Date de fin d'essai&nbsp;:&nbsp;</span>
                        {subscription &&
                          moment
                            .unix(subscription.trial_end)
                            .subtract(1, 'days')
                            .format('DD/MM/YYYY')}
                      </div>
                    )}
                  {subscription &&
                    subscription.plan &&
                    subscription.plan.nickname &&
                    subscription.plan.nickname.includes('annuel') && (
                      <div className="package-benefit">
                        <span>Date de fin d'abonnement&nbsp;:&nbsp;</span>
                        {moment
                          .unix(subscription.current_period_start)
                          .subtract(1, 'days')
                          .add(1, 'years')
                          .format('DD/MM/YYYY')}
                      </div>
                    )}
                </Fragment>
              )}
            </div>
          </div>

          {subscription ? (
            canceled ? (
              <Fragment>
                <div
                  className="btn btn-account"
                  onClick={() => navigation.push('/tarifs-retour', { user })}
                >
                  <span className="btn-account-info">
                    <span className="btn-account-icon">
                      <i className="far fa-credit-card" />
                    </span>
                    <span>Me réabonner</span>
                  </span>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {user && user.profil !== 'STRUCTURE' && (
                  <div
                    className="btn btn-account"
                    onClick={() => setOpenModalCardUpdatePayment(true)}
                  >
                    <span className="btn-account-info">
                      <span className="btn-account-icon">
                        <i className="far fa-credit-card" />
                      </span>
                      <span>Mettre à jour mon moyen de paiement</span>
                    </span>
                  </div>
                )}

                <div className="btn btn-account" onClick={() => setOpenModalDelete(true)}>
                  <span className="btn-account-info">
                    <span className="btn-account-icon">
                      <i className="far fa-ban" />
                    </span>
                    <span>Résilier mon abonnement</span>
                  </span>
                </div>
              </Fragment>
            )
          ) : null}

          {error && <div className="error-message">{error}</div>}
          <Modal
            className="modal-content small-modal"
            overlayClassName="modal-overlay"
            id="confirm-delete"
            isOpen={openModalDelete}
          >
            <div className="modal-body">
              <div className="message">Êtes-vous sûr de vouloir vous désabonner ?</div>
            </div>

            <div className="modal-footer">
              <div className="btn-group center">
                <button
                  onClick={() => {
                    setOpenModalDelete(false);
                    setError(null);
                  }}
                  className="btn btn-secondary"
                >
                  <span>Annuler</span>
                </button>
                <button className="btn btn-primary" onClick={() => unsubscribeAbonnement()}>
                  <span>Oui</span>
                </button>
              </div>
            </div>
          </Modal>
          <ModalCardUpdatePayment
            customerId={user && user.transactionReceipt && user.transactionReceipt.customer_id}
            isOpen={openModalCardUpdatePayment}
            setOpenModalCardUpdatePayment={v => setOpenModalCardUpdatePayment(v)}
            setModalSuccess={v => setModalSuccess(v)}
          />
          {modalSuccess && (
            <ModalSuccessCardUpdate
              isOpen={modalSuccess}
              setIsOpen={v => setModalSuccess(v)}
              message={'Votre moyen de paiement a été mis à jour avec succès ! '}
            />
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

MonAbonnementPage.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  getSubscription: PropTypes.func.isRequired,
  deleteSubscription: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {
  updateUser,
  getSubscription,
  logout,
  purge,
  deleteSubscription
})(MonAbonnementPage);
