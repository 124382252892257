import React from 'react';

import { connect } from 'react-redux';

import BaseLayout from '../shared/BaseLayout';

import { values } from 'lodash';

const InfosCompteSecondaire = ({ userSecondaire }) => {
  return (
    <BaseLayout headerPageTitle="Compte secondaire" isPage backBtn noFooter>
      <div className="main-container alignCenter">
        <div className="content">
          <form>
            <div className="field">
              <input
                value={userSecondaire && userSecondaire.pseudo}
                type="text"
                disabled
                placeholder="Papa, Maman, ..."
              />
              <label>Profil</label>
            </div>

            <div className="field">
              <input value={userSecondaire && userSecondaire.email} type="text" disabled />
              <label>E-mail</label>
            </div>

            <div className="field">
              <input value={userSecondaire && userSecondaire.adresse1} type="text" disabled />
              <label>Adresse 1</label>
            </div>

            <div className="field">
              <input value={userSecondaire && userSecondaire.adresse2} type="text" disabled />
              <label>Adresse 2</label>
            </div>

            <div className="field">
              <input value={userSecondaire && userSecondaire.tel1} type="text" disabled />
              <label>Téléphone</label>
            </div>
          </form>
        </div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = state => ({
  userSecondaire: state.data.entities.users && values(state.data.entities.users)[0]
});

export default connect(mapStateToProps, {})(InfosCompteSecondaire);
