import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { register, createUserFamilleCode, checkEmail } from '../../store/modules/auth';
import { loadSettings } from '../../store/modules/settings';
import BaseLayout from '../shared/BaseLayout';
import ModalCardFormPayment from '../../components/modals/Stripe/ModalCardFormPayment';
import ModalSuccessAccountCreation from '../../components/modals/ModalSuccessAccountCreation';

const FormulePage = ({}) => {
  let location = useLocation();
  const navigation = useHistory();
  const dataForm = location.state;
  const [profilSelected, setProfilSelected] = useState(null);
  const [error, setError] = useState(null);
  const [chosenPrice, setChosenPrice] = useState(null);
  const [chosenSize, setChosenSize] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [isOpenModalCardFormPayment, setIsOpenModalCardFormPayment] = useState(false);

  const handleSubmit = (price, taille) => {
    setChosenPrice(price);
    setChosenSize(taille);
    setIsOpenModalCardFormPayment(true);
  };

  return (
    <BaseLayout
      notDisplayTopHeader
      backBtn
      noFooter
      isPage
      bigTitle
      headerPageTitle="Formule"
      classScreen="formules"
    >
      {modalSuccess && (
        <ModalSuccessAccountCreation
          isOpen={modalSuccess}
          titleMessage={`Bienvenue sur Origin'ailes !`}
          message={
            'Vous allez recevoir un e-mail vous permettant de définir votre mot de passe pour vous permettre de vous connecter.'
          }
        />
      )}
      <ModalCardFormPayment
        isMultipleTypePaiements
        dataUser={dataForm}
        chosenPrice={chosenPrice}
        chosenSize={chosenSize}
        isOpen={isOpenModalCardFormPayment}
        error={error}
        setError={v => setError(v)}
        setModalSuccess={v => setModalSuccess(v)}
        setIsOpenModalCardFormPayment={v => setIsOpenModalCardFormPayment(v)}
      />
      <div className="main-container">
        <div className="content">
          <div className="packages-container">
            <div className="grid-center">
              <div className="col-4_xs-12_md-4">
                <div className="package">
                  <div className="package-title">Tranche 1 à 5 places</div>
                  <div className="package-subtitle">Abonnement Annuel</div>
                  <div className="package-separator"></div>
                  <div className="package-price">
                    1599 €<span>TTC / an</span>
                  </div>
                  <div className="package-price-details">(soit 133,25€/mois)</div>
                  <div className="package-benefits-block">
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>Nombre d’intervenants illimité</span>
                    </div>
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>5 Go de stockage</span>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      handleSubmit('ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_1_AVEC_ESSAI', '5');
                    }}
                    className="btn btn-secondary"
                  >
                    Sélectionner
                  </button>
                  <div className="package-notes">Essai gratuit 30 jours</div>
                </div>
              </div>
              <div className="col-4_xs-12_md-4">
                <div className="package package-secondary">
                  <div className="package-title">Tranche 6 à 10 places</div>
                  <div className="package-subtitle">Abonnement Annuel</div>
                  <div className="package-separator"></div>
                  <div className="package-price">
                    2999 €<span>TTC / an</span>
                  </div>
                  <div className="package-price-details">(soit 249,92€/mois)</div>
                  <div className="package-benefits-block">
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>Nombre d’intervenants illimité</span>
                    </div>
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>5 Go de stockage</span>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      handleSubmit('ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_2_AVEC_ESSAI', '10');
                    }}
                    className="btn btn-secondary"
                  >
                    Sélectionner
                  </button>
                  <div className="package-notes">Essai gratuit 30 jours</div>
                </div>
              </div>
              <div className="col-4_xs-12_md-4">
                <div className="package package-tertiary">
                  <div className="package-title">Tranche 11 à 20 places</div>
                  <div className="package-subtitle">Abonnement Annuel</div>
                  <div className="package-separator"></div>
                  <div className="package-price">
                    5299 €<span>TTC / an</span>
                  </div>
                  <div className="package-price-details">(soit 451,58€/mois)</div>
                  <div className="package-benefits-block">
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>Nombre d’intervenants illimité</span>
                    </div>
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>5 Go de stockage</span>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      handleSubmit('ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_3_AVEC_ESSAI', '20');
                    }}
                    className="btn btn-secondary"
                  >
                    Sélectionner
                  </button>
                  <div className="package-notes">Essai gratuit 30 jours</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

FormulePage.propTypes = {
  register: PropTypes.func.isRequired,
  loadSettings: PropTypes.func.isRequired,
  createUserFamilleCode: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  register,
  createUserFamilleCode,
  checkEmail,
  loadSettings
})(FormulePage);
