import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircleLoader from 'react-spinners/CircleLoader';
import {
  createUserFamille,
  updateUserFamilyResuscribe,
  createUserStructure,
  updateUserStructureResuscribe
} from '../../../store/modules/auth';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import useResponsiveFontSize from '../../../utils/useResponsiveFontSize';
import { subscription, resubscription } from '../../../store/modules/stripe';
import { trialDays } from '../../../constants/Properties';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#005a8d',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: 'red'
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = ({
  user,
  dataUser,
  email,
  nom,
  profil,
  chosenPrice,
  chosenSize,
  promoCode,
  setError,
  subscription,
  resubscription,
  setModalSuccess,
  setIsOpenModalCardFormPayment,
  createUserFamille,
  updateUserFamilyResuscribe,
  createUserStructure,
  updateUserStructureResuscribe
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loading, setLoading] = useState(false);
  const [validCard, setValidCard] = useState(false);
  const [validDate, setValidDate] = useState(false);
  const [validCvc, setValidCvc] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    let subscriptionDataResponse;

    const cardNumberElement = elements && elements.getElement(CardNumberElement);
    const cardCvcElement = elements && elements.getElement(CardCvcElement);
    const cardExpiryElement = elements && elements.getElement(CardExpiryElement);
    cardNumberElement.update({ disabled: true });
    cardCvcElement.update({ disabled: true });
    cardExpiryElement.update({ disabled: true });

    //////////////////////////////////////////////////////
    // Si l'utilisateur ne possède pas encore de compte //
    //////////////////////////////////////////////////////
    if (!user) {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: {
          number: cardNumberElement,
          cvc: cardCvcElement,
          ...cardExpiryElement
        },
        billing_details: {
          email: (email && email) || (dataUser && dataUser.email)
        }
      });
      // ERREUR de CREATION de la METHODE de PAIEMENT, affichage du message d'erreur (affichage à nouveau du formulaire)
      if (result.error) {
        console.log('ERROR !', result.error.message);
        setLoading(false);
        cardNumberElement.update({ disabled: false });
        cardCvcElement.update({ disabled: false });
        cardExpiryElement.update({ disabled: false });
      }
      // REUSSITE de CREATION de la METHODE de PAIEMENT, tentative de CREATION de l'ABONNEMENT
      else {
        // eslint-disable-next-line camelcase
        let subscriptionData = {
          nom: (nom && nom) || (dataUser && dataUser.nom),
          email: (email && email) || (dataUser && dataUser.email),
          payment_method: result.paymentMethod.id,
          price: chosenPrice,
          trial_days: trialDays
        };

        if (promoCode) {
          subscriptionData = {
            ...subscriptionData,
            promo_code: promoCode
          };
        }

        let subscriptionResponse = '';
        let customer = '';
        let status = '';
        let client_secret = '';

        return subscription({
          ...subscriptionData
        })
          .then(res => {
            if (!res.response) {
              console.warn('TODO : à traiter', res);
            }
            subscriptionResponse = res.response.subscription;
            customer = res.response.customer_id;
            status = res.response.status;
            client_secret = res.response.client_secret;

            // Si la CREATION de l'ABONNEMENT nécessite une ACTION
            if (status === 'requires_action') {
              return Promise.resolve().then(() => stripe.confirmCardPayment(client_secret));
            } else {
              return 'no_action';
            }
          })
          .then(result => {
            // En cas d'ECHEC de CREATION de l'ABONNEMENT, affichage du message d'erreur
            if (result !== 'no_action' && result.error) {
              console.log('There was an issue!');
              console.log(result.error);
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              setLoading(false);
              cardNumberElement.update({ disabled: false });
              cardCvcElement.update({ disabled: false });
              cardExpiryElement.update({ disabled: false });
            }
            // Si la CREATION de l'ABONNEMENT aboutit MALGRE TOUT
            else {
              console.log('You got the money!');
              // Show a success message to your customer
              setError(null);
              if (profil === 'FAMILLE') {
                return createUserFamille(email, nom, customer.id, subscriptionResponse);
              }
              if (profil === 'STRUCTURE') {
                return createUserStructure(
                  email,
                  nom,
                  chosenSize,
                  customer.id,
                  subscriptionResponse
                );
              }
            }
          })
          .then(res => {
            setModalSuccess(true);
            setLoading(false);
            setIsOpenModalCardFormPayment(false);
          })
          .catch(err => {
            setError(err);
            setLoading(false);
            cardNumberElement.update({ disabled: false });
            cardCvcElement.update({ disabled: false });
            cardExpiryElement.update({ disabled: false });
          });
      }
    }
    /////////////////////////////////////////////
    // Si l'utilisateur possède déjà un compte //
    /////////////////////////////////////////////
    else {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: {
          number: cardNumberElement,
          cvc: cardCvcElement,
          ...cardExpiryElement
        },
        billing_details: {
          email: user.email
        }
      });
      console.log('result', result);
      // ERREUR de CREATION de la METHODE de PAIEMENT, affichage du message d'erreur (affichage à nouveau du formulaire)
      if (result.error) {
        console.log('ERROR !', result.error.message);
        setLoading(false);
        cardNumberElement.update({ disabled: false });
        cardCvcElement.update({ disabled: false });
        cardExpiryElement.update({ disabled: false });
      }
      // REUSSITE de CREATION de la METHODE de PAIEMENT, tentative de CREATION de l'ABONNEMENT
      else {
        // eslint-disable-next-line camelcase
        return resubscription({
          payment_method: result.paymentMethod.id,
          nom: user.nom,
          email: user.email,
          price: chosenPrice
        })
          .then(res => {
            console.log(res);
            if (!res.response) {
              console.warn('TODO : à traiter', res);
            }
            const { client_secret, status } = res.response; // res.data
            subscriptionDataResponse = res.response;

            // Si la CREATION de l'ABONNEMENT nécessite une ACTION
            if (status === 'requires_action' || status === 'requires_confirmation') {
              return stripe
                .confirmCardPayment(client_secret)
                .then(function(result) {
                  // En cas d'ECHEC de CREATION de l'ABONNEMENT, affichage du message d'erreur
                  if (result.error) {
                    console.log('There was an issue!');
                    console.log(result.error);
                    // Display error message in your UI.
                    // The card was declined (i.e. insufficient funds, card has expired, etc)
                    setLoading(false);
                    cardNumberElement.update({ disabled: false });
                    cardCvcElement.update({ disabled: false });
                    cardExpiryElement.update({ disabled: false });
                  }
                  // Si la CREATION de l'ABONNEMENT aboutit MALGRE TOUT
                  else {
                    console.log('It needed an action but you got the money!');
                    // Show a success message to your customer
                    setError(null);
                    if (profil === 'FAMILLE')
                      return updateUserFamilyResuscribe(
                        user.user_id,
                        subscriptionDataResponse.subscription.latest_invoice.customer,
                        subscriptionDataResponse.subscription
                      );
                    if (profil === 'STRUCTURE')
                      return updateUserStructureResuscribe(
                        user.user_id,
                        subscriptionDataResponse.subscription.latest_invoice.customer,
                        subscriptionDataResponse.subscription
                      );
                  }
                })
                .then(res => {
                  setModalSuccess(true);
                  setLoading(false);
                  setIsOpenModalCardFormPayment(false);
                })
                .catch(err => {
                  setError(err);
                  setLoading(false);
                  cardNumberElement.update({ disabled: false });
                  cardCvcElement.update({ disabled: false });
                  cardExpiryElement.update({ disabled: false });
                });
            }
            // Si la CREATION de l'ABONNEMENT aboutit
            else {
              console.log('You got the money!');
              // No additional information was needed
              // Show a success message to your customer
              setError(null);
              if (profil === 'FAMILLE')
                return updateUserFamilyResuscribe({
                  user_id: user.user_id,
                  customerId: subscriptionDataResponse.subscription.latest_invoice.customer,
                  subscription: subscriptionDataResponse.subscription
                });
              if (profil === 'STRUCTURE')
                return updateUserStructureResuscribe({
                  user_id: user.user_id,
                  customerId: subscriptionDataResponse.subscription.latest_invoice.customer,
                  subscription: subscriptionDataResponse.subscription
                });
            }
          })
          .then(() => {
            setLoading(false);
            setIsOpenModalCardFormPayment(false);
            setModalSuccess(true);
          })
          .catch(err => {
            setError(err);
            setLoading(false);
            cardNumberElement.update({ disabled: false });
            cardCvcElement.update({ disabled: false });
            cardExpiryElement.update({ disabled: false });
          });
      }
    }
  };

  return (
    <form className="StripeElement SplitFormElement" onSubmit={handleSubmit}>
      <div className="grid">
        <div className="col-12">
          <label>
            Numéro de Carte
            <CardNumberElement
              className="stripeInput"
              options={options}
              onReady={() => {
                console.log('CardNumberElement [ready]');
              }}
              onChange={event => {
                setValidCard(!event.error && !event.empty && event.complete);
              }}
            />
          </label>
        </div>
        <div className="col-6">
          <label>
            Date d'Expiration
            <CardExpiryElement
              className="stripeInput"
              options={options}
              onReady={() => {
                console.log('CardExpiryElement [ready]');
              }}
              onChange={event => {
                setValidDate(!event.error && !event.empty && event.complete);
              }}
            />
          </label>
        </div>
        <div className="col-6">
          <label>
            Cryptogramme
            <CardCvcElement
              className="stripeInput"
              options={options}
              onReady={() => {
                console.log('CardCvcElement [ready]');
              }}
              onChange={event => {
                setValidCvc(!event.error && !event.empty && event.complete);
              }}
            />
          </label>
        </div>
      </div>

      <div className="btn-group center">
        {!loading && (
          <button
            type="button"
            className="btn btn-secondary"
            disabled={!stripe}
            onClick={() => setIsOpenModalCardFormPayment(false)}
            style={{ margin: 10 }}
          >
            Annuler
          </button>
        )}

        <button
          className="btn btn-primary"
          type="submit"
          disabled={!stripe || !validCard || !validDate || !validCvc || loading}
          style={{ margin: 10 }}
        >
          {loading ? <CircleLoader size={30} color={'black'} loading={loading} /> : `Payer`}
        </button>
      </div>
    </form>
  );
};

SplitForm.propTypes = {
  user: PropTypes.object,
  dataUser: PropTypes.object,
  email: PropTypes.string,
  nom: PropTypes.string,
  chosenPrice: PropTypes.string.isRequired,
  chosenSize: PropTypes.string,
  setError: PropTypes.func.isRequired,
  subscription: PropTypes.func.isRequired,
  resubscription: PropTypes.func.isRequired,
  setModalSuccess: PropTypes.func.isRequired,
  setIsOpenModalCardFormPayment: PropTypes.func.isRequired,
  createUserFamille: PropTypes.func.isRequired,
  updateUserFamilyResuscribe: PropTypes.func.isRequired,
  createUserStructure: PropTypes.func.isRequired,
  updateUserStructureResuscribe: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {
  createUserFamille,
  updateUserFamilyResuscribe,
  createUserStructure,
  updateUserStructureResuscribe,
  subscription,
  resubscription
})(SplitForm);
