import React from 'react';

const FatigueSelector = ({ niveau, onChangeNiveau, notEdit }) => {
  return (
    <div className="fatigue-block">
      <div
        onClick={() => !notEdit && onChangeNiveau(1)}
        className={`fatigue-emoticon ${niveau === 1 && 'active'}`}
      >
        🙁
      </div>
      <div
        onClick={() => !notEdit && onChangeNiveau(2)}
        className={`fatigue-emoticon ${niveau === 2 && 'active'}`}
      >
        😕
      </div>
      <div
        onClick={() => !notEdit && onChangeNiveau(3)}
        className={`fatigue-emoticon ${niveau === 3 && 'active'}`}
      >
        🙂
      </div>
      <div
        onClick={() => !notEdit && onChangeNiveau(4)}
        className={`fatigue-emoticon ${niveau === 4 && 'active'}`}
      >
        😄
      </div>
    </div>
  );
};

export default FatigueSelector;
