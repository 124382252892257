import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const GET_INTERVENANTS_REQUEST = 'ori/GET_INTERVENANTS_REQUEST';
const GET_INTERVENANTS_SUCCESS = 'ori/GET_INTERVENANTS_SUCCESS';
const GET_INTERVENANTS_FAILURE = 'ori/GET_INTERVENANTS_FAILURE';

const UPDATE_INTERVENANT_REQUEST = 'ori/UPDATE_INTERVENANT_REQUEST';
const UPDATE_INTERVENANT_SUCCESS = 'ori/UPDATE_INTERVENANT_SUCCESS';
const UPDATE_INTERVENANT_FAILURE = 'ori/UPDATE_INTERVENANT_FAILURE';

const UPDATE_INTERVENANT_PROFIL_REQUEST = 'ori/UPDATE_INTERVENANT_PROFIL_REQUEST';
const UPDATE_INTERVENANT_PROFIL_SUCCESS = 'ori/UPDATE_INTERVENANT_PROFIL_SUCCESS';
const UPDATE_INTERVENANT_PROFIL_FAILURE = 'ori/UPDATE_INTERVENANT_PROFIL_FAILURE';

export const intervenantsActionsHandlers = {
  [GET_INTERVENANTS_SUCCESS]: (state, action) =>
    flow(
      set('entities.intervenants', action.response.entities.intervenants || {}),
      set('loaded.intervenants', true),
      set('loading.intervenants', false)
    )(state),
  [UPDATE_INTERVENANT_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.intervenants.${action.intervenant.user_id}`, {
        ...action.intervenant,
        ...action.response
      }),
      set('loaded.intervenants', true),
      set('loading.intervenants', false)
    )(state);
  },
  [UPDATE_INTERVENANT_PROFIL_SUCCESS]: (state, action) => {
    return flow(
      set('entities.intervenants', omit(state.entities.intervenants, action.intervenantId)),
      set('loaded.intervenants', true),
      set('loading.intervenants', false)
    )(state);
  }
};

export function getIntervenants(enfantId) {
  return {
    enfantId,
    [CALL_API]: {
      types: [GET_INTERVENANTS_REQUEST, GET_INTERVENANTS_SUCCESS, GET_INTERVENANTS_FAILURE],
      method: 'GET',
      endpoint: `/enfants/intervenants/${enfantId}`,
      schema: Schemas.INTERVENANT_ARRAY,
      successMessage: 'Intervenants chargés avec succès'
    }
  };
}
export function updateIntervenant(intervenant, data) {
  return {
    intervenant,
    [CALL_API]: {
      types: [UPDATE_INTERVENANT_REQUEST, UPDATE_INTERVENANT_SUCCESS, UPDATE_INTERVENANT_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/users/${intervenant.user_id}`,
      successMessage: "L'intervenant a bien été modifié"
    }
  };
}

export function removeIntervenantProfil(intervenantId, data) {
  return {
    intervenantId,
    [CALL_API]: {
      types: [
        UPDATE_INTERVENANT_PROFIL_REQUEST,
        UPDATE_INTERVENANT_PROFIL_SUCCESS,
        UPDATE_INTERVENANT_PROFIL_FAILURE
      ],
      method: 'PUT',
      body: data,
      endpoint: `/users/removeIntervenantProfil/${intervenantId}`,
      successMessage: "L'Intervenant a bien été modifié !"
    }
  };
}
