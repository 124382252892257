import React, { useEffect } from 'react';
import { withRouter, BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';

import { requireAuthentication } from '../components/hoc/AuthenticatedComponent';

import LoginPage from './auth/LoginPage';
import RegisterPage from './auth/RegisterPage';
import DefinirPasswordPage from './auth/DefinirPasswordPage';
import PasswordOubliePage from './auth/PasswordOubliePage';
import PriceChoicePage from './stripe/PriceChoicePage';
import PriceChoiceBackPage from './stripe/PriceChoiceBackPage';

import HomePage from './HomePage';

import MonComptePage from './parametres/MonComptePage';
import MesInfosPersonnelsPage from './parametres/MesInfosPersonnelsPage';
import MonEnfantPage from './parametres/MonEnfantPage';

import MentionsLegalesPage from './MentionsLegalesPage';
import FilActusPage from './FilActusPage';
import EquipePage from './EquipePage';

import ProgrammesPage from './programmes/ProgrammesPage';
import ProgrammePage from './programmes/ProgrammePage';

import PlanningsPage from './plannings/PlanningsPage';
import PlanningPage from './plannings/PlanningPage';

import PointagesPage from './PointagesPage';

import CompteRenduPage from './comptes-rendus/CompteRenduPage';
import ComptesRendusPage from './comptes-rendus/ComptesRendusPage';
import AddEditCompteRenduPage from './comptes-rendus/AddEditCompteRenduPage';

import EnfantPage from './EnfantPage';

import DossiersPage from './dossiers/DossiersPage';
import AddEditDossierPage from './dossiers/AddEditDossierPage';
import DossierPage from './dossiers/DossierPage';

import MediasPage from './medias/MediasPage';
import MediaPage from './medias/MediaPage';
import HomeAuthPage from './auth/HomeAuthPage';
import MonAbonnementPage from './parametres/MonAbonnementPage';
import InfosCompteSecondaire from './parametres/InfosCompteSecondaire';
import SelectProfilPageRegister from './auth/SelectProfilPageRegister';
import FormulePage from './auth/FormulePage';
import SelectAddDeleteEnfants from './SelectAddDeleteEnfants';

function _ScrollToTop(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const Router = function() {
  return (
    <BrowserRouter>
      <Switch>
        <ScrollToTop>
          <Route exact path="/" component={requireAuthentication(SelectAddDeleteEnfants)} />
          <Route exact path="/dashboard" component={requireAuthentication(HomePage)} />

          <Route exact path="/connexion" component={LoginPage} />
          <Route exact path="/inscription/profil" component={SelectProfilPageRegister} />
          <Route exact path="/inscription/profil/:profilType" component={RegisterPage} />
          <Route exact path="/inscription/profil/:profilType/formule" component={FormulePage} />
          <Route exact path="/tarifs" component={PriceChoicePage} />
          <Route
            exact
            path="/tarifs-retour"
            component={requireAuthentication(PriceChoiceBackPage)}
          />

          <Route exact path="/mon-compte" component={requireAuthentication(MonComptePage)} />
          <Route exact path="/actus" component={requireAuthentication(FilActusPage)} />
          <Route exact path="/equipe" component={requireAuthentication(EquipePage)} />
          <Route exact path="/pointages" component={requireAuthentication(PointagesPage)} />
          <Route
            exact
            path="/comptes-rendus"
            component={requireAuthentication(ComptesRendusPage)}
          />
          <Route
            exact
            path="/comptes-rendus/ajouter"
            component={requireAuthentication(AddEditCompteRenduPage)}
          />
          <Route
            exact
            path="/compte-rendu/editer/:id"
            component={requireAuthentication(AddEditCompteRenduPage)}
          />
          <Route
            exact
            path="/compte-rendu/:id"
            component={requireAuthentication(CompteRenduPage)}
          />
          <Route
            exact
            path="/dossier-prise-en-charge/:id"
            component={requireAuthentication(DossierPage)}
          />
          <Route
            exact
            path="/dossiers-prise-en-charge"
            component={requireAuthentication(DossiersPage)}
          />
          <Route
            exact
            path="/dossiers-prise-en-charge/ajouter"
            component={requireAuthentication(AddEditDossierPage)}
          />
          <Route
            exact
            path="/dossier-prise-en-charge/editer/:id"
            component={requireAuthentication(AddEditDossierPage)}
          />
          <Route exact path="/medias" component={requireAuthentication(MediasPage)} />
          <Route exact path="/media/:id" component={requireAuthentication(MediaPage)} />
          <Route exact path="/plannings" component={requireAuthentication(PlanningsPage)} />
          <Route exact path="/planning/:id" component={requireAuthentication(PlanningPage)} />
          <Route exact path="/programmes" component={requireAuthentication(ProgrammesPage)} />
          <Route exact path="/programme/:id" component={requireAuthentication(ProgrammePage)} />
          <Route
            exact
            path="/mes-infos-personnelles"
            component={requireAuthentication(MesInfosPersonnelsPage)}
          />
          <Route
            exact
            path="/compte-secondaire"
            component={requireAuthentication(InfosCompteSecondaire)}
          />
          <Route
            exact
            path="/mon-abonnement"
            component={requireAuthentication(MonAbonnementPage)}
          />

          <Route
            exact
            path="/mon-enfant/:enfantId"
            component={requireAuthentication(MonEnfantPage)}
          />
          <Route exact path="/enfant/:enfantId" component={requireAuthentication(EnfantPage)} />
          <Route
            exact
            path="/mentions-legales"
            component={requireAuthentication(MentionsLegalesPage)}
          />
          <Route exact path="/mot-de-passe-oublie" component={PasswordOubliePage} />

          <Route
            exact
            path="/definir-mot-de-passe/:email?/:userid?/:token?/:profil?"
            component={DefinirPasswordPage}
          />
          <Route exact path="/auth" component={HomeAuthPage} />
        </ScrollToTop>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
