import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { optionsTaillesStructure } from '../../constants/Properties';

import { connect } from 'react-redux';
import {
  register,
  createUserFamilleCode,
  createUserStructureCode,
  checkEmail
} from '../../store/modules/auth';
import { loadSettings } from '../../store/modules/settings';

import { validateEmail } from '../../utils/StringValidator';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import { map, find, orderBy, omit } from 'lodash';

import BaseLayout from '../shared/BaseLayout';

import ButtonProfilFamille from '../../components/ButtonProfilFamille';
import ModalSuccessAccountCreation from '../../components/modals/ModalSuccessAccountCreation';

const RegisterPage = ({
  register,
  createUserFamilleCode,
  createUserStructureCode,
  checkEmail,
  types_profils,
  loadSettings
}) => {
  const navigation = useHistory();
  const [error, setError] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [hasCode, setHasCode] = useState(false);
  const { profilType } = useParams();

  useEffect(() => {
    loadSettings();
  }, []);

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';
  const requiredConditionGenerales = value =>
    value ? undefined : "Merci d'accepter les conditions générales";

  const handleSubmit = data => {
    setError(null);

    if (data.profil === 'FAMILLE') {
      if (hasCode) {
        createUserFamilleCode(data.email, data.nom, data.pseudo, data.code)
          .then(res => {
            setModalSuccess(true);
          })
          .catch(err => {
            setError(err);
          });
      } else {
        return checkEmail(data.email)
          .then(res => {
            return navigation.push('/tarifs', { email: data.email, nom: data.nom });
          })
          .catch(err => setError(err));
      }
    }
    if (profilType === 'STRUCTURE') {
      if (hasCode) {
        createUserStructureCode(data.email, data.nom, data.taille, data.code)
          .then(res => {
            setModalSuccess(true);
          })
          .catch(err => {
            setError(err);
          });
      } else
        return checkEmail(data.email)
          .then(res => {
            return navigation.push('/inscription/profil/STRUCTURE/formule', {
              ...data,
              profil: 'STRUCTURE'
            });
          })
          .catch(err => setError(err));
    }
    if (profilType !== 'STRUCTURE' && data.profil !== 'FAMILLE') {
      return register(data)
        .then(() => {
          setModalSuccess(true);
        })
        .catch(err => setError(err));
    }
  };

  const optionsProfilIntervenant = map(
    //
    omit(types_profils, ['FAMILLE', 'FAMILLE_STRUCTURE', 'STRUCTURE']),
    (item, key) => {
      return { value: key, label: item };
    }
  );
  return (
    <BaseLayout backBtn noFooter isPage bigTitle headerPageTitle="Inscrivez-vous" classScreen="">
      <div className="main-container">
        <div className="content">
          {modalSuccess && (
            <ModalSuccessAccountCreation
              isOpen={modalSuccess}
              titleMessage={`Votre compte a été créé avec succès !`}
              message={
                'Vous allez recevoir un e-mail vous permettant de définir votre mot de passe pour vous permettre de vous connecter.'
              }
            />
          )}

          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                {profilType && profilType !== 'STRUCTURE' && (
                  <Field
                    name="profil"
                    component="select"
                    validate={profilType === 'STRUCTURE' ? false : required}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          {profilType && profilType === 'FAMILLE' ? (
                            <div className="grid">
                              <div className="col-6_md-12">
                                <ButtonProfilFamille
                                  profil="FAMILLE"
                                  profilSelected={input.value}
                                  onChangeProfil={() => input.onChange('FAMILLE')}
                                  customClass="vertical"
                                  icon="fa-family-pants"
                                  title="Famille"
                                  text="Vous êtes une famille, vivant en couple ou séparée et vous souhaitez pouvoir gérer vous-même le suivi de votre enfant et la coordination de l'équipe pluridisciplinaire assurant sa prise en charge."
                                />
                              </div>
                              <div className="col-6_md-12">
                                <ButtonProfilFamille
                                  profil="FAMILLE_STRUCTURE"
                                  profilSelected={input.value}
                                  onChangeProfil={() => input.onChange('FAMILLE_STRUCTURE')}
                                  customClass="vertical"
                                  icon="fa-school"
                                  icon2="fa-family-pants"
                                  title="Famille rattachée à une structure"
                                  text="Votre enfant est pris en charge dans le cadre d'un SESSAD, d'un IME ou autre, et cette structure utilise l'application Origin'ailes. Elle vous a souhaité vous intégrer comme un membre à part entière de l'équipe."
                                />
                              </div>
                            </div>
                          ) : (
                            <Select
                              isSearchable={false}
                              className="react-select"
                              classNamePrefix="react-select"
                              placeholder="Sélectionner un profil"
                              options={orderBy(optionsProfilIntervenant, ['label'], ['asc'])}
                              onChange={option => {
                                setHasCode(false);
                                input.onChange(option.value);
                              }}
                              value={find(optionsProfilIntervenant, o => o.value === input.value)}
                            />
                          )}
                          <label>Profil *</label>
                        </div>
                      );
                    }}
                  </Field>
                )}

                {(values.profil === 'FAMILLE' || profilType === 'STRUCTURE') && (
                  <div name="checkbox-code">
                    <div className="field field-checkbox">
                      <input id="code" type="checkbox" onClick={() => setHasCode(!hasCode)} />
                      <label htmlFor="code">Je suis référencé comme utilisateur pilote</label>
                    </div>
                  </div>
                )}
                {(values.profil === 'FAMILLE' || profilType === 'STRUCTURE') && hasCode && (
                  <Fragment>
                    <Field name="code" validate={required} component="input" className="field">
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>Code *</label>
                        </div>
                      )}
                    </Field>
                    {profilType === 'STRUCTURE' && hasCode && (
                      <Field name="taille" validate={required} component="input" className="field">
                        {({ input, meta }) => (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}
                            <Select
                              isSearchable={false}
                              className="react-select"
                              classNamePrefix="react-select"
                              placeholder="Sélectionner une taille de structure"
                              options={optionsTaillesStructure}
                              onChange={option => {
                                input.onChange(option.value);
                              }}
                              value={find(optionsTaillesStructure, o => o.value === input.value)}
                            />
                            <label>Taille de la structure *</label>
                          </div>
                        )}
                      </Field>
                    )}
                  </Fragment>
                )}
                <Field validate={required} name="nom" component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>{profilType === 'STRUCTURE' ? 'Nom de la structure' : 'Nom'} *</label>
                    </div>
                  )}
                </Field>
                {profilType !== 'STRUCTURE' && (
                  <Field name="prenom" validate={required} component="input" className="field">
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" />
                        <label>Prénom *</label>
                      </div>
                    )}
                  </Field>
                )}
                {values && (values.profil === 'FAMILLE' || values.profil === 'FAMILLE_STRUCTURE') && (
                  <Field name="pseudo" component="input" className="field">
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input
                          {...input}
                          type="text"
                          autoCapitalize="none"
                          placeholder="Papa, Maman, ..."
                        />
                        <label>Profil</label>
                      </div>
                    )}
                  </Field>
                )}
                <Field validate={requiredEmail} name="email" component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="email" autoCapitalize="none" />
                      <label>Adresse e-mail *</label>
                    </div>
                  )}
                </Field>
                <Field name="conditions" validate={requiredConditionGenerales}>
                  {({ input, meta }) => (
                    <div className="field field-checkbox">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input id="terms" {...input} type="checkbox" />
                      <label htmlFor="terms">
                        J'accepte les&nbsp;
                        <a
                          href="https://www.originailes.fr/cgu-cgv-application-originailes/"
                          target="_blank"
                          rel="noopener"
                        >
                          conditions générales d'utilisation et de vente
                        </a>
                      </label>
                    </div>
                  )}
                </Field>
                {error && <div className="error-block">{error}</div>}
                <div className="btn-group flexColumn center">
                  <button type="submit" className="btn btn-primary">
                    <span>{(hasCode && "Je m'inscris") || 'Suivant'} </span>
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

RegisterPage.propTypes = {
  register: PropTypes.func.isRequired,
  loadSettings: PropTypes.func.isRequired,
  createUserFamilleCode: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  types_profils:
    state.data.entities.settings &&
    state.data.entities.settings['types_profils'] &&
    state.data.entities.settings['types_profils'].data
});

export default connect(mapStateToProps, {
  register,
  createUserFamilleCode,
  createUserStructureCode,
  checkEmail,
  loadSettings
})(RegisterPage);
