import React from 'react';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

import { upperFirst } from 'lodash';

const MonthSelector = ({ month, prevMonth, nextMonth }) => {
  return (
    <div className="subheader">
      <div className="subheader-nav">
        <span className="subheader-nav-select">{upperFirst(month)}</span>
        <div className="subheader-nav-actions">
          <i className="far fa-angle-left" onClick={() => prevMonth()} />
          <i className="far fa-angle-right" onClick={() => nextMonth()} />
        </div>
      </div>
      <div className="subheader-bkg">
        <svg width="0" height="0">
          <defs>
            <clipPath
              id="submenumask"
              clipPathUnits="objectBoundingBox"
              transform="scale(0.00266666666,0.01428571428)"
            >
              <path
                d="M361.2,70L23.8,64.3c-8.6-0.2-17-3-23.8-8.3V0h375v67.9
              c-4.2,1.5-8.6,2.1-13.1,2.1H361.2z"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default MonthSelector;
