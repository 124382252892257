import { CALL_API, Schemas } from '../Schemas';

import { values } from 'lodash';

const LOAD_USER_REQUEST = 'origiwa/auth/LOAD_USER_REQUEST';
const LOAD_USER_SUCCESS = 'origiwa/auth/LOAD_USER_SUCCESS';
const LOAD_USER_FAIL = 'origiwa/auth/LOAD_USER_FAIL';

const LOGIN_REQUEST = 'origiwa/auth/LOGIN_REQUEST';
const LOGIN_SUCCESS = 'origiwa/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'origiwa/auth/LOGIN_FAIL';

const REGISTER_REQUEST = 'origiwa/auth/REGISTER_REQUEST';
const REGISTER_SUCCESS = 'origiwa/auth/REGISTER_SUCCESS';
const REGISTER_FAIL = 'origiwa/auth/REGISTER_FAIL';

const LOGOUT_REQUEST = 'origiwa/auth/LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'origiwa/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'origiwa/auth/LOGOUT_FAIL';

const UPDATE_AUTH_USER = 'origiwa/auth/UPDATE_USER_AUTH_REQUEST';

const UPDATE_USER_REQUEST = 'origiwa/auth/UPDATE_USER_FAMILLE_REQUEST';
const UPDATE_USER_SUCCESS = 'origiwa/auth/UPDATE_USER_FAMILLE_SUCCESS';
const UPDATE_USER_FAILURE = 'origiwa/auth/UPDATE_USER_FAMILLE_FAILURE';

const UPDATE_USER_FAMILLE_REQUEST = 'origiwa/auth/UPDATE_USER_FAMILLE_REQUEST';
const UPDATE_USER_FAMILLE_SUCCESS = 'origiwa/auth/UPDATE_USER_FAMILLE_SUCCESS';
const UPDATE_USER_FAMILLE_FAILURE = 'origiwa/auth/UPDATE_USER_FAMILLE_FAILURE';

const UPDATE_USER_STRUCTURE_REQUEST = 'origiwa/auth/UPDATE_USER_STRUCTURE_REQUEST';
const UPDATE_USER_STRUCTURE_SUCCESS = 'origiwa/auth/UPDATE_USER_STRUCTURE_SUCCESS';
const UPDATE_USER_STRUCTURE_FAILURE = 'origiwa/auth/UPDATE_USER_STRUCTURE_FAILURE';

const CREATE_PASSWORD_REQUEST = 'origiwa/auth/CREATE_PASSWORD_REQUEST';
const CREATE_PASSWORD_SUCCESS = 'origiwa/auth/CREATE_PASSWORD_SUCCESS';
const CREATE_PASSWORD_FAILURE = 'origiwa/auth/CREATE_PASSWORD_FAILURE';

const RECOVER_PASSWORD_REQUEST = 'origiwa/auth/RECOVER_PASSWORD_REQUEST';
const RECOVER_PASSWORD_SUCCESS = 'origiwa/auth/RECOVER_PASSWORD_SUCCESS';
const RECOVER_PASSWORD_FAILURE = 'origiwa/auth/RECOVER_PASSWORD_FAILURE';

const GET_USER_BY_EMAIL_REQUEST = 'origiwa/auth/GET_USER_BY_EMAIL_REQUEST';
const GET_USER_BY_EMAIL_SUCCESS = 'origiwa/auth/GET_USER_BY_EMAIL_SUCCESS';
const GET_USER_BY_EMAIL_FAILURE = 'origiwa/auth/GET_USER_BY_EMAIL_FAILURE';

const CHECK_EMAIL_REQUEST = 'origiwa/auth/CHECK_EMAIL_REQUEST';
const CHECK_EMAIL_SUCCESS = 'origiwa/auth/CHECK_EMAIL_SUCCESS';
const CHECK_EMAIL_FAILURE = 'origiwa/auth/CHECK_EMAIL_FAILURE';

const GET_SUBCRIPTION_REQUEST = 'origiwa/auth/GET_SUBCRIPTION_REQUEST';
const GET_SUBCRIPTION_SUCCESS = 'origiwa/auth/GET_SUBCRIPTION_SUCCESS';
const GET_SUBCRIPTION_FAILURE = 'origiwa/auth/GET_SUBCRIPTION_FAILURE';

const DELETE_SUBCRIPTION_REQUEST = 'origiwa/auth/DELETE_SUBCRIPTION_REQUEST';
const DELETE_SUBCRIPTION_SUCCESS = 'origiwa/auth/DELETE_SUBCRIPTION_SUCCESS';
const DELETE_SUBCRIPTION_FAILURE = 'origiwa/auth/DELETE_SUBCRIPTION_FAILURE';

const CHANGE_ENFANT_SELECTED = 'origiwa/auth/CHANGE_ENFANT_SELECTED';

const UPDATE_USER_TOKEN = 'origiwa/auth/UPDATE_USER_TOKEN';

const initialState = {
  enfantSelected: null,
  loaded: false,
  isConnected: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_USER_SUCCESS:
      const dataResponse = values(action.response.entities.user)[0];

      return {
        ...state,
        loading: false,
        loaded: true,
        user: {
          ...dataResponse,
          nom: state.user.nom,
          isPremium: state.user.isPremium
        }
      };
    case LOAD_USER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isConnected: true,
        user: values(action.response.entities.user)[0]
      };
    case UPDATE_USER_TOKEN: {
      return {
        ...state,
        loggingIn: false,
        isConnected: true,
        user: {
          ...state.user,
          token: split(action.token, ' ', 2)[1]
        }
      };
    }
    case UPDATE_AUTH_USER:
      return {
        ...state,
        user: {
          ...state.user,
          usersIdsSecondaire: action.data
        },
        loginError: action.error
      };

    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        user: null,
        loginError: action.error
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        enfantSelected: null,
        user: null
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isConnected: true,
        user: {
          nom: state.user.nom,
          isPremium: state.user.isPremium,
          enfants: state.user.enfants,
          ...values(action.response.entities.user)[0]
        }
      };
    case CHANGE_ENFANT_SELECTED:
      return {
        ...state,
        enfantSelected: action.enfant
      };
    default:
      return state;
  }
}

export function updateUserToken(token) {
  return {
    token: token,
    type: UPDATE_USER_TOKEN
  };
}

export function register(data) {
  return {
    [CALL_API]: {
      types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAIL],
      method: 'POST',
      endpoint: '/auth/create',
      body: data
    }
  };
}

export function createUserFamilleCode(email, nom, pseudo, code) {
  return {
    [CALL_API]: {
      types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAIL],
      method: 'POST',
      endpoint: '/auth/create/userFamilyCode',
      body: { email, nom, pseudo, code }
    }
  };
}

export function createUserFamille(email, nom, customer, subscription) {
  return {
    [CALL_API]: {
      types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAIL],
      method: 'POST',
      endpoint: '/auth/create/userFamily',
      body: { email, nom, customer, subscription }
    }
  };
}

export function createUserStructureCode(email, nom, taille, code) {
  return {
    [CALL_API]: {
      types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAIL],
      method: 'POST',
      endpoint: '/auth/create/userStructureCode',
      body: { email, nom, taille, code }
    }
  };
}

export function createUserStructure(email, nom, taille, customer, subscription) {
  return {
    [CALL_API]: {
      types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAIL],
      method: 'POST',
      endpoint: '/auth/create/userStructure',
      body: { email, nom, taille, customer, subscription },
      applyNormalize: false
    }
  };
}

export function login(email, password) {
  return {
    [CALL_API]: {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL],
      method: 'POST',
      endpoint: '/auth/login',
      schema: Schemas.USER,
      body: { email, password },
      applyNormalize: false
    }
  };
}

export function logout() {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function checkEmail(email) {
  return {
    [CALL_API]: {
      types: [CHECK_EMAIL_REQUEST, CHECK_EMAIL_SUCCESS, CHECK_EMAIL_FAILURE],
      method: 'POST',
      endpoint: '/auth/checkEmail',
      body: {
        email
      }
    }
  };
}

export function createPassword(data) {
  return {
    [CALL_API]: {
      types: [CREATE_PASSWORD_REQUEST, CREATE_PASSWORD_SUCCESS, CREATE_PASSWORD_FAILURE],
      method: 'PUT',
      endpoint: '/auth/reset-password',
      body: data
    }
  };
}

export function recoverPassword(email) {
  return {
    [CALL_API]: {
      types: [RECOVER_PASSWORD_REQUEST, RECOVER_PASSWORD_SUCCESS, RECOVER_PASSWORD_FAILURE],
      method: 'PUT',
      endpoint: '/auth/password',
      body: {
        email
      }
    }
  };
}

export function loadUser(userId) {
  return {
    userId,
    [CALL_API]: {
      types: [LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOAD_USER_FAIL],
      method: 'GET',
      endpoint: `/auth/me/${userId}`,
      schema: Schemas.USER,
      applyNormalize: false
    }
  };
}

export function getUserByEmail(email) {
  return {
    [CALL_API]: {
      types: [GET_USER_BY_EMAIL_REQUEST, GET_USER_BY_EMAIL_SUCCESS, GET_USER_BY_EMAIL_FAILURE],
      method: 'POST',
      endpoint: '/users/getUser/email',
      body: {
        email
      }
    }
  };
}

export function getIntervenantByEmail(email) {
  return {
    [CALL_API]: {
      types: [GET_USER_BY_EMAIL_REQUEST, GET_USER_BY_EMAIL_SUCCESS, GET_USER_BY_EMAIL_FAILURE],
      method: 'POST',
      endpoint: '/users/getIntervenant/email',
      body: {
        email
      }
    }
  };
}

export function getSubscription(userId) {
  return {
    userId,
    [CALL_API]: {
      types: [GET_SUBCRIPTION_REQUEST, GET_SUBCRIPTION_SUCCESS, GET_SUBCRIPTION_FAILURE],
      method: 'GET',
      endpoint: `/users/subscription/${userId}`
    }
  };
}

export function updateUser(user, data) {
  return {
    user,
    [CALL_API]: {
      types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
      method: 'PUT',
      schema: Schemas.USER,
      body: data,
      endpoint: `/users/${user.user_id}`,
      successNext: [state => loadUser(user.user_id)],
      successMessage: 'Votre compte a bien été modifier'
    }
  };
}

export function updateUserFamilyResuscribe(data) {
  return {
    [CALL_API]: {
      types: [
        UPDATE_USER_FAMILLE_REQUEST,
        UPDATE_USER_FAMILLE_SUCCESS,
        UPDATE_USER_FAMILLE_FAILURE
      ],
      method: 'PUT',
      schema: Schemas.USER_ARRAY,
      endpoint: `/users/update/userFamilyResuscribe/${data.user_id}`,
      body: data
    }
  };
}

export function updateUserStructureResuscribe(data) {
  return {
    [CALL_API]: {
      types: [
        UPDATE_USER_STRUCTURE_REQUEST,
        UPDATE_USER_STRUCTURE_SUCCESS,
        UPDATE_USER_STRUCTURE_FAILURE
      ],
      method: 'PUT',
      schema: Schemas.USER_ARRAY,
      endpoint: `/users/update/userStructureResuscribe/${data.user_id}`,
      body: data
    }
  };
}

export function changeEnfant(enfant) {
  return {
    type: CHANGE_ENFANT_SELECTED,
    enfant
  };
}

export function deleteSubscription(userId, subId) {
  return {
    userId,
    subId,
    [CALL_API]: {
      types: [DELETE_SUBCRIPTION_REQUEST, DELETE_SUBCRIPTION_SUCCESS, DELETE_SUBCRIPTION_FAILURE],
      method: 'DELETE',
      endpoint: `/users/subscription/${userId}/${subId}`
    }
  };
}

export function updateAuthUser(data) {
  return {
    type: UPDATE_AUTH_USER,
    data: [data]
  };
}
