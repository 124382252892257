import React, { Fragment, useState } from 'react';
import ModalCardFormPayment from '../../components/modals/Stripe/ModalCardFormPayment';
import ModalSuccessAccountCreation from '../../components/modals/ModalSuccessAccountCreation';

import BaseLayout from '../shared/BaseLayout';

const PriceChoiceBackPage = ({ user }) => {
  const [error, setError] = useState(null);
  const [chosenPrice, setChosenPrice] = useState(null);
  const [chosenSize, setChosenSize] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [isOpenModalCardFormPayment, setIsOpenModalCardFormPayment] = useState(false);

  const handleSubmit = (price, taille) => {
    setChosenPrice(price);
    setChosenSize(taille);
    setIsOpenModalCardFormPayment(true);
  };
  return (
    <BaseLayout backBtn noFooter isPage bigTitle headerPageTitle="Formules" classScreen="formules">
      {modalSuccess && (
        <ModalSuccessAccountCreation
          isOpen={modalSuccess}
          titleMessage={`Bon retour sur Origin'ailes !`}
          message={'Votre abonnement a été renouvelé avec succès !'}
        />
      )}

      {user && user.profil === 'FAMILLE' && (
        <ModalCardFormPayment
          user={user}
          chosenPrice={chosenPrice}
          isOpen={isOpenModalCardFormPayment}
          error={error}
          setError={() => setError()}
          setModalSuccess={v => setModalSuccess(v)}
          setIsOpenModalCardFormPayment={v => setIsOpenModalCardFormPayment(v)}
        />
      )}
      {user && user.profil === 'STRUCTURE' && (
        <ModalCardFormPayment
          isMultipleTypePaiements
          dataUser={user}
          chosenPrice={chosenPrice}
          chosenSize={chosenSize}
          isOpen={isOpenModalCardFormPayment}
          error={error}
          setError={() => setError()}
          setModalSuccess={v => setModalSuccess(v)}
          setIsOpenModalCardFormPayment={v => setIsOpenModalCardFormPayment(v)}
        />
      )}

      {user && user.profil === 'FAMILLE' && (
        <div className="content">
          <div className="container">
            <div className="packages-container">
              <div className="grid-center">
                <div className="col-4_xs-12_md-4">
                  <div className="package">
                    <div className="package-title">Abonnement Mensuel</div>
                    <div className="package-separator"></div>
                    <div className="package-price">
                      29,99 €<span>TTC / mois</span>
                    </div>
                    <div className="package-benefits-block">
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>1 compte famille avec 1 profil enfant</span>
                      </div>
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>Nombre d’intervenants illimité</span>
                      </div>
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>5 Go de stockage</span>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        handleSubmit('ABONNEMENT_MENSUEL_SANS_ESSAI');
                      }}
                      className="btn btn-secondary"
                    >
                      Sélectionner
                    </button>
                  </div>
                </div>
                <div className="col-4_xs-12_md-4">
                  <div className="package package-secondary">
                    <div className="package-promo-block">
                      <div className="package-promo">2 mois d’abonnement offerts</div>
                    </div>
                    <div className="package-title">Abonnement Annuel</div>
                    <div className="package-separator"></div>
                    <div className="package-price">
                      299,90 €<span>TTC / an</span>
                    </div>
                    <div className="package-price-details">(soit 24,99€/mois)</div>
                    <div className="package-benefits-block">
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>1 compte famille avec 1 profil enfant</span>
                      </div>
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>Nombre d’intervenants illimité</span>
                      </div>
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>5 Go de stockage</span>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        handleSubmit('ABONNEMENT_ANNUEL_SANS_ESSAI');
                      }}
                      className="btn btn-secondary"
                    >
                      Sélectionner
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {user && user.profil === 'STRUCTURE' && (
        <div className="main-container">
          <div className="content">
            <div className="packages-container">
              <div className="grid-center">
                <div className="col-4_xs-12_md-4">
                  <div className="package package-secondary">
                    <div className="package-title">Abonnement Annuel</div>
                    <div className="package-separator"></div>
                    <div className="package-price">
                      1599 €<span>TTC / an</span>
                    </div>
                    <div className="package-price-details">(soit 133,25€/mois)</div>
                    <div className="package-benefits-block">
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>1 compte qui peut gérer jusqu'à 5 enfants</span>
                      </div>
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>Nombre d’intervenants illimité</span>
                      </div>
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>5 Go de stockage</span>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        handleSubmit('ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_1_SANS_ESSAI', '5');
                      }}
                      className="btn btn-secondary"
                    >
                      Sélectionner
                    </button>
                  </div>
                </div>
                <div className="col-4_xs-12_md-4">
                  <div className="package package-secondary">
                    <div className="package-title">Abonnement Annuel</div>
                    <div className="package-separator"></div>
                    <div className="package-price">
                      2999 €<span>TTC / an</span>
                    </div>
                    <div className="package-price-details">(soit 249,92€/mois)</div>
                    <div className="package-benefits-block">
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>1 compte qui peut gérer jusqu'à 10 enfants</span>
                      </div>
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>Nombre d’intervenants illimité</span>
                      </div>
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>5 Go de stockage</span>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        handleSubmit('ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_2_SANS_ESSAI', '10');
                      }}
                      className="btn btn-secondary"
                    >
                      Sélectionner
                    </button>
                  </div>
                </div>
                <div className="col-4_xs-12_md-4">
                  <div className="package package-secondary">
                    <div className="package-title">Abonnement Annuel</div>
                    <div className="package-separator"></div>
                    <div className="package-price">
                      5299 €<span>TTC / an</span>
                    </div>
                    <div className="package-price-details">(soit 451,58€/mois)</div>
                    <div className="package-benefits-block">
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>1 compte qui peut gérer jusqu'à 20 enfants</span>
                      </div>
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>Nombre d’intervenants illimité</span>
                      </div>
                      <div className="package-benefit">
                        <i className="far fa-check" />
                        <span>5 Go de stockage</span>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        handleSubmit('ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_3_SANS_ESSAI', '20');
                      }}
                      className="btn btn-secondary"
                    >
                      Sélectionner
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </BaseLayout>
  );
};

export default PriceChoiceBackPage;
