import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toUpper, upperFirst, map, orderBy, filter, omit, values, includes } from 'lodash';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import ModalConfirmDelete from '../modals/ModalConfirmDelete';
import AvatarMask from '../../utils/AvatarMask';
import AvatarOutline from '../../utils/AvatarOutline';
import { deleteMessage, updateMessage } from '../../store/modules/messages';
import { removeExtension, getExtension } from '../../utils/StringUtils';
import { generateHexString } from '../../utils/key';
import { getProfilImgMin } from '../../utils/methodes';
import { API_URL } from '../../constants/Config';

const CardActu = ({ actu, types_profils, user, users, enfant, updateMessage, deleteMessage }) => {
  const [displayFormComment, setDisplayFormComment] = useState(false);
  const [openModalDeleteCom, setOpenModalDeleteCom] = useState(false);
  const [openModalDeleteActu, setOpenModalDeleteActu] = useState(false);
  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = data => {
    const actuMessage = omit(actu, ['_id', 'enfant', 'user', 'day']);

    const commentaire = [
      ...actu.commentaires,
      {
        commentaire_id: generateHexString(10),
        user_id: user.user_id,
        date: new Date(),
        text: data.commentaire
      }
    ];
    actuMessage.commentaires = commentaire;

    updateMessage(actu, actuMessage)
      .then(() => {
        setDisplayFormComment(false);
      })
      .catch(err => {
        setError(err);
      });
  };

  const deleteCommentaire = id => {
    return updateMessage(actu, {
      commentaires: filter(actu.commentaires, com => com.commentaire_id !== id)
    })
      .then(() => setDisplayFormComment(false))
      .catch(err => setError(err));
  };

  const deleteActu = () => {
    return deleteMessage(actu)
      .then(() => {
        setError(null);
        setOpenModalDeleteActu(false);
      })
      .catch(err => setError(err));
  };

  const isAuthor = actu.user_id === user.user_id;
  const isParent = user.user_id === enfant.parent_id;
  const isParentSecondaire = user.userIdPrincipal === enfant.parent_id;
  const isFamilleRattachee =
    user.profil === 'FAMILLE_STRUCTURE' && includes(enfant.equipe, user.user_id);

  const renderHeaderPost = () => {
    switch (actu.type) {
      case 'comptes_rendus':
        return (
          <Fragment>
            <div className="actu-message">Un compte-rendu a été ajouté</div>
            <NavLink className="element-tag" to={`/compte-rendu/${actu.type_id}`}>
              <i className="fal fa-file-contract" />
              <span>Séance du {moment(actu.date).format('DD-MM')}</span>
            </NavLink>
          </Fragment>
        );
      case 'programmes':
        return (
          <Fragment>
            <div className="actu-message">Un programme a été ajouté</div>
            <NavLink className="element-tag" to={`/programme/${actu.type_id}`}>
              <i className="fal fa-clipboard-list-check" />
              <span>{actu.text}</span>
            </NavLink>
          </Fragment>
        );
      case 'dossier_prise_charge':
        return (
          <Fragment>
            <div className="actu-message">Un dossier de prise en charge a été ajouté</div>
            <NavLink className="element-tag" to={`/dossier-prise-en-charge/${actu.type_id}`}>
              <i className="fal fa-folders" />
              <span>{actu.text}</span>
            </NavLink>
          </Fragment>
        );
    }
  };

  const displayAvatar = user => {
    if (user && (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE')) {
      // COMPTE DE TYPE FAMILLE
      return (
        <div className="member-avatar">
          <div className="avatar-mask">
            {enfant.profil.photo ? (
              <img src={getProfilImgMin(enfant.profil.photo)} />
            ) : (
              <i className="fas fa-user" />
            )}
          </div>
          <AvatarMask nameFile="CardActu" />
          <AvatarOutline nameFile="CardActu" />
        </div>
      );
    } else {
      return user && user.photo ? (
        <div className="member-avatar">
          <div className="avatar-mask">
            <img src={getProfilImgMin(user.photo)} />
          </div>
          <AvatarMask nameFile="CardActu" />
          <AvatarOutline nameFile="CardActu" />
        </div>
      ) : (
        <div className="member-avatar">
          <span>
            {user && user.prenom && toUpper(user.prenom[0])}
            {user && user.nom && toUpper(user.nom[0])}
          </span>
          <AvatarOutline nameFile="CardActu" />
        </div>
      );
    }
  };
  return (
    <div className={`card card-actu ${actu.user_id === user.user_id ? 'card-actu-perso' : ''}`}>
      <div className="card-actu-header">
        <div className="member">
          {displayAvatar(actu.user)}
          <div className="member-infos">
            <div className="member-name">
              {((actu.user_id === enfant.parent_id ||
                (actu.user && actu.user.userIdPrincipal === enfant.parent_id)) &&
                `Famille de ${actu.enfant &&
                  actu.enfant.profil &&
                  upperFirst(actu.enfant.profil.prenom)}`) ||
                (actu.user && actu.user.prenom)}
            </div>
            <div className="member-profil">
              {actu.user_id === enfant.parent_id ||
              (actu.user && actu.user.userIdPrincipal === enfant.parent_id)
                ? (actu.user && actu.user.pseudo) || 'Famille'
                : actu.user && types_profils[actu.user.profil]}
            </div>
          </div>
        </div>
        <div className="date">{moment(actu.date).format('HH:mm')}</div>
      </div>
      <div className="card-actu-content">
        {actu.type !== 'message' && renderHeaderPost()}

        {actu.file && (
          <div className="file-preview">
            {includes(['image/png', 'image/jpeg', 'image/jpg'], actu.file_type) && (
              <img
                srcSet={map(
                  ['640w', '1024w', '1920w'],
                  taille =>
                    `${API_URL}/uploads/${removeExtension(actu.file)}-${taille}${getExtension(
                      actu.file
                    )} ${taille}`
                )}
              />
            )}
            {actu.file_type === 'application/pdf' && (
              <a target="_blank" href={`${API_URL}/uploads/${actu.file}`}>
                <i className="fas fa-file-pdf" /> {actu.file}
              </a>
            )}
            {includes(['video/mp4', 'video/quicktime'], actu.file_type) && (
              <video controls width="100%">
                <source src={`${API_URL}/uploads/min_${actu.file}`} type="video/mp4" />
              </video>
            )}
          </div>
        )}
        {actu.type === 'message' && <div className="actu-message">{actu.text}</div>}
      </div>
      <div className="card-actu-actions">
        {(isAuthor || isParent || isParentSecondaire || isFamilleRattachee) && (
          <Fragment>
            <button className="btn" onClick={() => setOpenModalDeleteActu(true)}>
              <i className="fal fa-trash-alt" />
              <span>Supprimer</span>
            </button>
            <ModalConfirmDelete
              message={'Êtes-vous sûr de vouloir supprimer ce message ?'}
              openModal={openModalDeleteActu}
              closeModal={() => setOpenModalDeleteActu(false)}
              actionDelete={deleteActu}
            />
          </Fragment>
        )}
        <div className="btn" onClick={() => setDisplayFormComment(!displayFormComment)}>
          <i className="fal fa-comment-alt" />
          <span>Commenter</span>
        </div>
      </div>

      {actu.commentaires && actu.commentaires.length > 0 && (
        <div className="card-actu-comments">
          {actu.commentaires.length > 0 &&
            map(orderBy(actu.commentaires, ['date'], ['asc']), (com, index) => {
              const isAuthor = com.user_id === user.user_id;
              const isProprietaire = actu.user_id === user.user_id;

              const deleteAuthorize = isAuthor || isProprietaire;

              return (
                <div className="card-actu-comment" key={com.commentaire_id}>
                  {deleteAuthorize && (
                    <Fragment>
                      <button
                        className="btn-erase"
                        onClick={() => setOpenModalDeleteCom(com.commentaire_id)}
                      >
                        <i className="fas fa-trash-alt" />
                      </button>

                      <ModalConfirmDelete
                        message={'Êtes-vous sûr de vouloir supprimer ce commentaire ?'}
                        openModal={openModalDeleteCom === com.commentaire_id}
                        closeModal={() => setOpenModalDeleteCom(false)}
                        actionDelete={() => deleteCommentaire(com.commentaire_id)}
                      />
                    </Fragment>
                  )}

                  <div className="member">
                    {displayAvatar(com.user)}

                    <div className="card-actu-comment-block">
                      <div className="card-actu-comment-block-header">
                        <div className="member-infos">
                          <div className="member-name">
                            {((com.user_id === actu.famille_id ||
                              (com.user &&
                                com.user.userIdPrincipal &&
                                com.user.userIdPrincipal === actu.famille_id)) &&
                              `Famille de ${upperFirst(actu.enfant.profil.prenom)}`) ||
                              (users[com.user_id] && users[com.user_id].prenom) ||
                              ''}
                          </div>
                          <div className="member-pseudo">{(com.user && com.user.pseudo) || ''}</div>
                        </div>
                        <div className="card-actu-comment-seniority">
                          {moment(com.date).fromNow()}
                        </div>
                      </div>
                      <div className="card-actu-comment-message">{com.text}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {displayFormComment && (
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form className="card-actu-comment-form" onSubmit={handleSubmit}>
              <Field name="commentaire" validate={required} component="input" className="field">
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <textarea {...input} placeholder="Écrivez un commentaire..." />
                  </div>
                )}
              </Field>

              {error && <div className="error-block">{error}</div>}

              <button type="submit" className="btn btn-primary">
                <span>Publier</span>
              </button>
            </form>
          )}
        />
      )}
    </div>
  );
};

CardActu.propTypes = {
  actu: PropTypes.object.isRequired,
  updateMessage: PropTypes.func.isRequired,
  deleteMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const typeProfil = state.auth.user.profil;
  return {
    enfant: state.auth.enfantSelected,
    user: state.auth.user,
    users: ((typeProfil === 'FAMILLE' || typeProfil === 'STRUCTURE') && {
      ...{
        [state.auth.user.user_id]: {
          ...state.auth.user
        }
      },
      ...state.data.entities.intervenants
    }) || {
      ...state.data.entities.intervenants,
      ...state.data.entities.parents
    },
    types_profils:
      state.data.entities.settings &&
      state.data.entities.settings['types_profils'] &&
      state.data.entities.settings['types_profils'].data,
    enfants: state.data.entities.enfants
  };
};

export default connect(mapStateToProps, { updateMessage, deleteMessage })(CardActu);
