import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { API_URL } from '../../constants/Config';

import { connect } from 'react-redux';
import { getDocumentMedia, deleteDocumentMedia } from '../../store/modules/documentsMedias';
import { isDroitUser, isIOSDevice } from '../../utils/methodes';

import ModalAddUpdateProgramme from '../../components/modals/programmes/ModalAddUpdateProgramme';
import ModalConfirmDelete from '../../components/modals/ModalConfirmDelete';

const ProgrammePage = ({
  user,
  enfantSelected,
  programme,
  getDocumentMedia,
  deleteDocumentMedia
}) => {
  const [error, setError] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const navigation = useHistory();
  const documentId = useParams();

  useEffect(() => {
    getDocumentMedia(user.profil, user.user_id, enfantSelected.enfant_id, documentId.id);
  }, []);

  const deleteDoc = () => {
    return deleteDocumentMedia(programme)
      .then(() => {
        navigation.goBack();
      })
      .catch(err => {
        setError(err);
      });
  };

  return (
    <div id="wrapper" className="page-preview">
      <header className="header">
        {(isDroitUser('programmes', user, enfantSelected) === 'MODIFICATION' ||
          user.profil === 'FAMILLE' ||
          user.profil === 'STRUCTURE') && (
          <div className="header-right">
            <ModalAddUpdateProgramme isEdit programme={programme && programme} />

            <div onClick={() => setOpenModalDelete(true)} className="header-btn">
              <i className="fas fa-trash-alt" />
            </div>

            <ModalConfirmDelete
              message={'Êtes-vous sûr de vouloir supprimer ce programme ?'}
              openModal={openModalDelete}
              closeModal={() => setOpenModalDelete(false)}
              actionDelete={() => deleteDoc()}
            />
          </div>
        )}
        <div className="header-bkg">
          <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
          <svg height="0px" width="0px">
            <defs>
              <clipPath
                id="menumask"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.00266666666,0.01666666666)"
              >
                <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </header>
      <main>
        <div className="container">
          <h2>{programme && programme.titre}</h2>

          <a
            className="attachment"
            target="_blank"
            href={`${API_URL}/uploads/${programme && programme.file}`}
          >
            <i className="fas fa-file-pdf" />
            <span>{programme && programme.file}</span>
          </a>

          {isIOSDevice() && (
            <p>
              Pour revenir sur l'application après avoir consulté le document, balayez l'écran du
              bord gauche vers la droite
            </p>
          )}
        </div>

        <div className="btn-group center bottom-sticky">
          <button className="btn btn-primary" onClick={() => navigation.goBack()}>
            Fermer
          </button>
        </div>
      </main>
    </div>
  );
};

ProgrammePage.propTypes = {
  programme: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getDocumentMedia: PropTypes.func.isRequired,
  deleteDocumentMedia: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { documentId }
    }
  } = props;
  const programme = state?.data?.entities?.documentsMedias[props.match.params.id];
  return {
    enfantSelected: state?.auth?.enfantSelected,
    user: state?.auth?.user,
    programme: programme
  };
};

export default connect(mapStateToProps, { getDocumentMedia, deleteDocumentMedia })(ProgrammePage);
