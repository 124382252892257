import React, { useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { validationPassword } from '../../utils/StringValidator';

import { connect } from 'react-redux';
import { createPassword } from '../../store/modules/auth';

import BaseLayout from '../shared/BaseLayout';

import { Form, Field } from 'react-final-form';
import { toLower } from 'lodash';

const DefinirPasswordPage = ({ createPassword }) => {
  const history = useHistory();
  const { email, userid, token, profil } = useParams();
  const [displayPassword, setDisplayPassword] = useState(false);
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);

  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredPassword = value =>
    value
      ? validationPassword(value)
        ? undefined
        : 'Votre mot de passe doit au moins 8 caractères avec 1 minuscule, 1 majuscule et 1 chiffre'
      : 'Champ requis !';

  const handleSubmit = values => {
    const data = values;

    data.isFamille = profil === 'FAMILLE';
    data.authorization_token = token;
    data.reset_token = token;
    data.userId = Number(userid);

    if (data.password === data.password_repeat) {
      return createPassword(data)
        .then(() => {
          setError(null);
          history.push('/connexion');
        })
        .catch(err => setError(err));
    } else {
      setError("Votre mot de passe n'est pas identique !");
    }
  };
  return (
    <BaseLayout noFooter isPage classScreen="" headerPageTitle="Définir mon mot de passe">
      <div className="main-container">
        <div className="content">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="email"
                  component="input"
                  className="field"
                  initialValue={(email && toLower(email)) || ' '}
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" disabled={true} />
                      <label>Adresse e-mail *</label>
                    </div>
                  )}
                </Field>

                <Field
                  validate={requiredPassword}
                  name="password"
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input
                        {...input}
                        type={(displayPassword && 'text') || 'password'}
                        onChange={value => {
                          setError(null);
                          input.onChange(value);
                        }}
                      />
                      <label>Mot de passe *</label>
                      {displayPassword ? (
                        <span className="clear-password" onClick={() => setDisplayPassword(false)}>
                          <i className="fas fa-eye-slash" />
                        </span>
                      ) : (
                        <span className="clear-password" onClick={() => setDisplayPassword(true)}>
                          <i className="fas fa-eye" />
                        </span>
                      )}
                    </div>
                  )}
                </Field>
                <Field
                  validate={required}
                  name="password_repeat"
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input
                        {...input}
                        type={(displayConfirmPassword && 'text') || 'password'}
                        onChange={value => {
                          setError(null);
                          input.onChange(value);
                        }}
                      />
                      <label>Confirmer votre mot de passe *</label>
                      {displayConfirmPassword ? (
                        <span
                          className="clear-password"
                          onClick={() => setDisplayConfirmPassword(false)}
                        >
                          <i className="fas fa-eye-slash" />
                        </span>
                      ) : (
                        <span
                          className="clear-password"
                          onClick={() => setDisplayConfirmPassword(true)}
                        >
                          <i className="fas fa-eye" />
                        </span>
                      )}
                    </div>
                  )}
                </Field>
                {error && <div className="error-block">{error}</div>}
                <div className="btn-group flexColumn center">
                  <button type="submit" className="btn btn-primary">
                    <span>Valider</span>
                  </button>

                  <NavLink className="btn-link btn-link-secondary" to="/connexion">
                    Revenir à la page de connexion
                  </NavLink>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

DefinirPasswordPage.propTypes = {
  createPassword: PropTypes.func.isRequired
};

export default connect(null, { createPassword })(DefinirPasswordPage);
