import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { isDroitUser, sortObjectByKeys } from '../../utils/methodes';

import { connect } from 'react-redux';
import { getDocumentsMedias } from '../../store/modules/documentsMedias';

import BaseLayout from '../shared/BaseLayout';

import ModalAddMedia from '../../components/modals/ModalAddMedia';
import MediaCard from '../../components/cards/MediaCard';

import moment from 'moment';
import { filter, includes, map, groupBy, orderBy } from 'lodash';

const MediasPage = ({ medias, getDocumentsMedias, user, enfantSelected }) => {
  useEffect(() => {
    getDocumentsMedias(user.profil, user.user_id, enfantSelected && enfantSelected.enfant_id);
  }, []);

  const mediasGroupByDate = sortObjectByKeys(
    groupBy(
      map(medias, m => {
        return { ...m, day: moment(m.created_at).format('YYYY-MM-DD') };
      }),
      'day'
    ),
    'desc'
  );

  return (
    <BaseLayout headerPageTitle="Médias" isPage homeBtn noFooter classScreen="MediasPage">
      <div className="main-container">
        <div className="content">
          <div className="liste">
            {map(mediasGroupByDate, (medias, date) => {
              return (
                <div className="list-block" key={date}>
                  <div className="list-block-title">
                    {moment(medias[0].created_at).format('ddd DD MMM')}
                  </div>
                  <div className="grid-xs-gutter grid-6_xs-3_sm-4_md-5_lg-5_xl-6">
                    {map(orderBy(medias, ['created_at'], ['desc']), m =>
                      (enfantSelected && m.enfant_id === enfantSelected.enfant_id) ||
                      user.profil === 'FAMILLE' ? (
                        <div className="col" key={m.document_id}>
                          <MediaCard media={m} />
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {(user.profil === 'FAMILLE' ||
            user.profil === 'STRUCTURE' ||
            includes(['ADMINISTRATION'], isDroitUser('medias', user, enfantSelected))) && (
            <ModalAddMedia />
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

MediasPage.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  enfantSelected: state.auth.enfantSelected && state.auth.enfantSelected,
  medias:
    (state.data.entities.documentsMedias &&
      filter(
        state.data.entities.documentsMedias,
        d => d.type === 'medias' && d.enfant_id === state.auth.enfantSelected.enfant_id
      )) ||
    []
});

export default connect(mapStateToProps, { getDocumentsMedias })(MediasPage);
