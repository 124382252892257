import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getProfilImgMin, removeFirstCaracter0 } from '../../utils/methodes';

import { connect } from 'react-redux';
import { deleteEvent } from '../../store/modules/events';

import ModalAddUpdatePlanning from '../../components/modals/ModalAddUpdatePlanning';
import ModalConfirmDelete from '../../components/modals/ModalConfirmDelete';
import moment from 'moment';
import 'moment/locale/fr';
import AvatarMask from '../../utils/AvatarMask';
import AvatarOutline from '../../utils/AvatarOutline';

moment.locale('fr');

const PlanningPage = ({ user, event, deleteEvent, intervenant, types_profils }) => {
  const [error, setError] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const navigation = useHistory();

  const deletePlanning = () => {
    return deleteEvent(event)
      .then(() => {
        navigation.push('/plannings');
      })
      .catch(err => {
        setError(err);
      });
  };

  return (
    <div id="wrapper" className="page-preview">
      <header className="header">
        <div className="header-right">
          {user && (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') && (
            <ModalAddUpdatePlanning isEdit event={event && event} />
          )}
          {user && (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') && (
            <div onClick={() => setOpenModalDelete(true)} className="header-btn">
              <i className="fas fa-trash-alt" />
            </div>
          )}
          <ModalConfirmDelete
            message={'Êtes-vous sûr de vouloir supprimer cet événement ?'}
            openModal={openModalDelete}
            closeModal={() => setOpenModalDelete(false)}
            actionDelete={() => deletePlanning()}
          />
        </div>
        <div className="header-bkg">
          <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
          <svg height="0px" width="0px">
            <defs>
              <clipPath
                id="menumask"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.00266666666,0.01666666666)"
              >
                <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </header>
      <main>
        <div className="container">
          <h2>{event && event.titre}</h2>
          <div className="event-schedule-infos">
            <div className="event-date">{event && moment(event.date).format('dddd D MMMM')}</div>
            <div className="event-schedule">
              <span className="event-start">
                {event &&
                  removeFirstCaracter0(
                    moment(event.heure_debut)
                      .format('HH:mm')
                      .replace(':', 'h')
                  )}
              </span>
              &nbsp;-&nbsp;
              {event &&
                removeFirstCaracter0(
                  moment(event.heure_fin)
                    .format('HH:mm')
                    .replace(':', 'h')
                )}
            </div>
          </div>
          {event && event.description && (
            <div className="event-description">{event.description}</div>
          )}
          {intervenant && intervenant.profil !== 'FAMILLE' && (
            <Fragment>
              <div className="separator" />
              <div className="member">
                <div className="member-avatar">
                  {intervenant && intervenant.photo ? (
                    <Fragment>
                      <div className="avatar-mask">
                        <img src={getProfilImgMin(intervenant.photo)} alt="photo" />
                      </div>
                      <AvatarMask nameFile="PlanningPage" />
                      <AvatarOutline nameFile="PlanningPage" />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <span>
                        {intervenant.prenom[0]}
                        {intervenant.nom[0]}
                      </span>
                      <AvatarOutline nameFile="PlanningPage" bigger />
                    </Fragment>
                  )}
                </div>
                <div className="member-infos">
                  <div className="member-name">
                    {intervenant.prenom} {intervenant.nom}
                  </div>

                  <div className="member-profil">{types_profils[intervenant.profil]}</div>
                </div>
              </div>
            </Fragment>
          )}
          {event && event.adresse && (
            <Fragment>
              <div className="info-value">
                <div className="separator" />
                <i className="fas fa-map-marker-alt" />
                <a target="_blank" href={`https://www.google.com/maps/place/${event.adresse}`}>
                  {event.adresse}
                </a>
              </div>
            </Fragment>
          )}
        </div>

        <div className="btn-group center bottom-sticky">
          <button className="btn btn-primary" onClick={() => navigation.push('/plannings')}>
            Fermer
          </button>
        </div>
      </main>
    </div>
  );
};

PlanningPage.propTypes = {
  event: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  deleteEvent: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { id }
    }
  } = props;
  const event = state.data.entities.events && state.data.entities.events[id];
  return {
    user: state.auth.user,
    event: event,
    intervenant: event && event.contact !== 'AUCUN' && event.user_contact,
    types_profils:
      state.data.entities.settings &&
      state.data.entities.settings['types_profils'] &&
      state.data.entities.settings['types_profils'].data
  };
};

export default connect(mapStateToProps, { deleteEvent })(PlanningPage);
