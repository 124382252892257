import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, trim } from 'lodash';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Modal from 'react-modal';
import { STRIPE_PUBLIC_KEY } from '../../../constants/Config';
import { selectPrice } from '../../../constants/Properties';
import { checkPromoCode } from '../../../store/modules/stripe';
import SplitForm from '../../forms/Stripe/SplitForm';
import IbanForm from '../../forms/Stripe/IbanForm';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const ModalCardFormPayment = ({
  user,
  dataUser,
  email,
  nom,
  chosenPrice,
  chosenSize,
  isOpen,
  setModalSuccess,
  setIsOpenModalCardFormPayment,
  error,
  setError,
  isMultipleTypePaiements,
  checkPromoCode
}) => {
  const [typePaiement, setTypePaiement] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [loadingCode, setLoadingCode] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoInput, setPromoInput] = useState(null);
  const [emptyField, setEmptyField] = useState(true);
  const [promoAmount, setPromoAmount] = useState(null);

  function handleSubmit() {
    setLoadingCode(true);
    return Promise.resolve()
      .then(() => {
        return checkPromoCode(promoInput);
      })
      .then(data => {
        setLoadingCode(false);
        setPromoCode(data.response.id);
        setPromoAmount(parseFloat(data.response.coupon.amount_off) / 100);
      })
      .catch(err => {
        setErrorCode(err.message || err);
        setLoadingCode(false);
      });
  }

  return (
    <Fragment>
      <Modal
        className="modal-content full-modal formules"
        overlayClassName="modal-overlay"
        id="modal-CardFormPayment"
        isOpen={isOpen}
      >
        <header className="header header-normal header-big-title">
          <div className="header-container">
            <span
              className="btn"
              onClick={() => {
                setIsOpenModalCardFormPayment(false);
                setErrorCode(null);
                setPromoCode(null);
                setPromoAmount(null);
              }}
            >
              <i style={{ color: 'white' }} className="far fa-long-arrow-left" />
              <span>retour</span>
            </span>
            <div className="header-center">
              <div className="header-title">Paiement</div>
            </div>
          </div>
          <div className="header-bkg">
            <img className="oiseau" src={require(`../../../../assets/images/oiseau.svg`)} />
            <img className="hexa" src={require(`../../../../assets/images/hexa-orange.svg`)} />
            <svg height="0px" width="0px">
              <defs>
                <clipPath
                  id="menumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01666666666)"
                >
                  <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </header>
        <div className="modal-body">
          {selectPrice[chosenPrice]}

          {!isMultipleTypePaiements && (
            <div className="flexbox flexColumn alignCenter">
              <div className="field-input-button">
                <input
                  type="text"
                  placeholder={'Code promotionnel'}
                  onChange={event => {
                    setPromoInput(event.target.value);
                    setErrorCode(null);
                    if (!isEmpty(event.target.value)) {
                      setEmptyField(false);
                    } else if (emptyField) {
                      setEmptyField(true);
                    }
                  }}
                />
                <button
                  className="btn btn-primary"
                  disabled={(loadingCode, emptyField)}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Valider
                </button>
              </div>
              {errorCode && (
                <div className="error-block" style={{ marginTop: '2px', marginBottom: '2px' }}>
                  <div className="error-icon">
                    <i className="fas fa-exclamation-triangle" />
                  </div>
                  <div className="error-message">{errorCode}</div>
                </div>
              )}
              {promoAmount && (
                <div
                  className="highlight-block highlight-block-tertiary"
                  style={{ marginTop: '2px', marginBottom: '20px' }}
                >
                  {`La somme de ${promoAmount} € sera déduite de votre premier paiement`}
                </div>
              )}
            </div>
          )}

          {user && user.transactionReceiptHistory ? (
            <div className="package-description">
              Entrez vos coordonnées bancaires pour vous réabonner.
            </div>
          ) : (
            <div className="package-description">
              Entrez vos coordonnées bancaires pour débloquer votre essai gratuit de 30 jours. Vous
              serez débité à l’issue de cette période (sauf en cas de résiliation de votre part).
            </div>
          )}

          {isMultipleTypePaiements ? (
            <Elements stripe={stripePromise}>
              <div
                className={`accordion-item ${typePaiement &&
                  typePaiement === 'PRELEVEMENT' &&
                  'active'}`}
              >
                <div
                  className="accordion-title"
                  onClick={() =>
                    typePaiement && typePaiement === 'PRELEVEMENT'
                      ? setTypePaiement('')
                      : setTypePaiement('PRELEVEMENT')
                  }
                >
                  <div>
                    <i className="far fa-regular fa-landmark" />
                    <span>Prélèvement Automatique SEPA</span>
                  </div>
                  {typePaiement && typePaiement === 'PRELEVEMENT' ? (
                    <i className="fas fa-minus" />
                  ) : (
                    <i className="far fa-plus" />
                  )}
                </div>
                {typePaiement && typePaiement === 'PRELEVEMENT' && (
                  <div className="accordion-content">
                    <IbanForm
                      dataUser={dataUser}
                      chosenPrice={chosenPrice}
                      chosenSize={chosenSize}
                      error={error}
                      setError={v => setError(v)}
                      setModalSuccess={v => setModalSuccess(v)}
                      setIsOpenModalCardFormPayment={v => setIsOpenModalCardFormPayment(v)}
                    />
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${typePaiement && typePaiement === 'CB' && 'active'}`}
              >
                <div
                  className="accordion-title"
                  onClick={() =>
                    typePaiement && typePaiement === 'CB'
                      ? setTypePaiement('')
                      : setTypePaiement('CB')
                  }
                >
                  <div>
                    <i className="far fa-solid fa-credit-card-front" />
                    {'  '}
                    {'  '} Carte bancaire
                  </div>
                  {typePaiement && typePaiement === 'CB' ? (
                    <i className="fas fa-minus" />
                  ) : (
                    <i className="far fa-plus" />
                  )}
                </div>
                {typePaiement && typePaiement === 'CB' && (
                  <div className="accordion-content">
                    <SplitForm
                      dataUser={dataUser}
                      email={dataUser.email}
                      nom={dataUser.nom}
                      profil={'STRUCTURE'}
                      chosenPrice={chosenPrice}
                      chosenSize={chosenSize}
                      error={error}
                      setError={v => setError(v)}
                      setModalSuccess={v => setModalSuccess(v)}
                      setIsOpenModalCardFormPayment={v => setIsOpenModalCardFormPayment(v)}
                    />
                  </div>
                )}
              </div>
            </Elements>
          ) : (
            <Fragment>
              <Elements stripe={stripePromise}>
                <SplitForm
                  dataUser={dataUser && dataUser}
                  email={email}
                  nom={nom}
                  profil={'FAMILLE'}
                  chosenPrice={chosenPrice}
                  promoCode={promoCode}
                  error={error}
                  setError={v => setError(v)}
                  setModalSuccess={v => setModalSuccess(v)}
                  setIsOpenModalCardFormPayment={v => setIsOpenModalCardFormPayment(v)}
                />
              </Elements>
            </Fragment>
          )}
        </div>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

ModalCardFormPayment.propTypes = {
  user: PropTypes.object,
  dataUser: PropTypes.object,
  email: PropTypes.string,
  nom: PropTypes.string,
  isOpen: PropTypes.bool,
  setModalSuccess: PropTypes.func.isRequired,
  setIsOpenModalCardFormPayment: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
  checkPromoCode: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { checkPromoCode })(ModalCardFormPayment);
