import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getPointages, getPointagesIntervenant } from '../store/modules/pointages';

import BaseLayout from './shared/BaseLayout';

import {
  map,
  filter,
  orderBy,
  groupBy,
  upperFirst,
  sumBy,
  find,
  union,
  toNumber,
  keys,
  sortBy,
  isEmpty,
  isUndefined,
  parseFloat
} from 'lodash';
import moment from 'moment';
import 'moment-duration-format';

import Select from 'react-select';
import { isDroitUser, removeFirstCaracter0 } from '../utils/methodes';

import MonthSelector from '../components/shared/MonthSelector';
import ModalAddUpdatePointage from '../components/modals/ModalAddUpdatePointage';
import ModalInfo from '../components/modals/ModalInfo';

const PointagesPage = ({
  user,
  getPointages,
  getPointagesIntervenant,
  pointagesProps,
  enfantSelected,
  intervenants,
  types_profils
}) => {
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndate] = useState(moment().endOf('month'));
  const [intervenantSelected, setIntervenantSelected] = useState('TOUS');
  const [openModalEditPointage, setOpenModalEditPointage] = useState(0);
  const [openModalAddPointage, setOpenModalAddPointage] = useState(false);
  const [pointagesFiltred, setPointagesFiltred] = useState({});

  const [totalTime, setTotalTime] = useState(
    sumBy(map(pointagesFiltred, p => toNumber(p.totalHeures)))
  );
  const [modalPointageSuccess, setModalPointageSuccess] = useState(false);

  const onChangeFilter = () => {
    if (!isEmpty(pointagesProps) || !isUndefined(pointagesProps)) {
      const dataFilter = filter(pointagesProps, e => {
        if (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') {
          return (
            (moment(e.date).isBetween(
              moment(startDate).startOf('day'),
              moment(endDate).endOf('day')
            ) &&
              intervenantSelected === 'TOUS') ||
            (moment(e.date).isBetween(
              moment(startDate).startOf('day'),
              moment(endDate).endOf('day')
            ) &&
              e.user_id === intervenantSelected)
          );
        } else {
          return moment(e.date).isBetween(
            moment(startDate).startOf('day'),
            moment(endDate).endOf('day')
          );
        }
      });
      const dataPointages = groupBy(
        map(dataFilter, e => {
          return { ...e, day: moment(e.date).date() };
        }),
        'day'
      );
      setTotalTime(sumBy(map(dataFilter, p => toNumber(p.totalHeures))));
      setPointagesFiltred({
        pointages: dataPointages,
        keysSort: sortBy(map(keys(dataPointages), e => parseInt(e))).reverse()
      });
    } else {
      setPointagesFiltred({});
    }
  };

  const [optionsIntervenants, setOptionsIntervenants] = useState(
    union(
      map(
        filter(intervenants, i => i.isActive === true),
        intervenant => {
          return {
            label: `${intervenant.prenom} ${intervenant.nom}`,
            value: intervenant.user_id
          };
        }
      ),
      [{ value: 'TOUS', label: 'Tous' }]
    )
  );

  useEffect(() => {
    setOptionsIntervenants(
      union(
        map(
          filter(intervenants, i => i.isActive === true),
          intervenant => {
            return {
              label: `${intervenant.prenom} ${intervenant.nom}`,
              value: intervenant.user_id
            };
          }
        ),
        [{ value: 'TOUS', label: 'Tous' }]
      )
    );
  }, [intervenants]);

  useEffect(() => {
    if (user.profil === 'FAMILLE') {
      getPointages(user.userIdPrincipal ? user.userIdPrincipal : user.user_id);
    } else {
      getPointagesIntervenant(user.user_id, enfantSelected.enfant_id);
    }
    onChangeFilter();
  }, []);

  useEffect(() => {
    onChangeFilter();
  }, [startDate, endDate, intervenantSelected, pointagesProps]);

  const prevMonth = () => {
    setStartDate(
      moment(startDate)
        .subtract(1, 'months')
        .startOf('month')
    );
    setEndate(
      moment(endDate)
        .subtract(1, 'months')
        .endOf('month')
    );
  };

  const nextMonth = () => {
    setStartDate(
      moment(startDate)
        .add(1, 'months')
        .startOf('month')
    );
    setEndate(
      moment(endDate)
        .add(1, 'months')
        .endOf('month')
    );
  };

  const changeModalPointage = () => {
    setOpenModalEditPointage(0);
  };

  return (
    <BaseLayout homeBtn isPage noFooter withSubmenu headerPageTitle="Pointages">
      <ModalInfo
        isOpen={modalPointageSuccess}
        icon="check-circle"
        title="Pointage enregistré !"
        confirmText="Ok"
        confirmMethode={() => setModalPointageSuccess(false)}
        closeModal={() => setModalPointageSuccess(false)}
      />
      <div className="main-container">
        {user.profil === 'FAMILLE' && (
          <div className="subheader">
            <div className="subheader-nav">
              <Select
                className="react-select"
                classNamePrefix="react-select"
                options={optionsIntervenants}
                isSearchable={false}
                isClearable={false}
                defaultValue={intervenantSelected}
                value={find(optionsIntervenants, o => o.value === intervenantSelected)}
                onChange={option => {
                  setIntervenantSelected(option.value);
                }}
              />
            </div>
            <div className="subheader-bkg">
              <svg width="0" height="0">
                <defs>
                  <clipPath
                    id="submenumask"
                    clipPathUnits="objectBoundingBox"
                    transform="scale(0.00266666666,0.01428571428)"
                  >
                    <path
                      d="M361.2,70L23.8,64.3c-8.6-0.2-17-3-23.8-8.3V0h375v67.9
              c-4.2,1.5-8.6,2.1-13.1,2.1H361.2z"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        )}
        <MonthSelector
          month={moment(startDate).format('MMMM')}
          nextMonth={() => nextMonth()}
          prevMonth={() => prevMonth()}
        />
        <ModalAddUpdatePointage
          key={'modalAddPointage'}
          isOpen={openModalAddPointage === true}
          closeModal={t => {
            return setOpenModalAddPointage(false), setOpenModalEditPointage(0);
          }}
          openModalSuccess={() => setModalPointageSuccess(true)}
        />
        <div className="content">
          <div className="liste">
            {pointagesFiltred &&
              pointagesFiltred.keysSort &&
              map(pointagesFiltred.keysSort, key => {
                const pointages = pointagesFiltred.pointages[key];

                const totalTimeDay = sumBy(pointages, p => toNumber(p.totalHeures));

                return (
                  <Fragment key={key}>
                    <div className="list-block-title">
                      {upperFirst(moment(pointages[0].date).format('dddd D'))}
                      <div className="day-total">
                        {removeFirstCaracter0(
                          moment
                            .duration(totalTimeDay, 'hours')
                            .format('HH:mm', { trim: false })
                            .replace(':', 'h')
                        )}
                      </div>
                    </div>
                    {map(orderBy(pointages, ['heure_debut']), p => {
                      const totalTimeEvent = removeFirstCaracter0(
                        moment
                          .duration(p.totalHeures, 'hours')
                          .format('HH:mm', { trim: false })
                          .replace(':', 'h')
                      );

                      return (
                        <div
                          className="card card-event"
                          key={p.pointage_id}
                          onClick={() => {
                            return (
                              user.profil !== 'FAMILLE' && setOpenModalEditPointage(p.pointage_id)
                            );
                          }}
                        >
                          <ModalAddUpdatePointage
                            key={p.pointage_id}
                            isOpen={
                              (openModalEditPointage && openModalEditPointage === p.pointage_id) ||
                              false
                            }
                            isEdit
                            pointage={p}
                            closeModal={() => {
                              changeModalPointage();
                            }}
                            openModalSuccess={() => setModalPointageSuccess(true)}
                          />

                          {user.profil === 'FAMILLE' && (
                            <div className="card-infos">
                              <div className="card-subtitle">
                                {`${p.intervenant.prenom} ${p.intervenant.nom} [${
                                  types_profils[p.intervenant.profil]
                                }]`}
                              </div>
                            </div>
                          )}
                          <div className="event-schedule">
                            <div>
                              <span className="event-start">
                                {removeFirstCaracter0(
                                  moment(p.heure_debut)
                                    .format('HH:mm')
                                    .replace(':', 'h')
                                )}
                              </span>
                              &nbsp;-&nbsp;
                              {removeFirstCaracter0(
                                moment(p.heure_fin)
                                  .format('HH:mm')
                                  .replace(':', 'h')
                              )}
                            </div>
                            <div className="event-total">{totalTimeEvent}</div>
                          </div>
                        </div>
                      );
                    })}
                  </Fragment>
                );
              })}
          </div>
          {(isDroitUser('pointage_intervenant', user, enfantSelected) === 'ADMINISTRATION' ||
            isDroitUser('pointage_intervenant', user, enfantSelected) === 'MODIFICATION') && (
            <div className="bottom-sticky bottom-btn-add">
              <div className="btn-icon" onClick={() => setOpenModalAddPointage(true)}>
                <i className="fas fa-plus" />
              </div>
            </div>
          )}
          <div className="bottom-sticky bottom-0">
            <div className="pointage-total-block">
              <div>Total d'heures</div>
              <div>
                {removeFirstCaracter0(
                  moment
                    .duration(totalTime, 'hours')
                    .format('HH:mm')
                    .replace(':', 'h')
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

PointagesPage.propTypes = {
  user: PropTypes.object.isRequired,
  getPointages: PropTypes.func.isRequired,
  getPointagesIntervenant: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  let pointages = {};
  // pour les intervenants il faut récuperer que les pointages pour l'enfant concerné
  if (
    state.auth &&
    state.auth.user &&
    state.auth.user.profil !== 'FAMILLE' &&
    state.auth.user.profil !== 'STRUCTURE' &&
    state.auth.enfantSelected
  ) {
    pointages = groupBy(state.data.entities.pointages, 'enfant_id')[
      state.auth.enfantSelected.enfant_id
    ];
  } else {
    pointages = filter(
      state.data.entities.pointages,
      p => p.enfant_id === state.auth.enfantSelected.enfant_id
    );
  }
  // console.log('pointages :', pointages);
  return {
    user: state.auth.user,
    enfantSelected: state.auth.enfantSelected,
    intervenants: state.data.entities.intervenants,
    pointagesProps: pointages,
    types_profils:
      state.data.entities.settings &&
      state.data.entities.settings['types_profils'] &&
      state.data.entities.settings['types_profils'].data
  };
};

export default connect(mapStateToProps, { getPointages, getPointagesIntervenant })(PointagesPage);
