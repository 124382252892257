import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalSuccessAccountCreation = ({ isOpen, titleMessage, message }) => {
  const history = useHistory();

  const confirmOk = () => {
    return history.replace('/connexion');
  };

  return (
    <Modal
      {...modalDefaultClass}
      className="modal-content small-modal success-modal"
      id="modal-confirm"
      isOpen={isOpen}
    >
      <div className="modal-title">{titleMessage}</div>
      <div className="modal-body">
        {message}
        <div className="btn-group center">
          <button onClick={() => confirmOk()} className="btn btn-primary">
            <span>OK</span>
          </button>
        </div>
      </div>
      <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
    </Modal>
  );
};

export default ModalSuccessAccountCreation;
