import React from 'react';
import PropTypes from 'prop-types';

const ButtonProfilFamille = ({
  onChangeProfil,
  profil,
  profilSelected,
  icon,
  icon2,
  title,
  text,
  customClass
}) => {
  return (
    <div
      onClick={() => onChangeProfil(profil)}
      className={`card selection${profilSelected === profil ? ' actif' : ''}${
        customClass ? ' ' + customClass : ''
      }`}
    >
      {icon && (
        <div className="selection-icon-wrapper">
          <i className={`selection-icon fad ${icon}`}></i>
          {icon2 && (
            <div className="selection-icon2-wrapper">
              <i className={`selection-icon2 fad ${icon2}`}></i>
            </div>
          )}
        </div>
      )}
      <div className="selection-infos">
        <div className="selection-title">{title}</div>
        <div className="selection-text">{text}</div>
      </div>
    </div>
  );
};

ButtonProfilFamille.propTypes = {
  onChangeProfil: PropTypes.func.isRequired,
  profil: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default ButtonProfilFamille;
