import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { createUserSecondaireFamille } from '../../store/modules/users';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import { isEmpty, values } from 'lodash';

import { Form, Field } from 'react-final-form';

const ModalAddCompteSecondaire = ({
  user,
  users,
  className,
  openSuccess,
  createUserSecondaireFamille
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');

  const submit = data => {
    return createUserSecondaireFamille(user.user_id, data.email, data.pseudo)
      .then(() => {
        setIsOpen(false);
      })
      .then(() => {
        openSuccess();
      })
      .catch(err => setError(err));
  };

  const closeModal = () => {
    setIsOpen(false);
    setError(null);
  };

  const openModal = () => {
    if (user.pseudo) {
      setIsOpen(true);
    } else {
      setError('Veuillez renseigner votre profil');
    }
  };

  return (
    <div>
      {user &&
        user.profil === 'FAMILLE' &&
        !user.userIdPrincipal &&
        isEmpty(users) &&
        isEmpty(user.usersIdsSecondaires) && (
          <Fragment>
            {isEmpty(user.pseudo) && (
              <div className="package package-secondary">
                <div className="package-title">Compte secondaire</div>
                <div className="package-separator" />

                <div className="package-infos">
                  Si vous souhaitez ajouter un compte secondaire, veuillez au préalable renseigner
                  un nom de profil
                </div>

                <div className="btn-group center">
                  <button
                    onClick={() => {
                      openModal();
                    }}
                    disabled={isEmpty(user.pseudo)}
                    className={`${(className && className) || 'btn btn-secondary'} `}
                  >
                    <i className="fas fa-user-plus" />
                    <span>Ajouter un compte secondaire</span>
                  </button>
                </div>
              </div>
            )}
            {!isEmpty(user.pseudo) && (
              <div className="btn-group center">
                <button
                  onClick={() => {
                    openModal();
                  }}
                  className={`${(className && className) || 'btn btn-primary'} `}
                >
                  <i className="fas fa-user-plus" />
                  <span>Ajouter un compte secondaire</span>
                </button>
              </div>
            )}
          </Fragment>
        )}
      <Modal {...modalDefaultClass} id="modal-check" isOpen={isOpen}>
        <header className="header">
          <div className="modal-title">Ajouter un compte secondaire</div>
          <div className="header-bkg"></div>
        </header>

        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <Field name="email" validate={required} component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="email" autoCapitalize="none" />
                      <label>Adresse e-mail *</label>
                    </div>
                  )}
                </Field>
                <Field name="pseudo" validate={required} component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input
                        {...input}
                        type="text"
                        autoCapitalize="none"
                        placeholder="Papa, Maman, ..."
                      />
                      <label>Profil *</label>
                    </div>
                  )}
                </Field>
                {error && (
                  <div className="error-block">
                    <span className="error-icon">
                      <i className="fas fa-exclamation" />
                    </span>
                    <div className="error-message">{error}</div>
                  </div>
                )}
              </div>
              <div className="modal-footer bottom-sticky">
                <div className="btn-group center">
                  <button className="btn btn-tertiary" onClick={() => closeModal()}>
                    Annuler
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </Modal>
    </div>
  );
};

ModalAddCompteSecondaire.propTypes = {
  createUserSecondaireFamille: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  users: state.data.entities.users && values(state.data.entities.users)
});

export default connect(mapStateToProps, {
  createUserSecondaireFamille
})(ModalAddCompteSecondaire);
