import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { head, values } from 'lodash';
import { connect } from 'react-redux';
import { login } from '../../store/modules/auth';

import { Form, Field } from 'react-final-form';
import { validateEmail } from '../../utils/StringValidator';

import BaseLayout from '../shared/BaseLayout';

const LoginPage = ({ login }) => {
  const [displayPassword, setDisplayPassword] = useState(false);
  const history = useHistory();
  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';

  const handleSubmit = data => {
    return login(data.email, data.password)
      .then(({ response }) => {
        const { isPremium, profil } = head(values(response.entities.user));
        if (profil !== 'FAMILLE' || profil !== 'STRUCTURE' || isPremium) {
          return history.push('/');
        } else {
          return history.push('/mon-abonnement');
        }
      })
      .catch(err => setError(err));
  };
  return (
    <BaseLayout backBtn noFooter isPage bigTitle headerPageTitle="Identifiez-vous" classScreen="">
      <div className="main-container">
        <div className="content">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="email" validate={requiredEmail} component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="email" autoCapitalize="none" />
                      <label>Adresse email *</label>
                    </div>
                  )}
                </Field>

                <Field validate={required} name="password" component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input
                        {...input}
                        type={(displayPassword && 'text') || 'password'}
                        onChange={value => {
                          setError(null);
                          input.onChange(value);
                        }}
                        placeholder="Mot de passe *"
                      />
                      <label>Mot de passe *</label>
                      {displayPassword ? (
                        <span className="clear-password" onClick={() => setDisplayPassword(false)}>
                          <i className="fas fa-eye-slash" />
                        </span>
                      ) : (
                        <span className="clear-password" onClick={() => setDisplayPassword(true)}>
                          <i className="fas fa-eye" />
                        </span>
                      )}
                    </div>
                  )}
                </Field>

                {error && (
                  <div className="error-block">
                    <span className="error-icon">
                      <i className="fas fa-exclamation" />
                    </span>
                    <div className="error-message">{error}</div>
                  </div>
                )}
                <div className="btn-group flexColumn center">
                  <button type="submit" className="btn btn-primary">
                    <span>Je m’identifie</span>
                  </button>

                  <NavLink className="btn-link" to="/mot-de-passe-oublie">
                    Mot de passe oublié ?
                  </NavLink>
                  <NavLink className="btn-link btn-link-secondary" to="/inscription/profil">
                    Je n’ai pas encore de compte
                  </NavLink>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

LoginPage.propTypes = {
  login: PropTypes.func.isRequired
};

export default connect(null, { login })(LoginPage);
