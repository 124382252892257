import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import { map, find, isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import materialTheme from '../../../assets/stylesheets/materialThemeDatePicker';
import { uploadFiles, removeFile } from '../../store/modules/uploads';
import { createDocumentMedia, updateDocumentMedia } from '../../store/modules/documentsMedias';
import BaseLayout from '../shared/BaseLayout';
import FieldUploadDropZone from '../../components/shared/FieldUploadDropZone';
import { TYPES_DOCUMENTS } from '../../constants/Properties';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date)
      .locale('fr')
      .format('DD MMMM YYYY');
  }
}

const AddEditDossierPage = ({
  enfantSelected,
  user,
  uploadFiles,
  removeFile,
  createDocumentMedia,
  updateDocumentMedia,
  dossier
}) => {
  const [error, setError] = useState(false);
  const navigation = useHistory();
  const required = value => (value ? undefined : 'Champ requis !');

  const optionsTypesDocuments = map(TYPES_DOCUMENTS, (item, key) => {
    return { value: key, label: item };
  });

  const handleSubmit = data => {
    if (dossier) {
      // EN EDITION

      if (!isEmpty(data.file) && data.file !== dossier.file) {
        return uploadFiles({ files: data.file })
          .then(res => {
            data.file = res.response.fileName;

            return updateDocumentMedia(dossier, data)
              .then(() => {
                setError(null);
                navigation.push(`/dossier-prise-en-charge/${dossier.document_id}`);
              })
              .catch(err => setError(err));
          })
          .catch(err => setError(err));
      } else {
        return updateDocumentMedia(dossier, data)
          .then(() => {
            setError(null);
            navigation.push(`/dossier-prise-en-charge/${dossier.document_id}`);
          })
          .catch(err => setError(err));
      }
    } else {
      // CREATION

      return uploadFiles({ files: data.file })
        .then(res => {
          data.file = res.response.fileName;
          data.user_id;

          return createDocumentMedia({
            ...data,
            famille_id:
              (user.profil === 'FAMILLE' && user.user_id) ||
              (enfantSelected.parent_id && enfantSelected.parent_id),
            enfant_id: enfantSelected.enfant_id,
            user_id: user.user_id,
            type: 'dossier-prise-en-charge',
            file_type: 'pdf'
          })
            .then(() => navigation.push('/dossiers-prise-en-charge'))
            .catch(err => setError(err));
        })
        .catch(err => setError(err));
    }
  };
  return (
    <BaseLayout
      isPage
      noFooter
      headerPageTitle={(dossier && 'Modifier un dossier') || 'Ajouter un dossier'}
      classScreen="page-preview"
    >
      <Form
        initialValues={{
          titre: (dossier && dossier.titre) || '',
          date: (dossier && dossier.date) || new Date(),
          type_document: (dossier && dossier.type_document) || null,
          libelle_autre: (dossier && dossier.libelle_autre) || null,
          file: (dossier && dossier.file) || null
        }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <Field name="titre" validate={required} component="input" className="field">
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <input {...input} type="text" />
                    <label>Titre *</label>
                  </div>
                )}
              </Field>
              <Field name="type_document" component="select" validate={required}>
                {({ input, meta }) => {
                  return (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Sélectionner un type"
                        options={optionsTypesDocuments}
                        onChange={option => {
                          input.onChange(option.value);
                          if (option.value !== 'AUTRE') {
                            form.change('libelle_autre', null);
                          }
                        }}
                        value={find(optionsTypesDocuments, o => o.value === input.value)}
                      />
                      <label>Type *</label>
                    </div>
                  );
                }}
              </Field>
              {values.type_document === 'AUTRE' ? (
                <Field name="libelle_autre" validate={required} component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" placeholder="Précisez *" />
                    </div>
                  )}
                </Field>
              ) : null}
              <div className="separator" />
              <Field name="date" component="input" className="field" validate={required}>
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={LocalizedUtils}
                      locale={'fr'}
                    >
                      <ThemeProvider theme={materialTheme}>
                        <KeyboardDatePicker
                          className="datepicker-mui"
                          invalidDateMessage={null}
                          autoOk
                          value={input.value}
                          placeholder="jj/mm/aaaa"
                          okLabel={null}
                          clearLabel={null}
                          clearable={false}
                          cancelLabel={null}
                          onChange={date => {
                            input.onChange(date);
                          }}
                          maxDate={new Date()}
                          format="DD/MM/YYYY"
                          InputAdornmentProps={{ position: 'start' }}
                          inputVariant="outlined"
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <label>Date *</label>
                  </div>
                )}
              </Field>
              <div className="separator" />
              <Field name="file" validate={required}>
                {({ input, meta }) => {
                  return (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}

                      <FieldUploadDropZone
                        message1="Glisser et déposer votre fichier PDF ici ou"
                        message2="Choisir un fichier PDF"
                        type="pdf"
                        rmFile={file => removeFile(file)}
                        acceptedFiles="application/pdf"
                        initFile={(dossier && dossier.file) || null}
                        {...input}
                      />
                      <label>Pièce jointe *</label>
                    </div>
                  );
                }}
              </Field>

              {error && (
                <div className="error-block">
                  <span className="error-icon">
                    <i className="fas fa-exclamation" />
                  </span>
                  <div className="error-message">{error}</div>
                </div>
              )}
            </div>

            <div className="btn-group center bottom-sticky">
              <div className="btn btn-tertiary" onClick={() => navigation.goBack()}>
                Annuler
              </div>
              <button type="submit" className="btn btn-primary">
                Enregistrer
              </button>
            </div>
          </form>
        )}
      />
    </BaseLayout>
  );
};

AddEditDossierPage.propTypes = {
  user: PropTypes.object.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  createDocumentMedia: PropTypes.func.isRequired,
  updateDocumentMedia: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { id }
    }
  } = props;
  const dossier =
    (id && state.data.entities.documentsMedias && state.data.entities.documentsMedias[id]) || null;
  return {
    user: state.auth.user,
    dossier,
    enfantSelected: state.auth.enfantSelected
  };
};

export default connect(mapStateToProps, {
  uploadFiles,
  removeFile,
  createDocumentMedia,
  updateDocumentMedia
})(AddEditDossierPage);
