import { schema } from 'normalizr';

const userSchema = new schema.Entity('user', {}, { idAttribute: 'user_id' });
const settingSchema = new schema.Entity('settings', {}, { idAttribute: 'type' });
const enfantSchema = new schema.Entity('enfants', {}, { idAttribute: 'enfant_id' });
const intervenantSchema = new schema.Entity('intervenants', {}, { idAttribute: 'user_id' });
const documentMediaSchema = new schema.Entity(
  'documentsMedias',
  {},
  { idAttribute: 'document_id' }
);
const eventSchema = new schema.Entity('events', {}, { idAttribute: 'event_id' });
const pointageSchema = new schema.Entity('pointages', {}, { idAttribute: 'pointage_id' });
const parentSchema = new schema.Entity('parents', {}, { idAttribute: 'user_id' });
const compteRenduSchema = new schema.Entity('comptesRendus', {}, { idAttribute: 'compterendu_id' });
const messageSchema = new schema.Entity('messages', {}, { idAttribute: 'message_id' });
const usersSchema = new schema.Entity('users', {}, { idAttribute: 'user_id' });
const stripeSchema = new schema.Entity('stripe', {}, { idAttribute: 'customer_id' });

export const Schemas = {
  USER: userSchema,
  USER_ARRAY: new schema.Array(userSchema),
  SETTING: settingSchema,
  SETTING_ARRAY: new schema.Array(settingSchema),
  ENFANT: enfantSchema,
  ENFANT_ARRAY: new schema.Array(enfantSchema),
  INTERVENANT: intervenantSchema,
  INTERVENANT_ARRAY: new schema.Array(intervenantSchema),
  DOCUMENT_MEDIA: documentMediaSchema,
  DOCUMENT_MEDIA_ARRAY: new schema.Array(documentMediaSchema),
  EVENT: eventSchema,
  EVENT_ARRAY: new schema.Array(eventSchema),
  POINTAGE: pointageSchema,
  POINTAGE_ARRAY: new schema.Array(pointageSchema),
  PARENT: parentSchema,
  PARENT_ARRAY: new schema.Array(parentSchema),
  COMPTE_RENDU: compteRenduSchema,
  COMPTE_RENDU_ARRAY: new schema.Array(compteRenduSchema),
  MESSAGE: messageSchema,
  MESSAGE_ARRAY: new schema.Array(messageSchema),
  STRIPE: stripeSchema,
  STRIPE_ARRAY: new schema.Array(stripeSchema),
  USERS: usersSchema,
  USERS_ARRAY: new schema.Array(usersSchema)
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
