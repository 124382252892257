import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getDocumentsMedias } from '../store/modules/documentsMedias';
import { getIntervenants } from '../store/modules/intervenants';

import BaseLayout from './shared/BaseLayout';

import { filter, isUndefined, map, values, includes } from 'lodash';

import EnfantProfil from '../components/EnfantProfil';
import EnfantSante from '../components/EnfantSante';
import ModalAutorisation from '../components/modals/enfants/ModalAutorisation';

import ButtonsSelector from '../components/shared/ButtonsSelector';
const EnfantPage = ({ enfant, user, autorisations, getDocumentsMedias, getIntervenants }) => {
  useEffect(() => {
    getDocumentsMedias(user.profil, user.user_id, enfant.enfant_id);

    if (!isUndefined(enfant)) {
      getIntervenants(enfant.enfant_id);
    }
  }, []);

  const [tabSelected, setTabSelected] = useState('profil');

  const onChangeTab = tab => {
    setTabSelected(tab);
  };

  const renderForm = () => {
    switch (tabSelected) {
      case 'profil':
        return <EnfantProfil enfant={enfant} />;
      case 'sante':
        return <EnfantSante enfant={enfant} />;
      case 'autorisations':
        return (
          <Fragment>
            {autorisations &&
              autorisations.length > 0 &&
              map(autorisations, autorisation =>
                user.profil === 'FAMILLE' ||
                user.profil === 'STRUCTURE' ||
                (includes(enfant.autorisations, autorisation.document_id) &&
                  user.user_id === autorisation.user_id) ? (
                  <ModalAutorisation
                    key={autorisation.document_id}
                    autorisation={autorisation}
                    enfant={enfant}
                  />
                ) : null
              )}
          </Fragment>
        );

      default:
        break;
    }
  };

  return (
    <BaseLayout headerPageTitle={enfant.profil.prenom} isPage withSubmenu backBtn>
      <div className="main-container">
        <ButtonsSelector
          selected={tabSelected}
          onClickButton={value => onChangeTab(value)}
          buttons={map([
            {
              label: 'Son profil',
              value: 'profil'
            },
            {
              label: 'Sa santé',
              value: 'sante'
            },
            {
              label: 'Ses autorisations',
              value: 'autorisations'
            }
          ])}
        />
        <div className="content">{renderForm()}</div>
      </div>
    </BaseLayout>
  );
};

EnfantPage.propTypes = {
  getDocumentsMedias: PropTypes.func.isRequired,
  getIntervenants: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { enfantId }
    }
  } = props;

  return {
    enfant: state.data.entities.enfants && state.data.entities.enfants[Number(enfantId)],
    user: state.auth.user,
    intervenants: state.data.entities.intervenants && values(state.data.entities.intervenants),
    autorisations:
      (state.data.entities.documentsMedias &&
        filter(state.data.entities.documentsMedias, d => d.type === 'autorisation')) ||
      []
  };
};

export default connect(mapStateToProps, { getDocumentsMedias, getIntervenants })(EnfantPage);
