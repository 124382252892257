import { includes } from 'lodash';
import { API_URL } from '../constants/Config';

import { removeExtension, getExtension } from './StringUtils';

export function isDroitUser(type, user, enfant) {
  if (
    user.profil === 'FAMILLE' ||
    user.profil === 'FAMILLE_STRUCTURE' ||
    user.profil === 'STRUCTURE'
  ) {
    return user.droits[type];
  } else {
    // console.log('user.droits: ', user.droits);
    // console.log('enfant : ', enfant);
    if (
      enfant &&
      user.droits &&
      user.droits[enfant.enfant_id] &&
      user.droits[enfant.enfant_id][type]
    ) {
      return user.droits[enfant.enfant_id][type];
    } else {
      return 'PAS_ACCES';
    }
  }
}

export function getProfilImgMin(image) {
  return `${API_URL}/uploads/${removeExtension(image)}-640w${getExtension(image)}`;
}

export function sortObjectByKeys(data, order) {
  const tri = Object.keys(data).sort();

  if (order === 'desc') {
    tri.reverse();
  }
  return tri.reduce((r, k) => ((r[k] = data[k]), r), {});
}

export function removeFirstCaracter0(string) {
  if (string[0] === '0') {
    string = string.substring(1, string.length);
  }
  // ajouter 0h si il n'y aque des minutes
  if (!includes(string, 'h')) {
    // si nombre de minutes < 10 il faut rajouter un 0 pour avoir 0h04 par exemple
    string = '0h' + `${string.length && '0'}` + string;
  }
  return string;
}

export function getWidthOfElement(ref) {
  const width = ref.current.offsetWidth;
  return width;
}
export function getHeightOfElement(ref) {
  const height = ref.current.offsetHeight;
  return height;
}

export function isIOSDevice() {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}
