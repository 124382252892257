import { map } from 'lodash';

export function getExtension(filename) {
  var i = filename.lastIndexOf('.');
  return i < 0 ? '' : filename.substr(i);
}

export function removeExtension(filename) {
  var lastDotPosition = filename.lastIndexOf('.');
  if (lastDotPosition === -1) return filename;
  else return filename.substr(0, lastDotPosition);
}

export function displayNamesFilesWithSize(fileName) {
  return map(
    ['640w', '1024w', '1920w'],
    taille => `${removeExtension(fileName)}-${taille}${getExtension(fileName)}`
  );
}
