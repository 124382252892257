import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getIntervenants } from '../store/modules/intervenants';

import BaseLayout from './shared/BaseLayout';

import { isEmpty, values, map, filter, toLower } from 'lodash';
import removeAccents from 'remove-accents';

import EquipeCardIntervenant from '../components/cards/EquipeCardIntervenant';
import EquipeCard from '../components/cards/EquipeCard';
import FilterSearch from '../components/shared/FilterSearch';

const EquipePage = ({ enfant, intervenants, getIntervenants, types_profils, parents }) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!isEmpty(enfant) && isEmpty(intervenants)) {
      getIntervenants(enfant);
    }
  }, []);

  return (
    <BaseLayout homeBtn isPage headerPageTitle="L'Équipe">
      <div className="main-container">
        <div className="content">
          <FilterSearch searchFilter={search} updateSearchFilter={value => setSearch(value)} />

          {map(
            search
              ? filter(parents, i => {
                  return (
                    removeAccents(toLower(i.nom)).includes(removeAccents(toLower(search))) ||
                    removeAccents(toLower(i.prenom)).includes(removeAccents(toLower(search)))
                  );
                })
              : parents,
            user => {
              return (
                user && (
                  <EquipeCard key={user.user_id} user={user} parents={parents} enfant={enfant} />
                )
              );
            }
          )}
          {map(
            search
              ? filter(intervenants, i => {
                  return (
                    removeAccents(toLower(i.nom)).includes(removeAccents(toLower(search))) ||
                    removeAccents(toLower(i.prenom)).includes(removeAccents(toLower(search)))
                  );
                })
              : intervenants,
            intervenant => (
              <EquipeCardIntervenant
                key={intervenant.user_id}
                personne={intervenant}
                types_profils={types_profils}
              />
            )
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

EquipePage.propTypes = {
  user: PropTypes.object.isRequired,
  getIntervenants: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  enfant: state.auth.enfantSelected,
  users: state.data.entities.users,
  parents: state.data.entities.parents,

  types_profils:
    state.data.entities.settings &&
    state.data.entities.settings['types_profils'] &&
    state.data.entities.settings['types_profils'].data,
  intervenants: (state.data.entities.intervenants && values(state.data.entities.intervenants)) || {}
});

export default connect(mapStateToProps, { getIntervenants })(EquipePage);
