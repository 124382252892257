import React, { Fragment, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getProfilImgMin, isDroitUser, removeFirstCaracter0 } from '../../utils/methodes';

import { connect } from 'react-redux';
import { getComptesRendus } from '../../store/modules/comptesRendus';

import BaseLayout from '../shared/BaseLayout';

import { includes, map, filter, orderBy, groupBy, upperFirst, find, union, isEmpty } from 'lodash';

import moment from 'moment';

import Select from 'react-select';

import MonthSelector from '../../components/shared/MonthSelector';
import AvatarMask from '../../utils/AvatarMask';
import AvatarOutline from '../../utils/AvatarOutline';

const ComptesRendusPage = ({
  startDateProps,
  endDateProps,
  user,
  getComptesRendus,
  comptesRendusProps,
  enfantSelected,
  intervenants,
  types_profils
}) => {
  const [startDate, setStartDate] = useState(
    startDateProps ||
      moment()
        .startOf('month')
        .toDate()
  );
  const [endDate, setEndate] = useState(
    endDateProps ||
      moment()
        .endOf('month')
        .toDate()
  );
  const [intervenantSelected, setIntervenantSelected] = useState(
    (enfantSelected &&
      includes(['MODIFICATION'], isDroitUser('comptes_rendus', user, enfantSelected)) &&
      user.user_id) ||
      'TOUS'
  );

  useEffect(() => {
    if (enfantSelected) {
      getComptesRendus(enfantSelected.enfant_id);
    }
  }, []);

  useEffect(() => {
    onChangeFilter();
  }, [comptesRendusProps]);
  const [comptesRendusFiltred, setComptesRendusFiltred] = useState({});

  const onChangeFilter = () => {
    if (!isEmpty(comptesRendusProps)) {
      setComptesRendusFiltred(
        groupBy(
          orderBy(
            map(
              intervenantSelected !== 'TOUS'
                ? filter(
                    comptesRendusProps,
                    c =>
                      moment(c.date).isBetween(
                        moment(startDate).startOf('day'),
                        moment(endDate).endOf('day')
                      ) && c.user_id === intervenantSelected
                  )
                : filter(comptesRendusProps, c =>
                    moment(c.date).isBetween(
                      moment(startDate).startOf('day'),
                      moment(endDate).endOf('day')
                    )
                  ),

              c => {
                return {
                  ...c,
                  day: moment(c.date).format('YYYY-DD-MM')
                };
              }
            ),
            ['day'],
            ['desc']
          ),

          'day'
        )
      );
    }
  };

  const [optionsIntervenants, setOptionsIntervenants] = useState(
    union(
      map(
        filter(intervenants, i => i.isActive === true),
        intervenant => {
          return {
            label: `${intervenant.prenom} ${intervenant.nom}`,
            value: intervenant.user_id
          };
        }
      ),
      [{ value: 'TOUS', label: 'Tous' }]
    )
  );

  useEffect(() => {
    setOptionsIntervenants(
      union(
        map(
          filter(intervenants, i => i.isActive === true),
          intervenant => {
            return {
              label: `${intervenant.prenom} ${intervenant.nom}`,
              value: intervenant.user_id
            };
          }
        ),
        [{ value: 'TOUS', label: 'Tous' }]
      )
    );
  }, [intervenants]);

  useEffect(() => {
    onChangeFilter();
  }, [startDate, endDate, intervenantSelected]);

  const prevMonth = () => {
    setStartDate(
      moment(startDate)
        .subtract(1, 'months')
        .startOf('month')
        .toDate()
    );
    setEndate(
      moment(endDate)
        .subtract(1, 'months')
        .endOf('month')
        .toDate()
    );
  };

  const nextMonth = () => {
    setStartDate(
      moment(startDate)
        .add(1, 'months')
        .startOf('month')
        .toDate()
    );
    setEndate(
      moment(endDate)
        .add(1, 'months')
        .endOf('month')
        .toDate()
    );
  };

  return (
    <BaseLayout homeBtn isPage withSubmenu noFooter headerPageTitle="Comptes-rendus">
      <div className="main-container">
        {!includes(['MODIFICATION'], isDroitUser('comptes_rendus', user, enfantSelected)) && (
          <div className="subheader">
            <div className="subheader-nav">
              <Select
                className="react-select"
                classNamePrefix="react-select"
                options={optionsIntervenants}
                isSearchable={false}
                isClearable={false}
                defaultValue={intervenantSelected}
                value={find(optionsIntervenants, o => o.value === intervenantSelected)}
                onChange={option => {
                  setIntervenantSelected(option.value);
                }}
              />
            </div>
            <div className="subheader-bkg">
              <svg width="0" height="0">
                <defs>
                  <clipPath
                    id="submenumask"
                    clipPathUnits="objectBoundingBox"
                    transform="scale(0.00266666666,0.01428571428)"
                  >
                    <path
                      d="M361.2,70L23.8,64.3c-8.6-0.2-17-3-23.8-8.3V0h375v67.9
              c-4.2,1.5-8.6,2.1-13.1,2.1H361.2z"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        )}
        <MonthSelector
          month={moment(startDate).format('MMMM')}
          nextMonth={() => nextMonth()}
          prevMonth={() => prevMonth()}
        />

        <div className="content">
          {comptesRendusFiltred && !isEmpty(comptesRendusFiltred) ? (
            <div className="liste">
              {map(comptesRendusFiltred, (comptesRendus, day) => (
                <Fragment key={day}>
                  <div className="list-block-title">
                    {upperFirst(moment(comptesRendus[0].date).format('dddd D'))}
                  </div>
                  {map(orderBy(comptesRendus, ['heure_debut'], ['desc']), compteRendu => (
                    <Link
                      className="card card-member"
                      key={compteRendu.compterendu_id}
                      to={{
                        pathname: `/compte-rendu/${compteRendu.compterendu_id}`,
                        state: { startDate: startDate, endDate: endDate }
                      }}
                    >
                      <Fragment>
                        <div className="member-avatar">
                          {compteRendu.intervenant && compteRendu.intervenant.photo ? (
                            <Fragment>
                              <div className="avatar-mask">
                                <img
                                  src={getProfilImgMin(compteRendu.intervenant.photo)}
                                  alt="photo"
                                />
                              </div>
                              <AvatarMask nameFile="ComptesRendusPage" />
                              <AvatarOutline nameFile="ComptesRendusPage" smaller />
                            </Fragment>
                          ) : (
                            <Fragment>
                              <span>
                                {compteRendu?.intervenant?.prenom
                                  ? compteRendu.intervenant.prenom[0]
                                  : null}
                                {compteRendu.intervenant && compteRendu.intervenant.nom[0]}
                              </span>
                              <AvatarOutline nameFile="ComptesRendusPage" smaller />
                            </Fragment>
                          )}
                        </div>
                        <div className="card-infos">
                          <div className="card-title">
                            {compteRendu.intervenant && compteRendu.intervenant.prenom}
                          </div>
                          <div className="card-subtitle">
                            {
                              types_profils[
                                compteRendu.intervenant && compteRendu.intervenant.profil
                              ]
                            }
                          </div>
                        </div>
                      </Fragment>
                    </Link>
                  ))}
                </Fragment>
              ))}
            </div>
          ) : (
            <div className="form-message">
              <p>
                Aucun compte-rendu n'a encore été ajouté par un membre de l'équipe de votre enfant
              </p>
            </div>
          )}
          {(isDroitUser('comptes_rendus', user, enfantSelected) === 'ADMINISTRATION' ||
            user.profil === 'FAMILLE' ||
            user.profil === 'STRUCTURE' ||
            isDroitUser('comptes_rendus', user, enfantSelected) === 'MODIFICATION') && (
            <div className="bottom-sticky bottom-btn-add">
              <NavLink className="btn-icon" to="/comptes-rendus/ajouter">
                <i className="fas fa-plus" />
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

ComptesRendusPage.propTypes = {
  user: PropTypes.object.isRequired,
  getComptesRendus: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    startDateProps: props.location && props.location.state && props.location.state.startDate,
    endDateProps: props.location && props.location.state && props.location.state.endDate,
    user: state.auth.user,
    enfantSelected: state.auth.enfantSelected,
    intervenants: state.data.entities.intervenants,
    comptesRendusProps: filter(
      state.data.entities.comptesRendus,
      c => c.enfant_id === state.auth.enfantSelected.enfant_id
    ),
    types_profils:
      state.data.entities.settings &&
      state.data.entities.settings['types_profils'] &&
      state.data.entities.settings['types_profils'].data
  };
};

export default connect(mapStateToProps, { getComptesRendus })(ComptesRendusPage);
