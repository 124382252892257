import { flow, set } from 'lodash/fp';

import { CALL_API, Schemas } from '../Schemas';

const GET_PARENTS_REQUEST = 'ori/GET_PARENTS_REQUEST';
const GET_PARENTS_SUCCESS = 'ori/GET_PARENTS_SUCCESS';
const GET_PARENTS_FAILURE = 'ori/GET_PARENTS_FAILURE';

export const parentsActionsHandlers = {
  [GET_PARENTS_SUCCESS]: (state, action) =>
    flow(
      set('entities.parents', action.response.entities.parents || {}),
      set('loaded.parents', true),
      set('loading.parents', false)
    )(state)
};

export function getParents(parentsIds) {
  return {
    [CALL_API]: {
      types: [GET_PARENTS_REQUEST, GET_PARENTS_SUCCESS, GET_PARENTS_FAILURE],
      method: 'POST',
      body: parentsIds,
      endpoint: `/enfants/parents`,
      schema: Schemas.PARENT_ARRAY,
      successMessage: 'Parents chargés avec succès'
    }
  };
}
