import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, startsWith } from 'lodash';

import { getProfilImgMin } from '../../utils/methodes';
import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';
import { removeIntervenantProfil } from '../../store/modules/intervenants';
import ModalConfirmDelete from '../modals/ModalConfirmDelete';
import ModalEditDroitsIntervenant from '../modals/enfants/ModalEditDroitsIntervenant';
import AvatarOutline from '../../utils/AvatarOutline';
import AvatarMask from '../../utils/AvatarMask';

const ModalIntervenantProfil = ({
  intervenant,
  enfant,
  isOpen,
  close,
  removeIntervenantProfil,
  types_profils
}) => {
  const [isOpenModal, setIsOpenModal] = useState(isOpen);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDroits, setOpenModalDroits] = useState(false);
  const path = useLocation().pathname;

  return (
    <Modal {...modalDefaultClass} id="modal-intervenantProfil" isOpen={isOpenModal}>
      <header className="header">
        {path && startsWith(path, '/mon-enfant') && (
          <div className="header-right">
            <button className="header-btn" onClick={() => setOpenModalDroits(true)}>
              <i className="fas fa-pencil" />
            </button>
            <ModalEditDroitsIntervenant
              intervenant={intervenant}
              enfant={enfant[0]}
              isOpen={openModalDroits}
              close={() => setOpenModalDroits(false)}
            />
            <button className="header-btn" onClick={() => setOpenModalDelete(true)}>
              <i className="fas fa-trash-alt" />
            </button>
            <ModalConfirmDelete
              message={'Êtes-vous sûr de vouloir supprimer cet intervenant ?'}
              openModal={openModalDelete}
              closeModal={() => setOpenModalDelete(false)}
              actionDelete={() =>
                removeIntervenantProfil(intervenant.user_id, { intervenant, enfant })
              }
            />
          </div>
        )}
        <div className="header-bkg">
          <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
          <img className="hexa" src={require(`../../../assets/images/hexa-orange.svg`)} />
          <svg height="0px" width="0px">
            <defs>
              <clipPath
                id="menumask"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.00266666666,0.01666666666)"
              >
                <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </header>
      <div className="modal-body">
        <div className="member">
          <div className="member-avatar">
            {!isEmpty(intervenant.photo) ? (
              <Fragment>
                <div className="avatar-mask">
                  <img src={getProfilImgMin(intervenant.photo)} alt="photo" />
                </div>
                <AvatarMask nameFile="ModalProfil" />
                <AvatarOutline nameFile="ModalProfil" />
              </Fragment>
            ) : (
              <Fragment>
                <span>
                  {intervenant.prenom[0]}
                  {intervenant.nom[0]}
                </span>
                <AvatarOutline nameFile="ModalProfil" bigger />
              </Fragment>
            )}
          </div>
          <div className="member-infos">
            <div className="member-name">
              {intervenant && intervenant.prenom} {intervenant && intervenant.nom}
            </div>
            <div className="member-profil">
              {intervenant && `${types_profils[intervenant.profil]}`}
            </div>
          </div>
        </div>
        <div className="separator" />

        {intervenant.tel1 && (
          <a className="info-value" href={`tel:${intervenant.tel1}`}>
            <i className="fas fa-phone" />
            <span>{intervenant.tel1}</span>
          </a>
        )}
        {intervenant.tel2 && (
          <a className="info-value" href={`tel:${intervenant.tel2}`}>
            <i className="fas fa-phone" />
            <span>{intervenant.tel2}</span>
          </a>
        )}
        <a className="info-value" href={`mailto:${intervenant.email}`} target="_blank">
          <i className="fas fa-envelope" />
          <span>{intervenant.email}</span>
        </a>

        {intervenant.adresse && (
          <div className="info-value">
            <i className="fas fa-map-marker-alt" />

            <span
              dangerouslySetInnerHTML={{ __html: intervenant.adresse.replace(/\n/g, '<br/>') }}
            />
          </div>
        )}
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" onClick={() => close()}>
            Fermer
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalIntervenantProfil.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  removeIntervenantProfil: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  enfant: state.auth.enfantSelected,
  types_profils:
    state.data.entities.settings &&
    state.data.entities.settings['types_profils'] &&
    state.data.entities.settings['types_profils'].data
});

export default connect(mapStateToProps, { removeIntervenantProfil })(ModalIntervenantProfil);
