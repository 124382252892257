import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import { map, filter, orderBy, groupBy, upperFirst, truncate } from 'lodash';

import moment from 'moment';
import 'moment-duration-format';

import { getProfilImgMin, removeFirstCaracter0, sortObjectByKeys } from '../../utils/methodes';
import BaseLayout from '../shared/BaseLayout';

import { getEvents } from '../../store/modules/events';

import MonthSelector from '../../components/shared/MonthSelector';
import ModalAddUpdatePlanning from '../../components/modals/ModalAddUpdatePlanning';
import AvatarMask from '../../utils/AvatarMask';
import AvatarOutline from '../../utils/AvatarOutline';

const PlanningsPage = ({ user, getEvents, eventsProps, enfantSelected }) => {
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndate] = useState(moment().endOf('month'));

  useEffect(() => {
    getEvents(
      (user.profil === 'FAMILLE' && (user.userIdPrincipal ? user.userIdPrincipal : user.user_id)) ||
        enfantSelected.parent_id
    );
  }, []);

  const [eventsFiltred, setEventsFiltred] = useState(
    groupBy(
      map(
        filter(eventsProps, e =>
          moment(e.date).isBetween(moment(startDate).startOf('day'), moment(endDate).endOf('day'))
        ),
        e => {
          return { ...e, day: moment(e.date).format('YYYY-MM-DD') };
        }
      ),
      'day'
    )
  );

  useEffect(() => {
    setEventsFiltred(
      groupBy(
        map(
          filter(eventsProps, e =>
            moment(e.date).isBetween(moment(startDate).startOf('day'), moment(endDate).endOf('day'))
          ),
          e => {
            return { ...e, day: moment(e.date).format('YYYY-MM-DD') };
          }
        ),
        'day'
      )
    );
  }, [eventsProps]);

  useEffect(() => {
    onChangeFilter();
  }, [startDate, endDate]);

  const prevMonth = () => {
    setStartDate(
      moment(startDate)
        .subtract(1, 'months')
        .startOf('month')
    );
    setEndate(
      moment(endDate)
        .subtract(1, 'months')
        .endOf('month')
    );
  };

  const nextMonth = () => {
    setStartDate(
      moment(startDate)
        .add(1, 'months')
        .startOf('month')
    );
    setEndate(
      moment(endDate)
        .add(1, 'months')
        .endOf('month')
    );
  };

  const onChangeFilter = () => {
    setEventsFiltred(
      groupBy(
        map(
          filter(eventsProps, e =>
            moment(e.date).isBetween(moment(startDate).startOf('day'), moment(endDate).endOf('day'))
          ),
          e => {
            return { ...e, day: moment(e.date).format('YYYY-MM-DD') };
          }
        ),
        'day'
      )
    );
  };

  return (
    <BaseLayout homeBtn isPage withSubmenu noFooter headerPageTitle="Planning">
      <div className="main-container">
        <MonthSelector
          month={moment(startDate).format('MMMM')}
          nextMonth={() => nextMonth()}
          prevMonth={() => prevMonth()}
        />
        <div className="content">
          <div className="liste">
            {map(sortObjectByKeys(eventsFiltred, 'asc'), (events, day) => (
              <div className="list-block" key={day}>
                <div className="list-block-title">
                  {upperFirst(moment(events[0].date).format('dddd D'))}
                </div>
                {map(orderBy(events, ['heure_debut']), event => (
                  <div className="card card-event" key={event.event_id}>
                    <div className="card-header event-schedule">
                      <div>
                        <span className="event-start">
                          {removeFirstCaracter0(
                            moment(event.heure_debut)
                              .format('HH:mm')
                              .replace(':', 'h')
                          )}
                        </span>
                        &nbsp;-&nbsp;
                        {removeFirstCaracter0(
                          moment(event.heure_fin)
                            .format('HH:mm')
                            .replace(':', 'h')
                        )}
                      </div>
                      {event.adresse && (
                        <a
                          target="_blank"
                          href={`https://www.google.com/maps/place/${event.adresse}`}
                          className="element-tag element-tag-onlyIcon"
                        >
                          <i className="fas fa-route" />
                        </a>
                      )}
                    </div>

                    <NavLink className="flexbox alignCenter" to={`/planning/${event.event_id}`}>
                      {event.contact &&
                        event.contact !== 'AUCUN' &&
                        event.user_contact &&
                        event.user_contact.photo && (
                          <div className="member-avatar">
                            <div className="avatar-mask">
                              <img src={getProfilImgMin(event.user_contact.photo)} alt="photo" />
                            </div>
                            <AvatarMask nameFile="PlanningsPage" />
                            <AvatarOutline nameFile="PlanningsPage" />
                          </div>
                        )}
                      <div className="card-infos">
                        <div className="card-title">{event.titre}</div>
                        <div className="card-subtitle">
                          {truncate(event.description, { length: 25 })}
                        </div>
                      </div>
                    </NavLink>
                  </div>
                ))}
              </div>
            ))}
          </div>
          {user && (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') && (
            <ModalAddUpdatePlanning />
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

PlanningsPage.propTypes = {
  user: PropTypes.object.isRequired,
  getEvents: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  enfantSelected: state.auth.enfantSelected,
  intervenants: state.data.entities.intervenants,
  eventsProps:
    (state.data.entities.events &&
      filter(
        state.data.entities.events,
        d => d.type === 'planing_agenda' && d.enfant_id === state.auth.enfantSelected.enfant_id
      )) ||
    []
});

export default connect(mapStateToProps, { getEvents })(PlanningsPage);
