import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

import { loadUser } from './auth';
import { getIntervenants } from './intervenants';

const CREATE_ENFANT_REQUEST = 'ori/CREATE_ENFANT_REQUEST';
const CREATE_ENFANT_SUCCESS = 'ori/CREATE_ENFANT_SUCCESS';
const CREATE_ENFANT_FAILURE = 'ori/CREATE_ENFANT_FAILURE';

const GET_ENFANTS_REQUEST = 'ori/GET_ENFANTS_REQUEST';
const GET_ENFANTS_SUCCESS = 'ori/GET_ENFANTS_SUCCESS';
const GET_ENFANTS_FAILURE = 'ori/GET_ENFANTS_FAILURE';

const DELETE_ENFANT_REQUEST = 'ori/DELETE_ENFANT_REQUEST';
const DELETE_ENFANT_SUCCESS = 'ori/DELETE_ENFANT_SUCCESS';
const DELETE_ENFANT_FAILURE = 'ori/DELETE_ENFANT_FAILURE';

const UPDATE_ENFANT_REQUEST = 'ori/UPDATE_ENFANT_REQUEST';
const UPDATE_ENFANT_SUCCESS = 'ori/UPDATE_ENFANT_SUCCESS';
const UPDATE_ENFANT_FAILURE = 'ori/UPDATE_ENFANT_FAILURE';

const ADD_INTERVENANT_REQUEST = 'ori/ADD_INTERVENANT_REQUEST';
const ADD_INTERVENANT_SUCCESS = 'ori/ADD_INTERVENANT_SUCCESS';
const ADD_INTERVENANT_FAILURE = 'ori/ADD_INTERVENANT_FAILURE';

export const enfantsActionsHandlers = {
  [GET_ENFANTS_SUCCESS]: (state, action) =>
    flow(
      set('entities.enfants', action.response.entities.enfants || {}),
      set('loaded.enfants', true),
      set('loading.enfants', false)
    )(state),

  [CREATE_ENFANT_SUCCESS]: (state, action) => {
    return flow(
      set('entities.enfants', {
        ...state.entities.enfants,
        ...action.response.entities.enfants
      }),
      set('loaded.enfants', true),
      set('loading.enfants', false)
    )(state);
  },
  [DELETE_ENFANT_SUCCESS]: (state, action) =>
    flow(set('entities.enfants', omit(state.entities.enfants, action.enfant.enfant_id)))(state),

  [UPDATE_ENFANT_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.enfants.${action.enfant.enfant_id}`, {
        ...action.enfant,
        ...action.response
      }),
      set('loaded.enfants', true),
      set('loading.enfants', false)
    )(state);
  },
  [ADD_INTERVENANT_SUCCESS]: (state, action) => {
    return flow(
      set('entities.intervenants', {
        ...state.entities.intervenants,
        ...action.response.entities.intervenants
      }),
      set('loaded.intervenants', true),
      set('loading.intervenants', false)
    )(state);
  }
};

export function createEnfant(data) {
  return {
    [CALL_API]: {
      types: [CREATE_ENFANT_REQUEST, CREATE_ENFANT_SUCCESS, CREATE_ENFANT_FAILURE],
      method: 'POST',
      endpoint: '/enfants/create',
      body: data,
      schema: Schemas.ENFANT,
      successNext: [state => loadUser(state.auth.user.user_id)]
    }
  };
}

export function getEnfants(enfantsIds) {
  return {
    [CALL_API]: {
      types: [GET_ENFANTS_REQUEST, GET_ENFANTS_SUCCESS, GET_ENFANTS_FAILURE],
      method: 'POST',
      body: enfantsIds,
      endpoint: `/enfants/loadEnfants`,
      schema: Schemas.ENFANT_ARRAY,
      successMessage: 'Enfants chargés avec succès'
    }
  };
}

export function deleteEnfant(enfant, user) {
  return {
    enfant,
    user,
    [CALL_API]: {
      types: [DELETE_ENFANT_REQUEST, DELETE_ENFANT_SUCCESS, DELETE_ENFANT_FAILURE],
      method: 'DELETE',
      body: { enfant, user },
      endpoint: `/enfants/${enfant.enfant_id}`,
      successMessage: "L'enfant a bien été supprimé",
      successNext: [state => loadUser(state.auth.user.user_id)]
    }
  };
}

export function updateEnfant(enfant, data) {
  return {
    enfant,
    [CALL_API]: {
      types: [UPDATE_ENFANT_REQUEST, UPDATE_ENFANT_SUCCESS, UPDATE_ENFANT_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/enfants/${enfant.enfant_id}`,
      successMessage: "L'enfant a bien été modifié"
    }
  };
}
export function addIntervenant(data) {
  return {
    [CALL_API]: {
      types: [ADD_INTERVENANT_REQUEST, ADD_INTERVENANT_SUCCESS, ADD_INTERVENANT_FAILURE],
      method: 'POST',
      endpoint: '/users/addIntervenant/new',
      schema: Schemas.INTERVENANT_ARRAY,
      body: data,
      successNext: [state => getIntervenants([data.enfant.enfant_id])]
    }
  };
}
export function addIntervenantExistant(data) {
  return {
    [CALL_API]: {
      types: [ADD_INTERVENANT_REQUEST, ADD_INTERVENANT_SUCCESS, ADD_INTERVENANT_FAILURE],
      method: 'POST',
      endpoint: '/users/addIntervenant/existant',
      schema: Schemas.INTERVENANT_ARRAY,
      body: data,
      successNext: [state => getIntervenants([data.enfant.enfant_id])]
    }
  };
}
