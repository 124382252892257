import React from 'react';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

const IconCardLink = ({ icon, title, link }) => {
  return (
    <NavLink
      to={link}
      className="btn icon-card-link"
      style={{ backgroundColor: 'orange', display: 'flex', flexDirection: 'column' }}
    >
      <i className={`main-icon fal ${icon}`} />
      <span>{title}</span>
      <i className={`bkg-icon fas ${icon}`} />
    </NavLink>
  );
};

IconCardLink.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};
export default IconCardLink;
