import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { map, filter, groupBy, orderBy, includes, isEmpty, isNull } from 'lodash';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { uploadFiles } from '../store/modules/uploads';
import { createMessage, getMessages } from '../store/modules/messages';
import { sortObjectByKeys, isDroitUser } from '../utils/methodes';
import BaseLayout from './shared/BaseLayout';
import CardActu from '../components/cards/CardActu';
import FilterSearch from '../components/shared/FilterSearch';

const FilActusPage = ({ user, enfant, getMessages, createMessage, messages, uploadFiles }) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [typeSearched, setTypeSearched] = useState('undefined');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);

  const typeOptions = [
    { label: 'Toutes les actus', value: 'undefined' },
    { label: 'Comptes-rendus', value: 'comptes_rendus' },
    { label: 'Dossiers de prise en charge', value: 'dossier_prise_charge' },
    { label: 'Messages', value: 'message' },
    { label: 'Programmes', value: 'programmes' }
  ];

  const scrollToBottom = () => {
    const heightScreen = document.getElementById('_main').clientHeight;

    document.getElementById('wrapper').scrollTo(0, heightScreen);
  };

  useEffect(() => {
    scrollToBottom();

    if (enfant && !isEmpty(enfant)) {
      getMessages(enfant.enfant_id);
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, []);

  const messagesGroupByDate = sortObjectByKeys(
    groupBy(
      map(
        filter(messages, message =>
          message.type !== 'message' &&
          (user.userIdPrincipal
            ? message.famille_id !== user.userIdPrincipal
            : message.famille_id !== user.user_id) // ici il faut check si il n'a pas un user id principal qui correspond au famille_id
            ? (['MODIFICATION', 'CONSULTATION', 'ADMINISTRATION'],
              isDroitUser(message.type, user, enfant))
            : {}
        ),
        m => {
          return {
            ...m,
            day: moment(m.date).format('YYYY-MM-DD')
          };
        }
      ),
      'day'
    ),
    'asc'
  );

  const onChangeSearch = (value, type) => {
    setSearch(value);
    setTypeSearched(type);
    return getMessages(enfant.enfant_id, value || undefined, type || undefined).then(() => {
      scrollToBottom();
    });
  };

  const onChangeFile = event => {
    const file = event.target.files[0];
    file.minetype = file.type;
    setFile(file);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    const data = {
      type: 'message',
      text: message,
      user_id: user.user_id,
      famille_id: enfant.parent_id,
      enfant_id: enfant.enfant_id,
      date: new Date(),
      commentaires: []
    };

    if (file) {
      return uploadFiles({ files: [file] })
        .then(res => {
          data.file = res.response.fileName;
          data.file_type = res.response.fileType;
          return createMessage(data)
            .then(() => {
              setLoading(false);
              setFile(null);
              setError(false);
              setMessage('');
            })
            .then(() => {
              if (enfant && !isEmpty(enfant)) {
                getMessages(enfant.enfant_id);
              }
            })
            .catch(err => {
              setError(err);
              setLoading(false);
            });
        })
        .catch(err => {
          setLoading(false);
          setError(err);
        });
    } else {
      return createMessage(data)
        .then(() => {
          setLoading(false);
          setError(false);
          setMessage('');
        })
        .then(() => {
          if (enfant && !isEmpty(enfant)) {
            getMessages(enfant.enfant_id);
          }
        })
        .then(() => {
          return scrollToBottom();
        })
        .catch(err => {
          setLoading(false);
          setError(err);
        });
    }
  };

  return (
    <BaseLayout
      homeBtn
      isPage
      headerPageTitle="Fil d'actus"
      classScreen="actus"
      headerClass="header-bkg-solid"
    >
      <div id="messages-container" className="main-container padding-0">
        <div className="content">
          <div className="sticky-search-block">
            <div className="grid-noBottom">
              <div className="col-8_xs-12">
                <FilterSearch
                  isCancel
                  searchFilter={search}
                  updateSearchFilter={value => onChangeSearch(value, typeSearched)}
                />
              </div>
              <div className="col-4_xs-12">
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  defaultValue={typeOptions[0]}
                  onChange={type => {
                    onChangeSearch(search, type.value);
                  }}
                  options={(typeOptions && map(typeOptions)) || []}
                />
              </div>
            </div>
          </div>
          <div className="container">
            {map(messagesGroupByDate, (messages, date) => {
              return (
                <div className="list-block" key={date}>
                  <div className="list-block-title">
                    {(moment(messages[0].date).format('DD-MM-YYYY') ===
                      moment().format('DD-MM-YYYY') &&
                      "Aujourd'hui") ||
                      moment(messages[0].date).format('dddd DD MMMM')}
                  </div>

                  {map(orderBy(messages, ['date'], ['asc']), message => {
                    return <CardActu key={message.message_id} actu={message} />;
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="chat-block-wrapper">
          <div className="chat-block bottom-sticky">
            <form onSubmit={e => handleSubmit(e)}>
              {file && (
                <div className="file-preview">
                  <div className="file-preview-actions">
                    <div onClick={() => setFile(null)} className="btn-action">
                      <i className="far fa-arrow-left" />
                    </div>
                  </div>

                  {includes(['image/png', 'image/jpeg', 'image/jpg'], file.type) && (
                    <img src={URL.createObjectURL(file)} />
                  )}
                  {file.type === 'application/pdf' && (
                    <span>
                      <i className="fas fa-file-pdf" /> {file.name}
                    </span>
                  )}
                  {includes(['video/mp4', 'video/quicktime'], file.type) && (
                    <video controls width="100%">
                      <source src={URL.createObjectURL(file)} type="video/mp4" />
                    </video>
                  )}
                </div>
              )}
              <div className="field">
                <textarea
                  onChange={e => setMessage(e.target.value)}
                  id="message-input"
                  value={message}
                  className="chat-message-input"
                  placeholder="Tapez votre message"
                />
                {!file && (
                  <div className="btn-file">
                    <label htmlFor="file-upload">
                      <i className="far fa-paperclip" />
                    </label>

                    <input
                      onChange={e => onChangeFile(e)}
                      type="file"
                      id="file-upload"
                      name="file-upload"
                      accept="image/png,image/jpeg,image/jpg,application/pdf,video/mp4,video/quicktime,video/mov"
                    />
                  </div>
                )}
              </div>

              <button
                className="btn btn-primary"
                type="submit"
                disabled={(isEmpty(message) && isNull(file)) || (!isNull(file) && false)}
              >
                {(loading && <ClipLoader size={15} color={'white'} loading={loading} />) || (
                  <i className="fas fa-paper-plane" />
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

FilActusPage.propTypes = {
  uploadFiles: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getMessages: PropTypes.func.isRequired,
  createMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  enfant: state.auth.enfantSelected,
  user: state.auth.user,
  messages: state.data.entities.messages
});

export default connect(mapStateToProps, { getMessages, createMessage, uploadFiles })(FilActusPage);
