import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

const ProgrammeCard = ({ programme }) => {
  return (
    <NavLink className="card card-programme" to={`/programme/${programme.document_id}`}>
      <i className="fas fa-file-pdf" /> <span>{programme.titre}</span>
    </NavLink>
  );
};

ProgrammeCard.propTypes = {
  programme: PropTypes.object.isRequired
};
export default ProgrammeCard;
