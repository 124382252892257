import React, { useEffect, useState } from 'react';

const AvatarMask = ({ nameFile, zIndex }) => {
  const [value, setValue] = useState({
    height: '',
    width: '',
    id: '',
    d: '',
    fill: ''
  });
  useEffect(() => {
    switch (nameFile) {
      case 'CardActu':
        setValue({
          height: '0px',
          width: '0px',
          id: 'avatar-mask',
          d:
            'M24,18.79A4.43,4.43,0,0,1,22,22.56l-7.9,4.86a4,4,0,0,1-4.13,0l-7.84-4.8A4.44,4.44,0,0,1,0,18.82V9.18a4.44,4.44,0,0,1,2.08-3.8L10,.58a4,4,0,0,1,4.13,0L22,5.38A4.42,4.42,0,0,1,24,9.14Z',
          fill: '#F4FAFF'
        });
        break;
      case 'EquipeCard':
      case 'ModalProfil':
      case 'ModalAddMembreEquipe':
      case 'ModalAutorisation':
      case 'SelectAddDeleteEnfants':
      case 'ComptesRendusPage':
      case 'DossierPage':
      case 'DossiersPage':
      case 'PlanningPage':
      case 'PlanningsPage':
      case 'EnfantProfil':
        setValue({
          height: '0px',
          width: '0px',
          id: 'avatar-mask',
          d:
            'M22,47.2a8.1,8.1,0,0,1-4.1-1.1L5,38.7A8.4,8.4,0,0,1,.9,31.5V16.6A8.2,8.2,0,0,1,5,9.5L17.9,2a8.2,8.2,0,0,1,8.2,0L39,9.5a8.2,8.2,0,0,1,4.1,7.1V31.5A8.4,8.4,0,0,1,39,38.7L26.1,46.1A8.1,8.1,0,0,1,22,47.2Z',
          fill: '#F4FAFF'
        });
        break;
      case 'HeaderPage':
      case 'FieldUploadDropZone':
        setValue({
          height: '0px',
          width: '0px',
          id: 'avatar-mask-big',
          d:
            'M82,60.4c0,5-2.7,9.6-7,12.1L48,88.1c-4.4,2.5-9.7,2.5-14.1,0L7.1,72.7C2.7,70.2,0,65.5,0,60.5v-31c0-5,2.7-9.7,7.1-12.2L34,1.9c4.4-2.5,9.7-2.5,14.1,0L75,17.3c4.3,2.5,7,7.1,7,12.1V60.4z',
          fill: ''
        });
        break;
      case 'default':
        break;
    }
  }, []);

  return (
    <svg height={value.height} width={value.width} style={zIndex ? zIndex : {}}>
      <defs>
        <clipPath id={value.id}>
          <path id={value.id} d={value.d} fill={value.fill}></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AvatarMask;
