import React, { Fragment, useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { API_URL } from '../../constants/Config';
import { getWidthOfElement } from '../../utils/methodes';
import { removeExtension, getExtension } from '../../utils/StringUtils';

const MediaCard = ({ media }) => {
  const [sizeCard, setSizeCard] = useState(null);
  const refCard = useRef();

  useEffect(() => {
    setSizeCard(getWidthOfElement(refCard));
  }, []);

  const renderContent = () => {
    switch (media.file_type) {
      case 'image/gif':
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return (
          <img
            src={`${API_URL}/uploads/${removeExtension(media.file)}-vignette${getExtension(
              media.file
            )}`}
          />
        );
      case 'video/quicktime':
      case 'video/mp4':
        return (
          <Fragment>
            <i className="far fa-play-circle" />
            <img src={`${API_URL}/uploads/vignette_video_${removeExtension(media.file)}.png`} />
          </Fragment>
        );
    }
  };
  return (
    <NavLink className="media-link" ref={refCard} to={`/media/${media.document_id}`}>
      {renderContent()}
    </NavLink>
  );
};

MediaCard.propTypes = {
  media: PropTypes.object.isRequired
};
export default MediaCard;
