export default {
  entities: {
    enfants: {},
    settings: {},
    intervenants: {},
    documentsMedias: {},
    events: {},
    parents: {},
    comptesRendus: {},
    messages: {},
    users: {},
    stripe: {}
  },
  loaded: {
    appstorage: false,
    enfants: false,
    settings: false,
    intervenants: false,
    documentsMedias: false,
    events: false,
    parents: false,
    comptesRendus: false,
    messages: false,
    users: false,
    stripe: false
  },
  loading: {
    enfants: false,
    settings: false,
    intervenants: false,
    documentsMedias: false,
    events: false,
    parents: false,
    comptesRendus: false,
    messages: false,
    users: false,
    stripe: false
  }
};
