import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const CREATE_EVENT_REQUEST = 'ori/CREATE_EVENT_REQUEST';
const CREATE_EVENT_SUCCESS = 'ori/CREATE_EVENT_SUCCESS';
const CREATE_EVENT_FAILURE = 'ori/CREATE_EVENT_FAILURE';

const GET_EVENTS_REQUEST = 'ori/GET_EVENTS_REQUEST';
const GET_EVENTS_SUCCESS = 'ori/GET_EVENTS_SUCCESS';
const GET_EVENTS_FAILURE = 'ori/GET_EVENTS_FAILURE';

const DELETE_EVENT_REQUEST = 'ori/DELETE_EVENT_REQUEST';
const DELETE_EVENT_SUCCESS = 'ori/DELETE_EVENT_SUCCESS';
const DELETE_EVENT_FAILURE = 'ori/DELETE_EVENT_FAILURE';

const UPDATE_EVENT_REQUEST = 'ori/UPDATE_EVENT_REQUEST';
const UPDATE_EVENT_SUCCESS = 'ori/UPDATE_EVENT_SUCCESS';
const UPDATE_EVENT_FAILURE = 'ori/UPDATE_EVENT_FAILURE';

export const eventsActionsHandlers = {
  [GET_EVENTS_SUCCESS]: (state, action) =>
    flow(
      set('entities.events', action.response.entities.events || {}),
      set('loaded.events', true),
      set('loading.events', false)
    )(state),

  [CREATE_EVENT_SUCCESS]: (state, action) => {
    return flow(
      set('entities.events', {
        ...state.entities.events,
        ...action.response.entities.events
      }),
      set('loaded.events', true),
      set('loading.events', false)
    )(state);
  },
  [DELETE_EVENT_SUCCESS]: (state, action) =>
    flow(set('entities.events', omit(state.entities.events, action.event.event_id)))(state),

  [UPDATE_EVENT_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.events.${action.event.event_id}`, {
        ...action.event,
        ...action.response
      }),
      set('loaded.events', true),
      set('loading.events', false)
    )(state);
  }
};

export function createEvent(data) {
  return {
    [CALL_API]: {
      types: [CREATE_EVENT_REQUEST, CREATE_EVENT_SUCCESS, CREATE_EVENT_FAILURE],
      method: 'POST',
      endpoint: '/events',
      body: data,
      schema: Schemas.EVENT,
      successNext: [
        state =>
          getEvents(
            state.auth.user.profil === 'FAMILLE' || state.auth.user.profil === 'STRUCTURE'
              ? state.auth.user.userIdPrincipal
                ? state.auth.user.userIdPrincipal
                : state.auth.user.user_id
              : state.auth.user.enfantSelected.parent_id
          )
      ]
    }
  };
}

export function getEvents(parentId) {
  return {
    parentId,
    [CALL_API]: {
      types: [GET_EVENTS_REQUEST, GET_EVENTS_SUCCESS, GET_EVENTS_FAILURE],
      method: 'GET',
      endpoint: `/events/${parentId}`,
      schema: Schemas.EVENT_ARRAY,
      successMessage: 'Events chargés avec succès'
    }
  };
}

export function deleteEvent(event) {
  return {
    event,
    [CALL_API]: {
      types: [DELETE_EVENT_REQUEST, DELETE_EVENT_SUCCESS, DELETE_EVENT_FAILURE],
      method: 'DELETE',
      body: event,
      endpoint: `/events/${event.event_id}`,
      successMessage: "L'event a bien été supprimé"
    }
  };
}

export function updateEvent(event, data) {
  return {
    event,
    [CALL_API]: {
      types: [UPDATE_EVENT_REQUEST, UPDATE_EVENT_SUCCESS, UPDATE_EVENT_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/events/${event.event_id}`,
      successMessage: "L'event a bien été modifié"
    }
  };
}
