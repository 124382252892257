import React, { useState } from 'react';
import ModalCardFormPayment from '../../components/modals/Stripe/ModalCardFormPayment';
import ModalSuccessAccountCreation from '../../components/modals/ModalSuccessAccountCreation';

import BaseLayout from '../shared/BaseLayout';

const PriceChoicePage = ({ location }) => {
  const infos = { email: location.state.email, nom: location.state.nom };
  const [error, setError] = useState(null);
  const [chosenPrice, setChosenPrice] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [isOpenModalCardFormPayment, setIsOpenModalCardFormPayment] = useState(false);

  const handleSubmit = price => {
    return Promise.resolve()
      .then(data => {
        setChosenPrice(price);
        setIsOpenModalCardFormPayment(true);
      })
      .catch(err => setError(err.message || JSON.stringify(err)));
  };

  return (
    <BaseLayout backBtn noFooter isPage bigTitle headerPageTitle="Formules" classScreen="formules">
      {modalSuccess && (
        <ModalSuccessAccountCreation
          isOpen={modalSuccess}
          titleMessage={`Votre compte a été créé avec succès !`}
          message={
            'Votre compte a été créé avec succès ! vous allez recevoir un e-mail vous permettant de définir votre mot de passe pour vous permettre de vous connecter.'
          }
        />
      )}
      <ModalCardFormPayment
        email={infos.email}
        nom={infos.nom}
        chosenPrice={chosenPrice}
        isOpen={isOpenModalCardFormPayment}
        error={error}
        setError={() => setError()}
        setModalSuccess={v => setModalSuccess(v)}
        setIsOpenModalCardFormPayment={v => setIsOpenModalCardFormPayment(v)}
      />

      <div className="content">
        <div className="container">
          <div className="packages-container">
            <div className="grid-center">
              <div className="col-4_xs-12_md-4">
                <div className="package">
                  <div className="package-title">Abonnement Mensuel</div>
                  <div className="package-separator"></div>
                  <div className="package-price">
                    29,99 €<span>TTC / mois</span>
                  </div>
                  <div className="package-benefits-block">
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>1 compte famille avec 1 profil enfant</span>
                    </div>
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>Nombre d’intervenants illimité</span>
                    </div>
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>5 Go de stockage</span>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      handleSubmit('ABONNEMENT_MENSUEL_AVEC_ESSAI');
                    }}
                    className="btn btn-secondary"
                  >
                    Sélectionner
                  </button>
                  <div className="package-notes">Essai gratuit 30 jours</div>
                </div>
              </div>
              <div className="col-4_xs-12_md-4">
                <div className="package package-secondary">
                  <div className="package-promo-block">
                    <div className="package-promo">2 mois d’abonnement offerts</div>
                  </div>
                  <div className="package-title">Abonnement Annuel</div>
                  <div className="package-separator"></div>
                  <div className="package-price">
                    299,90 €<span>TTC / an</span>
                  </div>
                  <div className="package-price-details">(soit 24,99€/mois)</div>
                  <div className="package-benefits-block">
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>1 compte famille avec 1 profil enfant</span>
                    </div>
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>Nombre d’intervenants illimité</span>
                    </div>
                    <div className="package-benefit">
                      <i className="far fa-check" />
                      <span>5 Go de stockage</span>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      handleSubmit('ABONNEMENT_ANNUEL_AVEC_ESSAI');
                    }}
                    className="btn btn-secondary"
                  >
                    Sélectionner
                  </button>
                  <div className="package-notes">Essai gratuit 30 jours</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default PriceChoicePage;
