import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const CREATE_DOCUMENT_MEDIA_REQUEST = 'ori/CREATE_DOCUMENT_MEDIA_REQUEST';
const CREATE_DOCUMENT_MEDIA_SUCCESS = 'ori/CREATE_DOCUMENT_MEDIA_SUCCESS';
const CREATE_DOCUMENT_MEDIA_FAILURE = 'ori/CREATE_DOCUMENT_MEDIA_FAILURE';

const GET_DOCUMENTS_MEDIAS_REQUEST = 'ori/GET_DOCUMENTS_MEDIAS_REQUEST';
const GET_DOCUMENTS_MEDIAS_SUCCESS = 'ori/GET_DOCUMENTS_MEDIAS_SUCCESS';
const GET_DOCUMENTS_MEDIAS_FAILURE = 'ori/GET_DOCUMENTS_MEDIAS_FAILURE';

const DELETE_DOCUMENT_MEDIA_REQUEST = 'ori/DELETE_DOCUMENT_MEDIA_REQUEST';
const DELETE_DOCUMENT_MEDIA_SUCCESS = 'ori/DELETE_DOCUMENT_MEDIA_SUCCESS';
const DELETE_DOCUMENT_MEDIA_FAILURE = 'ori/DELETE_DOCUMENT_MEDIA_FAILURE';

const UPDATE_DOCUMENT_MEDIA_REQUEST = 'ori/UPDATE_DOCUMENT_MEDIA_REQUEST';
const UPDATE_DOCUMENT_MEDIA_SUCCESS = 'ori/UPDATE_DOCUMENT_MEDIA_SUCCESS';
const UPDATE_DOCUMENT_MEDIA_FAILURE = 'ori/UPDATE_DOCUMENT_MEDIA_FAILURE';

export const documentsMediasActionsHandlers = {
  [GET_DOCUMENTS_MEDIAS_SUCCESS]: (state, action) =>
    flow(
      set('entities.documentsMedias', action.response.entities.documentsMedias || {}),
      set('loaded.documentsMedias', true),
      set('loading.documentsMedias', false)
    )(state),

  [CREATE_DOCUMENT_MEDIA_SUCCESS]: (state, action) => {
    return flow(
      set('entities.documentsMedias', {
        ...state.entities.documentsMedias,
        ...action.response.entities.documentsMedias
      }),
      set('loaded.documentsMedias', true),
      set('loading.documentsMedias', false)
    )(state);
  },
  [DELETE_DOCUMENT_MEDIA_SUCCESS]: (state, action) => {
    return flow(
      set('entities.documentsMedias', omit(state.entities.documentsMedias, action.doc.document_id))
    )(state);
  },

  [UPDATE_DOCUMENT_MEDIA_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.documentsMedias.${action.doc.document_id}`, {
        ...action.document,
        ...action.response
      }),
      set('loaded.documentsMedias', true),
      set('loading.documentsMedias', false)
    )(state);
  }
};

// READ

export function createDocumentMedia(data) {
  return {
    [CALL_API]: {
      types: [
        CREATE_DOCUMENT_MEDIA_REQUEST,
        CREATE_DOCUMENT_MEDIA_SUCCESS,
        CREATE_DOCUMENT_MEDIA_FAILURE
      ],
      method: 'POST',
      endpoint: '/documentsMedias',
      body: data,
      schema: Schemas.DOCUMENT_MEDIA
    }
  };
}

// READ

export function getDocumentMedia(profil, userId, enfantId, documentId) {
  return {
    profil,
    userId,
    enfantId,
    documentId,
    [CALL_API]: {
      types: [
        GET_DOCUMENTS_MEDIAS_REQUEST,
        GET_DOCUMENTS_MEDIAS_SUCCESS,
        GET_DOCUMENTS_MEDIAS_FAILURE
      ],
      method: 'GET',
      endpoint: `/documentsMedias/${profil}/${userId}/${enfantId}/${documentId}`,
      schema: Schemas.DOCUMENT_MEDIA_ARRAY,
      successMessage: 'Document/media chargé avec succès'
    }
  };
}

export function getDocumentsMedias(profil, userId, enfantId) {
  return {
    profil,
    userId,
    enfantId,
    [CALL_API]: {
      types: [
        GET_DOCUMENTS_MEDIAS_REQUEST,
        GET_DOCUMENTS_MEDIAS_SUCCESS,
        GET_DOCUMENTS_MEDIAS_FAILURE
      ],
      method: 'GET',
      endpoint: `/documentsMedias/${profil}/${userId}/${enfantId}`,
      schema: Schemas.DOCUMENT_MEDIA_ARRAY,
      successMessage: 'Documents/medias chargés avec succès'
    }
  };
}

//UPDATE

export function updateDocumentMedia(doc, data) {
  return {
    doc,
    [CALL_API]: {
      types: [
        UPDATE_DOCUMENT_MEDIA_REQUEST,
        UPDATE_DOCUMENT_MEDIA_SUCCESS,
        UPDATE_DOCUMENT_MEDIA_FAILURE
      ],
      method: 'PUT',
      body: data,
      endpoint: `/documentsMedias/${doc.document_id}`,
      successMessage: "L'enfant a bien été modifié"
    }
  };
}

// DELETE

export function deleteDocumentMedia(doc) {
  return {
    doc,
    [CALL_API]: {
      types: [
        DELETE_DOCUMENT_MEDIA_REQUEST,
        DELETE_DOCUMENT_MEDIA_SUCCESS,
        DELETE_DOCUMENT_MEDIA_FAILURE
      ],
      method: 'DELETE',
      body: doc,
      endpoint: `/documentsMedias`,
      successMessage: 'Le document a bien été supprimé'
    }
  };
}
