import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { version } from '../../../package.json';

import { connect } from 'react-redux';
import { logout } from '../../store/modules/auth';
import { purge } from '../../store/modules/settings';
import { createEnfant } from '../../store/modules/enfants';
import { changeEnfant } from '../../store/modules/auth';

import BaseLayout from '../shared/BaseLayout';
import { isEmpty, map, values } from 'lodash';

const MonComptePage = ({
  logout,
  createEnfant,
  changeEnfant,
  purge,
  enfants,
  user,
  users,
  enfantSelected
}) => {
  const navigation = useHistory();
  const deconnexion = () => {
    return Promise.resolve()
      .then(() => logout())
      .then(() => purge());
  };

  const [allowToAddEnfant, setAllowToAddEnfant] = useState(false);

  useEffect(() => {
    // regarder si l'user peut ajouter un enfant :
    if (user && user.profil === 'FAMILLE') {
      // pas plus de 2 enfants :
      if (user && user.enfants && user.enfants.length >= 2) {
        setAllowToAddEnfant(false);
      } else {
        setAllowToAddEnfant(true);
      }
    }
    if (user && user.profil === 'STRUCTURE') {
      // pas plus des enfants dans l'abbo:
      if (user && user.enfants && user.enfants.length >= Number(user.taille)) {
        setAllowToAddEnfant(false);
      } else {
        setAllowToAddEnfant(true);
      }
    }
  }, []);

  const createNewEnfant = () => {
    let enfant;

    return createEnfant({
      parent_id: user.user_id,
      equipe: [],
      profil: {},
      sante: {},
      autorisations: []
    })
      .then(res => {
        enfant = values(res.response.entities.enfants)[0];
      })
      .then(() => {
        return changeEnfant(enfant);
      })
      .then(() => {
        navigation.push(`/mon-enfant/${enfant.enfant_id}`, { isPrevSelectEnfant: true });
      });
  };

  return (
    <BaseLayout
      headerPageTitle="Paramètres"
      isPage
      homeBtn
      displayTopHeader
      noFooter
      // homeBtn={user && user.isPremium && user.isPremium ? true : false}
    >
      <div className="main-container alignCenter">
        <div className="content alignCenter">
          {((user && user.profil !== 'FAMILLE') ||
            (user.profil === 'FAMILLE' && user.isPremium)) && (
            <NavLink to="/mes-infos-personnelles" className="btn btn-account">
              <span className="btn-account-info">
                <span className="btn-account-icon">
                  <i className={`far${user.profil === 'STRUCTURE' ? ' fa-school' : ' fa-user'}`} />
                </span>
                <span>
                  {user.profil === 'STRUCTURE' ? 'Infos de la structure' : 'Mes infos personnelles'}
                </span>
              </span>
              <i className="far fa-chevron-right" />
            </NavLink>
          )}

          {user &&
            user.profil === 'FAMILLE' &&
            !user.userIdPrincipal &&
            users &&
            users.length > 0 &&
            map(users, u => (
              <NavLink key={u.user_id} to="/compte-secondaire" className="btn btn-account">
                <span className="btn-account-info">
                  <span className="btn-account-icon">
                    <i className="far fa-user" />
                  </span>
                  <span>Les infos de {u.pseudo}</span>
                </span>
                <i className="far fa-chevron-right" />
              </NavLink>
            ))}

          {user && (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') && (
            <Fragment>
              {enfants && enfants.length > 0 ? (
                <Fragment>
                  <div className="settings-title">
                    {user && user.profil === 'FAMILLE' ? 'Mes enfants' : 'Mes patients'}
                  </div>
                  {map(enfants, e => (
                    <NavLink
                      key={e.enfant_id}
                      to={`/mon-enfant/${e.enfant_id}`}
                      onClick={() => changeEnfant(e)}
                      className="btn btn-account"
                    >
                      <span className="btn-account-info">
                        <span className="btn-account-icon">
                          <i className="far fa-user" />
                        </span>
                        <span>
                          {e.profil.prenom || ''} {e.profil.nom || ''}
                        </span>
                      </span>
                      <i className="far fa-chevron-right" />
                    </NavLink>
                  ))}
                  {/* // si on est un compte secondaire on ne peut pas rajouter d'enfnat */}
                  {user && !user.userIdPrincipal && (
                    <div className="btn-group flexColumn center">
                      <button
                        disabled={!allowToAddEnfant}
                        className="btn btn-primary"
                        onClick={() => createNewEnfant()}
                      >
                        <i className="fas fa-user-plus"></i>
                        <span>Ajouter un enfant</span>
                      </button>
                      {!allowToAddEnfant && (
                        <div className="btn-legend">
                          {user && user.profil === 'FAMILLE'
                            ? 'Vous avez atteint la limite maximum de profils Enfant'
                            : 'Vous avez atteint votre quota. Si vous le souhaitez, vous pouvez modifier votre abonnement'}
                        </div>
                      )}
                    </div>
                  )}
                </Fragment>
              ) : (
                // <button className="btn btn-account" onClick={() => createNewEnfant()}>
                //   <span className="btn-account-info">
                //     <span className="btn-account-icon">
                //       <i className="far fa-users" />
                //     </span>
                //     <span>
                //       {user.profil === 'STRUCTURE' ? ' Ajouter un patient' : ' Ajouter mon enfant'}
                //     </span>
                //   </span>
                //   <i className="far fa-chevron-right" />
                // </button>
                <div className="btn-group center">
                  <button className="btn btn-primary" onClick={() => createNewEnfant()}>
                    <i className="fas fa-user-plus"></i>
                    <span>Ajouter un enfant</span>
                  </button>
                </div>
              )}
            </Fragment>
          )}

          {user && user.profil !== 'FAMILLE' && user.profil !== 'STRUCTURE' && !isEmpty(enfants) && (
            <Fragment>
              <div className="settings-title">
                {user && user.profil === 'FAMILLE_STRUCTURE' ? 'Mes enfants' : 'Mes patients'}
              </div>
              {enfants &&
                map(enfants, e =>
                  enfantSelected && e.enfant_id && e.enfant_id === enfantSelected.enfant_id ? (
                    <NavLink
                      key={e.enfant_id}
                      to={`/enfant/${e.enfant_id}`}
                      onClick={() => changeEnfant(e)}
                      className="btn btn-account"
                    >
                      <span className="btn-account-info">
                        <span className="btn-account-icon">
                          <i className="far fa-user" />
                        </span>
                        <span>
                          {e.profil.prenom} {e.profil.nom}
                        </span>
                      </span>
                      <i className="far fa-chevron-right" />
                    </NavLink>
                  ) : (
                    <NavLink
                      key={e.enfant_id}
                      to={`/enfant/${e.enfant_id}`}
                      className="btn btn-account"
                      onClick={() => changeEnfant(e)}
                    >
                      <span className="btn-account-info">
                        <span className="btn-account-icon">
                          <i className="far fa-user" />
                        </span>
                        <span>
                          {e.profil.prenom} {e.profil.nom}
                        </span>
                      </span>
                      <i className="far fa-chevron-right" />
                    </NavLink>
                  )
                )}
            </Fragment>
          )}
          <div className="settings-title">Infos</div>
          <a
            href="https://www.originailes.fr/cgu-cgv-application-originailes/"
            target="_blank"
            rel="noopener"
            className="btn btn-account"
          >
            <span className="btn-account-info">
              <span className="btn-account-icon">
                <i className="far fa-info-circle" />
              </span>
              <span>Mentions légales et CGU/CGV</span>
            </span>
            <i className="far fa-chevron-right" />
          </a>
          {user &&
            (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') &&
            !user.code &&
            !user.userIdPrincipal && (
              <NavLink to="/mon-abonnement" className="btn btn-account">
                <span className="btn-account-info">
                  <span className="btn-account-icon">
                    <i className="far fa-gem" />
                  </span>
                  <span>Mon abonnement</span>
                </span>
                <i className="far fa-chevron-right" />
              </NavLink>
            )}
          <span className="version">
            <i className="far fa-laptop-code" />
            Version {version}
          </span>
        </div>
        <NavLink className="btn btn-primary" onClick={() => deconnexion()} to="/connexion">
          Déconnexion
        </NavLink>
      </div>
    </BaseLayout>
  );
};

MonComptePage.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  purge: PropTypes.func.isRequired,
  createEnfant: PropTypes.func.isRequired,
  changeEnfant: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  users: state.data.entities.users && values(state.data.entities.users),
  enfants: values(state.data.entities.enfants) || {},
  enfantSelected: state.auth.enfantSelected
});

export default connect(mapStateToProps, { logout, purge, createEnfant, changeEnfant })(
  MonComptePage
);
