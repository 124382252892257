import React, { Fragment } from 'react';
// on recupère la donné TYPES_PROFILS dans la base de donnée

export const DROITS = {
  MODIFICATION: 'Modification',
  CONSULTATION: 'Consultation',
  ADMINISTRATION: 'Administration',
  PAS_ACCES: "Pas d'accès"
};

export const COOPERATIONS_ELEVE = {
  TRES_COOPERANT: 'Très coopérant',
  MOTIVE: 'Motivé',
  MOTIVATION_COMPORTEMENT_VARIABLE: 'Motivation et comportement variable selon les programmes',
  ECHAPPEMENT_TACHE_OPPOSANT: 'Échappement à la tâche et opposant',
  AUTRE: 'Autre'
};
export const FONCTIONS_TROUBLE_COMPORTEMENT = {
  AUTOSTIMULATION: 'Autostimulation',
  ECHAPPEMENT: 'Echappement',
  OBTENTION_TANGIBLE: 'Obtention d’un tangible',
  ATTENTION: 'Attention',
  AUTRE: 'Autre'
};

export const TOPOGRAPHIES = {
  CRI: 'Cri',
  TAPE_OBJET: 'Tape objet',
  STEREOTYPIES: 'Stéréotypies',
  REFUS_TRAVAILLER: 'Refus de travailler',
  AUTOAGRESSIVITE: 'Autoagressivité',
  HETEROAGRESSIVITE: 'Hétéroagressivité',
  LANCE_OBJET: 'Lance objet',
  PLEURS: 'Pleurs',
  AUTRE: 'Autre'
};
export const TYPES_DOCUMENTS = {
  EVALUATION: 'Evaluation',
  SUPERVISION: 'Supervision',
  BILAN: 'Bilan',
  OUTIL_MATERIEL: 'Outil et Matériel',
  AUTRE: 'Autre'
};

export const selectPrice = {
  ABONNEMENT_MENSUEL_AVEC_ESSAI: (
    <div className="packages-container">
      <div className="package">
        <div className="package-title">Abonnement Mensuel</div>
        <div className="package-separator"></div>
        <div className="package-price">
          29,99 €<span>TTC / mois</span>
        </div>
        <div className="package-notes">Essai gratuit 30 jours</div>
      </div>
    </div>
  ),
  ABONNEMENT_MENSUEL_SANS_ESSAI: (
    <div className="packages-container">
      <div className="package">
        <div className="package-title">Abonnement Mensuel</div>
        <div className="package-separator"></div>
        <div className="package-price">
          29,99 €<span>TTC / mois</span>
        </div>
      </div>
    </div>
  ),
  ABONNEMENT_ANNUEL_AVEC_ESSAI: (
    <div className="packages-container">
      <div className="package package-secondary">
        <div className="package-promo-block">
          <div className="package-promo">2 mois d’abonnement offerts</div>
        </div>
        <div className="package-title">Abonnement Annuel</div>
        <div className="package-separator"></div>
        <div className="package-price">
          299,90 €<span>TTC / an</span>
        </div>
        <div className="package-notes">Essai gratuit 30 jours</div>
      </div>
    </div>
  ),
  ABONNEMENT_ANNUEL_SANS_ESSAI: (
    <div className="packages-container">
      <div className="package package-secondary">
        <div className="package-promo-block">
          <div className="package-promo">Essai gratuit 30 jours</div>
        </div>
        <div className="package-title">Abonnement Annuel</div>
        <div className="package-separator"></div>
        <div className="package-price">
          299,90 €<span>TTC / an</span>
        </div>
      </div>
    </div>
  ),
  ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_1_AVEC_ESSAI: (
    <div className="package package-secondary">
      <div className="package-promo-block">
        <div className="package-promo">Essai gratuit 30 jours</div>
      </div>
      <div className="package-title">Abonnement Annuel</div>
      <div className="package-separator"></div>
      <div className="package-price">
        1599 €<span>TTC / an</span>
      </div>
      <div className="package-price-details">(soit 133,25€/mois)</div>
      <div className="package-benefits-block">
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>1 compte qui peut gérer jusqu'à 5 enfants</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>Nombre d’intervenants illimité</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>5 Go de stockage</span>
        </div>
      </div>
    </div>
  ),
  ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_2_AVEC_ESSAI: (
    <div className="package package-secondary">
      <div className="package-promo-block">
        <div className="package-promo">Essai gratuit 30 jours</div>
      </div>
      <div className="package-title">Abonnement Annuel</div>
      <div className="package-separator"></div>
      <div className="package-price">
        2999 €<span>TTC / an</span>
      </div>
      <div className="package-price-details">(soit 249,92€/mois)</div>
      <div className="package-benefits-block">
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>1 compte qui peut gérer jusqu'à 10 enfants</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>Nombre d’intervenants illimité</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>5 Go de stockage</span>
        </div>
      </div>
    </div>
  ),
  ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_3_AVEC_ESSAI: (
    <div className="package package-secondary">
      <div className="package-promo-block">
        <div className="package-promo">Essai gratuit 30 jours</div>
      </div>
      <div className="package-title">Abonnement Annuel</div>
      <div className="package-separator"></div>
      <div className="package-price">
        5299 €<span>TTC / an</span>
      </div>
      <div className="package-price-details">(soit 451,58€/mois)</div>
      <div className="package-benefits-block">
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>1 compte qui peut gérer jusqu'à 20 enfants</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>Nombre d’intervenants illimité</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>5 Go de stockage</span>
        </div>
      </div>
    </div>
  ),
  ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_1_SANS_ESSAI: (
    <div className="package package-secondary">
      <div className="package-title">Abonnement Annuel</div>
      <div className="package-separator"></div>
      <div className="package-price">
        1599 €<span>TTC / an</span>
      </div>
      <div className="package-price-details">(soit 133,25€/mois)</div>
      <div className="package-benefits-block">
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>1 compte qui peut gérer 1 à 5 enfants</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>Nombre d’intervenants illimité</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>5 Go de stockage</span>
        </div>
      </div>
    </div>
  ),
  ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_2_SANS_ESSAI: (
    <div className="package package-secondary">
      <div className="package-title">Abonnement Annuel</div>
      <div className="package-separator"></div>
      <div className="package-price">
        2999 €<span>TTC / an</span>
      </div>
      <div className="package-price-details">(soit 249,92€/mois)</div>
      <div className="package-benefits-block">
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>1 compte qui peut gérer 6 à 10 enfants</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>Nombre d’intervenants illimité</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>5 Go de stockage</span>
        </div>
      </div>
    </div>
  ),
  ABONNEMENT_ANNUEL_STRUCTURE_FORMULE_3_SANS_ESSAI: (
    <div className="package package-secondary">
      <div className="package-title">Abonnement Annuel</div>
      <div className="package-separator"></div>
      <div className="package-price">
        5299 €<span>TTC / an</span>
      </div>
      <div className="package-price-details">(soit 451,58€/mois)</div>
      <div className="package-benefits-block">
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>1 compte qui peut gérer 11 à 20 enfants</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>Nombre d’intervenants illimité</span>
        </div>
        <div className="package-benefit">
          <i className="far fa-check" />
          <span>5 Go de stockage</span>
        </div>
      </div>
    </div>
  )
};

export const trialDays = 30;

export const optionsTaillesStructure = [
  { value: '5', label: "jusqu'à 5 enfants" },
  { value: '10', label: "jusqu'à 10 enfants" },
  { value: '20', label: "jusqu'à 20 enfants" }
];
