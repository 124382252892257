import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { API_URL } from '../../constants/Config';
import { removeExtension, getExtension } from '../../utils/StringUtils';
import { isDroitUser } from '../../utils/methodes';

import { connect } from 'react-redux';
import { deleteDocumentMedia } from '../../store/modules/documentsMedias';

import ImageGallery from 'react-image-gallery';

import BaseLayout from '../shared/BaseLayout';

import { map, includes, get, findIndex, values, filter, find, startsWith, orderBy } from 'lodash';
import ModalConfirmDelete from '../../components/modals/ModalConfirmDelete';

const MediaPage = ({ user, enfantSelected, deleteDocumentMedia, documentsMedias }) => {
  const paramsRoute = useParams();
  const [documentId, setDocumentId] = useState(paramsRoute && paramsRoute.id);
  const [indexMedia, setIndexMedia] = useState(0);

  const galleryRef = useRef(null);

  const [mediaSelected, setMediaSelected] = useState(
    find(documentsMedias, d => d.document_id === Number(documentId))
  );

  const navigation = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const deleteMedia = () => {
    deleteDocumentMedia(mediaSelected).then(() => {
      navigation.goBack();
    });
  };

  useEffect(() => {
    if (galleryRef && galleryRef.current) {
      const indexMedia = findIndex(
        values(documentsMedias),
        d => d.document_id === Number(documentId)
      );

      setIndexMedia(indexMedia);

      galleryRef.current.slideToIndex(indexMedia);
    }
  }, []);

  const onChangeSlide = index => {
    const newMedia = documentsMedias[index];
    setDocumentId(newMedia.document_id);
    setMediaSelected(newMedia);
    setIndexMedia(index);
  };

  const renderItem = item => {
    switch (item.file_type) {
      case 'image/gif':
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return (
          <Fragment>
            <div className="image-gallery-slide-image_wrapper">
              <img
                src={`${API_URL}/uploads/${removeExtension(item.file)}${getExtension(item.file)} `}
                style={{ zIndex: 1000 }}
                srcSet={map(
                  ['640w', '1024w', '1920w'],
                  taille =>
                    `${API_URL}/uploads/${removeExtension(item.file)}-${taille}${getExtension(
                      item.file
                    )} ${taille}`
                )}
              />
            </div>
            {item && item.description && (
              <div className="image-gallery-slide-description">{item.description}</div>
            )}
          </Fragment>
        );
      case 'video/quicktime':
      case 'video/mp4':
        return (
          <Fragment>
            <div className="image-gallery-slide-image_wrapper">
              <video
                style={{ zIndex: 1000 }}
                controls
                preload="metadata"
                poster={`${API_URL}/uploads/vignette_video_${removeExtension(item.file)}.png`}
              >
                <source
                  src={`${API_URL}/uploads/${(item && item.file && 'min_' + item.file) || ''}`}
                  type="video/mp4"
                />
              </video>
            </div>
            {item && item.description && (
              <div className="image-gallery-slide-description">{item.description}</div>
            )}
          </Fragment>
        );
    }
  };

  const isItemVideo = item => {
    if (item.file_type && startsWith(item.file_type, 'video')) {
      return true;
    }
  };
  return (
    <BaseLayout noHeader noFooter classScreen="media-preview">
      <div className="media-toolbar">
        <span className="btn" onClick={() => navigation.goBack()}>
          <i style={{ color: 'white' }} className="far fa-arrow-left" />
        </span>
        {(user.profil === 'FAMILLE' ||
          user.profil === 'STRUCTURE' ||
          includes(['ADMINISTRATION'], isDroitUser('medias', user, enfantSelected))) && (
          <Fragment>
            <span className="btn" onClick={() => setOpenModalDelete(true)}>
              <i style={{ color: 'white' }} className="fas fa-trash-alt" />
            </span>
            <ModalConfirmDelete
              message={`Êtes-vous sûr de vouloir supprimer ce média ?`}
              openModal={openModalDelete}
              closeModal={() => setOpenModalDelete(false)}
              actionDelete={() => deleteMedia()}
            />
          </Fragment>
        )}
      </div>

      {mediaSelected && (
        <ImageGallery
          showNav={true}
          lazyLoad={true}
          showThumbnails={false}
          showBullets={false}
          startIndex={indexMedia}
          onSlide={sIndex => onChangeSlide(sIndex)}
          ref={galleryRef}
          showPlayButton={false}
          showFullscreenButton={false}
          items={map(orderBy(documentsMedias, ['created_at'], ['desc']), item => {
            return {
              ...item,
              original: `${API_URL}/uploads/${removeExtension(item.file)}${getExtension(
                item.file
              )} `,
              thumbnail: isItemVideo(item)
                ? `${API_URL}/uploads/vignette_video_${removeExtension(item.file)}.png`
                : `${API_URL}/uploads/${removeExtension(item.file)}-640w${getExtension(item.file)}`,
              renderItem: () => renderItem(item)
            };
          })}
        />
      )}
    </BaseLayout>
  );
};

MediaPage.propTypes = {
  user: PropTypes.object.isRequired,
  deleteDocumentMedia: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const documentsMedias = filter(
    values(get(state, 'data.entities.documentsMedias')),
    d => d.type === 'medias'
  );

  return {
    documentsMedias: orderBy(documentsMedias, ['created_at'], ['desc']),
    enfantSelected: get(state, 'auth.enfantSelected') || null,
    user: get(state, 'auth.user')
  };
};

export default connect(mapStateToProps, { deleteDocumentMedia })(MediaPage);
