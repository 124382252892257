import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircleLoader from 'react-spinners/CircleLoader';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import useResponsiveFontSize from '../../../utils/useResponsiveFontSize';
import {
  subscription,
  subscriptionSepa,
  resubscriptionStructure,
  createCustomer,
  getSecretSepa,
  attachDefaultPaymentMethod
} from '../../../store/modules/stripe';
import { createUserStructure, updateUserStructureResuscribe } from '../../../store/modules/auth';
import { trialDays } from '../../../constants/Properties';

// Custom styling can be passed as options when creating an Element.
const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      supportedCountries: ['SEPA'],
      // Elements can use a placeholder as an example IBAN that reflects
      // the IBAN format of your customer's country. If you know your
      // customer's country, we recommend that you pass it to the Element as the
      // placeholderCountry.
      placeholderCountry: 'FR',
      style: {
        base: {
          color: '#32325d',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          },
          ':-webkit-autofill': {
            color: '#32325d'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a'
          }
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const IbanForm = ({
  user,
  dataUser,
  chosenPrice,
  chosenSize,
  subscriptionSepaMethod,
  resubscriptionStructureMethod,
  createCustomer,
  getSecretSepa,
  attachDefaultPaymentMethod,
  createUserStructure,
  updateUserStructureResuscribe,
  setModalSuccess,
  setIsOpenModalCardFormPayment
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loading, setLoading] = useState(false);
  const [validIban, setValidIban] = useState(false);
  const [error, setError] = useState(null);
  const profil = user && user.profil;

  const handleSubmit = async event => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    let subscriptionDataResponse;
    let customerId;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log("ERROR ! Stripe n'est pas chargé");
      setLoading(false);
      ibanElement.update({ disabled: false });
      return;
    }

    const ibanElement = elements && elements.getElement(IbanElement);
    const nomCompte = event.target.nomCompte;
    const emailCompte = event.target.emailCompte;

    ibanElement.update({ disabled: true });

    //////////////////////////////////////////////////////
    // Si l'utilisateur ne possède pas encore de compte //
    //////////////////////////////////////////////////////
    return Promise.resolve()
      .then(() => {
        if (!user) {
          return createCustomer(nomCompte.value, emailCompte.value)
            .then(customer => {
              // Création du setupIntent pour ce customer chez Stripe)
              customerId = customer.response.customer.id;
              return getSecretSepa(customerId);
            })
            .then(setupIntent => {
              const clientSecret = setupIntent.response.client_secret;
              // Confirmation du prélévement SEPA chez Stripe
              return stripe.confirmSepaDebitSetup(clientSecret, {
                payment_method: {
                  sepa_debit: ibanElement,
                  billing_details: {
                    name: nomCompte.value,
                    email: emailCompte.value
                  }
                }
              });
            })
            .then(result => {
              // ERREUR de CREATION de la METHODE de PAIEMENT, affichage du message d'erreur (affichage à nouveau du formulaire)
              if (result.error) {
                console.log('ERROR !', result.error.message);
                setError(result.error.message);
                setLoading(false);
                ibanElement.update({ disabled: false });
                return 'error';
              }
              // REUSSITE de CREATION de la METHODE de PAIEMENT, tentative de CREATION de l'ABONNEMENT
              else {
                // // Rattachement de la méthode de paiement au customer
                const paymentMethod = result.setupIntent.payment_method;
                return attachDefaultPaymentMethod(customerId, paymentMethod)
                  .then(res => {
                    if (!res.response) {
                      console.warn('TODO : à traiter', res);
                    }
                    // eslint-disable-next-line camelcase
                    return subscriptionSepaMethod({
                      customer: customerId,
                      price: chosenPrice,
                      trial_days: trialDays
                    });
                  })
                  .then(res => {
                    if (!res.response) {
                      console.warn('TODO : à traiter', res);
                    }
                    const { client_secret, status, customer_id, subscription } = res.response; // res.data

                    // Si la CREATION de l'ABONNEMENT nécessite une ACTION
                    if (status === 'requires_action' || status === 'requires_confirmation') {
                      return stripe
                        .confirmSepaDebitPayment(client_secret, {
                          payment_method: {
                            sepa_debit: ibanElement,
                            billing_details: { name: nomCompte.value, email: emailCompte.value }
                          }
                        })
                        .then(function(result) {
                          // En cas d'ECHEC de CREATION de l'ABONNEMENT, affichage du message d'erreur
                          if (result && result.error) {
                            console.log('There was an issue!');
                            console.log(result.error);
                            // Display error message in your UI.
                            setError(result.error);
                            // The card was declined (i.e. insufficient funds, card has expired, etc)
                            setLoading(false);
                            ibanElement.update({ disabled: true });
                            return 'error';
                          }
                          // Si la CREATION de l'ABONNEMENT aboutit MALGRE TOUT
                          else {
                            console.log('It needed an action but you got the money!');
                            // Show a success message to your customer
                            setError(null);
                            return createUserStructure(
                              dataUser.email,
                              dataUser.nom,
                              chosenSize,
                              customer_id,
                              subscription
                            );
                          }
                        });
                    }
                    // Si la CREATION de l'ABONNEMENT aboutit
                    else {
                      console.log('You got the money!');
                      // No additional information was needed
                      // Show a success message to your customer
                      setError(null);
                      return createUserStructure(
                        dataUser.email,
                        dataUser.nom,
                        chosenSize,
                        customer_id,
                        subscription
                      );
                    }
                  });
              }
            });
        }

        /////////////////////////////////////////////
        // Si l'utilisateur possède déjà un compte //
        /////////////////////////////////////////////
        else {
          // Création du setupIntent pour ce customer chez Stripe
          customerId = user.transactionReceipt.customer_id;
          return getSecretSepa(customerId)
            .then(setupIntent => {
              const clientSecret = setupIntent.response.client_secret;
              // Confirmation du prélévement SEPA chez Stripe
              return stripe.confirmSepaDebitSetup(clientSecret, {
                payment_method: {
                  sepa_debit: ibanElement,
                  billing_details: {
                    name: nomCompte.value,
                    email: emailCompte.value
                  }
                }
              });
            })
            .then(result => {
              // ERREUR de CREATION de la METHODE de PAIEMENT, affichage du message d'erreur
              // (affichage à nouveau du formulaire)
              if (result.error) {
                console.log('ERROR !', result.error.message);
                setError(result.error.message);
                setLoading(false);
                ibanElement.update({ disabled: false });
                return 'error';
              }
              // REUSSITE de CREATION de la METHODE de PAIEMENT, tentative de CREATION de l'ABONNEMENT
              else {
                // Rattachement de la méthode de paiement au customer
                const paymentMethod = result.setupIntent.payment_method;
                return attachDefaultPaymentMethod(customerId, paymentMethod)
                  .then(res => {
                    const paymentMethod = res.response.invoice_settings.default_payment_method;
                    if (!res.response) {
                      console.warn('TODO : à traiter', res);
                    } else {
                      // eslint-disable-next-line camelcase
                      return resubscriptionStructureMethod({
                        customerId,
                        payment_method: paymentMethod,
                        price: chosenPrice
                      });
                    }
                  })
                  .then(res => {
                    if (!res.response) {
                      console.warn('TODO : à traiter', res);
                    } else {
                      const { client_secret, status } = res.response; // res.data
                      subscriptionDataResponse = res.response;

                      // Si la CREATION de l'ABONNEMENT nécessite une ACTION
                      if (status === 'requires_action' || status === 'requires_confirmation') {
                        return stripe
                          .confirmSepaDebitPayment(client_secret, {
                            payment_method: {
                              sepa_debit: ibanElement,
                              billing_details: { name: nomCompte.value, email: emailCompte.value }
                            }
                          })
                          .then(() => {
                            // Si la CREATION de l'ABONNEMENT aboutit MALGRE TOUT

                            console.log('It needed an action but you got the money!');
                            // Show a success message to your customer
                            setError(null);
                            return updateUserStructureResuscribe({
                              user_id: user.user_id,
                              customerId: subscriptionDataResponse.customer_id,
                              chosenSize,
                              subscription: subscriptionDataResponse.subscription
                            });
                          });
                      } else {
                        console.log('You got the money!');
                        // No additional information was needed
                        // Show a success message to your customer
                        setError(null);
                        return updateUserStructureResuscribe({
                          user_id: user.user_id,
                          customerId: subscriptionDataResponse.customer_id,
                          chosenSize,
                          subscription: subscriptionDataResponse.subscription
                        });
                      }
                    }
                  })
                  .then(result => {
                    // En cas d'ECHEC de CREATION de l'ABONNEMENT, affichage du message d'erreur
                    if (result && result.error) {
                      console.log('There was an issue!');
                      console.log(result.error);
                      // Display error message in your UI.
                      setError(result.error);
                      // The card was declined (i.e. insufficient funds, card has expired, etc)
                      setLoading(false);
                      ibanElement.update({ disabled: false });
                      return 'error';
                    }
                  });
              }
            });
        }
      })
      .then(() => {
        setLoading(false);
        setIsOpenModalCardFormPayment(false);
        setModalSuccess(true);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
        ibanElement.update({ disabled: false });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row inline">
        <div className="col">
          <label htmlFor="nomCompte">
            Nom
            <input name="nomCompte" disabled={loading} defaultValue={dataUser.nom} required />
          </label>
        </div>

        <div className="col">
          <label htmlFor="emailCompte">
            Adresse Email
            <input
              name="emailCompte"
              type="email"
              disabled={loading}
              defaultValue={dataUser.email}
              required
            />
          </label>
        </div>
      </div>

      <div className="form-row">
        <label>
          IBAN
          {options ? (
            <IbanElement
              className="stripeInput"
              options={options}
              onReady={() => {
                console.log('IbanElement [ready]');
              }}
              onChange={event => {
                setValidIban(!event.error && !event.empty && event.complete);
              }}
            />
          ) : null}
        </label>
      </div>
      {error && <div className="error-block">{error}</div>}
      <button
        type="submit"
        disabled={loading}
        style={{ marginTop: '10px', marginBottom: '10px', fontWeight: 'bold' }}
        // disabled={!stripe || !validIban || loading}
      >
        {loading ? (
          <CircleLoader size={30} color={'black'} loading={loading} />
        ) : (
          `Mettre en place le prélèvement SEPA`
        )}
      </button>
      {/* Display mandate acceptance text. */}
      <div className="mandate-acceptance" style={{ fontSize: 'x-small' }}>
        En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A)
        ORIGIN'AILES et Stripe, notre prestataire de services de paiement et/ou PPRO, son
        prestataire de services local, à envoyer des instructions à votre banque pour débiter votre
        compte et (B) votre banque à débiter votre compte conformément à ces instructions. Vous
        avez, entre autres, le droit de vous faire rembourser par votre banque selon les modalités
        et conditions du contrat conclu avec votre banque. La demande de remboursement doit être
        soumise dans un délai de 8 semaines à compter de la date à laquelle votre compte a été
        débité. Vos droits sont expliqués dans une déclaration disponible auprès de votre banque.
        Vous acceptez de recevoir des notifications des débits à venir dans les 2 jours précédant
        leur réalisation.
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

IbanForm.propTypes = {
  user: PropTypes.object,
  dataUser: PropTypes.object.isRequired,
  chosenPrice: PropTypes.string.isRequired,
  chosenSize: PropTypes.string.isRequired,
  subscriptionSepaMethod: PropTypes.func.isRequired,
  resubscriptionStructureMethod: PropTypes.func.isRequired,
  createCustomer: PropTypes.func.isRequired,
  getSecretSepa: PropTypes.func.isRequired,
  attachDefaultPaymentMethod: PropTypes.func.isRequired,
  createUserStructure: PropTypes.func.isRequired,
  updateUserStructureResuscribe: PropTypes.func.isRequired,
  setModalSuccess: PropTypes.func,
  setIsOpenModalCardFormPayment: PropTypes.func
};

export default connect(mapStateToProps, {
  subscriptionSepaMethod: subscriptionSepa,
  resubscriptionStructureMethod: resubscriptionStructure,
  createCustomer,
  getSecretSepa,
  attachDefaultPaymentMethod,
  createUserStructure,
  updateUserStructureResuscribe
})(IbanForm);
