import React from 'react';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalSuccessUpdateEnfant = ({ isOpen, closeModal }) => {
  return (
    <Modal
      {...modalDefaultClass}
      className="modal-content small-modal success-modal"
      id="modal-send-password"
      isOpen={isOpen}
    >
      <div className="modal-title">
        <i className="fal fa-check-circle" />
        Vos informations ont bien été mises à jour
      </div>
      <div className="modal-body">
        <div className="btn-group center">
          <button className="btn btn-primary" onClick={() => closeModal()}>
            <span>Ok</span>
          </button>
        </div>
      </div>
      <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
    </Modal>
  );
};

export default ModalSuccessUpdateEnfant;
