import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DROITS } from '../../../constants/Properties';

import { connect } from 'react-redux';
import { updateIntervenant } from '../../../store/modules/intervenants';

import Modal from 'react-modal';
import modalDefaultClass from '../../../utils/ModalDefaultClass';

import { Form, Field } from 'react-final-form';

import { map, find, get } from 'lodash';

import Select from 'react-select';

const ModalEditDroitsIntervenant = ({
  user,
  enfant,
  intervenant,
  updateIntervenant,
  roles,
  close,
  isOpen
}) => {
  const [error, setError] = useState(null);
  const required = value => (value ? undefined : 'Champ requis !');
  const optionsDroits = map(DROITS, (item, key) => {
    return { value: key, label: item };
  });

  const closeModal = () => {
    close();
    setError(null);
  };

  const updateDroits = data => {
    return updateIntervenant(intervenant, {
      droits: {
        ...intervenant.droits,
        [enfant.enfant_id]: {
          ...intervenant.droits[enfant.enfant_id],
          dossier_prise_charge: data.dossier_prise_charge,
          medias: data.medias,
          pointage_intervenant: data.pointage_intervenant,
          programmes: data.programmes,
          comptes_rendus: data.comptes_rendus
        }
      }
    })
      .then(() => {
        closeModal();
      })
      .catch(err => setError(err));
  };
  return (
    <Modal {...modalDefaultClass} id="modal-editDroits" isOpen={isOpen}>
      <header className="header">
        <div className="modal-title">Modifier les droits d'un membre de l'équipe</div>
        <div className="header-bkg">
          <img className="oiseau" src={require(`../../../../assets/images/oiseau.svg`)} />
          <img className="hexa" src={require(`../../../../assets/images/hexa-orange.svg`)} />
          <svg height="0px" width="0px">
            <defs>
              <clipPath
                id="menumask"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.00266666666,0.01666666666)"
              >
                <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </header>

      <Form
        onSubmit={updateDroits}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <Field
                name="pointage_intervenant"
                component="select"
                validate={required}
                initialValue={
                  intervenant.droits &&
                  intervenant.droits[enfant.enfant_id] &&
                  intervenant.droits[enfant.enfant_id]['pointage_intervenant']
                }
              >
                {({ input, meta }) => {
                  return (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        options={map(
                          roles &&
                            intervenant.profil &&
                            roles[intervenant.profil]['pointage_intervenant'] &&
                            roles[intervenant.profil]['pointage_intervenant'].droits,
                          droit => {
                            return { label: DROITS[droit], value: droit };
                          }
                        )}
                        onChange={option => {
                          input.onChange(option.value);
                        }}
                        value={find(optionsDroits, o => o.value === input.value)}
                      />
                      <label>Pointages</label>
                    </div>
                  );
                }}
              </Field>
              <Field
                name="programmes"
                component="select"
                validate={required}
                initialValue={intervenant.droits[enfant.enfant_id]['programmes']}
              >
                {({ input, meta }) => {
                  return (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        options={map(
                          roles &&
                            intervenant.profil &&
                            roles[intervenant.profil]['programmes'] &&
                            roles[intervenant.profil]['programmes'].droits,
                          droit => {
                            return { label: DROITS[droit], value: droit };
                          }
                        )}
                        onChange={option => {
                          input.onChange(option.value);
                        }}
                        value={find(optionsDroits, o => o.value === input.value)}
                      />
                      <label>Programmes</label>
                    </div>
                  );
                }}
              </Field>
              <Field
                name="comptes_rendus"
                component="select"
                validate={required}
                initialValue={intervenant.droits[enfant.enfant_id]['comptes_rendus']}
              >
                {({ input, meta }) => {
                  return (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        options={map(
                          roles &&
                            intervenant.profil &&
                            roles[intervenant.profil]['comptes_rendus'] &&
                            roles[intervenant.profil]['comptes_rendus'].droits,
                          droit => {
                            return { label: DROITS[droit], value: droit };
                          }
                        )}
                        onChange={option => {
                          input.onChange(option.value);
                        }}
                        value={find(optionsDroits, o => o.value === input.value)}
                      />
                      <label>Comptes-rendus</label>
                    </div>
                  );
                }}
              </Field>
              <Field
                name="dossier_prise_charge"
                component="select"
                validate={required}
                initialValue={intervenant.droits[enfant.enfant_id]['dossier_prise_charge']}
              >
                {({ input, meta }) => {
                  return (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        options={map(
                          roles &&
                            intervenant.profil &&
                            roles[intervenant.profil]['dossier_prise_charge'] &&
                            roles[intervenant.profil]['dossier_prise_charge'].droits,
                          droit => {
                            return { label: DROITS[droit], value: droit };
                          }
                        )}
                        onChange={option => {
                          input.onChange(option.value);
                        }}
                        value={find(optionsDroits, o => o.value === input.value)}
                      />
                      <label>Dossier de prise en charge</label>
                    </div>
                  );
                }}
              </Field>
              <Field
                name="medias"
                component="select"
                validate={required}
                initialValue={intervenant.droits[enfant.enfant_id]['medias']}
              >
                {({ input, meta }) => {
                  return (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        options={map(
                          roles &&
                            intervenant.profil &&
                            roles[intervenant.profil]['medias'] &&
                            roles[intervenant.profil]['medias'].droits,
                          droit => {
                            return { label: DROITS[droit], value: droit };
                          }
                        )}
                        onChange={option => {
                          input.onChange(option.value);
                        }}
                        value={find(optionsDroits, o => o.value === input.value)}
                      />
                      <label>Médias</label>
                    </div>
                  );
                }}
              </Field>
              {error && (
                <div className="error-block">
                  <span className="error-icon">
                    <i className="fas fa-exclamation" />
                  </span>
                  <div className="error-message">{error}</div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <div className="btn-group center">
                <button className="btn btn-tertiary" onClick={() => closeModal()}>
                  Annuler
                </button>
                <button type="submit" className="btn btn-primary">
                  Valider
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

ModalEditDroitsIntervenant.propTypes = {
  updateIntervenant: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  intervenant: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  enfant: get(state, 'auth.enfantSelected', null),
  user: get(state, 'auth.user', null),
  roles: get(state, 'data.entities.settings.roles_defaut.data')
});

export default connect(mapStateToProps, { updateIntervenant })(ModalEditDroitsIntervenant);
