import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useMediaQuery } from 'react-responsive';

import { isDroitUser, removeFirstCaracter0 } from '../utils/methodes';

import { connect } from 'react-redux';
import { loadSettings } from '../store/modules/settings';
import { getEnfants } from '../store/modules/enfants';
import { loadUser } from '../store/modules/auth';
import { getParents } from '../store/modules/parents';
import { getEvents } from '../store/modules/events';
import { getPointages, getPointagesIntervenant } from '../store/modules/pointages';
import { getDocumentsMedias } from '../store/modules/documentsMedias';
import { getUsersSecondaireFamille } from '../store/modules/users';

import { values, map, filter, isNull, sumBy, includes, toNumber, isEmpty } from 'lodash';
import moment from 'moment';

import { Carousel } from 'react-responsive-carousel';

import BaseLayout from './shared/BaseLayout';
import IconCardLink from '../components/shared/IconCardLink';

import ModalInfo from '../components/modals/ModalInfo';
import ModalAddUpdatePointage from '../components/modals/ModalAddUpdatePointage';

const HomePage = ({
  loadSettings,
  loadUser,
  getEnfants,
  user,
  getParents,
  enfants,
  planings,
  getEvents,
  enfantSelected,
  intervenants,
  pointages,
  getPointages,
  getDocumentsMedias,
  getPointagesIntervenant,
  getUsersSecondaireFamille
}) => {
  const [modalPointageSuccess, setModalPointageSuccess] = useState(false);

  const [slidePlanningSelected, setPlanningSlideSelected] = useState(0);
  const [slidePointageSelected, setSlidePointageSelected] = useState(0);
  const [openModalAddUpdatePointage, setOpenModalAddUpdatePointage] = useState(false);

  useEffect(() => {
    if (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') {
      getDocumentsMedias(
        user.profil,
        user.userIdPrincipal ? user.userIdPrincipal : user.user_id,
        enfantSelected && enfantSelected.enfant_id
      );
      getPointages(user.userIdPrincipal ? user.userIdPrincipal : user.user_id);

      if (user.profil === 'FAMILLE') {
        getUsersSecondaireFamille(user.userIdPrincipal ? user.userIdPrincipal : user.user_id);
      }
    } else {
      if (enfantSelected) {
        getDocumentsMedias(user.profil, user.user_id, enfantSelected);
        getPointagesIntervenant(user.user_id, enfantSelected && enfantSelected.enfant_id);
      }
    }

    loadSettings();
    loadUser(user.user_id);

    if (user.enfants && !isEmpty(user.enfants)) {
      getEnfants(user.enfants);
    }

    if (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') {
      getEvents(user.userIdPrincipal ? user.userIdPrincipal : user.user_id);
    } else {
      if (!isNull(enfantSelected)) {
        getEvents(enfantSelected.parent_id);
      }
    }
  }, [enfantSelected]);

  // useEffect(() => {
  //   if (enfantSelected) {
  //     if (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') {
  //       getPointages(user.userIdPrincipal ? user.userIdPrincipal : user.user_id);
  //     } else {
  //       getPointagesIntervenant(user.user_id, enfantSelected.enfant_id);
  //     }

  //     getEvents(enfantSelected.parent_id);
  //     const parentsIds = map(enfants, e => e.parent_id);
  //     getParents(parentsIds);
  //   }
  // }, [enfantSelected]);

  const updateCurrentSlidePlanning = index => {
    if (slidePlanningSelected !== index) {
      setPlanningSlideSelected(index);
    }
  };

  const updateCurrentSlidePointage = index => {
    if (slidePointageSelected !== index) {
      setSlidePointageSelected(index);
    }
  };
  const pointagesIntervenants =
    intervenants &&
    !isEmpty(intervenants) &&
    map(
      user && (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE')
        ? filter(
            intervenants,
            i =>
              i.droits &&
              i.droits[enfantSelected.enfant_id] &&
              i.droits[enfantSelected.enfant_id]['pointage_intervenant'] !== 'PAS_ACCES' &&
              i.isActive === true
          )
        : filter(intervenants, i => i.user_id === user.user_id),
      i => {
        return {
          intervenant: intervenants && i,
          dayPointages: sumBy(
            map(
              filter(
                pointages,
                p =>
                  moment(p.date).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY') &&
                  p.user_id === i.user_id
              ),
              p => toNumber(p.totalHeures)
            )
          ),
          weeklyPointages: sumBy(
            map(
              filter(
                pointages,
                p =>
                  moment(p.date).isBetween(moment().startOf('week'), moment().endOf('week')) &&
                  p.user_id === i.user_id
              ),
              p => toNumber(p.totalHeures)
            )
          ),
          monthPointages: sumBy(
            map(
              filter(
                pointages,
                p =>
                  moment(p.date).isBetween(moment().startOf('month'), moment().endOf('month')) &&
                  p.user_id === i.user_id
              ),
              p => toNumber(p.totalHeures)
            )
          )
        };
      }
    );

  const planningsDay = filter(
    planings,
    p => moment(p.date).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
  );

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  // console.log('pointagesIntervenants : ', pointagesIntervenants);

  return (
    <BaseLayout isHome user={user} enfant={enfantSelected} displayTopHeader classScreen="home">
      <div className="main-container padding-0">
        {planningsDay && planningsDay.length > 0 && (
          <div className="home-block">
            <div className="container">
              <div className="home-block-title">
                Planning de la journée
                <div className="home-nb-indicator">
                  {(planningsDay && planningsDay.length) || 0}
                </div>
              </div>
            </div>

            {isTabletOrMobile ? (
              <Carousel
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                showIndicators={planningsDay && planningsDay.length > 1}
                emulateTouch={true}
                useKeyboardArrows={false}
                autoPlay={false}
                infiniteLoop={planningsDay && planningsDay.length > 1}
                centerMode={true}
                centerSlidePercentage={94}
                selectedItem={slidePlanningSelected}
                onChange={updateCurrentSlidePlanning}
              >
                {map(planningsDay, p => (
                  <NavLink
                    to={`/planning/${p.event_id}`}
                    key={p.event_id}
                    className="card card-column"
                  >
                    <div className="event-schedule">
                      <div>
                        <span className="event-start">
                          {p &&
                            p.heure_debut &&
                            removeFirstCaracter0(
                              moment(p.heure_debut)
                                .format('HH:mm', { trim: false })
                                .replace(':', 'h')
                            )}
                        </span>
                        &nbsp;-&nbsp;
                        {p &&
                          p.heure_fin &&
                          removeFirstCaracter0(
                            moment(p.heure_fin)
                              .format('HH:mm', { trim: false })
                              .replace(':', 'h')
                          )}
                      </div>
                    </div>
                    <div className="card-infos">
                      <div className="card-title">{p.titre}</div>
                      <div className="card-subtitle">{p.description}</div>
                    </div>
                  </NavLink>
                ))}
              </Carousel>
            ) : (
              <div className="container">
                {map(planningsDay, p => (
                  <NavLink
                    to={`/planning/${p.event_id}`}
                    key={p.event_id}
                    className="card card-column"
                  >
                    <div className="event-schedule">
                      <div>
                        <span className="event-start">
                          {p &&
                            p.heure_debut &&
                            removeFirstCaracter0(
                              moment(p.heure_debut)
                                .format('HH:mm', { trim: false })
                                .replace(':', 'h')
                            )}
                        </span>
                        &nbsp;-&nbsp;
                        {p &&
                          p.heure_fin &&
                          removeFirstCaracter0(
                            moment(p.heure_fin)
                              .format('HH:mm', { trim: false })
                              .replace(':', 'h')
                          )}
                      </div>
                    </div>
                    <div className="card-infos">
                      <div className="card-title">{p.titre}</div>
                      <div className="card-subtitle">{p.description}</div>
                    </div>
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        )}

        {(user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') &&
          !isEmpty(pointagesIntervenants) && (
            <div className="home-block">
              <div className="container">
                <div className="home-block-title">Pointages</div>
              </div>
              {isTabletOrMobile ? (
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  showArrows={false}
                  showIndicators={true}
                  useKeyboardArrows={false}
                  autoPlay={false}
                  infiniteLoop={true}
                  centerMode={true}
                  centerSlidePercentage={94}
                  selectedItem={slidePointageSelected}
                  onChange={updateCurrentSlidePointage}
                >
                  {map(
                    user.profil === 'FAMILLE'
                      ? pointagesIntervenants
                      : filter(pointagesIntervenants, x => x.intervenant.user_id === user.user_id),
                    (p, index) => (
                      <div key={index} className="pointage-block card">
                        <div className="pointage-member-name">
                          {p.intervenant.prenom} {p.intervenant.nom}
                        </div>

                        <div className="pointage">
                          <div className="pointage-time">
                            {removeFirstCaracter0(
                              moment
                                .duration(p.dayPointages, 'hours')
                                .format('HH:mm', { trim: false })
                                .replace(':', 'h')
                            )}
                          </div>
                          <div className="pointage-label">Aujourd'hui</div>
                        </div>
                        <div className="pointage-separator" />
                        <div className="pointage">
                          <div className="pointage-time">
                            {removeFirstCaracter0(
                              moment
                                .duration(p.weeklyPointages, 'hours')
                                .format('HH:mm', { trim: false })
                                .replace(':', 'h')
                            )}
                          </div>
                          <div className="pointage-label">Cette semaine</div>
                        </div>
                        <div className="pointage-separator" />
                        <div className="pointage">
                          <div className="pointage-time">
                            {removeFirstCaracter0(
                              moment
                                .duration(p.monthPointages, 'hours')
                                .format('HH:mm', { trim: false })
                                .replace(':', 'h')
                            )}
                          </div>
                          <div className="pointage-label">Ce mois ci</div>
                        </div>
                      </div>
                    )
                  )}
                </Carousel>
              ) : (
                <div className="container">
                  <div className="grid">
                    {map(
                      user.profil === 'FAMILLE'
                        ? pointagesIntervenants
                        : filter(
                            pointagesIntervenants,
                            x => x.intervenant.user_id === user.user_id
                          ),
                      (p, index) => {
                        return (
                          <div key={index} className="col-6">
                            <div className="pointage-block card">
                              <div className="pointage-member-name">
                                {p.intervenant.prenom} {p.intervenant.nom}
                              </div>

                              <div className="pointage">
                                <div className="pointage-time">
                                  {removeFirstCaracter0(
                                    moment
                                      .duration(p.dayPointages, 'hours')
                                      .format('HH:mm', { trim: false })
                                      .replace(':', 'h')
                                  )}
                                </div>
                                <div className="pointage-label">Aujourd'hui</div>
                              </div>
                              <div className="pointage-separator" />
                              <div className="pointage">
                                <div className="pointage-time">
                                  {removeFirstCaracter0(
                                    moment
                                      .duration(p.weeklyPointages, 'hours')
                                      .format('HH:mm', { trim: false })
                                      .replace(':', 'h')
                                  )}
                                </div>
                                <div className="pointage-label">Cette semaine</div>
                              </div>
                              <div className="pointage-separator" />
                              <div className="pointage">
                                <div className="pointage-time">
                                  {removeFirstCaracter0(
                                    moment
                                      .duration(p.monthPointages, 'hours')
                                      .format('HH:mm', { trim: false })
                                      .replace(':', 'h')
                                  )}
                                </div>
                                <div className="pointage-label">Ce mois ci</div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        {includes(['MODIFICATION'], isDroitUser('pointage_intervenant', user, enfantSelected)) && (
          <div className="home-block">
            <div className="container">
              <div className="home-block-title">Pointage</div>

              {map(
                filter(pointagesIntervenants, x => x.intervenant.user_id === user.user_id),
                (p, index) => (
                  <div key={index} className="pointage-block card">
                    <div className="pointage">
                      <div className="pointage-time">
                        {removeFirstCaracter0(
                          moment
                            .duration(p.dayPointages, 'hours')
                            .format('HH:mm', { trim: false })
                            .replace(':', 'h')
                        )}
                      </div>
                      <div className="pointage-label">Aujourd'hui</div>
                    </div>
                    <div className="pointage-separator" />
                    <div className="pointage">
                      <div className="pointage-time">
                        {removeFirstCaracter0(
                          moment
                            .duration(p.weeklyPointages, 'hours')
                            .format('HH:mm', { trim: false })
                            .replace(':', 'h')
                        )}
                      </div>
                      <div className="pointage-label">Cette semaine</div>
                    </div>
                    <div className="pointage-separator" />
                    <div className="pointage">
                      <div className="pointage-time">
                        {removeFirstCaracter0(
                          moment
                            .duration(p.monthPointages, 'hours')
                            .format('HH:mm', { trim: false })
                            .replace(':', 'h')
                        )}
                      </div>
                      <div className="pointage-label">Ce mois</div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}

        <ModalInfo
          isOpen={modalPointageSuccess}
          icon="check-circle"
          title="Pointage enregistré !"
          confirmText="Ok"
          confirmMethode={() => setModalPointageSuccess(false)}
          closeModal={() => setModalPointageSuccess(false)}
        />
        {(user.profil !== 'FAMILLE' || user.profil !== 'STRUCTURE') &&
          (includes(
            ['ADMINISTRATION', 'MODIFICATION'],
            isDroitUser('comptes_rendus', user, enfantSelected)
          ) ||
            includes(
              ['ADMINISTRATION', 'MODIFICATION'],
              isDroitUser('pointage_intervenant', user, enfantSelected)
            )) && (
            <div className="home-main-shortcuts container">
              <div className="grid-sm-gutter">
                {(user.profil !== 'FAMILLE' || user.profil !== 'STRUCTURE') &&
                  includes(
                    ['ADMINISTRATION', 'MODIFICATION'],
                    isDroitUser('pointage_intervenant', user, enfantSelected)
                  ) && (
                    <div className="col">
                      <span
                        onClick={() => setOpenModalAddUpdatePointage(true)}
                        className="btn icon-card-link icon-card-link-secondary"
                      >
                        <i className={`main-icon fal fa-clock`} />
                        <span>Je pointe ma journée</span>
                        <i className={`bkg-icon fas fa-clock`} />
                      </span>
                      <ModalAddUpdatePointage
                        isOpen={openModalAddUpdatePointage}
                        closeModal={() => setOpenModalAddUpdatePointage(false)}
                        openModalSuccess={() => setModalPointageSuccess(true)}
                      />
                    </div>
                  )}

                {(user.profil !== 'FAMILLE' || user.profil !== 'STRUCTURE') &&
                  includes(
                    ['ADMINISTRATION', 'MODIFICATION'],
                    isDroitUser('comptes_rendus', user, enfantSelected)
                  ) && (
                    <div className="col">
                      <NavLink
                        to="/comptes-rendus/ajouter"
                        className="btn icon-card-link icon-card-link-secondary"
                      >
                        <i className={`main-icon fal fa-file-contract`} />
                        <span>Je rédige mon compte-rendu</span>
                        <i className={`bkg-icon fas fa-file-contract`} />
                      </NavLink>
                    </div>
                  )}
              </div>
            </div>
          )}

        <div className={`home-shortcuts container`}>
          <div className={`grid-sm-gutter-center grid-6_xs-3_md-6}`}>
            {isDroitUser('planing_agenda', user, enfantSelected) !== 'PAS_ACCES' && (
              <div className="col">
                <IconCardLink title="Planning" icon="fa-calendar-alt" link="/plannings" />
              </div>
            )}
            {(user.profil === 'FAMILLE' ||
              user.profil === 'STRUCTURE' ||
              includes(
                ['ADMINISTRATION', 'MODIFICATION'],
                isDroitUser('pointage_intervenant', user, enfantSelected)
              )) && (
              <div className="col">
                <IconCardLink title="Pointages" icon="fa-clock" link="/pointages" />
              </div>
            )}

            {isDroitUser('medias', user, enfantSelected) !== 'PAS_ACCES' && (
              <div className="col">
                <IconCardLink title="Médias" icon="fa-photo-video" link="/medias" />
              </div>
            )}
            {isDroitUser('comptes_rendus', user, enfantSelected) !== 'PAS_ACCES' && (
              <div className="col">
                <IconCardLink
                  title="Comptes-rendus"
                  icon="fa-file-contract"
                  link="/comptes-rendus"
                />
              </div>
            )}

            {isDroitUser('programmes', user, enfantSelected) !== 'PAS_ACCES' && (
              <div className="col">
                <IconCardLink
                  title="Programmes"
                  icon="fa-clipboard-list-check"
                  link="/programmes"
                />
              </div>
            )}
            {isDroitUser('dossier_prise_charge', user, enfantSelected) !== 'PAS_ACCES' && (
              <div className="col">
                <IconCardLink
                  title="Dossiers de prise en charge"
                  icon="fa-folders"
                  link="/dossiers-prise-en-charge"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

HomePage.propTypes = {
  loadSettings: PropTypes.func.isRequired,
  getEnfants: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  getParents: PropTypes.func.isRequired,
  getPointages: PropTypes.func.isRequired,
  getDocumentsMedias: PropTypes.func.isRequired,
  getPointagesIntervenant: PropTypes.func.isRequired,
  getUsersSecondaireFamille: PropTypes.func.isRequired,
  getUsersSecondaireFamille: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  enfantSelected: state.auth.enfantSelected,
  planings:
    (state.auth &&
      state.auth.enfantSelected &&
      state.data.entities.events &&
      filter(
        state.data.entities.events,
        d => d.type === 'planing_agenda' && d.enfant_id === state.auth.enfantSelected.enfant_id
      )) ||
    [],
  user: state.auth.user,
  pointages: state.data.entities.pointages,
  enfants: (state.data.entities.enfants && values(state.data.entities.enfants)) || {},
  intervenants: state.data.entities.intervenants && values(state.data.entities.intervenants)
});

export default connect(mapStateToProps, {
  loadSettings,
  loadUser,
  getEnfants,
  getParents,
  getEvents,
  getPointages,
  getDocumentsMedias,
  getPointagesIntervenant,
  getUsersSecondaireFamille
})(HomePage);
