import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const CREATE_COMPTE_RENDU_REQUEST = 'ori/CREATE_COMPTE_RENDU_REQUEST';
const CREATE_COMPTE_RENDU_SUCCESS = 'ori/CREATE_COMPTE_RENDU_SUCCESS';
const CREATE_COMPTE_RENDU_FAILURE = 'ori/CREATE_COMPTE_RENDU_FAILURE';

const GET_COMPTES_RENDUS_REQUEST = 'ori/GET_COMPTES_RENDUS_REQUEST';
const GET_COMPTES_RENDUS_SUCCESS = 'ori/GET_COMPTES_RENDUS_SUCCESS';
const GET_COMPTES_RENDUS_FAILURE = 'ori/GET_COMPTES_RENDUS_FAILURE';

const DELETE_COMPTE_RENDU_REQUEST = 'ori/DELETE_COMPTE_RENDU_REQUEST';
const DELETE_COMPTE_RENDU_SUCCESS = 'ori/DELETE_COMPTE_RENDU_SUCCESS';
const DELETE_COMPTE_RENDU_FAILURE = 'ori/DELETE_COMPTE_RENDU_FAILURE';

const UPDATE_COMPTE_RENDU_REQUEST = 'ori/UPDATE_COMPTE_RENDU_REQUEST';
const UPDATE_COMPTE_RENDU_SUCCESS = 'ori/UPDATE_COMPTE_RENDU_SUCCESS';
const UPDATE_COMPTE_RENDU_FAILURE = 'ori/UPDATE_COMPTE_RENDU_FAILURE';

export const comptesRendusActionsHandlers = {
  [GET_COMPTES_RENDUS_SUCCESS]: (state, action) =>
    flow(
      set('entities.comptesRendus', action.response.entities.comptesRendus || {}),
      set('loaded.comptesRendus', true),
      set('loading.comptesRendus', false)
    )(state),

  [CREATE_COMPTE_RENDU_SUCCESS]: (state, action) => {
    return flow(
      set('entities.comptesRendus', {
        ...state.entities.comptesRendus,
        ...action.response.entities.comptesRendus
      }),
      set('loaded.comptesRendus', true),
      set('loading.comptesRendus', false)
    )(state);
  },
  [DELETE_COMPTE_RENDU_SUCCESS]: (state, action) =>
    flow(
      set(
        'entities.comptesRendus',
        omit(state.entities.comptesRendus, action.compteRendu.compterendu_id)
      )
    )(state),

  [UPDATE_COMPTE_RENDU_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.comptesRendus.${action.compteRendu.compterendu_id}`, {
        ...action.document,
        ...action.response
      }),
      set('loaded.comptesRendus', true),
      set('loading.comptesRendus', false)
    )(state);
  }
};

// CREATE

export function createCompteRendu(data) {
  return {
    [CALL_API]: {
      types: [
        CREATE_COMPTE_RENDU_REQUEST,
        CREATE_COMPTE_RENDU_SUCCESS,
        CREATE_COMPTE_RENDU_FAILURE
      ],
      method: 'POST',
      endpoint: '/comptesRendus',
      body: data,
      schema: Schemas.COMPTE_RENDU
    }
  };
}

// READ

export function getCompteRendu(enfantId, compteRenduId) {
  return {
    enfantId,
    compteRenduId,
    [CALL_API]: {
      types: [GET_COMPTES_RENDUS_REQUEST, GET_COMPTES_RENDUS_SUCCESS, GET_COMPTES_RENDUS_FAILURE],
      method: 'GET',
      endpoint: `/comptesRendus/${enfantId}/${compteRenduId}`,
      schema: Schemas.COMPTE_RENDU_ARRAY,
      successMessage: 'Compte rendu chargé avec succès'
    }
  };
}

export function getComptesRendus(enfantId) {
  return {
    enfantId,
    [CALL_API]: {
      types: [GET_COMPTES_RENDUS_REQUEST, GET_COMPTES_RENDUS_SUCCESS, GET_COMPTES_RENDUS_FAILURE],
      method: 'GET',
      endpoint: `/comptesRendus/${enfantId}`,
      schema: Schemas.COMPTE_RENDU_ARRAY,
      successMessage: 'Comptes rendus chargés avec succès'
    }
  };
}

// UPDATE

export function updateCompteRendu(compteRendu, data) {
  return {
    compteRendu,
    [CALL_API]: {
      types: [
        UPDATE_COMPTE_RENDU_REQUEST,
        UPDATE_COMPTE_RENDU_SUCCESS,
        UPDATE_COMPTE_RENDU_FAILURE
      ],
      method: 'PUT',
      body: data,
      endpoint: `/comptesRendus/${compteRendu.compterendu_id}`,
      successMessage: 'Le compte-rendu a bien été modifié'
    }
  };
}

// DELETE

export function deleteCompteRendu(compteRendu) {
  return {
    compteRendu,
    [CALL_API]: {
      types: [
        DELETE_COMPTE_RENDU_REQUEST,
        DELETE_COMPTE_RENDU_SUCCESS,
        DELETE_COMPTE_RENDU_FAILURE
      ],
      method: 'DELETE',
      body: compteRendu,
      endpoint: `/comptesRendus`,
      successMessage: 'Le compte-rendu a bien été supprimé'
    }
  };
}
