import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { uploadFiles, removeFile } from '../../store/modules/uploads';
import { createCompteRendu, updateCompteRendu } from '../../store/modules/comptesRendus';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Select from 'react-select';
import { isEmpty, map, find, filter, includes, get } from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
import 'moment-duration-format';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import materialTheme from '../../../assets/stylesheets/materialThemeDatePicker';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ClipLoader from 'react-spinners/ClipLoader';
import BaseLayout from '../shared/BaseLayout';
import FatigueSelector from '../../components/shared/FatigueSelector';
import SwitchComponent from '../../components/shared/SwitchComponent';
import FieldUploadDropZone from '../../components/shared/FieldUploadDropZone';
import {
  COOPERATIONS_ELEVE,
  FONCTIONS_TROUBLE_COMPORTEMENT,
  TOPOGRAPHIES
} from '../../constants/Properties';
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date)
      .locale('fr')
      .format('DD MMMM YYYY');
  }
}

const AddEditCompteRenduPage = ({
  enfantSelected,
  user,
  uploadFiles,
  removeFile,
  createCompteRendu,
  updateCompteRendu,
  compteRendu
}) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigation = useHistory();
  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    setLoading(true);
    const data = values;
    data.famille_id = (user.profil === 'FAMILLE' && user.user_id) || enfantSelected.parent_id;
    data.user_id = (compteRendu && compteRendu.user_id) || user.user_id;
    data.enfant_id = (compteRendu && compteRendu.enfant_id) || enfantSelected.enfant_id;
    if (!data.troubleComportement) {
      data.troubles = [];
    }

    if (compteRendu) {
      // EN EDITION

      if (!isEmpty(data.file) && data.file !== compteRendu.file) {
        return uploadFiles({ files: data.file })
          .then(res => {
            data.file = res.response.fileName;
            data.file_type = res.response.fileType;
            return updateCompteRendu(compteRendu, data)
              .then(() => {
                setLoading(false);
                setError(null);
                navigation.push(`/compte-rendu/${compteRendu.compterendu_id}`);
              })
              .catch(err => {
                setError(err);
                setLoading(false);
              });
          })
          .catch(err => {
            setLoading(false);
            setError(err);
          });
      } else {
        return updateCompteRendu(compteRendu, data)
          .then(() => {
            setError(null);
            setLoading(false);
            navigation.push(`/compte-rendu/${compteRendu.compterendu_id}`);
          })
          .catch(err => {
            setLoading(false);
            setError(err);
          });
      }
    } else {
      // CREATION
      if (data.file) {
        return uploadFiles({ files: data.file })
          .then(res => {
            data.file = res.response.fileName;
            data.file_type = res.response.fileType;
            return createCompteRendu(data)
              .then(() => {
                setError(null);
                navigation.push('/comptes-rendus');
              })
              .catch(err => setError(err));
          })
          .catch(err => setError(err));
      } else {
        return createCompteRendu(data)
          .then(() => {
            setError(null);
            navigation.push('/comptes-rendus');
          })
          .catch(err => setError(err));
      }
    }
  };

  const optionsCooperationEleve = map(COOPERATIONS_ELEVE, (item, key) => {
    return { value: key, label: item };
  });
  const optionsFonctionsTroubles = map(FONCTIONS_TROUBLE_COMPORTEMENT, (item, key) => {
    return { value: key, label: item };
  });
  const optionsTopographies = map(TOPOGRAPHIES, (item, key) => {
    return { value: key, label: item };
  });

  return (
    <BaseLayout
      isPage
      noFooter
      classScreen="compteRendu page-preview"
      headerPageTitle={(compteRendu && "J'édite mon compte-rendu") || 'Je rédige mon compte-rendu'}
    >
      <Form
        onSubmit={handleSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={{
          date: (compteRendu && compteRendu.date) || new Date(),
          lieu: (compteRendu && compteRendu.lieu) || '',
          fatigue_niveau: (compteRendu && compteRendu.fatigue_niveau) || null,
          fatigue_observations: (compteRendu && compteRendu.fatigue_observations) || '',
          cooperation_eleve: (compteRendu && compteRendu.cooperation_eleve) || null,
          libelle_coop_eleve: (compteRendu && compteRendu.libelle_coop_eleve) || null,
          troubleComportement: (compteRendu && compteRendu.troubleComportement) || false,
          commentaires: (compteRendu && compteRendu.commentaires) || '',
          file: (compteRendu && compteRendu.file) || null,
          programmes: (compteRendu && compteRendu.programmes) || [
            {
              nomProgramme: null,
              commentaire: ''
            }
          ],
          troubles: (compteRendu &&
            map(compteRendu.troubles, trouble => {
              return {
                fonction: trouble.fonction,
                topographies: map(trouble.topographies, t => t),
                libelle_topographies: trouble.libelle_topographies && trouble.libelle_topographies,
                libelle_fonction: trouble?.libelle_fonction && trouble.libelle_fonction
              };
            })) || [
            {
              fonction: null,
              topographies: [],
              libelle_topographies: null,
              libelle_fonction: null
            }
          ]
        }}
        render={({
          handleSubmit,
          form,
          form: {
            mutators: { push, pop }
          },
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="grid-noBottom">
                <div className="col-6">
                  <Field name="date" component="input" className="field" validate={required}>
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={LocalizedUtils}
                          locale={'fr'}
                        >
                          <ThemeProvider theme={materialTheme}>
                            <KeyboardDateTimePicker
                              className="datepicker-mui datetimepicker-mui"
                              invalidDateMessage={null}
                              autoOk
                              value={input.value}
                              mask="__/__/____ __:__"
                              okLabel={null}
                              clearLabel={null}
                              clearable={false}
                              cancelLabel={null}
                              ampm={false}
                              onChange={date => {
                                input.onChange(date);
                              }}
                              maxDate={new Date()}
                              format="DD/MM/YYYY HH:mm"
                              InputAdornmentProps={{ position: 'start' }}
                              inputVariant="outlined"
                              InputProps={{ readOnly: true }}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        <label>Date *</label>
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <Field name="lieu" className="field">
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <input {...input} />
                    <label>Lieu</label>
                  </div>
                )}
              </Field>
              <div className="separator" />
              <Field name="fatigue_niveau" className="field" validate={required}>
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}

                    <FatigueSelector
                      niveau={input.value}
                      onChangeNiveau={value => input.onChange(value)}
                    />
                    <label>Humeur *</label>
                  </div>
                )}
              </Field>
              <Field name="fatigue_observations" component="input" className="field">
                {({ input, meta }) => (
                  <div className="field">
                    <textarea
                      placeholder="Ajouter vos observations sur l'humeur de l’élève"
                      {...input}
                    />
                  </div>
                )}
              </Field>
              <div className="separator" />
              <Field name="cooperation_eleve" component="select" validate={required}>
                {({ input, meta }) => {
                  return (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Sélectionner un niveau de coopération"
                        options={optionsCooperationEleve}
                        onChange={option => {
                          input.onChange(option.value);
                          if (option.value !== 'AUTRE') {
                            form.change('libelle_coop_eleve', null);
                          }
                        }}
                        value={find(optionsCooperationEleve, o => o.value === input.value)}
                      />
                      <label>Coopération de l’élève *</label>
                    </div>
                  );
                }}
              </Field>
              {values.cooperation_eleve === 'AUTRE' ? (
                <Field
                  name="libelle_coop_eleve"
                  validate={required}
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" placeholder="Précisez *" />
                    </div>
                  )}
                </Field>
              ) : null}
              <div className="separator" />
              <FieldArray name="programmes">
                {({ fields }) =>
                  fields.map((nom, index) => {
                    return (
                      <div className="repeat-block" key={index}>
                        {index !== 0 && (
                          <div
                            onClick={() => fields.remove(index)}
                            className="btn btn-primary btn-close"
                          >
                            <i className="fas fa-trash-alt" />
                          </div>
                        )}

                        <Field
                          validate={required}
                          name={`${nom}.nomProgramme`}
                          component="input"
                          className="field"
                        >
                          {({ input, meta }) => (
                            <div className="field">
                              {meta.error && meta.touched && (
                                <div className="field-error">{meta.error}</div>
                              )}
                              <label>Programme travaillé *</label>
                              <input {...input} />
                            </div>
                          )}
                        </Field>

                        <Field name={`${nom}.commentaire`} component="input" className="field">
                          {({ input, meta }) => (
                            <div className="field">
                              {meta.error && meta.touched && (
                                <div className="field-error">{meta.error}</div>
                              )}
                              <label>Commentaire</label>
                              <textarea {...input} />
                            </div>
                          )}
                        </Field>
                      </div>
                    );
                  })
                }
              </FieldArray>
              <div className="content alignCenter">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    push('programmes', {
                      nomProgramme: '',
                      commentaire: ''
                    });
                  }}
                >
                  Ajouter un programme
                </button>
              </div>
              <div className="separator" />
              <Field name="troubleComportement" component="input" className="field">
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <SwitchComponent
                      onChange={value => {
                        input.onChange(value);
                      }}
                      label="Troubles du comportement / Comportements défi durant la séance"
                      customStyle="switch-wrapper-left"
                      value={input.value}
                    />
                  </div>
                )}
              </Field>
              {values && values.troubleComportement === true && (
                <Fragment>
                  <FieldArray name="troubles">
                    {({ fields }) =>
                      fields.map((nom, index) => {
                        return (
                          <div key={index} className="repeat-block">
                            {index !== 0 && (
                              <div
                                onClick={() => fields.remove(index)}
                                className="btn btn-primary btn-close"
                              >
                                <i className="fas fa-trash-alt" />
                              </div>
                            )}
                            <Field name={`${nom}.fonction`} component="select" validate={required}>
                              {({ input, meta }) => {
                                return (
                                  <div className="field">
                                    {meta.error && meta.touched && (
                                      <div className="field-error">{meta.error}</div>
                                    )}
                                    <Select
                                      className="react-select"
                                      classNamePrefix="react-select"
                                      placeholder="Sélectionner une fonction"
                                      options={optionsFonctionsTroubles}
                                      onChange={option => {
                                        input.onChange(option.value);
                                        if (option.value !== 'AUTRE') {
                                          form.change(`${nom}.libelle_fonction`, null);
                                        }
                                      }}
                                      value={find(
                                        optionsFonctionsTroubles,
                                        o => o.value === input.value
                                      )}
                                    />
                                    <label>Fonction</label>
                                  </div>
                                );
                              }}
                            </Field>
                            {// (compteRendu && get(compteRendu, nom).libelle_topographies) ||
                            values &&
                            values.troubles &&
                            values.troubles[index] &&
                            values.troubles[index].fonction === 'AUTRE' ? (
                              <Field
                                name={`${nom}.libelle_fonction`}
                                validate={required}
                                component="input"
                                className="field"
                              >
                                {({ input, meta }) => {
                                  {
                                    return (
                                      <div className="field">
                                        {meta.error && meta.touched && (
                                          <div className="field-error">{meta.error}</div>
                                        )}
                                        <input {...input} type="text" placeholder="Précisez *" />
                                      </div>
                                    );
                                  }
                                }}
                              </Field>
                            ) : null}
                            <Field
                              validate={required}
                              name={`${nom}.topographies`}
                              component="input"
                              className="field"
                            >
                              {({ input, meta }) => {
                                return (
                                  <div className="field">
                                    {meta.error && meta.touched && (
                                      <div className="field-error">{meta.error}</div>
                                    )}
                                    <Select
                                      isMulti
                                      className="react-select"
                                      classNamePrefix="react-select"
                                      onChange={valeurs => {
                                        input.onChange(map(valeurs, v => v.value));
                                        if (
                                          !includes(
                                            map(valeurs, v => v.value),
                                            'AUTRE'
                                          )
                                        ) {
                                          form.change(`${nom}.libelle_topographies`, null);
                                        }
                                      }}
                                      placeholder="Selectionner"
                                      options={optionsTopographies}
                                      defaultValue={
                                        (compteRendu &&
                                          map(
                                            filter(optionsTopographies, u =>
                                              includes(input.value, u.value)
                                            ),
                                            u => {
                                              return {
                                                value: u.value,
                                                label: TOPOGRAPHIES[u.value]
                                              };
                                            }
                                          )) ||
                                        []
                                      }
                                    />
                                    <label>Topographies</label>
                                  </div>
                                );
                              }}
                            </Field>
                            {// (compteRendu && get(compteRendu, nom).libelle_topographies) ||
                            values &&
                            values.troubles &&
                            values.troubles[index] &&
                            includes(values.troubles[index].topographies, 'AUTRE') ? (
                              <Field
                                name={`${nom}.libelle_topographies`}
                                validate={required}
                                component="input"
                                className="field"
                              >
                                {({ input, meta }) => {
                                  {
                                    return (
                                      <div className="field">
                                        {meta.error && meta.touched && (
                                          <div className="field-error">{meta.error}</div>
                                        )}
                                        <input {...input} type="text" placeholder="Précisez *" />
                                      </div>
                                    );
                                  }
                                }}
                              </Field>
                            ) : null}
                          </div>
                        );
                      })
                    }
                  </FieldArray>
                  <div className="content alignCenter">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        push('troubles', {
                          fonction: '',
                          topographies: ''
                        });
                      }}
                    >
                      Ajouter un trouble
                    </button>
                  </div>

                  <Field name="commentaires" component="input" className="field">
                    {({ input, meta }) => (
                      <div className="field">
                        <textarea {...input} />
                        <label>Commentaires</label>
                      </div>
                    )}
                  </Field>
                </Fragment>
              )}
              <div className="separator" />
              <Field name="file">
                {({ input, meta }) => {
                  return (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <label>Photo ou vidéo</label>
                      <FieldUploadDropZone
                        message1="Glisser et déposer votre fichier ici ou"
                        message2="Choisir un fichier"
                        rmFile={file => removeFile(file)}
                        acceptedFiles={['.png', '.jpg', '.jpeg', '.mov', '.wmv', '.avi', '.mp4']}
                        {...input}
                      />
                    </div>
                  );
                }}
              </Field>
              {error && (
                <div className="error-block">
                  <span className="error-icon">
                    <i className="fas fa-exclamation" />
                  </span>
                  <div className="error-message">{error}</div>
                </div>
              )}
            </div>

            <div className="btn-group center bottom-sticky">
              <div className="btn btn-tertiary" onClick={() => navigation.goBack()}>
                Annuler
              </div>

              <button type="submit" className="btn btn-primary">
                <span>
                  {loading && <ClipLoader size={15} color={'white'} loading={loading} />}
                  Enregistrer
                </span>
              </button>
            </div>
          </form>
        )}
      />
    </BaseLayout>
  );
};

AddEditCompteRenduPage.propTypes = {
  user: PropTypes.object.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  updateCompteRendu: PropTypes.func.isRequired,
  createCompteRendu: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { id }
    }
  } = props;
  const compteRendu =
    (id && state.data.entities.comptesRendus && state.data.entities.comptesRendus[id]) || null;
  return {
    user: state.auth.user,
    compteRendu,
    enfantSelected: state.auth.enfantSelected
  };
};

export default connect(mapStateToProps, {
  uploadFiles,
  removeFile,
  createCompteRendu,
  updateCompteRendu
})(AddEditCompteRenduPage);
