import { createMuiTheme } from '@material-ui/core';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0c70be'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black'
      }
    },
    MuiPickersClock: {
      pin: { backgroundColor: '#0c70be' }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#0c70be'
      },
      thumb: {
        borderColor: '#0c70be'
      },
      noPoint: {
        backgroundColor: '#0c70be'
      }
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#cbcbcb',
          borderRadius: 8
        },
        '&:hover $notchedOutline': {
          borderColor: '#0c70be'
        },
        '&$focused $notchedOutline': {
          borderColor: '#0c70be',
          borderWidth: 1
        },
        '&&& $input': {
          padding: '10px 0',
          fontSize: '15px'
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: '#0c70be'
      },
      daySelected: {
        backgroundColor: '#0c70be'
      },
      dayDisabled: {
        color: '#0c70be'
      },
      current: {
        color: '#0c70be'
      }
    },
    MuiPickersYear: {
      year: {
        color: 'red'
      },
      yearSelected: {
        color: '#0c70be'
      }
    },
    MuiButton: {
      textPrimary: {
        color: 'black'
      }
    }
  }
});

export default materialTheme;
