import React, { useEffect, useState, Fragment } from 'react';

import { API_URL } from '../../constants/Config';

import { useDropzone } from 'react-dropzone';

import { isEmpty, map, includes } from 'lodash';
import AvatarMask from '../../utils/AvatarMask';
import AvatarOutline from '../../utils/AvatarOutline';

const FieldUploadDropZone = props => {
  const [files, setFiles] = useState([]);
  const [fileInit, setFileInit] = useState(props.initFile && props.initFile);

  function fileSizeValidator(file) {
    if (file.size > 500000000) {
      return {
        code: 'file-too-large',
        message: `Votre fichier ${file.path} ne doit pas dépasser la taille de 500Mo`
      };
    }
    return null;
  }

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: props.acceptedFiles,
    validator: fileSizeValidator,
    multiple: false,
    noDrag: false,
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          fileType: file.type,
          preview: URL.createObjectURL(file)
        })
      );

      setFiles(files);
      if (props.onChange) {
        props.onChange(files);
      }
    }
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) =>
    errors.map(e => (
      <div key={e.code} className="field-error">
        {e.message}
      </div>
    ))
  );

  const removeFile = file => () => {
    props.onChange(null);
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const removeFileExist = file => {
    props.onChange(null);
    props.rmFile(file);
    setFileInit(null);
    setFiles(null);
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files && files.length > 0 && files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Fragment>
      {isEmpty(files) && !fileInit ? (
        <div {...getRootProps({ className: 'dropzone-container' })}>
          <div className={`dropzone ${props.type === 'img' && 'dropzone-avatar'}`}>
            <input {...getInputProps()} />
            {props.type !== 'img' ? (
              <Fragment>
                <span>{props.message1}</span>
                <span className="btn btn-primary">{props.message2}</span>
              </Fragment>
            ) : (
              <Fragment>
                <div className="fake-btn">
                  <i className="fal fa-camera" />
                </div>
                <i className="fas fa-user" />
              </Fragment>
            )}
            {fileRejectionItems}
          </div>
          {props.type === 'img' && (
            <Fragment>
              <AvatarMask nameFile="FieldUploadDropZone" />
              <AvatarOutline nameFile="FieldUploadDropZone" />
            </Fragment>
          )}
        </div>
      ) : fileInit ? (
        <div className={`uploaded-file-block ${props.type === 'img' && 'uploaded-file-block-img'}`}>
          <div className="uploaded-file">
            {props.type === 'application/pdf' && (
              <Fragment>
                <i className="fas fa-file-pdf" />
                <span>{fileInit}</span>
              </Fragment>
            )}
            {props.type === 'img' && (
              <Fragment>
                <div className="avatar-mask-big">
                  <img src={`${API_URL}/uploads/${fileInit}`} />
                </div>
                <AvatarMask nameFile="FieldUploadDropZone" />
                <AvatarOutline nameFile="FieldUploadDropZone" />
              </Fragment>
            )}
            {props.type === 'video' && (
              <video controls width="100%">
                <source src={`${API_URL}/uploads/${fileInit}`} type="video/mp4" />
              </video>
            )}
          </div>
          <button type="button" className="btn" onClick={() => removeFileExist(fileInit)}>
            <i className={`${props.type != 'pdf' ? ' far fa-times' : ' fas fa-trash-alt'}`} />
            <span style={{ marginLeft: '5px' }}>{fileInit.substring(16)}</span>
          </button>
        </div>
      ) : (
        <aside>
          {map(files, file => (
            <div
              className={`uploaded-file-block ${props.type === 'img' && 'uploaded-file-block-img'}`}
              key={file.name}
            >
              <div className={`uploaded-file${file.type != 'application/pdf' ? ' img' : ''}`}>
                {file.type === 'application/pdf' && (
                  <Fragment>
                    <i className="fas fa-file-pdf" />
                    <span>{file.path}</span>
                  </Fragment>
                )}
                {includes(
                  [
                    'image/gif',
                    'image/jpeg',
                    'image/jpg',
                    'image/png',
                    'image/heic',
                    'image/heic-sequence',
                    'image/heif-sequence',
                    'image/heif'
                  ],
                  file.type
                ) && (
                  <Fragment>
                    {props.type === 'img' ? (
                      <Fragment>
                        <div className="avatar-mask-big" style={{ zIndex: 1000 }}>
                          <img src={file.preview} alt="preview" />
                        </div>
                        <AvatarMask nameFile="FieldUploadDropZone" zIndex={{ zIndex: 2000 }} />
                        <AvatarOutline nameFile="FieldUploadDropZone" />
                      </Fragment>
                    ) : (
                      <img src={file.preview} alt="preview" />
                    )}
                  </Fragment>
                )}
                {includes(['video/mp4', 'video/quicktime'], file.type) && (
                  <video controls width="100%">
                    <source src={file.preview} type="video/mp4" />
                  </video>
                )}
              </div>
              <button
                type="button"
                className="btn"
                onClick={removeFile(file)}
                style={{ zIndex: 3000 }}
              >
                <i
                  className={`${
                    file.type != 'application/pdf' ? ' far fa-times' : ' fas fa-trash-alt'
                  }`}
                />
              </button>
            </div>
          ))}
        </aside>
      )}
    </Fragment>
  );
};

export default FieldUploadDropZone;
