import React from 'react';
import PropTypes from 'prop-types';

const FilterSearch = ({ updateSearchFilter, searchFilter, isCancel }) => {
  return (
    <div className="field search">
      <input
        onChange={e => updateSearchFilter(e.target.value)}
        placeholder="Rechercher"
        value={searchFilter || ''}
      />
      {isCancel && searchFilter && searchFilter !== '' && (
        <span className="btn-erase" onClick={() => updateSearchFilter('')}>
          <i className="fa-solid fa-xmark" />
        </span>
      )}
    </div>
  );
};

FilterSearch.propTypes = {
  updateSearchFilter: PropTypes.func.isRequired
};

export default FilterSearch;
