import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { uploadFiles, removeFile } from '../../../store/modules/uploads';
import { createDocumentMedia, updateDocumentMedia } from '../../../store/modules/documentsMedias';

import Modal from 'react-modal';
import modalDefaultClass from '../../../utils/ModalDefaultClass';

import { Form, Field } from 'react-final-form';

import FieldUploadDropZone from '../../shared/FieldUploadDropZone';

const ModalAddUpdateProgramme = ({
  isEdit,
  enfantSelected,
  programme,
  user,
  uploadFiles,
  removeFile,
  createDocumentMedia,
  updateDocumentMedia
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
    setError(null);
  };
  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = data => {
    if (isEdit) {
      if (data.fileName !== programme.file) {
        return uploadFiles({ files: data.fileName })
          .then(res => {
            return updateDocumentMedia(programme, {
              famille_id: enfantSelected.parent_id,
              enfant_id: enfantSelected.enfant_id,
              user_id: user.user_id,
              file: res.response.fileName,
              type: 'programme',
              file_type: 'pdf',
              titre: data.titre
            })
              .then(() => setIsOpen(false))
              .catch(err => setError(err));
          })
          .catch(err => setError(err));
      } else {
        return updateDocumentMedia(programme, {
          famille_id: enfantSelected.parent_id,
          enfant_id: enfantSelected.enfant_id,
          user_id: user.user_id,
          file: data.file,
          type: 'programme',
          file_type: 'pdf',
          titre: data.titre
        })
          .then(() => setIsOpen(false))
          .catch(err => setError(err));
      }
    } else {
      return uploadFiles({ files: data.fileName })
        .then(res => {
          return createDocumentMedia({
            famille_id: enfantSelected.parent_id,
            enfant_id: enfantSelected.enfant_id,
            user_id: user.user_id,
            file: res.response.fileName,
            type: 'programme',
            file_type: 'pdf',
            titre: data.titre
          })
            .then(() => setIsOpen(false))
            .catch(err => setError(err));
        })
        .catch(err => setError(err));
    }
  };

  return (
    <Fragment>
      {isEdit ? (
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          className="header-btn"
        >
          <i className="fas fa-pencil" />
        </div>
      ) : (
        <div className="bottom-sticky bottom-btn-add">
          <div onClick={() => setIsOpen(true)} className="btn-icon">
            <i className="fas fa-plus" />
          </div>
        </div>
      )}

      <Modal {...modalDefaultClass} id="modal-confirm" isOpen={isOpen}>
        <header className="header">
          <div className="modal-title">
            {(isEdit && 'Modifier un programme') || 'Ajouter un programme'}
          </div>
          <div className="header-bkg">
            <img className="oiseau" src={require(`../../../../assets/images/oiseau.svg`)} />
            <img className="hexa" src={require(`../../../../assets/images/hexa-orange.svg`)} />
            <svg height="0px" width="0px">
              <defs>
                <clipPath
                  id="menumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01666666666)"
                >
                  <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </header>

        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <Field
                  name="titre"
                  validate={required}
                  component="input"
                  className="field"
                  initialValue={(isEdit && programme && programme.titre) || ''}
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>Nom du programme *</label>
                    </div>
                  )}
                </Field>

                <Field
                  name="fileName"
                  validate={required}
                  initialValue={(isEdit && programme && programme.file) || null}
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <FieldUploadDropZone
                          message1="Glisser et déposer votre fichier PDF ici ou"
                          message2="Choisir un fichier PDF"
                          type="pdf"
                          rmFile={file => removeFile(file)}
                          acceptedFiles="application/pdf"
                          initFile={(isEdit && programme && programme.file) || null}
                          {...input}
                        />
                        <label>Pièce jointe *</label>
                      </div>
                    );
                  }}
                </Field>
                {error && (
                  <div className="error-block">
                    <span className="error-icon">
                      <i className="fas fa-exclamation" />
                    </span>
                    <div className="error-message">{error}</div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <div className="btn-group center">
                  <button className="btn btn-tertiary" onClick={() => closeModal()}>
                    Annuler
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Enregistrer
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </Modal>
    </Fragment>
  );
};

ModalAddUpdateProgramme.propTypes = {
  user: PropTypes.object.isRequired,
  enfantSelected: PropTypes.object.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  createDocumentMedia: PropTypes.func.isRequired,
  updateDocumentMedia: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  user: state.auth.user,
  enfantSelected: state.auth.enfantSelected
});

export default connect(mapStateToProps, {
  uploadFiles,
  createDocumentMedia,
  updateDocumentMedia,
  removeFile
})(ModalAddUpdateProgramme);
