import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { updateEnfant } from '../../../store/modules/enfants';
import { changeEnfant } from '../../../store/modules/auth';
import { uploadFiles, removeFile } from '../../../store/modules/uploads';

import { map, findIndex, mapValues, forEach, omit } from 'lodash';

import { Form, Field } from 'react-final-form';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
import MomentUtils from '@date-io/moment';

import materialTheme from '../../../../assets/stylesheets/materialThemeDatePicker';
import { ThemeProvider } from '@material-ui/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import ClipLoader from 'react-spinners/ClipLoader';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date)
      .locale('fr')
      .format('DD MMMM YYYY');
  }
}

import SwitchComponent from '../../shared/SwitchComponent';
import FieldUploadDropZone from '../../shared/FieldUploadDropZone';
import ModalSuccessUpdateEnfant from '../../modals/ModalSuccessUpdateEnfant';

const FormEnfantProfil = ({
  user,
  enfant,
  changeEnfant,
  enfantSelected,
  sensCategories,
  updateEnfant,
  uploadFiles,
  removeFile,
  setScrollToTop
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const initialSens = mapValues(sensCategories, group => {
    let objectSens = {};

    forEach(group.sens, sens => {
      objectSens[sens.categorieValue] = {
        reponse: false,
        details: ''
      };
    });

    return objectSens;
  });

  const [sensorialite, setSensorialite] = useState((enfant && enfant.profil.sens) || initialSens);

  const [selectedDate, setSelectedDate] = useState(
    (enfant && enfant.profil && enfant.profil.date_naissance) || null
  );

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = data => {
    setLoading(true);
    const fieldsSens = omit(data, [
      'photo',
      'nom',
      'prenom',
      'date_naissance',
      'scolarise',
      'lieuPrincipalHabitat',
      'centresInteret',
      'autre_particularite',
      'urgenceNom',
      'urgenceTel'
    ]);

    const sensData = initialSens;

    forEach(fieldsSens, (value, f) => {
      const field = f.split('-');
      const group = field[0];
      const sens = field[1];

      const fieldReponse = fieldsSens[`${group}-${sens}-reponse`];
      const fieldDetails = fieldsSens[`${group}-${sens}-details`];

      sensData[group][sens] = {
        reponse: fieldReponse,
        details: fieldDetails || ''
      };
    });

    if (enfant && enfant.profil && data.photo && data.photo !== enfant.profil.photo) {
      return uploadFiles({ files: data.photo })
        .then(res => {
          const dataValues = {
            photo: res.response.fileName,
            nom: data.nom,
            prenom: data.prenom,
            date_naissance: data.date_naissance,
            scolarise: data.scolarise,
            lieuPrincipalHabitat: data.lieuPrincipalHabitat,
            centresInteret: data.centresInteret,
            autre_particularite: data.autre_particularite,
            sens: sensData,
            urgenceNom: data.urgenceNom,
            urgenceTel: data.urgenceTel
          };

          return updateEnfant(enfant, {
            profil: dataValues
          })
            .then(() => {
              if (enfantSelected && enfantSelected.enfant_id === enfant.enfant_id) {
                enfantSelected.profil = dataValues;
                changeEnfant(enfantSelected);
              }
            })

            .then(res => {
              setLoading(false);
              setOpenModalSuccess(true);
            })
            .catch(err => {
              setError(err);
              setLoading(false);
            });
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
    } else {
      const dataValues = {
        photo: data.photo,
        nom: data.nom,
        prenom: data.prenom,
        date_naissance: data.date_naissance,
        scolarise: data.scolarise,
        lieuPrincipalHabitat: data.lieuPrincipalHabitat,
        centresInteret: data.centresInteret,
        autre_particularite: data.autre_particularite,
        sens: sensData,
        urgenceNom: data.urgenceNom,
        urgenceTel: data.urgenceTel
      };

      return updateEnfant(enfant, {
        profil: dataValues
      })
        .then(() => {
          if (enfantSelected && enfantSelected.enfant_id === enfant.enfant_id) {
            enfantSelected.profil = dataValues;
            changeEnfant(enfantSelected);
          }
        })

        .then(res => {
          setLoading(false);
          setOpenModalSuccess(true);
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="photo"
            initialValue={(enfant && enfant.profil && enfant.profil.photo) || null}
          >
            {({ input, meta }) => {
              return (
                <div className="field alignCenter">
                  {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                  <FieldUploadDropZone
                    message1="Glisser et déposer votre fichier ici ou"
                    message2="Choisir un fichier"
                    rmFile={file => removeFile(file)}
                    type="img"
                    initFile={(enfant && enfant.profil && enfant.profil.photo) || null}
                    acceptedFiles="image/*"
                    {...input}
                  />
                </div>
              );
            }}
          </Field>

          <Field
            name="prenom"
            validate={required}
            initialValue={(enfant && enfant.profil.prenom) || ''}
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <input {...input} type="text" />
                <label>Prénom *</label>
              </div>
            )}
          </Field>
          <Field
            validate={required}
            initialValue={(enfant && enfant.profil.nom) || ''}
            name="nom"
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <input {...input} type="text" />
                <label>Nom *</label>
              </div>
            )}
          </Field>

          <Field
            name="date_naissance"
            component="input"
            className="field"
            validate={required}
            initialValue={(enfant && enfant.profil.date_naissance) || null}
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils} locale={'fr'}>
                  <ThemeProvider theme={materialTheme}>
                    <KeyboardDatePicker
                      className="datepicker-mui"
                      invalidDateMessage={null}
                      autoOk
                      value={selectedDate}
                      placeholder="jj/mm/aaaa"
                      okLabel={null}
                      clearLabel={null}
                      clearable={false}
                      cancelLabel={null}
                      onChange={date => {
                        setSelectedDate(date);
                        input.onChange(date);
                      }}
                      format="DD/MM/YYYY"
                      InputAdornmentProps={{ position: 'start' }}
                      inputVariant="outlined"
                      maxDate={new Date()}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <label>Date de naissance *</label>
              </div>
            )}
          </Field>
          <Field
            name="scolarise"
            initialValue={(enfant && enfant.profil.scolarise) || ''}
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field inline">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <SwitchComponent
                  onChange={value => {
                    input.onChange(value);
                  }}
                  label="Scolarisé ?"
                  value={input.value}
                />
              </div>
            )}
          </Field>
          <Field
            name="lieuPrincipalHabitat"
            validate={required}
            initialValue={(enfant && enfant.profil.lieuPrincipalHabitat) || ''}
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <textarea {...input} />
                <label>Lieu principal d'habitation *</label>
              </div>
            )}
          </Field>
          <Field
            name="centresInteret"
            validate={required}
            initialValue={(enfant && enfant.profil.centresInteret) || ''}
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <textarea {...input} />
                <label>Centres d'intêrêt *</label>
              </div>
            )}
          </Field>
          <Field
            name="autre_particularite"
            initialValue={(enfant && enfant.profil.autre_particularite) || ''}
            component="input"
            className="field"
          >
            {({ input, meta }) => (
              <div className="field">
                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                <textarea
                  placeholder="Ajouter ici toute information utile pour la prise en charge"
                  {...input}
                />
                <label>Autres particularités</label>
              </div>
            )}
          </Field>
          <div className="field">
            {map(sensorialite, (senses, group) => (
              <div key={group}>
                <h3 className="sticky-content-title">{sensCategories[group].label}</h3>

                {map(senses, (data, sens) => {
                  const indexSens = findIndex(
                    sensCategories[group].sens,
                    s => s.categorieValue === sens
                  );

                  const label = sensCategories[group].sens[indexSens].label;

                  return (
                    <Fragment key={`${group}-${sens}`}>
                      <Field
                        name={`${group}-${sens}-reponse`}
                        initialValue={data.reponse}
                        component="input"
                      >
                        {({ input, meta }) => (
                          <div className="field inline">
                            <SwitchComponent
                              onChange={value => {
                                input.onChange(value);
                              }}
                              key={sens}
                              label={label}
                              value={input.value}
                            />
                          </div>
                        )}
                      </Field>

                      {values[`${group}-${sens}-reponse`] === true && (
                        <Field
                          name={`${group}-${sens}-details`}
                          validate={required}
                          initialValue={data.details}
                          component="input"
                          className="field"
                        >
                          {({ input, meta }) => (
                            <div className="field">
                              {meta.error && meta.touched && (
                                <div className="field-error">{meta.error}</div>
                              )}
                              <textarea {...input} />
                              <label>Détails *</label>
                            </div>
                          )}
                        </Field>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            ))}
          </div>
          <div className="highlight-block">
            <div className="highlight-block-title">
              Personne à contacter
              <br />
              en cas d'urgence
            </div>
            <div className="highlight-block-separator"></div>
            <Field
              name="urgenceNom"
              validate={required}
              initialValue={enfant && enfant.profil && enfant.profil.urgenceNom}
              component="input"
              className="field"
            >
              {({ input, meta }) => (
                <div className="field">
                  {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                  <input {...input} type="text" />
                  <label>Nom *</label>
                </div>
              )}
            </Field>
            <Field
              name="urgenceTel"
              validate={required}
              initialValue={enfant && enfant.profil && enfant.profil.urgenceTel}
              component="input"
              className="field"
            >
              {({ input, meta }) => (
                <div className="field">
                  {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                  <input {...input} type="phone" />
                  <label>Téléphone *</label>
                </div>
              )}
            </Field>
            <div className="highlight-block-infos">
              Ce numéro pourra être utilisé par les membres de l'équipe en cas d'urgence
            </div>
            <div className="highlight-block-icon">
              <i className="fas fa-info-circle" />
            </div>
          </div>
          <ModalSuccessUpdateEnfant
            isOpen={openModalSuccess}
            closeModal={() => {
              setOpenModalSuccess(false);
              setScrollToTop();
            }}
          />
          {error && <div className="error-block">{error}</div>}
          <div className="btn-group center">
            <button type="submit" className="btn btn-primary" disabled={loading}>
              <span>
                {(loading && <ClipLoader size={15} color={'white'} loading={loading} />) ||
                  'Enregistrer'}
              </span>
            </button>
          </div>
        </form>
      )}
    />
  );
};

FormEnfantProfil.propTypes = {
  user: PropTypes.object.isRequired,
  enfant: PropTypes.object.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  changeEnfant: PropTypes.func.isRequired,
  updateEnfant: PropTypes.func.isRequired,
  sensCategories: PropTypes.object.isRequired,
  setScrollToTop: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  enfantSelected: state.auth.enfantSelected,
  sensCategories:
    state.data.entities.settings && state.data.entities.settings['sens_categories'].data
});

export default connect(mapStateToProps, {
  changeEnfant,
  uploadFiles,
  removeFile,
  updateEnfant
})(FormEnfantProfil);
