import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { getProfilImgMin } from '../utils/methodes';

import { connect } from 'react-redux';

import moment from 'moment';
import { isEmpty, map, find } from 'lodash';

import Accordion from './shared/Accordion';
import AvatarOutline from '../utils/AvatarOutline';
import AvatarMask from '../utils/AvatarMask';

const EnfantProfil = ({ enfant, settings }) => {
  const profil = enfant.profil;
  const age = moment(new Date()).diff(moment(profil.date_naissance), 'years');
  const dateNaissance = moment(profil.date_naissance).format('DD/MM/YYYY');
  const sensSettings = settings.sens_categories.data;

  return (
    <Fragment>
      <div className="member">
        {!isEmpty(profil.photo) && (
          <div className="member-avatar">
            <div className="avatar-mask">
              <img src={getProfilImgMin(enfant.profil.photo)} alt="photo" />
            </div>
            <AvatarMask nameFile="EnfantProfil" />
            <AvatarOutline nameFile="EnfantProfil" />
          </div>
        )}
        <div className="member-infos">
          <div className="member-name">{`${profil && profil.prenom ? profil.prenom : ''}  ${
            profil && profil.nom ? profil.nom : ''
          }`}</div>
          <div className="member-profil">{`${age} ans (${dateNaissance})`}</div>
          <div className="member-details">
            <i className="fas fa-graduation-cap" />
            <span>{profil.scolarise ? 'Scolarisé' : 'Non scolarisé'}</span>
          </div>
        </div>
      </div>

      {profil && profil.lieuPrincipalHabitat && (
        <div className="info-block">
          <div className="separator" />
          <div className="info-value">
            <i className="fas fa-map-marker-alt" />
            <span>{profil && profil.lieuPrincipalHabitat}</span>
          </div>
        </div>
      )}

      {profil && profil.centresInteret && (
        <div className="info-block">
          <div className="separator" />
          <h3>Centres d'intérêt</h3>
          <div className="info-value">{profil && profil.centresInteret}</div>
        </div>
      )}
      {profil && profil.autre_particularite && (
        <div className="info-block">
          <div className="separator" />
          <h3>Autres particularités</h3>
          <div className="info-value">{profil && profil.autre_particularite}</div>
        </div>
      )}
      {profil && profil.sens && profil.sens.HYPER_SENSORIALITE && (
        <div className="info-block">
          <div className="separator" />
          <h3>Hypersensorialité</h3>
          {map(profil.sens.HYPER_SENSORIALITE, (value, sens) => {
            let sensLabel = find(sensSettings.HYPER_SENSORIALITE.sens, ['categorieValue', sens]);
            return (
              value.reponse && (
                <Accordion key={sens} title={sensLabel.label} content={value.details} />
              )
            );
          })}
        </div>
      )}
      {profil && profil.sens && profil.sens.HYPO_SENSORIALITE && (
        <div className="info-block">
          <div className="separator" />
          <h3>Hyposensorialité</h3>
          {map(profil.sens.HYPO_SENSORIALITE, (value, sens) => {
            let sensLabel = find(sensSettings.HYPO_SENSORIALITE.sens, ['categorieValue', sens]);
            return (
              value.reponse && (
                <Accordion key={sens} title={sensLabel.label} content={value.details} />
              )
            );
          })}
        </div>
      )}
    </Fragment>
  );
};

EnfantProfil.propTypes = {
  enfant: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  settings: state.data.entities.settings && state.data.entities.settings
});

export default connect(mapStateToProps, {})(EnfantProfil);
