import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { filter, isUndefined, map, values } from 'lodash';
import { getDocumentsMedias } from '../../store/modules/documentsMedias';
import { getIntervenants } from '../../store/modules/intervenants';
import BaseLayout from '../shared/BaseLayout';
import ButtonsSelector from '../../components/shared/ButtonsSelector';
import FormEnfantProfil from '../../components/forms/enfants/FormEnfantProfil';
import FormEnfantSante from '../../components/forms/enfants/FormEnfantSante';
import ModalAddMembreEquipe from '../../components/modals/enfants/ModalAddMembreEquipe';
import ModalAddUpdateAutorisation from '../../components/modals/enfants/ModalAddUpdateAutorisation';
import ModalAutorisation from '../../components/modals/enfants/ModalAutorisation';
import EquipeCardIntervenant from '../../components/cards/EquipeCardIntervenant';

const MonEnfantPage = ({
  user,
  enfant,
  intervenants,
  autorisations,
  getDocumentsMedias,
  getIntervenants,
  types_profils
}) => {
  let location = useLocation();
  const isPrevPageSelectEnfant = location && location.state && location.state.isPrevSelectEnfant;
  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => {
    document.getElementById('wrapper').scrollTo(0, 0);
    setScrollToTop(false);
  }, [scrollToTop]);

  useEffect(() => {
    if (enfant && enfant.enfant_id) {
      getDocumentsMedias(user.profil, user.user_id, enfant.enfant_id);
    }

    if (!isUndefined(enfant && enfant.enfant_id)) {
      getIntervenants(enfant.enfant_id);
    }
  }, []);

  const [tabSelected, setTabSelected] = useState('profil');

  const onChangeTab = tab => {
    setTabSelected(tab);
  };

  const renderForm = () => {
    switch (tabSelected) {
      case 'profil':
        return <FormEnfantProfil enfant={enfant} setScrollToTop={() => setScrollToTop(true)} />;

      case 'sante':
        return <FormEnfantSante enfant={enfant} setScrollToTop={() => setScrollToTop(true)} />;
      case 'equipe':
        return (
          <Fragment>
            {intervenants && intervenants.length > 0 ? (
              <div className="liste">
                {map(intervenants, intervenant => (
                  <EquipeCardIntervenant
                    key={intervenant.user_id}
                    personne={intervenant}
                    types_profils={types_profils}
                  />
                ))}
              </div>
            ) : (
              <div className="form-message">
                <p>
                  Enregistrer ici les membres de l'équipe.
                  <br />
                  <br />
                  Vos intervenants recevront un lien de connexion pour compléter leur profil.
                  <br />
                  <br />
                  Vérifiez si votre intervenant est déja utilisateur de notre application. Si oui
                  rattachez-le à votre compte.
                </p>
              </div>
            )}

            <ModalAddMembreEquipe enfant={enfant} />
          </Fragment>
        );
      case 'autorisations':
        return (
          <Fragment>
            {autorisations && autorisations.length > 0 ? (
              <div className="liste">
                {map(autorisations, autorisation => (
                  <ModalAutorisation
                    key={autorisation.document_id}
                    autorisation={autorisation}
                    enfant={enfant}
                  />
                ))}
              </div>
            ) : (
              <div className="form-message">
                <p>
                  Téléversez ici une autorisation spécifique pour un membre de l'équipe.
                  <br />
                  <br />
                  Ce document est consultable, téléchargeable et/ou imprimable.
                  <br />
                  <br />
                  Vous pouvez supprimer ou modifier cette autorisation
                </p>
              </div>
            )}

            <ModalAddUpdateAutorisation enfant={enfant} />
          </Fragment>
        );

      default:
        break;
    }
  };

  return (
    <BaseLayout
      headerPageTitle={`${(enfant && enfant.profil.prenom) || ''} ${(enfant && enfant.profil.nom) ||
        ''}`}
      isPage
      withSubmenu
      noFooter
      backBtn={isPrevPageSelectEnfant ? 'GO_HOME' : true}
    >
      <div className="main-container" id="scroll-container">
        <ButtonsSelector
          selected={tabSelected}
          onClickButton={value => onChangeTab(value)}
          buttons={map([
            {
              label: 'Son profil',
              value: 'profil'
            },
            {
              label: 'Sa santé',
              value: 'sante'
            },
            {
              label: ' Son équipe',
              value: 'equipe'
            },
            {
              label: 'Ses autorisations',
              value: 'autorisations'
            }
          ])}
        />
        <div className="content">{renderForm()}</div>
      </div>
    </BaseLayout>
  );
};

MonEnfantPage.propTypes = {
  user: PropTypes.object.isRequired,
  enfant: PropTypes.object.isRequired,
  getDocumentsMedias: PropTypes.func.isRequired,
  getIntervenants: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { enfantId }
    }
  } = props;

  return {
    user: state.auth.user,
    autorisations:
      (state.data.entities.documentsMedias &&
        filter(state.data.entities.documentsMedias, d => d.type === 'autorisation')) ||
      [],
    types_profils:
      state.data.entities.settings &&
      state.data.entities.settings['types_profils'] &&
      state.data.entities.settings['types_profils'].data,
    enfant: state.data.entities.enfants && state.data.entities.enfants[Number(enfantId)],
    intervenants:
      (state.data.entities.intervenants && values(state.data.entities.intervenants)) || []
  };
};

export default connect(mapStateToProps, { getDocumentsMedias, getIntervenants })(MonEnfantPage);
