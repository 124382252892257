import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, NavLink, useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
import { isDroitUser } from '../../utils/methodes';
import { getCompteRendu, deleteCompteRendu } from '../../store/modules/comptesRendus';
import Accordion from '../../components/shared/Accordion';
import FatigueSelector from '../../components/shared/FatigueSelector';
import ModalConfirmDelete from '../../components/modals/ModalConfirmDelete';
import { API_URL } from '../../constants/Config';
import { removeExtension, getExtension } from '../../utils/StringUtils';
import {
  COOPERATIONS_ELEVE,
  FONCTIONS_TROUBLE_COMPORTEMENT,
  TOPOGRAPHIES
} from '../../constants/Properties';

const CompteRenduPage = ({
  user,
  compteRendu,
  getCompteRendu,
  deleteCompteRendu,
  enfantSelected,
  startDate,
  endDate
}) => {
  const [error, setError] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const navigation = useHistory();
  const compteRenduId = useParams();

  useEffect(() => {
    getCompteRendu(enfantSelected.enfant_id, compteRenduId.id);
  }, []);

  const deleteItem = () => {
    return deleteCompteRendu(compteRendu)
      .then(() => {
        navigation.push('/comptes-rendus');
      })
      .catch(err => {
        setError(err);
      });
  };

  const renderContent = () => {
    switch (compteRendu.file_type) {
      case 'image/gif':
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return (
          <img
            srcSet={map(
              ['640w', '1024w', '1920w'],
              taille =>
                `${API_URL}/uploads/${removeExtension(compteRendu.file)}-${taille}${getExtension(
                  compteRendu.file
                )} ${taille}`
            )}
          />
        );
      case 'video/quicktime':
      case 'video/mp4':
        return (
          <video controls width="100%">
            <source
              src={`${API_URL}/uploads/${(compteRendu &&
                compteRendu.file &&
                'min_' + compteRendu.file) ||
                ''}`}
              type="video/mp4"
            />
          </video>
        );
    }
  };
  return (
    <div id="wrapper" className="page-preview">
      <header className="header">
        {compteRendu && (
          <div className="header-right">
            {(user.profil === 'STRUCTURE' ||
              user.profil === 'FAMILLE' ||
              (user.user_id === compteRendu.user_id &&
                isDroitUser('comptes_rendus', user, enfantSelected) === 'ADMINISTRATION') ||
              (isDroitUser('comptes_rendus', user, enfantSelected) === 'MODIFICATION' &&
                user.user_id === compteRendu.user_id)) && (
              <NavLink
                to={`/compte-rendu/editer/${compteRendu && compteRendu.compterendu_id}`}
                className="header-btn"
              >
                <i className="fas fa-pencil" />
              </NavLink>
            )}

            {((isDroitUser('comptes_rendus', user, enfantSelected) === 'ADMINISTRATION' &&
              user.user_id === compteRendu.user_id) ||
              user.profil === 'FAMILLE' ||
              user.profil === 'STRUCTURE' ||
              (isDroitUser('comptes_rendus', user, enfantSelected) === 'MODIFICATION' &&
                user.user_id === compteRendu.user_id)) && (
              <div onClick={() => setOpenModalDelete(true)} className="header-btn">
                <i className="fas fa-trash-alt" />
              </div>
            )}
            <ModalConfirmDelete
              message={'Êtes-vous sûr de vouloir supprimer ce compte rendu ?'}
              openModal={openModalDelete}
              closeModal={() => setOpenModalDelete(false)}
              actionDelete={() => deleteItem()}
            />
          </div>
        )}

        <div className="header-bkg">
          <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
          <svg height="0px" width="0px">
            <defs>
              <clipPath
                id="menumask"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.00266666666,0.01666666666)"
              >
                <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </header>
      <main>
        <div className="container">
          <div className="event-schedule-infos">
            <div className="event-date">
              {compteRendu &&
                moment(compteRendu.date)
                  .format('dddd D MMMM HH:mm')
                  .replace(':', 'h')}
            </div>
          </div>

          {compteRendu && compteRendu.lieu && (
            <div className="event-description">{compteRendu.lieu}</div>
          )}
          <div className="separator" />
          <div className="info-block">
            <h3>Humeur de l'élève</h3>

            <FatigueSelector niveau={compteRendu && compteRendu.fatigue_niveau} notEdit />
            {compteRendu && compteRendu.fatigue_observations && (
              <div className="info-value info-commentaire">{compteRendu.fatigue_observations}</div>
            )}
          </div>

          <div className="separator" />
          <div className="info-block">
            <h3>Coopération de l’élève</h3>
            <div className="info-value">
              {compteRendu &&
              COOPERATIONS_ELEVE[compteRendu.cooperation_eleve] &&
              COOPERATIONS_ELEVE[compteRendu.cooperation_eleve] !== 'Autre'
                ? COOPERATIONS_ELEVE[compteRendu.cooperation_eleve]
                : compteRendu && compteRendu.libelle_coop_eleve
                ? compteRendu.libelle_coop_eleve
                : null}
            </div>
          </div>
          <div className="separator" />
          <div className="info-block">
            <h3>Programme(s) travaillé(s)</h3>
            {map(compteRendu && compteRendu.programmes, (programme, index) => (
              <Accordion
                key={index}
                title={programme.nomProgramme}
                content={`Commentaire: ${programme.commentaire}`}
              />
            ))}
          </div>
          {compteRendu?.troubleComportement && (
            <div className="info-block">
              <div className="separator" />
              <h3>Troubles du comportement / Comportements défi durant la séance</h3>
              {map(compteRendu.troubles, (trouble, index) => {
                return (
                  <div className="accordion-item" key={index}>
                    <div className="accordion-content">
                      <h4>
                        {FONCTIONS_TROUBLE_COMPORTEMENT[trouble.fonction] &&
                        trouble.fonction !== 'AUTRE'
                          ? FONCTIONS_TROUBLE_COMPORTEMENT[trouble.fonction]
                          : trouble.libelle_fonction
                          ? trouble.libelle_fonction
                          : null}
                      </h4>
                      <ul className="tags-list">
                        {map(trouble.topographies, (t, i) => {
                          return (
                            <li className="tag" key={i}>
                              {TOPOGRAPHIES[t] && t !== 'AUTRE'
                                ? TOPOGRAPHIES[t]
                                : trouble.libelle_topographies
                                ? trouble.libelle_topographies
                                : null}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {compteRendu && compteRendu.commentaires && (
            <div className="info-block">
              <div className="separator" />
              <h3>Commentaire</h3>
              <div className="info-value">{compteRendu.commentaires}</div>
            </div>
          )}
          {compteRendu && compteRendu.file && (
            <div className="info-block">
              <div className="separator" />
              <h3>Photo/vidéo</h3>
              {renderContent()}
            </div>
          )}
        </div>
        <div className="btn-group center bottom-sticky">
          {startDate && endDate ? (
            <Link
              className="btn btn-primary"
              to={{
                pathname: `/comptes-rendus`,
                state: { startDate: startDate, endDate: endDate }
              }}
            >
              Fermer
            </Link>
          ) : (
            <Link
              className="btn btn-primary"
              to={{
                pathname: `/comptes-rendus`
              }}
            >
              Fermer
            </Link>
          )}
        </div>
      </main>
    </div>
  );
};

CompteRenduPage.propTypes = {
  compteRendu: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getCompteRendu: PropTypes.func.isRequired,
  deleteCompteRendu: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { id }
    }
  } = props;

  const compteRendu = state?.data?.entities?.comptesRendus[props.match.params.id];
  return {
    startDate: props.location && props.location.state && props.location.state.startDate,
    endDate: props.location && props.location.state && props.location.state.endDate,
    user: state?.auth?.user,
    enfantSelected: state?.auth?.enfantSelected,
    compteRendu: compteRendu,
    intervenant: compteRendu && state?.data?.entities?.intervenants[compteRendu.user_id]
  };
};

export default connect(mapStateToProps, { getCompteRendu, deleteCompteRendu })(CompteRenduPage);
