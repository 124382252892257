import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Modal from 'react-modal';

import { getProfilImgMin } from '../../utils/methodes';
import modalDefaultClass from '../../utils/ModalDefaultClass';
import AvatarOutline from '../../utils/AvatarOutline';
import AvatarMask from '../../utils/AvatarMask';

const ModalFamilleProfil = ({ user, enfant, isOpen, close }) => {
  const [isOpenModal, setIsOpenModal] = useState(isOpen);

  return (
    <Modal {...modalDefaultClass} id="modal-profil" isOpen={isOpenModal}>
      <header className="header">
        <div className="header-bkg">
          <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
          <img className="hexa" src={require(`../../../assets/images/hexa-orange.svg`)} />
          <svg height="0px" width="0px">
            <defs>
              <clipPath
                id="menumask"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.00266666666,0.01666666666)"
              >
                <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </header>
      <div className="modal-body">
        <div className="member">
          <div className="member-avatar">
            {!isEmpty(user.photo) ? (
              <Fragment>
                <div className="avatar-mask">
                  <img src={getProfilImgMin(user.photo)} alt="photo" />
                </div>
                <AvatarMask nameFile="ModalProfil" />
                <AvatarOutline nameFile="ModalProfil" />
              </Fragment>
            ) : (
              <Fragment>
                {user.prenom && user.nom ? (
                  <Fragment>
                    <span>
                      {user.prenom && user.prenom[0]}
                      {user.nom && user.nom[0]}
                    </span>
                    <AvatarOutline nameFile="ModalProfil" />
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="avatar-mask">
                      <i className="fas fa-user user-icon" />
                    </div>
                    <AvatarMask nameFile="ModalProfil" />
                    <AvatarOutline nameFile="ModalProfil" />
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
          <div className="member-infos">
            <div className="member-name">
              {user && user.prenom} {user && user.nom}
            </div>
            <div className="member-profil">
              {user.pseudo} de {enfant.profil.prenom}
            </div>
          </div>
        </div>
        <div className="separator" />

        {user.tel1 && (
          <a className="info-value" href={`tel:${user.tel1}`}>
            <i className="fas fa-phone" />
            <span>{user.tel1}</span>
          </a>
        )}
        {user.tel2 && (
          <a className="info-value" href={`tel:${user.tel2}`}>
            <i className="fas fa-phone" />
            <span>{user.tel2}</span>
          </a>
        )}
        <a className="info-value" href={`mailto:${user.email}`} target="_blank">
          <i className="fas fa-envelope" />
          <span>{user.email}</span>
        </a>

        {user.adresse1 && (
          <div className="info-value">
            <i className="fas fa-map-marker-alt" />
            <span
              dangerouslySetInnerHTML={{
                __html: user.adresse1
                  .concat(user.adresse2 ? user.adresse2 : '')
                  .replace(/\n/g, '<br/>')
              }}
            />
          </div>
        )}
        {user.adresse2 && (
          <div className="info-value">
            <i className="fas fa-map-marker-alt" />
            <span
              dangerouslySetInnerHTML={{
                __html: user.adresse2.replace(/\n/g, '<br/>')
              }}
            />
          </div>
        )}
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" onClick={() => close()}>
            Fermer
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalFamilleProfil.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default ModalFamilleProfil;
