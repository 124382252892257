import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadFiles, removeFile } from '../../../store/modules/uploads';
import { createDocumentMedia, updateDocumentMedia } from '../../../store/modules/documentsMedias';
import { Form, Field } from 'react-final-form';
import { map, find, values } from 'lodash';
import Select from 'react-select';
import Modal from 'react-modal';
import modalDefaultClass from '../../../utils/ModalDefaultClass';
import FieldUploadDropZone from '../../shared/FieldUploadDropZone';

const ModalAddUpdateAutorisation = ({
  isEdit,
  enfant,
  autorisation,
  intervenants,
  user,
  uploadFiles,
  removeFile,
  createDocumentMedia,
  updateDocumentMedia,
  types_profils
}) => {
  // console.log('AUTORISATION', autorisation);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
    setError(null);
  };
  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = data => {
    if (isEdit) {
      if (data.fileName !== autorisation.file) {
        return uploadFiles({ files: data.fileName })
          .then(res => {
            return updateDocumentMedia(autorisation, {
              enfant_id: enfant.enfant_id,
              famille_id: user.user_id,
              user_id: data.user_id,
              file: res.response.fileName,
              type: 'autorisation',
              file_type: 'application/pdf',
              titre: data.titre
            })
              .then(() => setIsOpen(false))
              .catch(err => setError(err));
          })
          .catch(err => setError(err));
      } else {
        return updateDocumentMedia(autorisation, {
          enfant_id: enfant.enfant_id,
          famille_id: user.user_id,
          user_id: data.user_id,
          file: autorisation.file,
          type: 'autorisation',
          file_type: 'application/pdf',
          titre: data.titre
        })
          .then(() => setIsOpen(false))
          .catch(err => setError(err));
      }
    } else {
      return uploadFiles({ files: data.fileName })
        .then(res => {
          return createDocumentMedia({
            enfant_id: enfant.enfant_id,
            famille_id: user.user_id,
            user_id: data.user_id,
            file: res.response.fileName,
            type: 'autorisation',
            file_type: 'pdf',
            titre: data.titre
          })
            .then(() => setIsOpen(false))
            .catch(err => setError(err));
        })
        .catch(err => setError(err));
    }
  };

  const optionsIntervenants = map(intervenants, item => {
    return { value: item.user_id, label: `${item.prenom} [${types_profils[item.profil]}]` };
  });

  return (
    <Fragment>
      {isEdit ? (
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          className="header-btn"
        >
          <i className="fas fa-pencil" />
        </div>
      ) : (
        <div className="btn-group center bottom-sticky">
          <div
            onClick={() => {
              setIsOpen(true);
            }}
            className="btn btn-primary"
          >
            <i className="fas fa-plus-circle" />
            <span>Ajouter</span>
          </div>
        </div>
      )}

      <Modal {...modalDefaultClass} id="modal-confirm" isOpen={isOpen}>
        <header className="header">
          <div className="modal-title">
            {(isEdit && 'Modifier une autorisation') || 'Ajouter une autorisation'}
          </div>
          <div className="header-bkg">
            <img className="oiseau" src={require(`../../../../assets/images/oiseau.svg`)} />
            <img className="hexa" src={require(`../../../../assets/images/hexa-orange.svg`)} />
            <svg height="0px" width="0px">
              <defs>
                <clipPath
                  id="menumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01666666666)"
                >
                  <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </header>

        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <Field
                  name="titre"
                  validate={required}
                  component="input"
                  className="field"
                  initialValue={(isEdit && autorisation && autorisation.titre) || ''}
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>Titre *</label>
                    </div>
                  )}
                </Field>
                <Field
                  name="user_id"
                  component="select"
                  validate={required}
                  initialValue={(isEdit && autorisation && autorisation.user_id) || null}
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          placeholder="Sélectionner un destinataire"
                          options={optionsIntervenants}
                          onChange={option => {
                            input.onChange(option.value);
                          }}
                          value={find(optionsIntervenants, o => o.value === input.value)}
                        />
                        <label>Destinataire *</label>
                      </div>
                    );
                  }}
                </Field>
                <Field
                  name="fileName"
                  validate={required}
                  initialValue={(isEdit && autorisation && autorisation.file) || null}
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <FieldUploadDropZone
                          message1="Glisser et déposer votre fichier PDF ici ou"
                          message2="Choisir un fichier PDF"
                          type="pdf"
                          rmFile={file => removeFile(file)}
                          acceptedFiles="application/pdf"
                          initFile={(isEdit && autorisation && autorisation.file) || null}
                          {...input}
                        />
                        <label>Pièce jointe *</label>
                      </div>
                    );
                  }}
                </Field>
                {error && (
                  <div className="error-block">
                    <span className="error-icon">
                      <i className="fas fa-exclamation" />
                    </span>
                    <div className="error-message">{error}</div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <div className="btn-group center">
                  <button className="btn btn-tertiary" onClick={() => closeModal()}>
                    Annuler
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Valider
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </Modal>
    </Fragment>
  );
};

ModalAddUpdateAutorisation.propTypes = {
  user: PropTypes.object.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  createDocumentMedia: PropTypes.func.isRequired,
  updateDocumentMedia: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  enfant: state.auth.enfantSelected,
  user: state.auth.user,
  types_profils:
    state.data.entities.settings &&
    state.data.entities.settings['types_profils'] &&
    state.data.entities.settings['types_profils'].data,
  intervenants: (state.data.entities.intervenants && values(state.data.entities.intervenants)) || []
});

export default connect(mapStateToProps, {
  uploadFiles,
  createDocumentMedia,
  updateDocumentMedia,
  removeFile
})(ModalAddUpdateAutorisation);
