import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
import 'moment-duration-format';

import MomentUtils from '@date-io/moment';

import { createPointage, deletePointage, updatePointage } from '../../store/modules/pointages';

import ModalConfirmDelete from '../modals/ModalConfirmDelete';

import modalDefaultClass from '../../utils/ModalDefaultClass';

import materialTheme from '../../../assets/stylesheets/materialThemeDatePicker';
import { ThemeProvider } from '@material-ui/styles';

import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date)
      .locale('fr')
      .format('DD MMMM YYYY');
  }
}

const ModalAddUpdatePointage = ({
  openModalSuccess,
  enfantSelected,
  isEdit,
  pointage,
  user,
  isOpen,
  createPointage,
  deletePointage,
  updatePointage,
  closeModal
}) => {
  const [error, setError] = useState(false);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [closeModalState, setCloseModalState] = useState(false);
  const required = value => (value ? undefined : 'Champ requis !');
  const requiredTime = value => (value && value !== '00:00' ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    const data = values;
    data.user_id = user.user_id;
    data.parent_id = enfantSelected.parent_id;

    // calcul du total heures :

    var duration = moment.duration(moment(data.heure_fin).diff(moment(data.heure_debut)));
    var hours = duration.asHours();

    // tricks pour catcher quand on clique sur le bouton Annuler
    // une demi journée pour arriver a ca... j'ai pas trouvé mieux
    if (!closeModalState) {
      // check if datetime !
      if (hours <= 0) {
        setError('Erreur dans les heures de pointage');
        return;
      }

      data.totalHeures = hours.toFixed(2);
      data.enfant_id = enfantSelected.enfant_id;

      if (!isEdit) {
        // on est a la création d'un pointage
        return createPointage(data)
          .then(() => {
            setError(false);
            closeModal();
            openModalSuccess();
          })
          .catch(err => setError(err));
      } else {
        // on est a l'édition d'un pointage
        return updatePointage(pointage.pointage_id, data)
          .then(() => {
            setError(false);
            closeModal();
            openModalSuccess();
          })
          .catch(err => setError(err));
      }
    } else {
      setError(false);
      closeModal();
      setCloseModalState(false);
    }
  };
  const actualDate = moment();
  return (
    <Modal {...modalDefaultClass} id="modal-pointage" isOpen={isOpen}>
      {(user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') && isEdit && (
        <header className="header">
          <div className="header-right">
            <button className="header-btn" onClick={() => setOpenModalDelete(true)}>
              <i className="fas fa-trash-alt" />
            </button>
            <ModalConfirmDelete
              message={'Êtes-vous sûr de vouloir supprimer ce pointage ?'}
              openModal={openModalDelete}
              closeModal={() => {
                closeModal();
                setOpenModalDelete(false);
              }}
              actionDelete={() => deletePointage(pointage)}
            />
          </div>

          <div className="header-bkg">
            <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
            <svg height="0px" width="0px">
              <defs>
                <clipPath
                  id="menumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01666666666)"
                >
                  <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </header>
      )}

      {(user.profil === 'FAMILLE' || user.profil === 'STRUCTURE' || !isEdit) && (
        <header className="header">
          <div className="modal-title">Je pointe ma journée</div>
          <div className="header-bkg">
            <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
            <img className="hexa" src={require(`../../../assets/images/hexa-orange.svg`)} />
            <svg height="0px" width="0px">
              <defs>
                <clipPath
                  id="menumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01666666666)"
                >
                  <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </header>
      )}

      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <Field
                name="date"
                component="input"
                className="field"
                validate={required}
                initialValue={(pointage && pointage.date) || actualDate}
              >
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={LocalizedUtils}
                      locale={'fr'}
                    >
                      <ThemeProvider theme={materialTheme}>
                        <KeyboardDatePicker
                          className="datepicker-mui"
                          invalidDateMessage={null}
                          autoOk
                          value={input.value}
                          placeholder="jj/mm/aaaa"
                          okLabel={null}
                          clearLabel={null}
                          clearable={false}
                          cancelLabel={null}
                          onChange={date => {
                            input.onChange(date);
                          }}
                          format="DD/MM/YYYY"
                          InputAdornmentProps={{ position: 'start' }}
                          inputVariant="outlined"
                          maxDate={new Date()}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <label>Date *</label>
                  </div>
                )}
              </Field>

              <div className="grid">
                <div className="col">
                  <Field
                    name="heure_debut"
                    component="input"
                    className="field"
                    initialValue={(pointage && pointage.heure_debut) || actualDate}
                    validate={requiredTime}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <label>Début *</label>

                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={LocalizedUtils}
                          locale={'fr'}
                        >
                          <KeyboardTimePicker
                            className="datepicker-mui timepicker-mui"
                            InputAdornmentProps={{ position: 'start' }}
                            inputVariant="outlined"
                            placeholder="00:00"
                            okLabel={null}
                            invalidDateMessage={null}
                            clearLabel={null}
                            ampm={false}
                            clearable={false}
                            cancelLabel={null}
                            mask="__:__"
                            autoOk
                            value={input.value}
                            onChange={value => input.onChange(value)}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col">
                  <Field
                    name="heure_fin"
                    component="input"
                    className="field"
                    validate={requiredTime}
                    initialValue={(pointage && pointage.heure_fin) || actualDate}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <label>Fin *</label>
                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={LocalizedUtils}
                          locale={'fr'}
                        >
                          <KeyboardTimePicker
                            className="datepicker-mui timepicker-mui"
                            InputAdornmentProps={{ position: 'start' }}
                            inputVariant="outlined"
                            placeholder="00:00"
                            okLabel={null}
                            invalidDateMessage={null}
                            clearLabel={null}
                            ampm={false}
                            clearable={false}
                            cancelLabel={null}
                            mask="__:__"
                            autoOk
                            value={input.value}
                            onChange={value => input.onChange(value)}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )}
                  </Field>
                </div>
              </div>

              {error && (
                <div className="error-block">
                  <span className="error-icon">
                    <i className="fas fa-exclamation" />
                  </span>
                  <div className="error-message">{error}</div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <div className="btn-group center">
                <button className="btn btn-tertiary" onClick={() => setCloseModalState(true)}>
                  Annuler
                </button>
                <button type="submit" className="btn btn-primary">
                  Enregistrer
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

ModalAddUpdatePointage.propTypes = {
  user: PropTypes.object.isRequired,
  createPointage: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  user: state.auth.user,
  enfantSelected: state.auth.enfantSelected
});

export default connect(mapStateToProps, {
  createPointage,
  deletePointage,
  updatePointage
})(ModalAddUpdatePointage);
