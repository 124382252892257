import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import Accordion from './shared/Accordion';

const EnfantSante = enfant => {
  const sante = enfant.enfant.sante;

  return (
    <Fragment>
      {sante.diagnosticPose && 
        <Accordion
          title={`Diagnostic`}
          content={sante.diagnosticPoseDetail && sante.diagnosticPoseDetail}
        />
      }
      {sante.allergies &&
        <Accordion 
          title={`Allergies`} 
          content={sante.allergiesDetail && sante.allergiesDetail} 
        />
      }
      {sante.intolerances && 
        <Accordion
          title={`Intolérances`}
          content={sante.intolerancesDetail && sante.intolerancesDetail}
        />
      }
      {!isEmpty(sante.autre) && 
        <Accordion 
          title={`Autres`} 
          content={sante.autre} 
        />
      }
      {!isEmpty(sante.medicationProtocole) && 
        <Accordion
          title={`Traitement et protocole`}
          content={sante.medicationProtocole && sante.medicationProtocole}
        />
      }
      
    </Fragment>
  );
};

EnfantSante.propTypes = {
  enfant: PropTypes.object.isRequired
};

export default EnfantSante;
