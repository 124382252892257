import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { validateEmail } from '../../utils/StringValidator';

import { connect } from 'react-redux';
import { recoverPassword } from '../../store/modules/auth';

import { Form, Field } from 'react-final-form';

import BaseLayout from '../shared/BaseLayout';
import ModalSuccessResetPasswordSend from '../../components/modals/ModalSuccessResetPasswordSend';

const PasswordOubliePage = ({ recoverPassword }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const required = value => (value ? undefined : 'Champ requis !');

  const submitRecover = data => {
    if (validateEmail(data.email)) {
      return recoverPassword(data.email)
        .then(() => {
          setSuccess(true);
        })
        .catch(err =>
          setError(
            "Une erreur s'est produite lors de la récupération de votre compte ! : L'adresse email est inconnue"
          )
        );
    } else {
      setError(
        "Une erreur s'est produite lors de la récupération de votre compte ! : L'adresse email est invalide"
      );
    }
  };
  return (
    <BaseLayout
      notDisplayTopHeader
      backBtn
      noFooter
      isPage
      bigTitle
      headerPageTitle="Vous avez oublié votre mot de passe ?"
      headerPageSubtitle="Un email vous sera adressé afin de pouvoir redéfinir votre mot de passe."
      classScreen=""
    >
      <div className="content">
        {success && <ModalSuccessResetPasswordSend />}

        <div className="field text-center">
          <Form
            onSubmit={submitRecover}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="email" validate={required} component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="email" autoCapitalize="none" />
                      <label>Email</label>
                    </div>
                  )}
                </Field>
                {error && (
                  <div className="error-block">
                    <span className="error-icon">
                      <i className="fas fa-exclamation" />
                    </span>
                    <div className="error-message">{error}</div>
                  </div>
                )}
                <div className="btn-group flexColumn center">
                  <button type="submit" className="btn btn-primary">
                    Envoyer
                  </button>
                  <NavLink className="btn-link btn-link-secondary" to="/connexion">
                    Revenir à la page de connexion
                  </NavLink>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

PasswordOubliePage.propTypes = {
  recoverPassword: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  account: state.auth
});

export default connect(mapStateToProps, { recoverPassword })(PasswordOubliePage);
