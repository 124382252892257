import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalSuccessResetPasswordSend = () => {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();

  const confirm = () => {
    setIsOpen(false);
    history.push('/connexion');
  };
  return (
    <Modal
      className="modal-content small-modal"
      overlayClassName="modal-overlay"
      id="modal-send-password"
      isOpen={isOpen}
    >
      <div className="modal-body">
        <div className="message">Un email pour définir votre mot de passe vous a été envoyé</div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" onClick={() => confirm()}>
            <span>Ok</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSuccessResetPasswordSend;
