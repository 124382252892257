import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { uploadFiles, removeFile } from '../../store/modules/uploads';
import { createDocumentMedia } from '../../store/modules/documentsMedias';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import { Form, Field } from 'react-final-form';

import ClipLoader from 'react-spinners/ClipLoader';

import FieldUploadDropZone from '../../components/shared/FieldUploadDropZone';

const ModalAddMedia = ({
  uploadFiles,
  removeFile,
  createDocumentMedia,
  enfant,
  user,
  enfantSelected
}) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = data => {
    setLoading(true);
    return uploadFiles({ files: data.fichier })
      .then(res => {
        return createDocumentMedia({
          enfant_id: enfant.enfant_id,
          description: data.description || null,
          famille_id:
            user.profil === 'FAMILLE' || user.profil === 'STRUCTURE'
              ? user.user_id
              : enfantSelected.parent_id,
          user_id: user.user_id,
          file: res.response.fileName,
          type: 'medias',
          file_type: res.response.fileType,
          titre: data.titre
        })
          .then(() => {
            setLoading(false);
            setIsOpen(false);
          })
          .catch(err => {
            setLoading(false);
            setError(err);
          });
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  };
  return (
    <Fragment>
      <div className="bottom-sticky bottom-btn-add">
        <div onClick={() => setIsOpen(true)} className="btn-icon">
          <i className="fas fa-plus" />
        </div>
      </div>
      <Modal {...modalDefaultClass} id="modal-media" isOpen={isOpen}>
        <header className="header">
          <div className="modal-title">Ajouter un media</div>
          <div className="header-bkg">
            <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
            <img className="hexa" src={require(`../../../assets/images/hexa-orange.svg`)} />
            <svg height="0px" width="0px">
              <defs>
                <clipPath
                  id="menumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01666666666)"
                >
                  <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </header>

        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <Field name="description">
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <textarea {...input} type="text" rows={5} />
                        <label>Description</label>
                      </div>
                    );
                  }}
                </Field>
                <Field name="fichier" validate={required}>
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <FieldUploadDropZone
                          message1="Glisser et déposer votre fichier ici ou"
                          message2="Choisir un fichier"
                          rmFile={file => removeFile(file)}
                          acceptedFiles={['.png', '.jpg', '.jpeg', '.mov', '.wmv', '.avi', '.mp4']}
                          {...input}
                        />
                      </div>
                    );
                  }}
                </Field>
              </div>
              {error && <div className="error-block">{error}</div>}
              <div className="modal-footer">
                <div className="btn-group center">
                  <button onClick={() => setIsOpen(false)} className="btn btn-tertiary">
                    <span>Annuler</span>
                  </button>
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    <span>
                      {(loading && <ClipLoader size={15} color={'white'} loading={loading} />) ||
                        'Ajouter'}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </Modal>
    </Fragment>
  );
};

ModalAddMedia.propTypes = {
  user: PropTypes.object.isRequired,
  createDocumentMedia: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  enfantSelected:
    state.auth.user.profil !== 'FAMILLE' && state.auth.user.profil !== 'STUCTURE'
      ? state.auth.enfantSelected
      : null,
  enfant: state.auth.enfantSelected
});

export default connect(mapStateToProps, { uploadFiles, removeFile, createDocumentMedia })(
  ModalAddMedia
);
