import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toLower, values, isEmpty } from 'lodash';
import {
  getUserByEmail,
  getIntervenantByEmail,
  updateUser,
  checkEmail
} from '../../store/modules/auth';
import { uploadFiles, removeFile } from '../../store/modules/uploads';
import { validateEmail } from '../../utils/StringValidator';
import BaseLayout from '../shared/BaseLayout';
import FieldUploadDropZone from '../../components/shared/FieldUploadDropZone';

import ModalAddCompteSecondaire from '../../components/modals/ModalAddCompteSecondaire';
import ModalInfo from '../../components/modals/ModalInfo';

const MesInfosPersonnelsPage = ({ user, updateUser, checkEmail, uploadFiles, removeFile }) => {
  const [error, setError] = useState(null);
  const [modalSuccessCreateSecondUser, setModalSuccessCreateSecondUser] = useState(false);

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';

  const handleSubmit = data => {
    data.firstConnexion = false;
    if (data.photo && data.photo !== user.photo) {
      return uploadFiles({ files: data.photo })
        .then(res => {
          data.photo = res.response.fileName;

          if (data.email !== user.email) {
            checkEmail(toLower(data.email))
              .then(res => {
                return updateUser(user, data)
                  .then(() => {
                    return history.push('/mon-compte');
                  })
                  .catch(err => setError(err));
              })
              .catch(err => setError(err));
          } else {
            return updateUser(user, data)
              .then(() => {
                return history.push('/mon-compte');
              })
              .catch(err => setError(err));
          }
        })
        .catch(err => setError(err));
    } else {
      if (data.email !== user.email) {
        checkEmail(toLower(data.email))
          .then(res => {
            return updateUser(user, data)
              .then(() => {
                return history.push('/mon-compte');
              })
              .catch(err => setError(err));
          })
          .catch(err => setError(err));
      } else {
        return updateUser(user, data)
          .then(() => {
            return history.push('/mon-compte');
          })
          .catch(err => setError(err));
      }
    }
  };

  const history = useHistory();
  return (
    <BaseLayout
      headerPageTitle={`${
        user.profil === 'STRUCTURE' ? 'Infos de la structure' : 'Mes infos personnelles'
      }`}
      isPage
      backBtn
      noFooter
    >
      <div className="main-container alignCenter">
        <div className="content">
          <ModalAddCompteSecondaire openSuccess={() => setModalSuccessCreateSecondUser(true)} />
          <ModalInfo
            isOpen={modalSuccessCreateSecondUser}
            text="Le compte secondaire a été créé"
            confirmMethode={() => {
              setModalSuccessCreateSecondUser(false);
              history.push('/mon-compte');
            }}
            closeModal={() => setModalSuccessCreateSecondUser(false)}
            confirmText="OK"
          />
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {user && (
                  <Field name="photo" initialValue={(user && user.photo) || null}>
                    {({ input, meta }) => {
                      return (
                        <div className="field alignCenter">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <FieldUploadDropZone
                            message1="Glisser et déposer votre fichier ici ou"
                            message2="Choisir un fichier"
                            rmFile={file => removeFile(file)}
                            type="img"
                            initFile={(user && user.photo) || null}
                            acceptedFiles="image/*"
                            {...input}
                          />
                        </div>
                      );
                    }}
                  </Field>
                )}

                {user && user.profil !== 'STRUCTURE' && (
                  <Field
                    name="prenom"
                    validate={user.profil !== 'FAMILLE' && required}
                    initialValue={user && user.prenom}
                    component="input"
                    parse={v => v}
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" />
                        <label>Prénom{user.profil !== 'FAMILLE' && ' *'}</label>
                      </div>
                    )}
                  </Field>
                )}
                {user && (
                  <Field
                    validate={!user.userIdPrincipal && required}
                    initialValue={user && user.nom}
                    name="nom"
                    component="input"
                    className="field"
                    parse={v => v}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" />
                        <label>Nom{!user.userIdPrincipal && ' *'}</label>
                      </div>
                    )}
                  </Field>
                )}

                {user && user.profil === 'FAMILLE' && (
                  <Field
                    name="pseudo"
                    validate={
                      user &&
                      (!isEmpty(user.usersIdsSecondaires) || user.userIdPrincipal) &&
                      required
                    }
                    initialValue={user && user.pseudo}
                    component="input"
                    className="field"
                    parse={v => v}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" placeholder="Papa, Maman, ..." />
                        <label>
                          Profil
                          {user &&
                            (!isEmpty(user.usersIdsSecondaire) || user.userIdPrincipal) &&
                            ' *'}
                        </label>
                      </div>
                    )}
                  </Field>
                )}

                <Field
                  validate={requiredEmail}
                  initialValue={user && user.email}
                  name="email"
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>E-mail *</label>
                    </div>
                  )}
                </Field>

                {user.profil === 'FAMILLE' && (
                  <Field
                    name="adresse1"
                    validate={!user.userIdPrincipal && required}
                    initialValue={user && user.adresse1}
                    component="input"
                    className="field"
                    parse={v => v}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <textarea {...input} type="text" rows="4" />
                        <label>Adresse 1 {!user.userIdPrincipal && '*'}</label>
                      </div>
                    )}
                  </Field>
                )}

                {user.profil === 'FAMILLE' ? (
                  <Field
                    name="adresse2"
                    initialValue={user && user.adresse2}
                    component="input"
                    className="field"
                    parse={v => v}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <textarea {...input} type="text" rows="4" />
                        <label>Adresse 2</label>
                      </div>
                    )}
                  </Field>
                ) : (
                  !user.userIdPrincipal && (
                    <Field
                      name="adresse"
                      initialValue={user && user.adresse}
                      component="input"
                      className="field"
                      validate={required}
                      parse={v => v}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <textarea {...input} type="text" rows="4" />
                          <label>Adresse *</label>
                        </div>
                      )}
                    </Field>
                  )
                )}

                {user && (
                  <Fragment>
                    <Field
                      name="tel1"
                      validate={!user.userIdPrincipal && required}
                      initialValue={user && user.tel1}
                      component="input"
                      className="field"
                      parse={v => v}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="phone" />
                          <label>Téléphone {!user.userIdPrincipal && '1 *'}</label>
                        </div>
                      )}
                    </Field>
                    {!user.userIdPrincipal && (
                      <Field
                        name="tel2"
                        initialValue={user && user.tel2}
                        component="input"
                        className="field"
                        parse={v => v}
                      >
                        {({ input, meta }) => (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}
                            <input {...input} type="phone" />
                            <label>Téléphone 2</label>
                          </div>
                        )}
                      </Field>
                    )}
                  </Fragment>
                )}
                {/* #6375 ajout des notifications */}
                <Field
                  name="notifications"
                  initialValue={user && user.notifications}
                  component="input"
                  type="checkbox"
                >
                  {({ input, meta }) => (
                    <div className="field ">
                      <input {...input} id="notifications" type="checkbox" />
                      <label htmlFor="notifications">Je souhaite recevoir les notifications</label>
                    </div>
                  )}
                </Field>
                {error && (
                  <div className="error-block">
                    <span className="error-icon">
                      <i className="fas fa-exclamation" />
                    </span>
                    {error}
                  </div>
                )}
                <div className="btn-group center">
                  <button type="submit" className="btn btn-primary">
                    <span>Enregistrer</span>
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

MesInfosPersonnelsPage.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  checkEmail: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  users: state.data.entities.users && values(state.data.entities.users)
});

export default connect(mapStateToProps, {
  updateUser,
  uploadFiles,
  removeFile,
  getUserByEmail,
  getIntervenantByEmail,
  checkEmail
})(MesInfosPersonnelsPage);
