import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, map, values, orderBy } from 'lodash';
import { getDocumentsMedias } from '../../store/modules/documentsMedias';
import BaseLayout from '../shared/BaseLayout';
import ModalAddUpdateProgramme from '../../components/modals/programmes/ModalAddUpdateProgramme';
import ProgrammeCard from '../../components/cards/ProgrammeCard';
import { isDroitUser } from '../../utils/methodes';

const ProgrammesPage = ({ programmes, getDocumentsMedias, user, enfantSelected }) => {
  useEffect(() => {
    if (enfantSelected) {
      getDocumentsMedias(user.profil, user.user_id, enfantSelected.enfant_id);
    }
  }, []);

  return (
    <BaseLayout headerPageTitle="Programmes" isPage homeBtn noFooter>
      <div className="main-container">
        <div className="content">
          {programmes && programmes.length > 0 ? (
            <div className="liste">
              <div className="card card-programme">
                <i className="fal fa-clipboard-list-check" /> <span>Le PEI</span>
              </div>
              {map(orderBy(programmes, ['created_at'], ['desc']), programme => (
                <ProgrammeCard key={programme.document_id} programme={programme} />
              ))}
            </div>
          ) : (
            <div className="form-message">
              <p>
                Cet espace est prévu pour intégrer les programmes de votre enfant.
                <br />
                <br />
                Rapprochez-vous de votre psychologue ou ajoutez-en vous même le cas échéant
              </p>
            </div>
          )}

          {(isDroitUser('programmes', user, enfantSelected) === 'MODIFICATION' ||
            user.profil === 'FAMILLE' ||
            user.profil === 'STRUCTURE') && <ModalAddUpdateProgramme />}
        </div>
      </div>
    </BaseLayout>
  );
};

ProgrammesPage.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  enfantSelected: state.auth.enfantSelected,
  programmes:
    state.data.entities.documentsMedias &&
    filter(
      state.data.entities.documentsMedias,
      d => d.type === 'programme' && d.enfant_id === state.auth.enfantSelected.enfant_id
    )
});

export default connect(mapStateToProps, { getDocumentsMedias })(ProgrammesPage);
