import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { getProfilImgMin } from '../../utils/methodes';
import ModalIntervenantProfil from '../modals/ModalIntervenantProfil';
import AvatarMask from '../../utils/AvatarMask';
import AvatarOutline from '../../utils/AvatarOutline';

const EquipeCardIntervenant = ({ personne, types_profils }) => {
  const [openModalIntervenantProfil, setOpenModalIntervenantProfil] = useState(false);

  return (
    <Fragment>
      {openModalIntervenantProfil && (
        <ModalIntervenantProfil
          intervenant={personne}
          isOpen={openModalIntervenantProfil}
          close={() => setOpenModalIntervenantProfil(false)}
        />
      )}
      <button
        onClick={() => setOpenModalIntervenantProfil(true)}
        intervenant={personne}
        className={`card card-member${personne && !personne.isActive ? ' unactive' : ''}`}
      >
        {personne && personne.photo ? (
          <div className="member-avatar">
            <div className="avatar-mask">
              <img src={getProfilImgMin(personne.photo)} alt="photo" />
            </div>
            <AvatarMask nameFile="EquipeCard" />
            <AvatarOutline nameFile="EquipeCard" />
          </div>
        ) : (
          <div className="member-avatar">
            {personne && personne.isActive ? (
              <Fragment>
                <span>
                  {personne.prenom[0]}
                  {personne.nom[0]}
                </span>
                <AvatarOutline nameFile="EquipeCard" smaller />
              </Fragment>
            ) : (
              <Fragment>
                <i className="far fa-sync" />
                <AvatarOutline nameFile="EquipeCard" smaller isGrey />
              </Fragment>
            )}
          </div>
        )}
        <div className="card-infos card-member-infos">
          <div className="card-title card-member-name">
            {personne.prenom} {personne.nom}
          </div>
          <div className="card-subtitle card-member-profil">{types_profils[personne.profil]}</div>
        </div>
      </button>
    </Fragment>
  );
};

EquipeCardIntervenant.propTypes = {
  personne: PropTypes.object.isRequired
};

export default EquipeCardIntervenant;
