import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { getProfilImgMin } from '../../utils/methodes';
import ModalFamilleProfil from '../modals/ModalFamilleProfil';
import AvatarMask from '../../utils/AvatarMask';
import AvatarOutline from '../../utils/AvatarOutline';

const EquipeCard = ({ user, parents, enfant }) => {
  const [openModalProfil, setOpenModalProfil] = useState(false);
  console.log('value of user : ', user);
  return (
    <Fragment>
      {openModalProfil && (
        <ModalFamilleProfil
          user={user}
          isOpen={openModalProfil}
          enfant={enfant}
          close={() => setOpenModalProfil(false)}
        />
      )}
      <button
        onClick={() => setOpenModalProfil(true)}
        user={user}
        className={`card card-member card-member-family`}
      >
        <div className="member-avatar">
          {user && user.photo ? (
            <Fragment>
              <div className="avatar-mask">
                <img src={getProfilImgMin(user.photo)} alt="photo" />
              </div>
              <AvatarMask nameFile="EquipeCard" />
              <AvatarOutline nameFile="EquipeCard" />
            </Fragment>
          ) : (
            <Fragment>
              {user.prenom && user.nom ? (
                <Fragment>
                  <span>
                    {user.prenom && user.prenom[0]}
                    {user.nom && user.nom[0]}
                  </span>
                  <AvatarOutline nameFile="ModalProfil" />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="avatar-mask">
                    <i className="fas fa-user user-icon" />
                  </div>
                  <AvatarMask nameFile="ModalProfil" />
                  <AvatarOutline nameFile="ModalProfil" />
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
        <div className="card-infos card-member-infos">
          <div className="card-title card-member-name">
            {user.prenom} {user.nom}
          </div>
          <div className="card-subtitle card-member-profil">
            {user.pseudo} de {enfant.profil.prenom}
          </div>
        </div>
      </button>
    </Fragment>
  );
};

EquipeCard.propTypes = {
  user: PropTypes.object.isRequired
};

export default EquipeCard;
