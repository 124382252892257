import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const CREATE_MESSAGE_REQUEST = 'ori/CREATE_MESSAGE_REQUEST';
const CREATE_MESSAGE_SUCCESS = 'ori/CREATE_MESSAGE_SUCCESS';
const CREATE_MESSAGE_FAILURE = 'ori/CREATE_MESSAGE_FAILURE';

const GET_MESSAGES_REQUEST = 'ori/GET_MESSAGES_REQUEST';
const GET_MESSAGES_SUCCESS = 'ori/GET_MESSAGES_SUCCESS';
const GET_MESSAGES_FAILURE = 'ori/GET_MESSAGES_FAILURE';

const DELETE_MESSAGE_REQUEST = 'ori/DELETE_MESSAGE_REQUEST';
const DELETE_MESSAGE_SUCCESS = 'ori/DELETE_MESSAGE_SUCCESS';
const DELETE_MESSAGE_FAILURE = 'ori/DELETE_MESSAGE_FAILURE';

const UPDATE_MESSAGE_REQUEST = 'ori/UPDATE_MESSAGE_REQUEST';
const UPDATE_MESSAGE_SUCCESS = 'ori/UPDATE_MESSAGE_SUCCESS';
const UPDATE_MESSAGE_FAILURE = 'ori/UPDATE_MESSAGE_FAILURE';

export const messagesActionsHandlers = {
  [GET_MESSAGES_SUCCESS]: (state, action) =>
    flow(
      set('entities.messages', action.response.entities.messages || {}),
      set('loaded.messages', true),
      set('loading.messages', false)
    )(state),

  [CREATE_MESSAGE_SUCCESS]: (state, action) => {
    return flow(
      set('entities.messages', {
        ...state.entities.messages,
        ...action.response.entities.messages
      }),
      set('loaded.messages', true),
      set('loading.messages', false)
    )(state);
  },
  [DELETE_MESSAGE_SUCCESS]: (state, action) =>
    flow(set('entities.messages', omit(state.entities.messages, action.message.message_id)))(state),

  [UPDATE_MESSAGE_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.messages.${action.message.message_id}`, {
        ...action.message,
        ...action.response
      }),
      set('loaded.messages', true),
      set('loading.messages', false)
    )(state);
  }
};

export function createMessage(data) {
  return {
    [CALL_API]: {
      types: [CREATE_MESSAGE_REQUEST, CREATE_MESSAGE_SUCCESS, CREATE_MESSAGE_FAILURE],
      method: 'POST',
      endpoint: '/messages',
      body: data,
      schema: Schemas.MESSAGE
    }
  };
}

export function getMessages(enfantId, search, type) {
  return {
    enfantId,
    search,
    type,
    [CALL_API]: {
      types: [GET_MESSAGES_REQUEST, GET_MESSAGES_SUCCESS, GET_MESSAGES_FAILURE],
      method: 'GET',
      endpoint: `/messages/${enfantId}/${search}/${type}`,
      schema: Schemas.MESSAGE_ARRAY,
      successMessage: 'Messages chargés avec succès'
    }
  };
}

export function deleteMessage(message) {
  return {
    message,
    [CALL_API]: {
      types: [DELETE_MESSAGE_REQUEST, DELETE_MESSAGE_SUCCESS, DELETE_MESSAGE_FAILURE],
      method: 'DELETE',
      body: message,
      endpoint: `/messages/${message.message_id}`,
      successMessage: 'Le message a bien été supprimé'
    }
  };
}

export function updateMessage(message, data) {
  return {
    message,
    [CALL_API]: {
      types: [UPDATE_MESSAGE_REQUEST, UPDATE_MESSAGE_SUCCESS, UPDATE_MESSAGE_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/messages/${message.message_id}`,
      successMessage: 'Le message a bien été modifié',
      successNext: [state => getMessages(message.enfant_id)]
    }
  };
}
