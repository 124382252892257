import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { TYPES_DOCUMENTS } from '../../constants/Properties';
import { getProfilImgMin, isDroitUser, sortObjectByKeys } from '../../utils/methodes';

import { connect } from 'react-redux';
import { getDocumentsMedias } from '../../store/modules/documentsMedias';

import BaseLayout from '../shared/BaseLayout';

import { map, filter, orderBy, groupBy, upperFirst, includes, union, find, isEmpty } from 'lodash';
import moment from 'moment';

import Select from 'react-select';
import MonthSelector from '../../components/shared/MonthSelector';
import AvatarMask from '../../utils/AvatarMask';
import AvatarOutline from '../../utils/AvatarOutline';

const DossiersPage = ({
  startDateProps,
  endDateProps,
  user,
  getDocumentsMedias,
  dossiersProps,
  enfantSelected,
  intervenants,
  types_profils
}) => {
  const [startDate, setStartDate] = useState(
    startDateProps ||
      moment()
        .startOf('month')
        .toDate()
  );
  const [endDate, setEndate] = useState(
    endDateProps ||
      moment()
        .endOf('month')
        .toDate()
  );

  const [intervenantSelected, setIntervenantSelected] = useState('TOUS');

  const [dossiersFiltred, setDossiersFiltred] = useState({});
  const [typeDocumentSelected, setTypeDocumentSelected] = useState('TOUS');

  const [optionsDocuments, setOptionsDocuments] = useState(
    union(
      map(TYPES_DOCUMENTS, (label, value) => {
        return {
          label: label,
          value: value
        };
      }),
      [{ value: 'TOUS', label: 'Tous les types' }]
    )
  );

  useEffect(() => {
    if (enfantSelected) {
      getDocumentsMedias(user.profil, user.user_id, enfantSelected.enfant_id);
    }
  }, []);

  useEffect(() => {
    setDossiersFiltred(
      sortObjectByKeys(
        groupBy(
          map(
            filter(dossiersProps, e =>
              moment(e.date).isBetween(
                moment(startDate).startOf('day'),
                moment(endDate).endOf('day')
              )
            ),
            e => {
              return { ...e, day: moment(e.date).format('YYYY-MM') };
            }
          ),
          'day'
        )
      ),
      'desc'
    );
  }, [dossiersProps]);

  useEffect(() => {
    onChangeFilter();
  }, [startDate, endDate, intervenantSelected, typeDocumentSelected]);

  const [optionsIntervenants, setOptionsIntervenants] = useState(
    union(
      map(
        filter(intervenants, i => i.isActive === true),
        intervenant => {
          return {
            label: `${intervenant.prenom} ${intervenant.nom}`,
            value: intervenant.user_id
          };
        }
      ),
      [{ value: 'TOUS', label: 'Tous' }]
    )
  );

  useEffect(() => {
    setOptionsIntervenants(
      union(
        map(
          filter(intervenants, i => i.isActive === true),
          intervenant => {
            return {
              label: `${intervenant.prenom} ${intervenant.nom}`,
              value: intervenant.user_id
            };
          }
        ),
        [{ value: 'TOUS', label: 'Tous' }]
      )
    );
  }, [intervenants]);

  const renderConditionFiltre = () => {
    if (intervenantSelected === 'TOUS' && typeDocumentSelected === 'TOUS') {
      return filter(dossiersProps, d =>
        moment(d.date).isBetween(moment(startDate).startOf('day'), moment(endDate).endOf('day'))
      );
    } else {
      if (typeDocumentSelected !== 'TOUS' && intervenantSelected !== 'TOUS') {
        return filter(
          dossiersProps,
          d =>
            moment(d.date).isBetween(
              moment(startDate).startOf('day'),
              moment(endDate).endOf('day')
            ) &&
            d.type_document === typeDocumentSelected &&
            d.user_id === intervenantSelected
        );
      } else {
        if (intervenantSelected !== 'TOUS') {
          return filter(
            dossiersProps,
            d =>
              moment(d.date).isBetween(
                moment(startDate).startOf('day'),
                moment(endDate).endOf('day')
              ) && d.user_id === intervenantSelected
          );
        }
        if (typeDocumentSelected !== 'TOUS') {
          return filter(
            dossiersProps,
            d =>
              moment(d.date).isBetween(
                moment(startDate).startOf('day'),
                moment(endDate).endOf('day')
              ) && d.type_document === typeDocumentSelected
          );
        }
      }
    }
  };

  const onChangeFilter = () => {
    if (!isEmpty(dossiersProps)) {
      setDossiersFiltred(
        sortObjectByKeys(
          groupBy(
            orderBy(
              map(renderConditionFiltre(), d => {
                return {
                  ...d,
                  day: moment(d.date).format('YYYY-MM')
                };
              }),
              ['day'],
              ['desc']
            ),

            'day'
          ),
          'desc'
        )
      );
    }
  };

  const prevMonth = () => {
    setStartDate(
      moment(startDate)
        .subtract(1, 'months')
        .startOf('month')
        .toDate()
    );
    setEndate(
      moment(endDate)
        .subtract(1, 'months')
        .endOf('month')
        .toDate()
    );
  };

  const nextMonth = () => {
    setStartDate(
      moment(startDate)
        .add(1, 'months')
        .startOf('month')
        .toDate()
    );
    setEndate(
      moment(endDate)
        .add(1, 'months')
        .endOf('month')
        .toDate()
    );
  };
  return (
    <BaseLayout homeBtn isPage withSubmenu noFooter headerPageTitle="Dossiers de prise en charge">
      <div className="main-container">
        <div className="subheader">
          <div className="subheader-nav">
            <Select
              className="react-select"
              classNamePrefix="react-select"
              options={optionsIntervenants}
              isSearchable={false}
              isClearable={false}
              defaultValue={intervenantSelected}
              value={find(optionsIntervenants, o => o.value === intervenantSelected)}
              onChange={option => {
                setIntervenantSelected(option.value);
              }}
            />
            <Select
              className="react-select"
              classNamePrefix="react-select"
              options={optionsDocuments}
              isSearchable={false}
              isClearable={false}
              defaultValue={typeDocumentSelected}
              value={find(optionsDocuments, o => o.value === typeDocumentSelected)}
              onChange={option => {
                setTypeDocumentSelected(option.value);
              }}
            />
          </div>
          <div className="subheader-bkg">
            <svg width="0" height="0">
              <defs>
                <clipPath
                  id="submenumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01428571428)"
                >
                  <path
                    d="M361.2,70L23.8,64.3c-8.6-0.2-17-3-23.8-8.3V0h375v67.9
              c-4.2,1.5-8.6,2.1-13.1,2.1H361.2z"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <MonthSelector
          month={moment(startDate).format('MMMM yyyy')}
          nextMonth={() => nextMonth()}
          prevMonth={() => prevMonth()}
        />
        <div className="content">
          {dossiersFiltred && !isEmpty(dossiersFiltred) ? (
            <div className="liste">
              {map(dossiersFiltred, (dossiers, day) => (
                <Fragment key={day}>
                  {map(orderBy(dossiers, ['date'], ['desc']), dossier => {
                    return (
                      <Link
                        className="card card-event"
                        key={dossier.document_id}
                        to={{
                          pathname: `/dossier-prise-en-charge/${dossier.document_id}`,
                          state: { startDate: startDate, endDate: endDate }
                        }}
                      >
                        <div className="card-header event-schedule">
                          <span className="event-start">
                            {upperFirst(moment(dossier.date).format('dddd D'))}
                          </span>
                          <div className="element-tag card-tag">
                            {TYPES_DOCUMENTS[dossier.type_document] +
                              (dossier && dossier.type_document === 'AUTRE'
                                ? ' - ' + (dossier && dossier.libelle_autre)
                                : '')}
                          </div>
                        </div>
                        <div className="flexbox alignCenter">
                          {dossier.user && dossier.user.photo ? (
                            <div className="member-avatar">
                              <div className="avatar-mask">
                                <img src={getProfilImgMin(dossier.user.photo)} alt="photo" />
                              </div>
                              <AvatarMask nameFile="DossiersPage" />
                              <AvatarOutline nameFile="DossiersPage" />
                            </div>
                          ) : (
                            <div className="member-avatar">
                              {dossier.user_id !== dossier.famille_id ? (
                                dossier.user && (
                                  <span>
                                    {dossier.user.prenom[0]}
                                    {dossier.user.nom[0]}
                                  </span>
                                )
                              ) : (
                                <i className="fas fa-users fa-family" />
                              )}
                              <AvatarOutline nameFile="DossiersPage" />
                            </div>
                          )}
                          <div className="card-infos">
                            <div className="card-title">{dossier.titre}</div>

                            {dossier.user_id !== dossier.famille_id ? (
                              dossier.user && (
                                <div className="card-subtitle">
                                  {dossier.user.prenom} [{types_profils[dossier.user.profil]}]
                                </div>
                              )
                            ) : (
                              <div className="card-subtitle">{`Famille ${user.nom}`}</div>
                            )}
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </Fragment>
              ))}
            </div>
          ) : (
            <div className="form-message">
              <p>
                Glissez ici et consultez les différents dossiers de prise en charge lorsqu'ils
                auront été ajoutés par l'équipe
              </p>
            </div>
          )}
          {(user.profil === 'FAMILLE' ||
            user.profil === 'STRUCTURE' ||
            includes(
              ['ADMINISTRATION', 'MODIFICATION'],
              isDroitUser('dossier_prise_charge', user, enfantSelected)
            )) && (
            <div className="bottom-sticky bottom-btn-add">
              <NavLink className="btn-icon" to="/dossiers-prise-en-charge/ajouter">
                <i className="fas fa-plus" />
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

DossiersPage.propTypes = {
  user: PropTypes.object.isRequired,
  getDocumentsMedias: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
  startDateProps: props.location && props.location.state && props.location.state.startDate,
  endDateProps: props.location && props.location.state && props.location.state.endDate,
  user: state.auth.user,
  enfantSelected: state.auth.enfantSelected,
  intervenants: state.data.entities.intervenants,
  dossiersProps:
    (state.data.entities.documentsMedias &&
      filter(
        state.data.entities.documentsMedias,
        d =>
          (d.type === 'dossier-prise-en-charge') &
          (d.enfant_id === state.auth.enfantSelected.enfant_id)
      )) ||
    [],
  types_profils:
    state.data.entities.settings &&
    state.data.entities.settings['types_profils'] &&
    state.data.entities.settings['types_profils'].data
});

export default connect(mapStateToProps, { getDocumentsMedias })(DossiersPage);
