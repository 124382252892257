import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createEvent, updateEvent } from '../../store/modules/events';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import { Form, Field } from 'react-final-form';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
import MomentUtils from '@date-io/moment';

import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import materialTheme from '../../../assets/stylesheets/materialThemeDatePicker';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date)
      .locale('fr')
      .format('DD MMMM YYYY');
  }
}

import {
  orderBy,
  map,
  find,
  values,
  isEmpty,
  union,
  isNull,
  filter,
  compact,
  omit,
  upperFirst
} from 'lodash';

import Select from 'react-select';

import InputSearchAddress from '../shared/InputSearchAddress';

const ModalAddUpdatePlanning = ({
  isEdit,
  enfant,
  event,
  user,
  intervenants,
  updateEvent,
  createEvent,
  types_profils
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [adresseInput, setAdresseInput] = useState(null);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [intervenantsState, setIntervenantsState] = useState(
    intervenants && filter(intervenants, i => i.isActive === true)
  );
  const actualDate = new Date();
  const closeModal = () => {
    setIsOpen(false);
    setError(null);
  };
  const required = value => (value ? undefined : 'Champ requis !');
  const requiredTime = value => (value && value !== '00:00' ? undefined : 'Champ requis !');

  const optionsIntervenants =
    intervenantsState.length === 0
      ? [{ value: 'AUCUN', label: 'Aucun contact' }]
      : union(
          map(intervenantsState, (item, key) => {
            return {
              value: item.user_id,
              label: `${item.prenom} ${item.nom} [${types_profils[item.profil]}]`
            };
          }),
          [{ value: 'AUCUN', label: 'Aucun contact' }]
        );
  const optionsIntervenantsAdresse = compact(
    union(
      map(intervenantsState, (item, key) => {
        return {
          value: item.user_id,
          label: `Chez ${upperFirst(item.nom)} [${types_profils[item.profil]}]`
        };
      })
    )
  );

  const optionsLieu = find(optionsIntervenantsAdresse, intervenant => intervenant.value !== 'AUCUN')
    ? union(
        [
          { value: 'UNKNOWN_ADDRESS', label: "Pas d'adresse connue" },
          { value: 'OTHER', label: 'Autre adresse' }
        ],
        orderBy(optionsIntervenantsAdresse, ['label'], ['asc'])
      )
    : [
        { value: 'UNKNOWN_ADDRESS', label: "Pas d'adresse connue" },
        { value: 'OTHER', label: 'Autre adresse' }
      ];

  const handleSubmit = values => {
    const data = values;
    // calcul du total heures :

    var duration = moment.duration(moment(data.heure_fin).diff(moment(data.heure_debut)));
    var hours = duration.asHours();

    data.totalHeures = hours.toFixed(2);
    if (isEdit) {
      return updateEvent(event, data)
        .then(() => setIsOpen(false))
        .catch(err => setError(err));
    } else {
      data.type = 'planing_agenda';
      data.user_id = user.user_id;
      data.enfant_id = enfant.enfant_id;
      data.famille_id = user.userIdPrincipal ? user.userIdPrincipal : user.user_id; // c'est le cas ou c'est le compte secondaire
      data.longitude = longitude || null;
      data.latitude = latitude || null;
      return createEvent(data)
        .then(() => setIsOpen(false))
        .catch(err => setError(err));
    }
  };

  return (
    <Fragment>
      {isEdit ? (
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          className="header-btn"
        >
          <i className="fas fa-pencil" />
        </div>
      ) : (
        <div className="bottom-sticky bottom-btn-add">
          <div onClick={() => setIsOpen(true)} className="btn-icon">
            <i className="fas fa-plus" />
          </div>
        </div>
      )}

      <Modal {...modalDefaultClass} id="modal-confirm" isOpen={isOpen}>
        <header className="header">
          <div className="modal-title">
            {(isEdit && 'Modifier un événement') || 'Ajouter un événement'}
          </div>
          <div className="header-bkg">
            <img className="oiseau" src={require(`../../../assets/images/oiseau.svg`)} />
            <img className="hexa" src={require(`../../../assets/images/hexa-orange.svg`)} />
            <svg height="0px" width="0px">
              <defs>
                <clipPath
                  id="menumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01666666666)"
                >
                  <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </header>

        <Form
          mutators={{
            putAddress: (args, state, utils) => {
              utils.changeValue(state, 'adresse', () => args[0]);
            }
          }}
          onSubmit={handleSubmit}
          initialValues={
            isEdit
              ? omit(event, '_id')
              : { date: actualDate, heure_debut: actualDate, heure_fin: actualDate }
          }
          initialValuesEqual={() => true}
          render={({ form, handleSubmit, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <Field name="titre" validate={required} component="input" className="field">
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" />
                        <label>Titre *</label>
                      </div>
                    )}
                  </Field>
                  <div className="separator" />
                  <Field name="date" component="input" className="field" validate={required}>
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={LocalizedUtils}
                          locale={'fr'}
                        >
                          <ThemeProvider theme={materialTheme}>
                            <KeyboardDatePicker
                              className="datepicker-mui"
                              autoOk
                              placeholder="jj/mm/aaaa"
                              okLabel={null}
                              clearLabel={null}
                              clearable={false}
                              cancelLabel={null}
                              value={input.value}
                              onChange={date => {
                                input.onChange(date);
                              }}
                              format="DD/MM/YYYY"
                              InputAdornmentProps={{ position: 'start' }}
                              inputVariant="outlined"
                              minDate={new Date()}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        <label>Date *</label>
                      </div>
                    )}
                  </Field>
                  <div className="grid-noBottom">
                    <div className="col">
                      <Field
                        name="heure_debut"
                        component="input"
                        className="field"
                        validate={requiredTime}
                      >
                        {({ input, meta }) => (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}

                            <label>Début *</label>
                            <MuiPickersUtilsProvider
                              libInstance={moment}
                              utils={LocalizedUtils}
                              locale={'fr'}
                            >
                              <KeyboardTimePicker
                                className="datepicker-mui timepicker-mui"
                                InputAdornmentProps={{ position: 'start' }}
                                inputVariant="outlined"
                                placeholder="00:00"
                                okLabel={null}
                                invalidDateMessage={null}
                                clearLabel={null}
                                ampm={false}
                                clearable={false}
                                cancelLabel={null}
                                mask="__:__"
                                autoOk
                                value={input.value}
                                onChange={value => input.onChange(value)}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col">
                      <Field
                        name="heure_fin"
                        component="input"
                        className="field"
                        validate={requiredTime}
                      >
                        {({ input, meta }) => {
                          return (
                            <div className="field">
                              {meta.error && meta.touched && (
                                <div className="field-error">{meta.error}</div>
                              )}
                              <label>Fin *</label>
                              <MuiPickersUtilsProvider
                                libInstance={moment}
                                utils={LocalizedUtils}
                                locale={'fr'}
                              >
                                <KeyboardTimePicker
                                  className="datepicker-mui timepicker-mui"
                                  InputAdornmentProps={{ position: 'start' }}
                                  inputVariant="outlined"
                                  okLabel={null}
                                  invalidDateMessage={null}
                                  clearLabel={null}
                                  ampm={false}
                                  clearable={false}
                                  cancelLabel={null}
                                  mask="__:__"
                                  autoOk
                                  value={input.value}
                                  onChange={(value, string) => {
                                    return input.onChange(value);
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                  <div className="separator" />
                  <Field name="contact" component="select">
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Sélectionner un contact"
                            options={orderBy(optionsIntervenants, ['label'], ['asc'])}
                            onChange={option => {
                              input.onChange(option.value);
                            }}
                            value={find(optionsIntervenants, o => o.value === input.value)}
                          />
                          <label>Contact</label>
                        </div>
                      );
                    }}
                  </Field>
                  <div className="separator" />
                  <Field name="lieu" component="select">
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Sélectionner"
                            options={optionsLieu}
                            onChange={option => {
                              // Quand on change le lieu, il faut mettre à jour l'adresse en fonction du choix qu'on a fait

                              // si c'est un intervenant, verifier qu'il est adresse sinon on met rien
                              if (
                                option.value &&
                                option.value !== 'OTHER' &&
                                find(intervenantsState, o => o.user_id === option.value)
                              ) {
                                // c'est un intervenant verifier si il a un adresse ou sinon mettre null
                                if (
                                  find(intervenantsState, o => o.user_id === option.value).adresse
                                ) {
                                  form.mutators.putAddress(
                                    find(intervenantsState, o => o.user_id === option.value).adresse
                                  );
                                } else {
                                  form.mutators.putAddress(null);
                                }
                              } else {
                                // si ce n'est pas un intervenant clear le champ adresse à null
                                form.mutators.putAddress(null);
                              }
                              input.onChange(option.value);
                            }}
                            value={find(optionsLieu, o => o.value === input.value)}
                          />
                          <label>Lieu</label>
                        </div>
                      );
                    }}
                  </Field>
                  {/* #5704 cas d'autre adresse, utilisateur dois renseigner une adresse*/}
                  {values && values.lieu && values.lieu === 'OTHER' && (
                    <Field
                      name="adresse"
                      className="field"
                      initialValue={isEdit && event.adresse}
                      validate={values.lieu && values.lieu === 'OTHER' ? required : null}
                    >
                      {({ input, meta }) => (
                        <div className="field" onChange={e => setAdresseInput(e.target.value)}>
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          {isEmpty(input.value) && adresseInput && adresseInput.length > 3 && (
                            <div className="field-error">
                              Veuillez sélectionner une proposition , dans la liste !{' '}
                            </div>
                          )}

                          <InputSearchAddress
                            onChangeInputProps={value => {
                              input.onChange(value);
                            }}
                            defaultValue={values && values.adresse}
                            classInput="input-adresse"
                            onChangePositionProps={position => {
                              if (!isNull(position)) {
                                setLatitude(position[0]);
                                setLongitude(position[1]);
                              }
                            }}
                          />
                          <label>Adresse {values.lieu === 'OTHER' && '*'}</label>
                        </div>
                      )}
                    </Field>
                  )}
                  {/* #5704 cas si l'utilitaseur a choisie un intervenant, afficher son adresse grisé si présente*/}
                  {values &&
                    values.lieu &&
                    values.lieu !== 'OTHER' &&
                    find(intervenantsState, o => o.user_id === values.lieu) &&
                    find(intervenantsState, o => o.user_id === values.lieu).adresse && (
                      <Field
                        name="adresse"
                        className="field"
                        initialValue={
                          find(intervenantsState, o => o.user_id === values.lieu).adresse
                        }
                      >
                        {({ input, meta }) => (
                          <div className="field">
                            <input
                              disabled={true}
                              type="text"
                              value={
                                find(intervenantsState, o => o.user_id === values.lieu).adresse
                              }
                            />
                            <label>Adresse</label>
                          </div>
                        )}
                      </Field>
                    )}
                  <Field
                    name="description"
                    component="input"
                    className="field"
                    initialValue={(isEdit && event && event.description) || ''}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <textarea {...input} type="text" rows="4" />
                        <label>Description</label>
                      </div>
                    )}
                  </Field>
                  {error && (
                    <div className="error-block">
                      <span className="error-icon">
                        <i className="fas fa-exclamation" />
                      </span>
                      <div className="error-message">{error}</div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <div className="btn-group center">
                    <button className="btn btn-tertiary" onClick={() => closeModal()}>
                      Annuler
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Enregistrer
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </Modal>
    </Fragment>
  );
};

ModalAddUpdatePlanning.propTypes = {
  user: PropTypes.object.isRequired,
  updateEvent: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  user: state.auth.user,
  enfant: state.auth.enfantSelected || {},
  intervenants: state.data.entities.intervenants || [],
  enfantSelected: state.auth.enfantSelected || {},

  types_profils:
    state.data.entities.settings &&
    state.data.entities.settings['types_profils'] &&
    state.data.entities.settings['types_profils'].data
});

export default connect(mapStateToProps, {
  createEvent,
  updateEvent
})(ModalAddUpdatePlanning);
