import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { loadSettings } from '../store/modules/settings';
import { createEnfant, getEnfants, deleteEnfant } from '../store/modules/enfants';
import { changeEnfant, loadUser } from '../store/modules/auth';
import { getParents } from '../store/modules/parents';
import { getEvents } from '../store/modules/events';

import { getPointages, getPointagesIntervenant } from '../store/modules/pointages';
import { getIntervenants } from '../store/modules/intervenants';
import { getUsersSecondaireFamille } from '../store/modules/users';

import moment from 'moment';
import Modal from 'react-modal';
import modalDefaultClass from '../utils/ModalDefaultClass';

import { API_URL } from '../constants/Config';
import BaseLayout from './shared/BaseLayout';
import ModalInfo from '../components/modals/ModalInfo';

import { map, isEmpty, values, isNull } from 'lodash';
import AvatarMask from '../utils/AvatarMask';
import { getProfilImgMin } from '../utils/methodes';

import AvatarOutline from '../utils/AvatarOutline';

const SelectAddDeleteEnfants = ({
  user,
  enfants,
  changeEnfant,
  deleteEnfant,
  createEnfant,
  enfantSelected,
  loadSettings,
  loadUser,
  getParents,
  getEvents,
  getEnfants,
  getPointages,
  getPointagesIntervenant,
  getUsersSecondaireFamille,
  getIntervenants
}) => {
  const navigation = useHistory();
  const [modalDelete, setModalDelete] = useState(false);
  const [modalBienvenue, setModalBienvenue] = useState(false);
  const [allowToAddEnfant, setAllowToAddEnfant] = useState(false);

  useEffect(() => {
    loadSettings();
    loadUser(user.user_id);

    if (user.enfants && !isEmpty(user.enfants)) {
      getEnfants(user.enfants);
    }

    if (user.profil === 'FAMILLE') {
      getUsersSecondaireFamille(user.userIdPrincipal ? user.userIdPrincipal : user.user_id);
    }

    if (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') {
      getEvents(user.userIdPrincipal ? user.userIdPrincipal : user.user_id);
    } else {
      if (!isNull(enfantSelected)) {
        getEvents(enfantSelected.parent_id);
      }
    }

    if (user && user.firstConnexion && (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE')) {
      // TODO ALEX
      setModalBienvenue(true);
    }

    // regarder si l'user peut ajouter un enfant :
    if (user && user.profil === 'FAMILLE') {
      // pas plus de 2 enfants :
      if (user && user.enfants && user.enfants.length >= 2) {
        setAllowToAddEnfant(false);
      } else {
        setAllowToAddEnfant(true);
      }
    }

    if (user && user.profil === 'STRUCTURE') {
      // pas plus des enfants dans l'abbo:
      if (user && user.enfants && user.enfants.length >= Number(user.taille)) {
        setAllowToAddEnfant(false);
      } else {
        setAllowToAddEnfant(true);
      }
    }
  }, []);
  useEffect(() => {
    // on reverifi si il peut ajouter un enfant

    // regarder si l'user peut ajouter un enfant :
    if (user && user.profil === 'FAMILLE') {
      // pas plus de 2 enfants :
      if (user && user.enfants && user.enfants.length >= 2) {
        setAllowToAddEnfant(false);
      } else {
        setAllowToAddEnfant(true);
      }
    }

    if (user && user.profil === 'STRUCTURE') {
      // pas plus des enfants dans l'abbo:
      if (user && user.enfants && user.enfants.length >= Number(user.taille)) {
        setAllowToAddEnfant(false);
      } else {
        setAllowToAddEnfant(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (enfantSelected) {
      if (user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') {
        getPointages(user.userIdPrincipal ? user.userIdPrincipal : user.user_id);
      } else {
        getPointagesIntervenant(user.user_id, enfantSelected.enfant_id);
      }

      getEvents(enfantSelected.parent_id);
      // const parentsIds = map(enfants, e => e.parent_id);
      // getParents(parentsIds);
    }
  }, []);

  // useEffect(() => {
  //   if (user.profil !== 'FAMILLE' || user.profil !== 'STRUCTURE') {
  //     const parentsIds = map(enfants, e => e.parent_id);
  //     getParents(parentsIds);
  //   }
  // }, []);

  const confirmMethode = () => {
    return navigation.push('/mes-infos-personnelles');
  };

  const selectEnfant = enfant => {
    Promise.resolve()
      .then(() => {
        return changeEnfant(enfant);
      })
      .then(() => {
        const parentsIds = enfant.parent_id;
        return getParents([parentsIds]);
      })
      .then(() => {
        return getIntervenants(enfant.enfant_id);
      })
      .then(() => {
        return navigation.push(`/dashboard`);
      });
  };

  const delEnfant = enfant => {
    return deleteEnfant(enfant, user)
      .then(() => {
        if (enfantSelected.enfant_id === enfant.enfant_id) {
          changeEnfant(null);
        }
        setModalDelete(false);
      })
      .catch(() => {
        setModalDelete(false);
      });
  };

  const createNewEnfant = () => {
    let enfant;

    return createEnfant({
      parent_id: user.user_id,
      equipe: [],
      profil: {},
      sante: {},
      autorisations: []
    })
      .then(res => {
        enfant = values(res.response.entities.enfants)[0];
      })
      .then(() => {
        return changeEnfant(enfant);
      })

      .then(() => {
        navigation.push(`/mon-enfant/${enfant.enfant_id}`, { isPrevSelectEnfant: true });
      });
  };

  return (
    <BaseLayout isPage displayTopHeader noFooter headerPageTitle="Accédez au profil d'un enfant">
      <div className="main-container">
        <ModalInfo
          isOpen={modalBienvenue}
          title="Bienvenue sur l'appli Origin'ailes"
          text={`Dans un premier temps nous vous invitons à renseigner les informations de votre compte${
            user && user.profil && user.profil === 'FAMILLE' ? ' puis celles de votre enfant' : ''
          }`}
          confirmText="Ok"
          confirmMethode={() => confirmMethode()}
          closeModal={() => setModalBienvenue(false)}
        />

        <div className={`content ${enfants && !isEmpty(enfants) ? '' : 'flexbox justifyCenter'}`}>
          {enfants && !isEmpty(enfants) ? (
            <Fragment>
              {map(enfants, enfant => (
                <Fragment key={enfant.enfant_id}>
                  <Modal
                    className="modal-content small-modal"
                    overlayClassName="modal-overlay"
                    id="modal-confirm"
                    isOpen={
                      modalDelete &&
                      modalDelete.enfant_id === enfant.enfant_id &&
                      modalDelete.isOpen
                    }
                  >
                    <div className="modal-body">
                      <div className="message">
                        Êtes-vous sûr de vouloir supprimer
                        <br />
                        le profil de&nbsp;
                        {`"${(enfant && enfant.profil && enfant.profil.prenom) || ''} ${(enfant &&
                          enfant.profil &&
                          enfant.profil.nom) ||
                          ''}"`}
                        &nbsp;?
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="btn-group center">
                        <button onClick={() => setModalDelete(false)} className="btn btn-secondary">
                          <span>Annuler</span>
                        </button>
                        <button onClick={() => delEnfant(enfant)} className="btn btn-primary">
                          <span> Confirmer</span>
                        </button>
                      </div>
                    </div>
                  </Modal>
                  <div
                    onClick={() => selectEnfant(enfant)}
                    key={enfant.enfant_id}
                    className="card card-member card-enfant"
                  >
                    <div className="member-avatar">
                      <div className="avatar-mask">
                        {enfant && enfant.profil && enfant.profil.photo ? (
                          <img src={getProfilImgMin(enfant.profil.photo)} alt="photo" />
                        ) : (
                          <i className="fas fa-user user-icon" />
                        )}
                      </div>
                      <AvatarMask nameFile="SelectAddDeleteEnfants" />
                      <AvatarOutline nameFile="SelectAddDeleteEnfants" />
                    </div>

                    <div className="card-infos card-member-infos">
                      <div className="card-title card-member-name">
                        {enfant && enfant.profil && enfant.profil.prenom}{' '}
                        {enfant && enfant.profil && enfant.profil.nom}
                      </div>
                      {enfant && enfant.profil && enfant.profil.date_naissance && (
                        <div className="card-subtitle card-member-profil">
                          <div className="member-profil">{`${moment(new Date()).diff(
                            moment(enfant.profil.date_naissance),
                            'years'
                          )} ans`}</div>
                        </div>
                      )}
                    </div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        setModalDelete({ enfant_id: enfant.enfant_id, isOpen: true });
                      }}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 30,
                        width: 30,
                        borderRadius: '90%',
                        backgroundColor: '#ff3b00',
                        color: 'white'
                      }}
                    >
                      <i className="fas fa-trash" />
                    </div>
                  </div>
                </Fragment>
              ))}
            </Fragment>
          ) : (
            <div className="intro">
              {user.profil != 'STRUCTURE' && user.profil != 'FAMILLE' ? (
                <Fragment>
                  <div className="intro-message">
                    Notre application vous intéresse, vous voulez en savoir plus ?
                  </div>
                  <a
                    className="btn btn-primary"
                    href="mailto:contact@originailes.fr"
                    target="_blank"
                  >
                    Contactez-nous
                  </a>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="intro-message">Vous n'avez pas encore ajouté d'enfant</div>

                  <div className="btn btn-primary" onClick={() => createNewEnfant()}>
                    <i className="fas fa-user-plus"></i>
                    <span>Ajouter un enfant</span>
                  </div>
                </Fragment>
              )}
            </div>
          )}
          {user &&
            enfants &&
            !isEmpty(enfants) &&
            !user.userIdPrincipal &&
            (user.profil === 'STRUCTURE' || user.profil === 'FAMILLE') && (
              <div className="btn-group flexColumn center mt-20">
                <button
                  disabled={!allowToAddEnfant}
                  className="btn btn-primary"
                  onClick={() => createNewEnfant()}
                >
                  <i className="fas fa-user-plus"></i>
                  <span>Ajouter un enfant</span>
                </button>
                {!allowToAddEnfant && (
                  <div className="btn-legend">
                    {user && user.profil === 'FAMILLE'
                      ? 'Vous avez atteint la limite maximum de profils Enfant'
                      : 'Vous avez atteint votre quota. Si vous le souhaitez, vous pouvez modifier votre abonnement'}
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = (state, props) => {
  return {
    enfantSelected: state.auth.enfantSelected,
    enfants: state.data.entities.enfants,
    user: state.auth.user
  };
};

export default connect(mapStateToProps, {
  getIntervenants,
  loadSettings,
  changeEnfant,
  createEnfant,
  deleteEnfant,
  getEnfants,
  loadUser,
  getParents,
  getEvents,
  getPointages,
  getPointagesIntervenant,
  getUsersSecondaireFamille
})(SelectAddDeleteEnfants);
