import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { API_URL } from '../../../constants/Config';
import { connect } from 'react-redux';
import { removeFile, uploadFiles } from '../../../store/modules/uploads';
import { createDocumentMedia, deleteDocumentMedia } from '../../../store/modules/documentsMedias';
import { getProfilImgMin } from '../../../utils/methodes';
import Modal from 'react-modal';
import modalDefaultClass from '../../../utils/ModalDefaultClass';
import ModalAddUpdateAutorisation from './ModalAddUpdateAutorisation';
import ModalConfirmDelete from '../ModalConfirmDelete';
import AvatarMask from '../../../utils/AvatarMask';
import AvatarOutline from '../../../utils/AvatarOutline';

const ModalAutorisation = ({
  autorisation,
  enfant,
  user,
  intervenants,
  deleteDocumentMedia,
  removeFile,
  types_profils
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const deleteDoc = () => {
    return deleteDocumentMedia(autorisation)
      .then(() => {
        removeFile(autorisation.file)
          .then(() => {
            setIsOpen(false);
          })
          .catch(err => {
            setError(err);
          });
      })
      .catch(err => {
        setError(err);
      });
  };

  const closeModal = () => {
    setIsOpen(false);
    setError(null);
  };
  const intervenant = intervenants && intervenants[autorisation.user_id];
  return (
    <Fragment>
      <div onClick={() => setIsOpen(true)} className="card card-link">
        <div className="card-infos">
          <div className="card-title">{autorisation.titre}</div>
          {(user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') && (
            <div className="card-subtitle">
              {intervenant && intervenant.prenom}{' '}
              {intervenant && `[${types_profils[intervenant.profil]}]`}
            </div>
          )}
        </div>
      </div>

      <Modal {...modalDefaultClass} id="modal-autorisation" isOpen={isOpen}>
        <header className="header">
          <div className="header-right">
            {(user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') && (
              <ModalAddUpdateAutorisation
                isEdit
                autorisation={autorisation}
                enfant={enfant}
                intervenants={intervenants}
              />
            )}
            {(user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') && (
              <div>
                <button className="header-btn" onClick={() => setOpenModalDelete(true)}>
                  <i className="fas fa-trash-alt" />
                </button>
                <ModalConfirmDelete
                  message={'Êtes-vous sûr de vouloir supprimer cette autorisation ?'}
                  openModal={openModalDelete}
                  closeModal={() => setOpenModalDelete(false)}
                  actionDelete={() => deleteDoc()}
                />
              </div>
            )}
          </div>
          <div className="header-bkg">
            <img className="oiseau" src={require(`../../../../assets/images/oiseau.svg`)} />
            <img className="hexa" src={require(`../../../../assets/images/hexa-orange.svg`)} />
            <svg height="0px" width="0px">
              <defs>
                <clipPath
                  id="menumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01666666666)"
                >
                  <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </header>
        <div className="modal-body">
          <h2>{autorisation.titre}</h2>
          {(user.profil === 'FAMILLE' || user.profil === 'STRUCTURE') && (
            <Fragment>
              <div className="separator" />
              <div className="member">
                {intervenant && intervenant.photo ? (
                  <div className="member-avatar">
                    <div className="avatar-mask">
                      <img src={getProfilImgMin(intervenant.photo)} alt="photo" />
                    </div>
                    <AvatarMask nameFile="ModalAutorisation" />
                    <AvatarOutline nameFile="ModalAutorisation" />
                  </div>
                ) : (
                  <div className="member-avatar">
                    <span>
                      {intervenant.prenom[0]}
                      {intervenant.nom[0]}
                    </span>
                    <AvatarOutline nameFile="ModalAutorisation" bigger />
                  </div>
                )}
                <div className="member-infos">
                  <div className="member-name">
                    {intervenant && intervenant.prenom} {intervenant && intervenant.nom}
                  </div>
                  <div className="member-profil">
                    {intervenant && `${types_profils[intervenant.profil]}`}
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          <div className="separator" />
          <a
            className="attachment"
            target="_blank"
            href={`${API_URL}/uploads/${autorisation.file}`}
          >
            <i className="fas fa-file-pdf" />
            <span>{autorisation.file.substring(16)}</span>
          </a>
        </div>
        <div className="modal-footer">
          <div className="btn-group center">
            <button className="btn btn-primary" onClick={() => closeModal()}>
              Fermer
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

ModalAutorisation.propTypes = {
  user: PropTypes.object.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  createDocumentMedia: PropTypes.func.isRequired,
  deleteDocumentMedia: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  user: state.auth.user,
  intervenants: state.data.entities.intervenants || [],
  types_profils:
    state.data.entities.settings &&
    state.data.entities.settings['types_profils'] &&
    state.data.entities.settings['types_profils'].data
});

export default connect(mapStateToProps, {
  uploadFiles,
  removeFile,
  createDocumentMedia,
  deleteDocumentMedia
})(ModalAutorisation);
