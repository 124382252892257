import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Modal from 'react-modal';
import SplitFormUpdate from '../../forms/Stripe/SplitFormUpdate';
import { getSecretCard } from '../../../store/modules/stripe';
import { STRIPE_PUBLIC_KEY } from '../../../constants/Config';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const ModalCardUpdatePayment = ({
  customerId,
  isOpen,
  setModalSuccess,
  setOpenModalCardUpdatePayment,
  getSecretCard
}) => {
  const [secret, setSecret] = useState(null);
  useEffect(() => {
    getSecretCard(customerId).then(res => {
      const clientSecret = res.response.client_secret;
      setSecret(clientSecret);
    });
  }, []);
  const clientSecret = secret;
  return (
    <Fragment>
      <Modal
        className="modal-content full-modal formules"
        overlayClassName="modal-overlay"
        id="modal-CardFormPayment"
        isOpen={isOpen}
      >
        <header className="header header-normal header-big-title">
          <div className="header-container">
            <span className="btn" onClick={() => setOpenModalCardUpdatePayment(false)}>
              <i style={{ color: 'white' }} className="far fa-long-arrow-left" />
              <span>retour</span>
            </span>
            <div className="header-center">
              <div className="header-title">Mise à jour</div>
            </div>
          </div>
          <div className="header-bkg">
            <img className="oiseau" src={require(`../../../../assets/images/oiseau.svg`)} />
            <img className="hexa" src={require(`../../../../assets/images/hexa-orange.svg`)} />
            <svg height="0px" width="0px">
              <defs>
                <clipPath
                  id="menumask"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.00266666666,0.01666666666)"
                >
                  <path d="M0,32.5,8.2,43.7A39.6,39.6,0,0,0,42.5,59.9L375,42.4V0H0Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </header>
        <div className="modal-body">
          <div className="package-description">
            Renseignez vos nouvelles coordonnées bancaires pour enregistrer votre carte.
          </div>
          <Elements stripe={stripePromise}>
            <SplitFormUpdate
              customerId={customerId}
              clientSecret={clientSecret}
              setModalSuccess={v => setModalSuccess(v)}
              setOpenModalCardUpdatePayment={v => setOpenModalCardUpdatePayment(v)}
            />
          </Elements>
        </div>
      </Modal>
    </Fragment>
  );
};

ModalCardUpdatePayment.propTypes = {
  isOpen: PropTypes.bool,
  setModalSuccess: PropTypes.func.isRequired,
  setOpenModalCardUpdatePayment: PropTypes.func.isRequired
};

export default connect(null, { getSecretCard })(ModalCardUpdatePayment);
